// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as QueryString$Pos from "../../primitives/QueryString.bs.js";

var baseRoute = "/settings";

var userRoute = baseRoute + "/user";

var shopsRoute = baseRoute + "/shops";

var labelPrinterRoute = baseRoute + "/label-printer";

var billingAccountBaseRoute = baseRoute + "/billing-account";

function isEditPaymentMethod(value) {
  if (value) {
    return false;
  } else {
    return true;
  }
}

function isConfirmMandate(value) {
  if (value) {
    return true;
  } else {
    return false;
  }
}

function fromString(string) {
  switch (string) {
    case "confirm-mandate" :
        return /* ConfirmMandate */1;
    case "edit-payment-method" :
        return /* EditPaymentMethod */0;
    default:
      return ;
  }
}

function toString(value) {
  if (value) {
    return "confirm-mandate";
  } else {
    return "edit-payment-method";
  }
}

var BillingAccountShowPageModal = {
  isEditPaymentMethod: isEditPaymentMethod,
  isConfirmMandate: isConfirmMandate,
  fromString: fromString,
  toString: toString
};

function billingAccountShowRoute(shopId, param) {
  if (shopId !== undefined) {
    return billingAccountBaseRoute + "/" + shopId;
  } else {
    return billingAccountBaseRoute;
  }
}

function billingAccountShowRouteWithModal(shopId, modal) {
  return billingAccountShowRoute(shopId, undefined) + "/" + (
          modal ? "confirm-mandate" : "edit-payment-method"
        );
}

var billingAccountEditRoute = billingAccountBaseRoute + "/edit";

function billingAccountInvoicesRoute(shopId) {
  return billingAccountBaseRoute + "/" + shopId + "/invoices";
}

function encoder(param) {
  return [
          param.activeShopId,
          param.corporateName,
          param.shopName,
          param.email,
          param.phone,
          param.vatNumber,
          param.billingAddress,
          param.shippingAddress
        ];
}

function decoder(param) {
  return {
          TAG: /* Ok */0,
          _0: {
            activeShopId: param[0],
            corporateName: param[1],
            shopName: param[2],
            email: param[3],
            phone: param[4],
            vatNumber: param[5],
            billingAddress: param[6],
            shippingAddress: param[7]
          }
        };
}

var address = JsonCodec$Pos.object4((function (param) {
        return [
                param.address,
                param.postalCode,
                param.city,
                param.country
              ];
      }), (function (param) {
        return {
                TAG: /* Ok */0,
                _0: {
                  address: param[0],
                  postalCode: param[1],
                  city: param[2],
                  country: param[3]
                }
              };
      }), JsonCodec$Pos.field("address", JsonCodec$Pos.string), JsonCodec$Pos.field("postalCode", JsonCodec$Pos.string), JsonCodec$Pos.field("city", JsonCodec$Pos.string), JsonCodec$Pos.field("country", JsonCodec$Pos.string));

var value = JsonCodec$Pos.object8(encoder, decoder, JsonCodec$Pos.field("activeShopId", JsonCodec$Pos.string), JsonCodec$Pos.field("corporateName", JsonCodec$Pos.string), JsonCodec$Pos.field("shopName", JsonCodec$Pos.string), JsonCodec$Pos.field("email", JsonCodec$Pos.string), JsonCodec$Pos.field("phone", JsonCodec$Pos.string), JsonCodec$Pos.optional(JsonCodec$Pos.field("vatNumber", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("billingAddress", address)), JsonCodec$Pos.optional(JsonCodec$Pos.field("shippingAddress", address)));

var BillingAccountEditQueryStringCodecs = {
  encoder: encoder,
  decoder: decoder,
  address: address,
  value: value
};

function decodeEditBillingAccountQueryString(query) {
  var values = JsonCodec$Pos.decodeWith(QueryString$Pos.parse(query), value);
  if (values.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: values._0
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
}

function encodeEditBillingAccountQueryString(activeShopId, corporateName, shopName, email, phone, billingAddress, shippingAddress, vatNumber) {
  return QueryString$Pos.stringify(JsonCodec$Pos.encodeWith({
                  activeShopId: activeShopId,
                  corporateName: corporateName,
                  shopName: shopName,
                  email: email,
                  phone: phone,
                  vatNumber: vatNumber,
                  billingAddress: billingAddress,
                  shippingAddress: shippingAddress
                }, value));
}

export {
  baseRoute ,
  userRoute ,
  shopsRoute ,
  labelPrinterRoute ,
  billingAccountBaseRoute ,
  BillingAccountShowPageModal ,
  billingAccountShowRoute ,
  billingAccountShowRouteWithModal ,
  billingAccountEditRoute ,
  billingAccountInvoicesRoute ,
  BillingAccountEditQueryStringCodecs ,
  decodeEditBillingAccountQueryString ,
  encodeEditBillingAccountQueryString ,
}
/* address Not a pure module */
