// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Stack$Pos from "../layout-and-structure/Stack.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TextStyle$Pos from "../typography/TextStyle.bs.js";

function TableDateCell(Props) {
  var value = Props.value;
  var label = Props.label;
  return React.createElement(Stack$Pos.make, {
              children: null,
              space: "none"
            }, React.createElement(TextStyle$Pos.make, {
                  children: value !== undefined ? Intl$Pos.dateTimeFormat("short", undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.valFromOption(value)) : "-"
                }), label !== undefined ? React.createElement(TextStyle$Pos.make, {
                    children: "(" + label + ")",
                    variation: "normal",
                    size: "xxsmall"
                  }) : null);
}

var make = React.memo(TableDateCell);

export {
  make ,
}
/* make Not a pure module */
