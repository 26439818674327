// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as Separator$Pos from "../../resources/layout-and-structure/Separator.bs.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as BarControl$Pos from "../../resources/layout-and-structure/BarControl.bs.js";
import * as EmptyState$Pos from "../../resources/layout-and-structure/EmptyState.bs.js";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as DateHelpers$Pos from "../../helpers/DateHelpers.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as AmountTableCell$Pos from "../../modules/AmountTableCell.bs.js";
import * as LegacyPagination$Pos from "../../resources/navigation/LegacyPagination.bs.js";
import * as LegacyResourceList$Pos from "../../core/LegacyResourceList.bs.js";
import * as SelectDateRangeFilter$Pos from "../../resources/selection-and-input/SelectDateRangeFilter.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query AnalyticsShopsPageQuery($filterBy: ShopKeyPerformanceIndicatorsQueryFilter)  {\nshopsKeyPerformanceIndicators(first: 50, filterBy: $filterBy)  {\n__typename  \npageInfo  {\n__typename  \nstartCursor  \nendCursor  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nshop  {\n__typename  \nid  \nname  \n}\n\nticketsRevenue  {\n__typename  \nincludingTaxes  \nexcludingTaxes  \n}\n\ninvoicesRevenue  {\n__typename  \nincludingTaxes  \nexcludingTaxes  \n}\n\nglobalRevenue  {\n__typename  \nincludingTaxes  \nexcludingTaxes  \n}\n\nsalesCount  \nsalesIndex  \nshoppingCartAverage  \ntotalAmountOfTaxes  \nproductsSoldCount  \ntotalPurchaseCost  \nmargin  \nmarginRate  \nmarkupRate  \n}\n\n}\n\ntotalCount  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.shopsKeyPerformanceIndicators;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.startCursor;
  var value$4 = value$2.endCursor;
  var value$5 = value$1.edges;
  return {
          shopsKeyPerformanceIndicators: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              startCursor: !(value$3 == null) ? value$3 : undefined,
              endCursor: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.shop;
                    var value$3 = value$1.ticketsRevenue;
                    var value$4 = value$1.invoicesRevenue;
                    var value$5 = value$1.globalRevenue;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              shop: {
                                __typename: value$2.__typename,
                                id: value$2.id,
                                name: value$2.name
                              },
                              ticketsRevenue: {
                                __typename: value$3.__typename,
                                includingTaxes: value$3.includingTaxes,
                                excludingTaxes: value$3.excludingTaxes
                              },
                              invoicesRevenue: {
                                __typename: value$4.__typename,
                                includingTaxes: value$4.includingTaxes,
                                excludingTaxes: value$4.excludingTaxes
                              },
                              globalRevenue: {
                                __typename: value$5.__typename,
                                includingTaxes: value$5.includingTaxes,
                                excludingTaxes: value$5.excludingTaxes
                              },
                              salesCount: value$1.salesCount,
                              salesIndex: value$1.salesIndex,
                              shoppingCartAverage: value$1.shoppingCartAverage,
                              totalAmountOfTaxes: value$1.totalAmountOfTaxes,
                              productsSoldCount: value$1.productsSoldCount,
                              totalPurchaseCost: value$1.totalPurchaseCost,
                              margin: value$1.margin,
                              marginRate: value$1.marginRate,
                              markupRate: value$1.markupRate
                            }
                          };
                  }), value$5),
            totalCount: value$1.totalCount
          }
        };
}

function serialize(value) {
  var value$1 = value.shopsKeyPerformanceIndicators;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.markupRate;
          var value$3 = value$1.marginRate;
          var value$4 = value$1.margin;
          var value$5 = value$1.totalPurchaseCost;
          var value$6 = value$1.productsSoldCount;
          var value$7 = value$1.totalAmountOfTaxes;
          var value$8 = value$1.shoppingCartAverage;
          var value$9 = value$1.salesIndex;
          var value$10 = value$1.salesCount;
          var value$11 = value$1.globalRevenue;
          var value$12 = value$11.excludingTaxes;
          var value$13 = value$11.includingTaxes;
          var value$14 = value$11.__typename;
          var globalRevenue = {
            __typename: value$14,
            includingTaxes: value$13,
            excludingTaxes: value$12
          };
          var value$15 = value$1.invoicesRevenue;
          var value$16 = value$15.excludingTaxes;
          var value$17 = value$15.includingTaxes;
          var value$18 = value$15.__typename;
          var invoicesRevenue = {
            __typename: value$18,
            includingTaxes: value$17,
            excludingTaxes: value$16
          };
          var value$19 = value$1.ticketsRevenue;
          var value$20 = value$19.excludingTaxes;
          var value$21 = value$19.includingTaxes;
          var value$22 = value$19.__typename;
          var ticketsRevenue = {
            __typename: value$22,
            includingTaxes: value$21,
            excludingTaxes: value$20
          };
          var value$23 = value$1.shop;
          var value$24 = value$23.name;
          var value$25 = value$23.id;
          var value$26 = value$23.__typename;
          var shop = {
            __typename: value$26,
            id: value$25,
            name: value$24
          };
          var value$27 = value$1.__typename;
          var node = {
            __typename: value$27,
            shop: shop,
            ticketsRevenue: ticketsRevenue,
            invoicesRevenue: invoicesRevenue,
            globalRevenue: globalRevenue,
            salesCount: value$10,
            salesIndex: value$9,
            shoppingCartAverage: value$8,
            totalAmountOfTaxes: value$7,
            productsSoldCount: value$6,
            totalPurchaseCost: value$5,
            margin: value$4,
            marginRate: value$3,
            markupRate: value$2
          };
          var value$28 = value.__typename;
          return {
                  __typename: value$28,
                  node: node
                };
        }), value$3);
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.startCursor;
  var startCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    startCursor: startCursor,
    endCursor: endCursor
  };
  var value$8 = value$1.__typename;
  var shopsKeyPerformanceIndicators = {
    __typename: value$8,
    pageInfo: pageInfo,
    edges: edges,
    totalCount: value$2
  };
  return {
          shopsKeyPerformanceIndicators: shopsKeyPerformanceIndicators
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectShopKeyPerformanceIndicatorsQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.startDate;
  var a$2 = inp.endDate;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          startDate: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          endDate: a$2 !== undefined ? Caml_option.valFromOption(a$2) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectShopKeyPerformanceIndicatorsQueryFilter(a) : undefined
        };
}

function makeVariables(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectShopKeyPerformanceIndicatorsQueryFilter(shopIds, startDate, endDate, param) {
  return {
          shopIds: shopIds,
          startDate: startDate,
          endDate: endDate
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined
        };
}

var AnalyticsShopsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectShopKeyPerformanceIndicatorsQueryFilter: serializeInputObjectShopKeyPerformanceIndicatorsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectShopKeyPerformanceIndicatorsQueryFilter: makeInputObjectShopKeyPerformanceIndicatorsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var AnalyticsShopsQuery_refetchQueryDescription = include.refetchQueryDescription;

var AnalyticsShopsQuery_useLazy = include.useLazy;

var AnalyticsShopsQuery_useLazyWithVariables = include.useLazyWithVariables;

var AnalyticsShopsQuery = {
  AnalyticsShopsQuery_inner: AnalyticsShopsQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectShopKeyPerformanceIndicatorsQueryFilter: serializeInputObjectShopKeyPerformanceIndicatorsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectShopKeyPerformanceIndicatorsQueryFilter: makeInputObjectShopKeyPerformanceIndicatorsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: AnalyticsShopsQuery_refetchQueryDescription,
  use: use,
  useLazy: AnalyticsShopsQuery_useLazy,
  useLazyWithVariables: AnalyticsShopsQuery_useLazyWithVariables
};

function jsonCodec(shops) {
  return JsonCodec$Pos.object2((function (param) {
                return [
                        Belt_Option.map(param.shop, (function (shop) {
                                return shop.id;
                              })),
                        Belt_Option.map(param.dateRange, (function (param) {
                                return [
                                        param[0].valueOf(),
                                        param[1].valueOf()
                                      ];
                              }))
                      ];
              }), (function (param) {
                var shopId = param[0];
                return {
                        TAG: /* Ok */0,
                        _0: {
                          shop: Belt_Array.getBy(shops, (function (shop) {
                                  return shop.id === shopId;
                                })),
                          dateRange: Belt_Option.flatMap(param[1], (function (range) {
                                  if (range.length !== 2) {
                                    return ;
                                  }
                                  var start = range[0];
                                  var end = range[1];
                                  return [
                                          new Date(start),
                                          new Date(end)
                                        ];
                                }))
                        }
                      };
              }), JsonCodec$Pos.optional(JsonCodec$Pos.field("shopId", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("dateRange", JsonCodec$Pos.array(JsonCodec$Pos.$$float))));
}

var AnalyticsShopsFilters = {
  jsonCodec: jsonCodec
};

function totalPages(rows) {
  return Math.ceil(rows.length / 50) | 0;
}

function paginate(rows, currentPage) {
  return Belt_Array.slice(rows, Math.imul(currentPage - 1 | 0, 50), 50);
}

function getPage(paginateAction, prevPage, totalPages) {
  switch (paginateAction) {
    case /* First */0 :
        return 1;
    case /* Prev */1 :
        if (prevPage !== 1) {
          return prevPage - 1 | 0;
        } else {
          return 1;
        }
    case /* Next */2 :
        if (prevPage >= totalPages) {
          return prevPage;
        } else {
          return prevPage + 1 | 0;
        }
    case /* Last */3 :
        return totalPages;
    
  }
}

function keyExtractor(param) {
  return param.shopId;
}

var AnalyticsShopsTableRow = {
  rowsPerPage: 50,
  totalPages: totalPages,
  paginate: paginate,
  getPage: getPage,
  keyExtractor: keyExtractor
};

function analyticsShopsTableRowsFromQueryResult(queryResult) {
  return Belt_Array.map(queryResult.shopsKeyPerformanceIndicators.edges, (function (param) {
                var node = param.node;
                return {
                        shopId: node.shop.id,
                        shopName: node.shop.name,
                        ticketsRevenueIncludingTaxes: node.ticketsRevenue.includingTaxes,
                        ticketsRevenueExcludingTaxes: node.ticketsRevenue.excludingTaxes,
                        invoicesRevenueIncludingTaxes: node.invoicesRevenue.includingTaxes,
                        invoicesRevenueExcludingTaxes: node.invoicesRevenue.excludingTaxes,
                        globalRevenueIncludingTaxes: node.globalRevenue.includingTaxes,
                        globalRevenueExcludingTaxes: node.globalRevenue.excludingTaxes,
                        salesCount: node.salesCount,
                        margin: node.margin,
                        marginRate: node.marginRate,
                        markupRate: node.markupRate,
                        salesIndex: node.salesIndex,
                        shoppingCartAverage: node.shoppingCartAverage,
                        productsSoldCount: node.productsSoldCount,
                        totalAmountOfTaxes: node.totalAmountOfTaxes,
                        totalPurchaseCost: node.totalPurchaseCost,
                        currency: "EUR"
                      };
              }));
}

function analyticsShopsVariablesFilterBy(param) {
  var dateRange = param.dateRange;
  var shop = param.shop;
  return {
          shopIds: shop !== undefined ? ({
                _in: [shop.id]
              }) : undefined,
          startDate: Belt_Option.map(dateRange, (function (param) {
                  return Scalar$Pos.Datetime.serialize(param[0]);
                })),
          endDate: Belt_Option.map(dateRange, (function (param) {
                  return Scalar$Pos.Datetime.serialize(param[1]);
                }))
        };
}

var columns = [
  {
    name: Intl$Pos.t("Shop"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 160
      },
      width: {
        NAME: "pct",
        VAL: 20
      },
      margin: "small",
      sticky: true
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: param.shopName
                  });
      })
  },
  {
    name: Intl$Pos.t("Revenue excl. VAT"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 110
      },
      width: {
        NAME: "fr",
        VAL: 2
      },
      sticky: true
    },
    render: (function (param) {
        return React.createElement(AmountTableCell$Pos.make, {
                    value: param.globalRevenueExcludingTaxes,
                    secondaryValue: param.globalRevenueIncludingTaxes
                  });
      })
  },
  {
    name: Intl$Pos.t("Margin"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 110
      },
      width: {
        NAME: "fr",
        VAL: 1.5
      }
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: Intl$Pos.currencyFormat(param.currency, undefined, undefined, param.margin),
                    weight: "semibold"
                  });
      })
  },
  {
    name: Intl$Pos.t("Margin/Markup rt"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 100
      },
      width: {
        NAME: "fr",
        VAL: 2.5
      }
    },
    render: (function (param) {
        var formattedMarginRate = Intl$Pos.percentFormat(2, 2, param.marginRate / 100);
        var formattedMarkupRate = Intl$Pos.percentFormat(2, 2, param.markupRate / 100);
        return React.createElement(Stack$Pos.make, {
                    children: null,
                    space: "xxsmall"
                  }, React.createElement(TextStyle$Pos.make, {
                        children: formattedMarginRate
                      }), React.createElement(TextStyle$Pos.make, {
                        children: formattedMarkupRate,
                        variation: "normal",
                        size: "xxsmall"
                      }));
      })
  },
  {
    name: Intl$Pos.t("Cost of sales"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 110
      },
      width: {
        NAME: "fr",
        VAL: 2
      },
      margin: "small",
      alignX: "flexEnd"
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: Intl$Pos.currencyFormat(param.currency, undefined, undefined, param.totalPurchaseCost)
                  });
      })
  },
  {
    name: Intl$Pos.t("Avg. basket"),
    layout: {
      margin: "small",
      alignX: "flexEnd"
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: Intl$Pos.currencyFormat(param.currency, undefined, undefined, param.shoppingCartAverage)
                  });
      })
  },
  {
    name: Intl$Pos.t("Sales"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 70
      },
      width: {
        NAME: "px",
        VAL: 120
      },
      alignX: "center"
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: param.salesCount
                  });
      })
  },
  {
    name: Intl$Pos.t("Sales index"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 70
      },
      width: {
        NAME: "px",
        VAL: 130
      },
      alignX: "center"
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: Intl$Pos.decimalFormat(undefined, undefined, param.salesIndex)
                  });
      })
  },
  {
    name: Intl$Pos.t("Products sold"),
    layout: {
      minWidth: {
        NAME: "px",
        VAL: 70
      },
      width: {
        NAME: "px",
        VAL: 130
      },
      alignX: "center"
    },
    render: (function (param) {
        return React.createElement(TextStyle$Pos.make, {
                    children: param.productsSoldCount
                  });
      })
  }
];

function analyticsShopsQueryVariables(param) {
  return {
          filterBy: analyticsShopsVariablesFilterBy(param.filters)
        };
}

function AnalyticsShopsPage(Props) {
  var shops = Auth$Pos.useShops(undefined);
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var match = React.useState(function () {
        return 1;
      });
  var setCurrentPage = match[1];
  var currentPage = match[0];
  var now = new Date();
  var initialFilters_dateRange = [
    DateHelpers$Pos.startOfDay(now),
    DateHelpers$Pos.endOfDay(now)
  ];
  var initialFilters = {
    shop: activeShop,
    dateRange: initialFilters_dateRange
  };
  var initialState = LegacyResourceList$Pos.initialState(initialFilters);
  var filtersJsonCodec = jsonCodec(shops);
  var resourceListPropState = LegacyResourceList$Pos.use(initialState, filtersJsonCodec);
  var dispatch = resourceListPropState[1];
  var state = resourceListPropState[0];
  var queryAsyncResult = ApolloHelpers$Pos.queryResultToAsyncResult(Curry.app(use, [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            analyticsShopsQueryVariables(state)
          ]));
  var tableColumns = [
    {
      key: "shop",
      name: Intl$Pos.t("Shop"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 160
        },
        width: {
          NAME: "pct",
          VAL: 20
        },
        margin: "small",
        sticky: true
      },
      render: (function (param) {
          return React.createElement(TextStyle$Pos.make, {
                      children: param.data.shopName
                    });
        })
    },
    {
      key: "revenues",
      name: Intl$Pos.t("Revenue excl. VAT"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        },
        width: {
          NAME: "fr",
          VAL: 2
        },
        sticky: true
      },
      render: (function (param) {
          var match = param.data;
          return React.createElement(AmountTableCell$Pos.make, {
                      value: match.globalRevenueExcludingTaxes,
                      secondaryValue: match.globalRevenueIncludingTaxes
                    });
        })
    },
    {
      key: "margin",
      name: Intl$Pos.t("Margin"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        },
        width: {
          NAME: "fr",
          VAL: 1.5
        }
      },
      render: (function (param) {
          var match = param.data;
          return React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.currencyFormat(match.currency, undefined, undefined, match.margin),
                      weight: "semibold"
                    });
        })
    },
    {
      key: "margin-markup-rates",
      name: Intl$Pos.t("Margin/Markup rt"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 100
        },
        width: {
          NAME: "fr",
          VAL: 2.5
        }
      },
      render: (function (param) {
          var match = param.data;
          var formattedMarginRate = Intl$Pos.percentFormat(2, 2, match.marginRate / 100);
          var formattedMarkupRate = Intl$Pos.percentFormat(2, 2, match.markupRate / 100);
          return React.createElement(Stack$Pos.make, {
                      children: null,
                      space: "xxsmall"
                    }, React.createElement(TextStyle$Pos.make, {
                          children: formattedMarginRate
                        }), React.createElement(TextStyle$Pos.make, {
                          children: formattedMarkupRate,
                          variation: "normal",
                          size: "tiny"
                        }));
        })
    },
    {
      key: "cost-of-sales",
      name: Intl$Pos.t("Cost of sales"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 110
        },
        width: {
          NAME: "fr",
          VAL: 2
        },
        margin: "small",
        alignX: "flexEnd"
      },
      render: (function (param) {
          var match = param.data;
          return React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.currencyFormat(match.currency, undefined, undefined, match.totalPurchaseCost)
                    });
        })
    },
    {
      key: "avg-basket",
      name: Intl$Pos.t("Avg. basket"),
      layout: {
        margin: "small",
        alignX: "flexEnd"
      },
      render: (function (param) {
          var match = param.data;
          return React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.currencyFormat(match.currency, undefined, undefined, match.shoppingCartAverage)
                    });
        })
    },
    {
      key: "sales",
      name: Intl$Pos.t("Sales"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 70
        },
        width: {
          NAME: "px",
          VAL: 120
        },
        alignX: "center"
      },
      render: (function (param) {
          return React.createElement(TextStyle$Pos.make, {
                      children: param.data.salesCount
                    });
        })
    },
    {
      key: "sales-index",
      name: Intl$Pos.t("Sales index"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 70
        },
        width: {
          NAME: "px",
          VAL: 130
        },
        alignX: "center"
      },
      render: (function (param) {
          return React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.decimalFormat(undefined, undefined, param.data.salesIndex)
                    });
        })
    },
    {
      key: "products-sold",
      name: Intl$Pos.t("Products sold"),
      layout: {
        minWidth: {
          NAME: "px",
          VAL: 70
        },
        width: {
          NAME: "px",
          VAL: 130
        },
        alignX: "center"
      },
      render: (function (param) {
          return React.createElement(TextStyle$Pos.make, {
                      children: param.data.productsSoldCount
                    });
        })
    }
  ];
  var tmp = {
    onChange: (function (shop) {
        Curry._1(dispatch, {
              TAG: /* FiltersUpdated */2,
              _0: (function (prev) {
                  return {
                          shop: shop,
                          dateRange: prev.dateRange
                        };
                })
            });
      })
  };
  if (state.filters.shop !== undefined) {
    tmp.value = Caml_option.valFromOption(state.filters.shop);
  }
  var tmp$1 = {
    placeholder: Intl$Pos.t("Select a period"),
    disabledResetButton: true,
    onChange: (function (dateRange) {
        Curry._1(dispatch, {
              TAG: /* FiltersUpdated */2,
              _0: (function (prev) {
                  return {
                          shop: prev.shop,
                          dateRange: dateRange
                        };
                })
            });
      }),
    triggerLabelDisplay: "showPreset"
  };
  if (state.filters.dateRange !== undefined) {
    tmp$1.value = Caml_option.valFromOption(state.filters.dateRange);
  }
  var filters = React.createElement(Inline$Pos.make, {
        children: null,
        space: "small"
      }, React.createElement(Auth$Pos.SelectShopFilter.make, tmp), React.createElement(Separator$Pos.make, {}), React.createElement(SelectDateRangeFilter$Pos.make, tmp$1));
  var tableRows = AsyncResult$Pos.mapError(AsyncResult$Pos.mapOk(queryAsyncResult, analyticsShopsTableRowsFromQueryResult), (function (param) {
          
        }));
  var paginatedTableRows = AsyncResult$Pos.mapOk(tableRows, (function (rows) {
          return paginate(rows, currentPage);
        }));
  var totalPages$1;
  if (typeof tableRows === "number") {
    totalPages$1 = 1;
  } else if (tableRows.TAG === /* Reloading */0) {
    var rows = tableRows._0;
    totalPages$1 = rows.TAG === /* Ok */0 ? totalPages(rows._0) : 1;
  } else {
    var rows$1 = tableRows._0;
    totalPages$1 = rows$1.TAG === /* Ok */0 ? totalPages(rows$1._0) : 1;
  }
  var onRequestPaginate = function (paginateAction) {
    Curry._1(setCurrentPage, (function (prevPage) {
            return getPage(paginateAction, prevPage, totalPages$1);
          }));
  };
  return React.createElement(Page$Pos.make, {
              children: null,
              variation: "compact",
              title: Intl$Pos.t("Performances per shop")
            }, React.createElement(BarControl$Pos.make, {
                  filters: filters
                }), React.createElement(TableView$Pos.make, {
                  data: paginatedTableRows,
                  columns: tableColumns,
                  keyExtractor: keyExtractor,
                  hideReloadingPlaceholder: true,
                  placeholderEmptyState: EmptyState$Pos.error
                }), React.createElement(LegacyPagination$Pos.make, {
                  currentPage: currentPage,
                  totalPages: totalPages$1,
                  onRequestPaginate: onRequestPaginate
                }));
}

var make = React.memo(AnalyticsShopsPage);

export {
  AnalyticsShopsQuery ,
  AnalyticsShopsFilters ,
  AnalyticsShopsTableRow ,
  analyticsShopsTableRowsFromQueryResult ,
  analyticsShopsVariablesFilterBy ,
  columns ,
  analyticsShopsQueryVariables ,
  make ,
}
/* query Not a pure module */
