// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as RoundButton$Pos from "../../resources/actions/RoundButton.bs.js";
import * as TooltipIcon$Pos from "../../resources/overlays/TooltipIcon.bs.js";
import * as PromotionEditDiscountedProduct$Pos from "./PromotionEditDiscountedProduct.bs.js";
import * as LegacyProductReferenceTableCell$Pos from "../Product/LegacyProductReferenceTableCell.bs.js";
import * as PromotionEditDiscountedProductDiscountTableCell$Pos from "./PromotionEditDiscountedProductDiscountTableCell.bs.js";

function getRetailPriceValue(retailPrices, selectedPriceName) {
  return Belt_Option.flatMap(retailPrices, (function (prices) {
                return Belt_Option.map(Belt_Array.getBy(prices, (function (price) {
                                  return price.name === selectedPriceName;
                                })), (function (price) {
                              return price.value;
                            }));
              }));
}

function keyExtractor(row) {
  return row.cku;
}

var Row = {
  keyExtractor: keyExtractor
};

function tableColumns(editable, selectedPriceName, allowRowReplication, onRequestDispatch) {
  return [
          {
            key: "reference",
            name: Intl$Pos.t("Product and description"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 250
              },
              width: {
                NAME: "pct",
                VAL: 40
              },
              margin: "large"
            },
            render: (function (param) {
                var match = param.data;
                var tmp = {
                  cku: match.cku,
                  name: match.name,
                  description: match.description,
                  openNewTab: true
                };
                if (param.errorMessage !== undefined) {
                  tmp.errorMessage = Caml_option.valFromOption(param.errorMessage);
                }
                return React.createElement(LegacyProductReferenceTableCell$Pos.make, tmp);
              })
          },
          {
            key: "purchase-price",
            name: Intl$Pos.t("Purchase price") + " " + Intl$Pos.t("VAT excl."),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                var match = param.data;
                return React.createElement(TextStyle$Pos.make, {
                            children: Intl$Pos.currencyFormat("EUR", 3, 3, match.purchasePrice) + Belt_Option.mapWithDefault(match.bulkUnit, "", (function (unit) {
                                    return "/" + unit + "";
                                  }))
                          });
              })
          },
          {
            key: "retail-price",
            name: Intl$Pos.t("Retail price") + " " + Intl$Pos.t("VAT incl."),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                var match = param.data;
                var name = match.name;
                var cku = match.cku;
                if (selectedPriceName === undefined) {
                  return React.createElement(TooltipIcon$Pos.make, {
                              children: Intl$Pos.t("Please select the price list first to see the retail price."),
                              variation: "info",
                              key: cku + name
                            });
                }
                var retailPriceValue = getRetailPriceValue(match.retailPrices, selectedPriceName);
                if (retailPriceValue !== undefined) {
                  return React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.currencyFormat("EUR", undefined, undefined, retailPriceValue) + Belt_Option.mapWithDefault(match.bulkUnit, "", (function (unit) {
                                      return "/" + unit + "";
                                    })),
                              key: cku + name
                            });
                } else {
                  return React.createElement(TooltipIcon$Pos.make, {
                              children: Intl$Pos.t("One of the promotional campaign linked shops does not have a retail price entered for that product on the selected price list."),
                              variation: "info",
                              key: cku + name
                            });
                }
              })
          },
          {
            key: "discount",
            name: Intl$Pos.t("Discount"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                var match = param.data;
                var cku = match.cku;
                var onRequestUpdate = function (current) {
                  Curry._1(onRequestDispatch, {
                        TAG: /* ProductDiscountUpdated */3,
                        _0: cku,
                        _1: current.amount,
                        _2: current.kind
                      });
                };
                var onRequestReplicate = function (param) {
                  Curry._1(onRequestDispatch, {
                        TAG: /* ProductDiscountReplicated */4,
                        _0: cku
                      });
                };
                var tmp = {
                  editable: editable,
                  disabled: Belt_Option.isNone(selectedPriceName),
                  discount: match.discount,
                  bulkUnit: match.bulkUnit,
                  onRequestUpdate: onRequestUpdate
                };
                var tmp$1 = allowRowReplication ? onRequestReplicate : undefined;
                if (tmp$1 !== undefined) {
                  tmp.onRequestReplicate = Caml_option.valFromOption(tmp$1);
                }
                return React.createElement(PromotionEditDiscountedProductDiscountTableCell$Pos.make, tmp);
              })
          },
          {
            key: "discounted-price",
            name: Intl$Pos.t("Discounted price") + " " + Intl$Pos.t("VAT incl."),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 120
              }
            },
            render: (function (param) {
                var match = param.data;
                var match$1 = match.discount;
                var kind = match$1.kind;
                var amount = match$1.amount;
                var retailPriceValue = getRetailPriceValue(match.retailPrices, selectedPriceName);
                var discountedPrice = Belt_Option.map(retailPriceValue, (function (value) {
                        return PromotionEditDiscountedProduct$Pos.ProductPrice.makeDiscounted(value, amount, kind);
                      }));
                return React.createElement(TextStyle$Pos.make, {
                            children: Intl$Pos.currencyFormat("EUR", undefined, undefined, Belt_Option.getWithDefault(discountedPrice, 0)) + Belt_Option.mapWithDefault(match.bulkUnit, "", (function (unit) {
                                    return "/" + unit + "";
                                  })),
                            variation: retailPriceValue !== undefined && retailPriceValue !== discountedPrice ? (
                                Caml_obj.lessthan(discountedPrice, 0) ? "negative" : (
                                    retailPriceValue !== 0 ? "neutral" : "normal"
                                  )
                              ) : "normal"
                          });
              })
          },
          {
            key: "actions",
            layout: {
              width: {
                NAME: "fr",
                VAL: 0
              },
              alignX: "flexEnd"
            },
            render: (function (param) {
                if (!editable) {
                  return null;
                }
                var cku = param.data.cku;
                return React.createElement(RoundButton$Pos.make, RoundButton$Pos.makeProps(undefined, "delete_light", undefined, undefined, undefined, (function (param) {
                                  Curry._1(onRequestDispatch, {
                                        TAG: /* ProductRemoved */2,
                                        _0: cku
                                      });
                                }), undefined, undefined, undefined));
              })
          }
        ];
}

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9, prim10, prim11, prim12, prim13, prim14, prim15, prim16, prim17, prim18, prim19, prim20, prim21, prim22, prim23, prim24, prim25, prim26, prim27) {
  var tmp = {
    data: prim0,
    columns: prim1,
    keyExtractor: prim2
  };
  if (prim3 !== undefined) {
    tmp.disabledRowsKeys = Caml_option.valFromOption(prim3);
  }
  if (prim4 !== undefined) {
    tmp.erroredRowsMap = Caml_option.valFromOption(prim4);
  }
  if (prim5 !== undefined) {
    tmp.selectionEnabled = prim5;
  }
  if (prim6 !== undefined) {
    tmp.selectAllEnabled = prim6;
  }
  if (prim7 !== undefined) {
    tmp.initialAllSelected = prim7;
  }
  if (prim8 !== undefined) {
    tmp.hideCard = prim8;
  }
  if (prim9 !== undefined) {
    tmp.hideReloadingPlaceholder = prim9;
  }
  if (prim10 !== undefined) {
    tmp.maxWidth = prim10;
  }
  if (prim11 !== undefined) {
    tmp.minHeight = prim11;
  }
  if (prim12 !== undefined) {
    tmp.maxHeight = prim12;
  }
  if (prim13 !== undefined) {
    tmp.compactRows = prim13;
  }
  if (prim14 !== undefined) {
    tmp.placeholderEmptyState = Caml_option.valFromOption(prim14);
  }
  if (prim15 !== undefined) {
    tmp.searchBar = Caml_option.valFromOption(prim15);
  }
  if (prim16 !== undefined) {
    tmp.searchPlaceholder = prim16;
  }
  if (prim17 !== undefined) {
    tmp.filters = Caml_option.valFromOption(prim17);
  }
  if (prim18 !== undefined) {
    tmp.typesDrop = Caml_option.valFromOption(prim18);
  }
  if (prim19 !== undefined) {
    tmp.sortDescriptor = Caml_option.valFromOption(prim19);
  }
  if (prim20 !== undefined) {
    tmp.onSortChange = Caml_option.valFromOption(prim20);
  }
  if (prim21 !== undefined) {
    tmp.onSearchQueryChange = Caml_option.valFromOption(prim21);
  }
  if (prim22 !== undefined) {
    tmp.onLoadMore = Caml_option.valFromOption(prim22);
  }
  if (prim23 !== undefined) {
    tmp.onSelectChange = Caml_option.valFromOption(prim23);
  }
  if (prim24 !== undefined) {
    tmp.onSuccessDrop = Caml_option.valFromOption(prim24);
  }
  if (prim25 !== undefined) {
    tmp.onErrorDrop = Caml_option.valFromOption(prim25);
  }
  if (prim26 !== undefined) {
    tmp.key = prim26;
  }
  return tmp;
}

var make = TableView$Pos.make;

export {
  getRetailPriceValue ,
  Row ,
  tableColumns ,
  make ,
  makeProps ,
}
/* react Not a pure module */
