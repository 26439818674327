// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as SupplierRoutes$Pos from "./SupplierRoutes.bs.js";
import * as SupplierNewPage$Pos from "./SupplierNewPage.bs.js";
import * as SupplierEditPage$Pos from "./SupplierEditPage.bs.js";
import * as SupplierListPage$Pos from "./SupplierListPage.bs.js";
import * as SupplierShowPage$Pos from "./SupplierShowPage.bs.js";
import * as SupplierOrderListPage$Pos from "./SupplierOrderListPage.bs.js";
import * as SupplierEditContactPage$Pos from "./SupplierEditContactPage.bs.js";
import * as SupplierEditLocationPage$Pos from "./SupplierEditLocationPage.bs.js";

function SupplierRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  var url = Navigation$Pos.useUrl(undefined);
  if (!subUrlPath) {
    return React.createElement(SupplierListPage$Pos.make, {
                supplierBaseRoute: SupplierRoutes$Pos.baseRoute,
                supplierNewRoute: SupplierRoutes$Pos.newRoute(undefined, undefined),
                supplierShowRoute: SupplierRoutes$Pos.showRoute
              });
  }
  var id = subUrlPath.hd;
  var exit = 0;
  if (id === "new" && !subUrlPath.tl) {
    var shopId = SupplierRoutes$Pos.decodeCreateSupplierQueryString(url.query);
    var tmp = {
      supplierShowRoute: SupplierRoutes$Pos.showRoute
    };
    if (shopId !== undefined) {
      tmp.shopId = Caml_option.valFromOption(shopId);
    }
    return React.createElement(SupplierNewPage$Pos.make, tmp);
  }
  exit = 2;
  if (exit === 2) {
    var match = subUrlPath.tl;
    if (!match) {
      return React.createElement(SupplierShowPage$Pos.make, {
                  id: id,
                  supplierEditRoute: SupplierRoutes$Pos.editRoute,
                  supplierEditLocationRoute: SupplierRoutes$Pos.editLocationRoute,
                  supplierEditContactRoute: SupplierRoutes$Pos.editContactRoute,
                  supplierBaseRoute: SupplierRoutes$Pos.baseRoute
                });
    }
    switch (match.hd) {
      case "edit" :
          var match$1 = match.tl;
          if (!match$1) {
            return React.createElement(SupplierEditPage$Pos.make, {
                        id: id,
                        supplierShowRoute: SupplierRoutes$Pos.showRoute
                      });
          }
          switch (match$1.hd) {
            case "contact" :
                if (!match$1.tl) {
                  return React.createElement(SupplierEditContactPage$Pos.make, {
                              id: id,
                              supplierShowRoute: SupplierRoutes$Pos.showRoute
                            });
                }
                break;
            case "location" :
                if (!match$1.tl) {
                  return React.createElement(SupplierEditLocationPage$Pos.make, {
                              id: id,
                              supplierShowRoute: SupplierRoutes$Pos.showRoute
                            });
                }
                break;
            default:
              
          }
          break;
      case "orders" :
          if (!match.tl) {
            return React.createElement(SupplierOrderListPage$Pos.make, {
                        id: id
                      });
          }
          break;
      default:
        
    }
  }
  return React.createElement(Navigation$Pos.Redirect.make, {
              route: SupplierRoutes$Pos.baseRoute
            });
}

var make = SupplierRouter;

export {
  make ,
}
/* react Not a pure module */
