import React from 'react'
import ReactDOM from 'react-dom'

import logrocket from 'logrocket'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import posthog from 'posthog-js'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
// NOTE - Should rely on Intl.locale for internationalization
import 'moment/dist/locale/fr'
import './stylex.css'

import { make as Root } from './Root.gen'

import {
  sentryDsn,
  logrocketAppID,
  intercomAppID,
  context,
  postHogAppKey,
} from './core/env'
import { installHelpCenter } from './core/helpCenter'

const contextValue = context()

const consoleError = console.error
const SUPPRESSED_ERRORS = ['%s cannot appear as a child of <%s>']

console.error =
  contextValue !== 'production'
    ? function (message, ...args) {
        if (!SUPPRESSED_ERRORS.some((error) => message.includes(error))) {
          consoleError(message, ...args)
        }
      }
    : console.error

// Polyfill for InputNumberField.res necessary for Safari browser < v13.0
// see: https://github.com/dm4t2/vue-currency-input/issues/15#issuecomment-618053196
if (!Intl.NumberFormat.prototype.formatToParts) {
  Intl.NumberFormat = require('intl').NumberFormat
  require('intl/locale-data/jsonp/en-US.js')
  require('intl/locale-data/jsonp/fr-FR.js')
}

if (contextValue === 'production') {
  const pathsToIgnore = ['sign-in', 'sign-up', 'password-reset']

  logrocket.init(logrocketAppID(), {
    network: {
      requestSanitizer: (request) => {
        request.headers['authorization'] = undefined

        if (pathsToIgnore.some((path) => request.url.includes(path))) {
          return null
        }
        return request
      },
    },
  })

  posthog.init(postHogAppKey(), {
    api_host: 'https://eu.i.posthog.com',
    // NOTE — Ensures person profiles are created only for identified users,
    // avoiding unnecessary profiles for anonymous users.
    person_profiles: 'identified_only',
    // NOTE — Excludes authentication-related pages from autocapture
    // to prevent logging unnecessary events.
    autocapture: { url_ignorelist: ['.*/auth/.*'] },
  })
}

installHelpCenter({ appId: intercomAppID() })

const sentryDsnValue = sentryDsn()

if (
  sentryDsnValue &&
  typeof sentryDsnValue === 'string' &&
  sentryDsnValue.length > 0 &&
  (contextValue === 'staging' || contextValue === 'production')
) {
  Sentry.init({
    dsn: sentryDsnValue,
    environment: contextValue,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

const node = document.getElementById('root')

if (node) {
  ReactDOM.render(<Root />, node)
}
