// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Nav$Pos from "../../resources/navigation/Nav.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Title$Pos from "../../resources/typography/Title.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Spinner$Pos from "../../resources/feedback-indicators/Spinner.bs.js";
import * as Tooltip$Pos from "../../resources/overlays/Tooltip.bs.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as OrderEdit$Pos from "../../app/Order/OrderEdit.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as HelpCenter$Pos from "../../core/HelpCenter.bs.js";
import * as InlineText$Pos from "../../resources/layout-and-structure/InlineText.bs.js";
import * as StyleSheet$Pos from "../../primitives/StyleSheet.bs.js";
import * as TextAction$Pos from "../../resources/actions/TextAction.bs.js";
import * as CartProduct$Pos from "../Cart/CartProduct.bs.js";
import * as OrderStatus$Pos from "./OrderStatus.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as SpinnerModal$Pos from "../../resources/feedback-indicators/SpinnerModal.bs.js";
import * as OrderEditForm$Pos from "./OrderEditForm.bs.js";
import * as CatalogProduct$Pos from "../Catalog/CatalogProduct.bs.js";
import * as OrderCartTable$Pos from "./OrderCartTable.bs.js";
import * as CartAmountsView$Pos from "../Cart/CartAmountsView.bs.js";
import * as CartOptionsView$Pos from "../Cart/CartOptionsView.bs.js";
import * as OrderPageActions$Pos from "./OrderPageActions.bs.js";
import * as OrderCart__Config$Pos from "./OrderCart__Config.bs.js";
import * as OrderCartActionsBar$Pos from "./OrderCartActionsBar.bs.js";
import * as PageBottomActionsBar$Pos from "../../resources/layout-and-structure/PageBottomActionsBar.bs.js";
import * as OrderProductFormAutoPicker$Pos from "./OrderProductFormAutoPicker.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as OrderProductFormPickerModalTable$Pos from "./OrderProductFormPickerModalTable.bs.js";

var Raw = {};

var query = Client.gql(["fragment supplier on Supplier   {\n__typename  \nid  \ncompanyName  \n}\n"]);

function parse(value) {
  return {
          __typename: value.__typename,
          id: value.id,
          companyName: value.companyName
        };
}

function serialize(value) {
  var value$1 = value.companyName;
  var value$2 = value.id;
  var value$3 = value.__typename;
  return {
          __typename: value$3,
          id: value$2,
          companyName: value$1
        };
}

function verifyArgsAndParse(_supplier, value) {
  return parse(value);
}

function verifyName(param) {
  
}

var SupplierNameFragment = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  verifyArgsAndParse: verifyArgsAndParse,
  verifyName: verifyName
};

var Raw$1 = {};

var query$1 = Client.gql(["query SupplierQuery($id: ID!)  {\nsupplier(id: $id)  {\n__typename  \nid  \nvariants  {\n__typename  \ntotalCount  \n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.supplier;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.variants;
    tmp = {
      __typename: value$1.__typename,
      id: value$1.id,
      variants: {
        __typename: value$2.__typename,
        totalCount: value$2.totalCount
      }
    };
  }
  return {
          supplier: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.supplier;
  var supplier;
  if (value$1 !== undefined) {
    var value$2 = value$1.variants;
    var value$3 = value$2.totalCount;
    var value$4 = value$2.__typename;
    var variants = {
      __typename: value$4,
      totalCount: value$3
    };
    var value$5 = value$1.id;
    var value$6 = value$1.__typename;
    supplier = {
      __typename: value$6,
      id: value$5,
      variants: variants
    };
  } else {
    supplier = null;
  }
  return {
          supplier: supplier
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var SupplierVariantsCountQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables
    });

var use = include.use;

var SupplierVariantsCountQuery_refetchQueryDescription = include.refetchQueryDescription;

var SupplierVariantsCountQuery_useLazy = include.useLazy;

var SupplierVariantsCountQuery_useLazyWithVariables = include.useLazyWithVariables;

var SupplierVariantsCountQuery = {
  SupplierVariantsCountQuery_inner: SupplierVariantsCountQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: SupplierVariantsCountQuery_refetchQueryDescription,
  use: use,
  useLazy: SupplierVariantsCountQuery_useLazy,
  useLazyWithVariables: SupplierVariantsCountQuery_useLazyWithVariables
};

var styles = StyleSheet$Pos.create({
      emptyDataView: Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#f8f8fb", "#e7e7ee", undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "solid", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(23)), undefined, undefined, undefined, undefined, undefined, undefined, undefined)
    });

var make = React.memo(function (Props) {
      var supplierName = Props.supplierName;
      return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.emptyDataView), undefined, undefined, undefined), React.createElement(Spinner$Pos.make, {
                      size: 45
                    }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Title$Pos.make, {
                          children: Intl$Pos.template(Intl$Pos.t("Search for {{supplier}} products in progress..."), {
                                supplier: supplierName
                              }, undefined),
                          level: 3
                        }), React.createElement(Box$Pos.make, Box$Pos.makeProps(undefined, undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(TextStyle$Pos.make, {
                          children: Intl$Pos.t("Analyzing products and their stock"),
                          align: "center"
                        })));
    });

var Loading = {
  make: make
};

var make$1 = React.memo(function (Props) {
      var disabled = Props.disabled;
      var errorMessage = Props.errorMessage;
      var onPressCreateOrder = Props.onPressCreateOrder;
      return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(styles.emptyDataView), undefined, undefined, undefined), React.createElement(InlineText$Pos.make, {
                      children: null
                    }, React.createElement(TextStyle$Pos.make, {
                          children: Intl$Pos.t("To save typing time, opt for the") + " ",
                          variation: "normal",
                          size: "xsmall"
                        }), React.createElement(TextAction$Pos.make, {
                          text: Intl$Pos.t("automatic order"),
                          size: "compact",
                          highlighted: true,
                          onPress: (function (param) {
                              HelpCenter$Pos.showArticle(HelpCenter$Pos.automaticOrdering);
                            })
                        }), React.createElement(TextStyle$Pos.make, {
                          children: " :",
                          variation: "normal",
                          size: "xsmall"
                        })), React.createElement(Tooltip$Pos.make, {
                      children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Create automatic order"), undefined, "neutral", undefined, undefined, undefined, disabled, undefined, undefined, undefined, (function (param) {
                                              Curry._1(onPressCreateOrder, undefined);
                                            }), undefined, undefined, undefined))), undefined, undefined, "normal", "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined)),
                      content: {
                        TAG: /* Text */0,
                        _0: Intl$Pos.t("Please select a supplier linked to the order.")
                      },
                      disabled: !disabled,
                      delay: 0
                    }), errorMessage !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                    children: errorMessage,
                                    variation: "negative",
                                    size: "xsmall"
                                  })), undefined, undefined, "normal", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null);
    });

var Default = {
  make: make$1
};

var CartPlaceholder = {
  styles: styles,
  Loading: Loading,
  Default: Default
};

function OrderCart(Props) {
  var orderId = Props.orderId;
  var cart = Props.cart;
  var statuses = Props.statuses;
  var editable = Props.editable;
  var rowErrors = Props.rowErrors;
  var edition = Props.edition;
  var onRequestCancelEdition = Props.onRequestCancelEdition;
  var onRequestCartRowsError = Props.onRequestCartRowsError;
  var onRequestDispatch = Props.onRequestDispatch;
  var match = Nav$Pos.Context.use(undefined);
  var notifier = Notifier$Pos.use(undefined, undefined);
  var formState = Curry._1(OrderEditForm$Pos.useFormState, undefined);
  var match$1 = React.useState(function () {
        return /* Pristine */0;
      });
  var setAutoPickingStatus = match$1[1];
  var autoPickingStatus = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setSupplierProductsModalOpened = match$2[1];
  var match$3 = React.useReducer(OrderCart__Config$Pos.reducer, OrderCart__Config$Pos.initialState);
  var dispatch = match$3[1];
  var state = match$3[0];
  var apolloClient = ReasonMLCommunity__ApolloClient.React.useApolloClient(undefined);
  var supplierId = formState.values.supplierId;
  var supplierName = React.useMemo((function () {
          return Belt_Option.flatMap(Curry._5(apolloClient.rescript_readFragment, {
                          query: query,
                          Raw: Raw,
                          parse: parse,
                          serialize: serialize
                        }, "Supplier:" + supplierId, undefined, undefined, undefined), (function (result) {
                        if (result.TAG === /* Ok */0) {
                          return result._0.companyName;
                        }
                        
                      }));
        }), [supplierId]);
  var match$4 = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        supplierId === "",
        undefined,
        {
          id: supplierId
        }
      ]);
  var match$5 = match$4.data;
  var tmp;
  if (match$5 !== undefined) {
    var match$6 = match$5.supplier;
    tmp = match$6 !== undefined ? match$6.variants.totalCount : undefined;
  } else {
    tmp = undefined;
  }
  var supplierVariantsCount = Belt_Option.getWithDefault(tmp, 0);
  React.useEffect((function () {
          if (typeof autoPickingStatus === "number") {
            if (autoPickingStatus >= 2) {
              Curry._3(notifier.reset, {
                    TAG: /* Error */1,
                    _0: Intl$Pos.t("Something went wrong during the automatic order taking proccess.")
                  }, undefined, undefined);
            }
            
          } else {
            var quantity = autoPickingStatus._0;
            if (quantity !== 0) {
              Curry._3(notifier.reset, {
                    TAG: /* Success */0,
                    _0: Intl$Pos.template(quantity !== 1 ? Intl$Pos.t("{{quantity}} products have been correctly added to the cart.") : Intl$Pos.t("1 product has been correctly added to the cart."), {
                          quantity: String(quantity)
                        }, undefined)
                  }, undefined, undefined);
            } else {
              Curry._3(notifier.reset, {
                    TAG: /* Warning */2,
                    _0: Intl$Pos.template(Intl$Pos.t("No products matching the set stock thresholds were found for the supplier {{supplier}}."), {
                          supplier: Belt_Option.getWithDefault(supplierName, "(?)")
                        }, undefined)
                  }, undefined, undefined);
            }
          }
        }), [autoPickingStatus]);
  var onRequestCartLoading = function (state) {
    Curry._1(dispatch, {
          TAG: /* CartLoadingStateRequested */2,
          _0: state
        });
  };
  var onRequestModalToggle = function (opened) {
    Curry._1(dispatch, {
          TAG: /* ModalCalled */0,
          _0: opened
        });
  };
  var onRequestFileImport = React.useCallback((function (file) {
          var shopId = formState.values.shopId;
          if (shopId !== "") {
            return Curry._1(dispatch, {
                        TAG: /* FileImported */1,
                        _0: file,
                        _1: shopId
                      });
          } else {
            return Curry._3(notifier.reset, {
                        TAG: /* Error */1,
                        _0: Intl$Pos.t("Please select the shop catalog from which the CSV products will be imported.")
                      }, undefined, undefined);
          }
        }), [formState.values.shopId]);
  var onPickProducts = React.useCallback((function (products) {
          Curry._1(onRequestDispatch, {
                TAG: /* BatchProductAdded */1,
                _0: products
              });
        }), []);
  var onRequestSupplierProducts = React.useCallback((function (param) {
          var fetcher = function (after) {
            var supplier = supplierId !== "" ? ({
                  id: supplierId,
                  name: Belt_Option.getWithDefault(supplierName, "")
                }) : undefined;
            return Curry._6(apolloClient.rescript_query, {
                        query: OrderProductFormPickerModalTable$Pos.Query.query,
                        Raw: OrderProductFormPickerModalTable$Pos.Query.Raw,
                        parse: OrderProductFormPickerModalTable$Pos.Query.parse,
                        serialize: OrderProductFormPickerModalTable$Pos.Query.serialize,
                        serializeVariables: OrderProductFormPickerModalTable$Pos.Query.serializeVariables
                      }, undefined, undefined, /* NetworkOnly */2, undefined, OrderProductFormPickerModalTable$Pos.makeVariables("", {
                            shopId: formState.values.shopId,
                            supplier: supplier
                          }, after, undefined));
          };
          Curry._1(setSupplierProductsModalOpened, (function (param) {
                  return true;
                }));
          Future.tapOk(Future.mapOk(Future.mapOk(OrderProductFormPickerModalTable$Pos.runScanEdges(fetcher, undefined, undefined, undefined), undefined, (function (result) {
                          return Belt_Array.map(OrderProductFormPickerModalTable$Pos.rowsFromEdgesData(result), (function (row) {
                                        return OrderProductFormPickerModalTable$Pos.setDefaultOrderedQuantityValue(CartProduct$Pos.makeProductInput(row.id, row.formattedName, CatalogProduct$Pos.Information.formateDescription(row.productKind, row.information, true, undefined), undefined, row.stockQuantity, row.packaging, row.tax, row.purchasedPrice, undefined, row.bulkPrecision, row.capacityUnit, undefined));
                                      }));
                        })), undefined, onPickProducts), (function (param) {
                  Curry._1(setSupplierProductsModalOpened, (function (param) {
                          return false;
                        }));
                }));
        }), [supplierId]);
  var columns = React.useMemo((function () {
          var hiddenColumns = OrderEdit$Pos.isBeforeReception(statuses) ? /* StatusBeforeReception */0 : /* StatusAtAfterReception */1;
          return OrderCartTable$Pos.tableColumns(statuses, hiddenColumns, cart.taxesFree, editable, cart.decimalPrecision, onRequestDispatch);
        }), [editable]);
  var errors = formState.validation;
  var cartError;
  cartError = errors.TAG === /* Ok */0 ? undefined : Belt_Array.get(Belt_Array.keepMap(errors._0, (function (param) {
                if (param[0]._0 !== 6) {
                  return ;
                } else {
                  return param[1];
                }
              })), 0);
  var disabledIds = Belt_Array.keepMap(cart.products, (function (product) {
          return product._0.identifier;
        }));
  var renderBottomActionBarEnd = function (param) {
    return React.createElement(OrderPageActions$Pos.make, {
                id: orderId,
                statuses: statuses,
                edition: edition,
                onRequestCancelEdition: onRequestCancelEdition
              });
  };
  var tmp$1 = {
    opened: state.modalOpened,
    shopId: formState.values.shopId !== "" ? formState.values.shopId : undefined,
    supplierId: supplierId,
    disabledIds: disabledIds,
    onCommit: onPickProducts,
    onRequestClose: (function (param) {
        Curry._1(dispatch, {
              TAG: /* ModalCalled */0,
              _0: false
            });
      })
  };
  if (supplierName !== undefined) {
    tmp$1.supplierName = Caml_option.valFromOption(supplierName);
  }
  var loading = state.loadingState;
  var tmp$2;
  if (loading !== undefined) {
    var tmp$3 = {
      status: /* Loading */0
    };
    if (loading.message !== undefined) {
      tmp$3.customText = loading.message;
    }
    tmp$2 = React.createElement(Placeholder$Pos.make, tmp$3);
  } else {
    var tmp$4;
    var exit = 0;
    if (autoPickingStatus === 1 && supplierName !== undefined) {
      tmp$4 = React.createElement(make, {
            supplierName: supplierName
          });
    } else {
      exit = 1;
    }
    if (exit === 1) {
      var match$7 = formState.submission;
      var tmp$5;
      tmp$5 = cartError !== undefined && !(typeof match$7 === "number" || match$7.TAG !== /* Failed */0) ? Intl$Pos.t(cartError) : undefined;
      var tmp$6 = {
        disabled: supplierId === "",
        onPressCreateOrder: (function (param) {
            Curry._1(setAutoPickingStatus, (function (param) {
                    return /* Loading */1;
                  }));
          })
      };
      if (tmp$5 !== undefined) {
        tmp$6.errorMessage = Caml_option.valFromOption(tmp$5);
      }
      tmp$4 = React.createElement(make$1, tmp$6);
    }
    tmp$2 = React.createElement(OrderCartTable$Pos.make, OrderCartTable$Pos.makeProps({
              TAG: /* Done */1,
              _0: {
                TAG: /* Ok */0,
                _0: cart.products
              }
            }, columns, OrderCartTable$Pos.keyExtractor, undefined, rowErrors, undefined, undefined, undefined, true, undefined, undefined, undefined, 540, undefined, Caml_option.some(tmp$4), undefined, undefined, undefined, [
              "csv",
              "excel"
            ], undefined, undefined, undefined, undefined, undefined, editable ? Curry.__1(onRequestFileImport) : undefined, (function (message) {
                Curry._3(notifier.reset, {
                      TAG: /* Error */1,
                      _0: message
                    }, undefined, undefined);
              }), supplierId, undefined));
  }
  return React.createElement(React.Fragment, undefined, React.createElement(SpinnerModal$Pos.make, {
                  title: Intl$Pos.t("Add supplier's products"),
                  opened: match$2[0]
                }), React.createElement(OrderProductFormPickerModalTable$Pos.make, tmp$1), React.createElement(OrderProductFormAutoPicker$Pos.make, {
                  status: autoPickingStatus,
                  orderId: orderId,
                  supplierId: supplierId,
                  supplierName: supplierName,
                  onQueryStatusChange: (function (status) {
                      Curry._1(setAutoPickingStatus, (function (param) {
                              return status;
                            }));
                    }),
                  onPickProducts: onPickProducts
                }), React.createElement(Card$Pos.make, {
                  children: null,
                  variation: "unset"
                }, editable && OrderEdit$Pos.isBeforeReception(statuses) ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(OrderCartActionsBar$Pos.make, {
                                    cartProducts: cart.products,
                                    shopId: formState.values.shopId,
                                    supplierId: supplierId,
                                    supplierVariantsCount: supplierVariantsCount,
                                    importedCsv: state.file,
                                    onRequestCartRowsError: onRequestCartRowsError,
                                    onRequestFileImport: onRequestFileImport,
                                    onRequestCartLoading: onRequestCartLoading,
                                    onRequestModalToggle: onRequestModalToggle,
                                    onRequestSupplierProducts: onRequestSupplierProducts,
                                    onRequestDispatch: onRequestDispatch
                                  })), "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, tmp$2, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                  children: null,
                                  align: editable && cart.products.length !== 0 ? "spaceBetween" : "end",
                                  alignY: "top"
                                }, editable && cart.products.length !== 0 ? React.createElement(CartOptionsView$Pos.make, {
                                        cart: cart,
                                        statuses: statuses,
                                        onRequestDispatch: onRequestDispatch
                                      }) : null, React.createElement(CartAmountsView$Pos.make, {
                                      cart: cart
                                    }))), undefined, undefined, undefined, "normal", "xlarge", "xxlarge", undefined, undefined, undefined, undefined, undefined))), OrderStatus$Pos.has(statuses, "PAID") ? null : React.createElement(PageBottomActionsBar$Pos.make, {
                    displayThreshold: 160,
                    renderEnd: renderBottomActionBarEnd,
                    navOpened: match[0].opened
                  }));
}

var make$2 = React.memo(OrderCart, (function (oldProps, newProps) {
        if (oldProps.cart === newProps.cart && oldProps.rowErrors === newProps.rowErrors && oldProps.statuses === newProps.statuses && oldProps.orderId === newProps.orderId && oldProps.editable === newProps.editable) {
          return oldProps.edition === newProps.edition;
        } else {
          return false;
        }
      }));

var CartTable;

var CartConfig;

var Utils;

export {
  CartTable ,
  CartConfig ,
  Utils ,
  SupplierNameFragment ,
  SupplierVariantsCountQuery ,
  CartPlaceholder ,
  make$2 as make,
}
/* query Not a pure module */
