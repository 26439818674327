// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query ProductQuery($cku: CKU!, $filterBy: InputProductsByVariantCkuQueryFilter, $productVariantsFilterBy: InputProductVariantsQueryFilter)  {\nproductsByVariantCku(cku: $cku, first: 50, filterBy: $filterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nformattedStatus  \nname  \nformattedDescription  \nformattedOrigin  \ncategory  {\n__typename  \nid  \nformattedName  \n}\n\ntax  {\n__typename  \nvalue  \n}\n\nshop  {\n__typename  \nid  \nname  \n}\n\ninternalNote  \nvariants(first: 50, filterBy: $productVariantsFilterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \ncku  \nid  \nname  \nshop  {\n__typename  \nname  \n}\n\nformattedStatus  \nformattedPurchasedPrice  \npurchasedPrice  \ncapacityUnit  \nbulk  \nmaxStockThreshold  \nminStockThreshold  \nstockOrderTriggerThreshold  \nvariantPrices  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nvalueIncludingTax  \nvalueExcludingTax  \nprice  {\n__typename  \nname  \nenableByDefault  \ntaxIncluded  \n}\n\n}\n\n}\n\n}\n\nstock  {\n__typename  \nformattedQuantity  \nstate  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.productsByVariantCku;
  var value$2 = value$1.edges;
  return {
          productsByVariantCku: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.formattedStatus;
                    var tmp;
                    if (value$2 == null) {
                      tmp = undefined;
                    } else {
                      var tmp$1;
                      switch (value$2) {
                        case "ACTIVE" :
                            tmp$1 = "ACTIVE";
                            break;
                        case "ARCHIVED" :
                            tmp$1 = "ARCHIVED";
                            break;
                        case "INACTIVE" :
                            tmp$1 = "INACTIVE";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp = tmp$1;
                    }
                    var value$3 = value$1.formattedDescription;
                    var value$4 = value$1.category;
                    var value$5 = value$1.tax;
                    var value$6 = value$1.shop;
                    var value$7 = value$1.internalNote;
                    var value$8 = value$1.variants;
                    var value$9 = value$8.edges;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              formattedStatus: tmp,
                              name: value$1.name,
                              formattedDescription: !(value$3 == null) ? value$3 : undefined,
                              formattedOrigin: value$1.formattedOrigin,
                              category: !(value$4 == null) ? ({
                                    __typename: value$4.__typename,
                                    id: value$4.id,
                                    formattedName: value$4.formattedName
                                  }) : undefined,
                              tax: {
                                __typename: value$5.__typename,
                                value: value$5.value
                              },
                              shop: {
                                __typename: value$6.__typename,
                                id: value$6.id,
                                name: value$6.name
                              },
                              internalNote: !(value$7 == null) ? Scalar$Pos.$$Text.parse(value$7) : undefined,
                              variants: {
                                __typename: value$8.__typename,
                                edges: Js_array.map((function (value) {
                                        var value$1 = value.node;
                                        var value$2 = value$1.shop;
                                        var value$3 = value$1.formattedStatus;
                                        var tmp;
                                        switch (value$3) {
                                          case "ACTIVE" :
                                              tmp = "ACTIVE";
                                              break;
                                          case "ARCHIVED" :
                                              tmp = "ARCHIVED";
                                              break;
                                          case "INACTIVE" :
                                              tmp = "INACTIVE";
                                              break;
                                          case "VARIABLE" :
                                              tmp = "VARIABLE";
                                              break;
                                          default:
                                            throw {
                                                  RE_EXN_ID: "Not_found",
                                                  Error: new Error()
                                                };
                                        }
                                        var value$4 = value$1.formattedPurchasedPrice;
                                        var value$5 = value$1.purchasedPrice;
                                        var value$6 = value$1.capacityUnit;
                                        var value$7 = value$1.bulk;
                                        var value$8 = value$1.maxStockThreshold;
                                        var value$9 = value$1.minStockThreshold;
                                        var value$10 = value$1.stockOrderTriggerThreshold;
                                        var value$11 = value$1.variantPrices;
                                        var value$12 = value$11.edges;
                                        var value$13 = value$1.stock;
                                        var value$14 = value$13.formattedQuantity;
                                        var value$15 = value$13.state;
                                        var tmp$1;
                                        if (value$15 == null) {
                                          tmp$1 = undefined;
                                        } else {
                                          var tmp$2;
                                          switch (value$15) {
                                            case "ALERT" :
                                                tmp$2 = "ALERT";
                                                break;
                                            case "DANGER" :
                                                tmp$2 = "DANGER";
                                                break;
                                            case "OK" :
                                                tmp$2 = "OK";
                                                break;
                                            default:
                                              throw {
                                                    RE_EXN_ID: "Not_found",
                                                    Error: new Error()
                                                  };
                                          }
                                          tmp$1 = tmp$2;
                                        }
                                        return {
                                                __typename: value.__typename,
                                                node: {
                                                  __typename: value$1.__typename,
                                                  cku: Scalar$Pos.CKU.parse(value$1.cku),
                                                  id: value$1.id,
                                                  name: value$1.name,
                                                  shop: {
                                                    __typename: value$2.__typename,
                                                    name: value$2.name
                                                  },
                                                  formattedStatus: tmp,
                                                  formattedPurchasedPrice: !(value$4 == null) ? value$4 : undefined,
                                                  purchasedPrice: !(value$5 == null) ? value$5 : undefined,
                                                  capacityUnit: !(value$6 == null) ? value$6 : undefined,
                                                  bulk: !(value$7 == null) ? value$7 : undefined,
                                                  maxStockThreshold: !(value$8 == null) ? value$8 : undefined,
                                                  minStockThreshold: !(value$9 == null) ? value$9 : undefined,
                                                  stockOrderTriggerThreshold: !(value$10 == null) ? value$10 : undefined,
                                                  variantPrices: {
                                                    __typename: value$11.__typename,
                                                    edges: Js_array.map((function (value) {
                                                            var value$1 = value.node;
                                                            var value$2 = value$1.price;
                                                            return {
                                                                    __typename: value.__typename,
                                                                    node: {
                                                                      __typename: value$1.__typename,
                                                                      valueIncludingTax: value$1.valueIncludingTax,
                                                                      valueExcludingTax: value$1.valueExcludingTax,
                                                                      price: !(value$2 == null) ? ({
                                                                            __typename: value$2.__typename,
                                                                            name: value$2.name,
                                                                            enableByDefault: value$2.enableByDefault,
                                                                            taxIncluded: value$2.taxIncluded
                                                                          }) : undefined
                                                                    }
                                                                  };
                                                          }), value$12)
                                                  },
                                                  stock: {
                                                    __typename: value$13.__typename,
                                                    formattedQuantity: !(value$14 == null) ? value$14 : undefined,
                                                    state: tmp$1
                                                  }
                                                }
                                              };
                                      }), value$9)
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.productsByVariantCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.variants;
          var value$3 = value$2.edges;
          var edges = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.stock;
                  var value$3 = value$2.state;
                  var state = value$3 !== undefined ? (
                      value$3 === "OK" ? "OK" : (
                          value$3 === "ALERT" ? "ALERT" : "DANGER"
                        )
                    ) : null;
                  var value$4 = value$2.formattedQuantity;
                  var formattedQuantity = value$4 !== undefined ? value$4 : null;
                  var value$5 = value$2.__typename;
                  var stock = {
                    __typename: value$5,
                    formattedQuantity: formattedQuantity,
                    state: state
                  };
                  var value$6 = value$1.variantPrices;
                  var value$7 = value$6.edges;
                  var edges = Js_array.map((function (value) {
                          var value$1 = value.node;
                          var value$2 = value$1.price;
                          var price;
                          if (value$2 !== undefined) {
                            var value$3 = value$2.taxIncluded;
                            var value$4 = value$2.enableByDefault;
                            var value$5 = value$2.name;
                            var value$6 = value$2.__typename;
                            price = {
                              __typename: value$6,
                              name: value$5,
                              enableByDefault: value$4,
                              taxIncluded: value$3
                            };
                          } else {
                            price = null;
                          }
                          var value$7 = value$1.valueExcludingTax;
                          var value$8 = value$1.valueIncludingTax;
                          var value$9 = value$1.__typename;
                          var node = {
                            __typename: value$9,
                            valueIncludingTax: value$8,
                            valueExcludingTax: value$7,
                            price: price
                          };
                          var value$10 = value.__typename;
                          return {
                                  __typename: value$10,
                                  node: node
                                };
                        }), value$7);
                  var value$8 = value$6.__typename;
                  var variantPrices = {
                    __typename: value$8,
                    edges: edges
                  };
                  var value$9 = value$1.stockOrderTriggerThreshold;
                  var stockOrderTriggerThreshold = value$9 !== undefined ? value$9 : null;
                  var value$10 = value$1.minStockThreshold;
                  var minStockThreshold = value$10 !== undefined ? value$10 : null;
                  var value$11 = value$1.maxStockThreshold;
                  var maxStockThreshold = value$11 !== undefined ? value$11 : null;
                  var value$12 = value$1.bulk;
                  var bulk = value$12 !== undefined ? value$12 : null;
                  var value$13 = value$1.capacityUnit;
                  var capacityUnit = value$13 !== undefined ? value$13 : null;
                  var value$14 = value$1.purchasedPrice;
                  var purchasedPrice = value$14 !== undefined ? value$14 : null;
                  var value$15 = value$1.formattedPurchasedPrice;
                  var formattedPurchasedPrice = value$15 !== undefined ? value$15 : null;
                  var value$16 = value$1.formattedStatus;
                  var formattedStatus = value$16 === "INACTIVE" ? "INACTIVE" : (
                      value$16 === "ARCHIVED" ? "ARCHIVED" : (
                          value$16 === "ACTIVE" ? "ACTIVE" : "VARIABLE"
                        )
                    );
                  var value$17 = value$1.shop;
                  var value$18 = value$17.name;
                  var value$19 = value$17.__typename;
                  var shop = {
                    __typename: value$19,
                    name: value$18
                  };
                  var value$20 = value$1.name;
                  var value$21 = value$1.id;
                  var value$22 = value$1.cku;
                  var value$23 = Scalar$Pos.CKU.serialize(value$22);
                  var value$24 = value$1.__typename;
                  var node = {
                    __typename: value$24,
                    cku: value$23,
                    id: value$21,
                    name: value$20,
                    shop: shop,
                    formattedStatus: formattedStatus,
                    formattedPurchasedPrice: formattedPurchasedPrice,
                    purchasedPrice: purchasedPrice,
                    capacityUnit: capacityUnit,
                    bulk: bulk,
                    maxStockThreshold: maxStockThreshold,
                    minStockThreshold: minStockThreshold,
                    stockOrderTriggerThreshold: stockOrderTriggerThreshold,
                    variantPrices: variantPrices,
                    stock: stock
                  };
                  var value$25 = value.__typename;
                  return {
                          __typename: value$25,
                          node: node
                        };
                }), value$3);
          var value$4 = value$2.__typename;
          var variants = {
            __typename: value$4,
            edges: edges
          };
          var value$5 = value$1.internalNote;
          var internalNote = value$5 !== undefined ? Scalar$Pos.$$Text.serialize(value$5) : null;
          var value$6 = value$1.shop;
          var value$7 = value$6.name;
          var value$8 = value$6.id;
          var value$9 = value$6.__typename;
          var shop = {
            __typename: value$9,
            id: value$8,
            name: value$7
          };
          var value$10 = value$1.tax;
          var value$11 = value$10.value;
          var value$12 = value$10.__typename;
          var tax = {
            __typename: value$12,
            value: value$11
          };
          var value$13 = value$1.category;
          var category;
          if (value$13 !== undefined) {
            var value$14 = value$13.formattedName;
            var value$15 = value$13.id;
            var value$16 = value$13.__typename;
            category = {
              __typename: value$16,
              id: value$15,
              formattedName: value$14
            };
          } else {
            category = null;
          }
          var value$17 = value$1.formattedOrigin;
          var value$18 = value$1.formattedDescription;
          var formattedDescription = value$18 !== undefined ? value$18 : null;
          var value$19 = value$1.name;
          var value$20 = value$1.formattedStatus;
          var formattedStatus = value$20 !== undefined ? (
              value$20 === "ARCHIVED" ? "ARCHIVED" : (
                  value$20 === "ACTIVE" ? "ACTIVE" : "INACTIVE"
                )
            ) : null;
          var value$21 = value$1.id;
          var value$22 = value$1.__typename;
          var node = {
            __typename: value$22,
            id: value$21,
            formattedStatus: formattedStatus,
            name: value$19,
            formattedDescription: formattedDescription,
            formattedOrigin: value$17,
            category: category,
            tax: tax,
            shop: shop,
            internalNote: internalNote,
            variants: variants
          };
          var value$23 = value.__typename;
          return {
                  __typename: value$23,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var productsByVariantCku = {
    __typename: value$3,
    edges: edges
  };
  return {
          productsByVariantCku: productsByVariantCku
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputProductVariantsQueryFilter(inp) {
  var a = inp.archived;
  return {
          archived: a !== undefined ? (
              a === "ONLY" ? "ONLY" : (
                  a === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeInputObjectInputProductsByVariantCkuQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.archived;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          archived: a$1 !== undefined ? (
              a$1 === "ONLY" ? "ONLY" : (
                  a$1 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  var a$1 = inp.productVariantsFilterBy;
  return {
          cku: inp.cku,
          filterBy: a !== undefined ? serializeInputObjectInputProductsByVariantCkuQueryFilter(a) : undefined,
          productVariantsFilterBy: a$1 !== undefined ? serializeInputObjectInputProductVariantsQueryFilter(a$1) : undefined
        };
}

function makeVariables(cku, filterBy, productVariantsFilterBy, param) {
  return {
          cku: cku,
          filterBy: filterBy,
          productVariantsFilterBy: productVariantsFilterBy
        };
}

function makeInputObjectInputProductsByVariantCkuQueryFilter(shopIds, archived, param) {
  return {
          shopIds: shopIds,
          archived: archived
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectInputProductVariantsQueryFilter(archived, param) {
  return {
          archived: archived
        };
}

var ProductQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputProductsByVariantCkuQueryFilter: serializeInputObjectInputProductsByVariantCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectInputProductVariantsQueryFilter: serializeInputObjectInputProductVariantsQueryFilter,
  makeVariables: makeVariables,
  makeInputObjectInputProductsByVariantCkuQueryFilter: makeInputObjectInputProductsByVariantCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectInputProductVariantsQueryFilter: makeInputObjectInputProductVariantsQueryFilter
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var ProductQuery_refetchQueryDescription = include.refetchQueryDescription;

var ProductQuery_use = include.use;

var ProductQuery_useLazy = include.useLazy;

var ProductQuery_useLazyWithVariables = include.useLazyWithVariables;

var ProductQuery = {
  ProductQuery_inner: ProductQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputProductsByVariantCkuQueryFilter: serializeInputObjectInputProductsByVariantCkuQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectInputProductVariantsQueryFilter: serializeInputObjectInputProductVariantsQueryFilter,
  makeVariables: makeVariables,
  makeInputObjectInputProductsByVariantCkuQueryFilter: makeInputObjectInputProductsByVariantCkuQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectInputProductVariantsQueryFilter: makeInputObjectInputProductVariantsQueryFilter,
  refetchQueryDescription: ProductQuery_refetchQueryDescription,
  use: ProductQuery_use,
  useLazy: ProductQuery_useLazy,
  useLazyWithVariables: ProductQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query ProductQuery($cku: CKU!)  {\nproductsByVariantCku(cku: $cku, first: 50, filterBy: {archived: INCLUDED})  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nshop  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.productsByVariantCku;
  var value$2 = value$1.edges;
  return {
          productsByVariantCku: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.shop;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              shop: {
                                __typename: value$2.__typename,
                                id: value$2.id
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.productsByVariantCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.shop;
          var value$3 = value$2.id;
          var value$4 = value$2.__typename;
          var shop = {
            __typename: value$4,
            id: value$3
          };
          var value$5 = value$1.id;
          var value$6 = value$1.__typename;
          var node = {
            __typename: value$6,
            id: value$5,
            shop: shop
          };
          var value$7 = value.__typename;
          return {
                  __typename: value$7,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var productsByVariantCku = {
    __typename: value$3,
    edges: edges
  };
  return {
          productsByVariantCku: productsByVariantCku
        };
}

function serializeVariables$1(inp) {
  return {
          cku: inp.cku
        };
}

function makeVariables$1(cku, param) {
  return {
          cku: cku
        };
}

var ProductShopsQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var ProductShopsQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var ProductShopsQuery_use = include$1.use;

var ProductShopsQuery_useLazy = include$1.useLazy;

var ProductShopsQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var ProductShopsQuery = {
  ProductShopsQuery_inner: ProductShopsQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  refetchQueryDescription: ProductShopsQuery_refetchQueryDescription,
  use: ProductShopsQuery_use,
  useLazy: ProductShopsQuery_useLazy,
  useLazyWithVariables: ProductShopsQuery_useLazyWithVariables
};

export {
  ProductQuery ,
  ProductShopsQuery ,
}
/* query Not a pure module */
