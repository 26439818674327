// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Uuid$Pos from "../../externals/Uuid.bs.js";
import * as Group$Pos from "../../resources/layout-and-structure/Group.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Divider$Pos from "../../resources/layout-and-structure/Divider.bs.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as AsyncData$Pos from "../../primitives/AsyncData.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as Belt_SortArray from "rescript/lib/es6/belt_SortArray.js";
import * as HelpCenter$Pos from "../../core/HelpCenter.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as TextAction$Pos from "../../resources/actions/TextAction.bs.js";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as TooltipIcon$Pos from "../../resources/overlays/TooltipIcon.bs.js";
import * as InputTextField$Pos from "../../resources/selection-and-input/InputTextField.bs.js";
import * as AccountingVendor$Pos from "../../modules/AccountingVendor.bs.js";
import * as InputSelectField$Pos from "../../resources/selection-and-input/InputSelectField.bs.js";
import * as InputCheckboxField$Pos from "../../resources/selection-and-input/InputCheckboxField.bs.js";
import * as FieldsetLayoutPanel$Pos from "../../resources/layout-and-structure/FieldsetLayoutPanel.bs.js";
import * as ResourceDetailsPage$Pos from "../../core/ResourceDetailsPage.bs.js";
import * as FiscalYearOpeningMonth$Pos from "../../modules/FiscalYearOpeningMonth.bs.js";
import * as AccountingConfiguration$Pos from "../../modules/AccountingConfiguration.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var shopAccountingConfigurationRequestBaseUrl = Env$Pos.gatewayUrl(undefined) + "/accounting-export-configurations/";

function createEndpoint(param) {
  return shopAccountingConfigurationRequestBaseUrl;
}

function updateEndpoint(shopId) {
  return shopAccountingConfigurationRequestBaseUrl + shopId;
}

function encodeBodyJson(value) {
  var isaComptaAccountNumber = value.isaComptaAccountNumber;
  return Json$Pos.encodeDict(Js_dict.fromArray([
                  [
                    "shopId",
                    Json$Pos.encodeString(value.shopId)
                  ],
                  [
                    "fiscalYearOpeningMonth",
                    FiscalYearOpeningMonth$Pos.encodeToJson(value.fiscalYearOpeningMonth)
                  ],
                  [
                    "isaComptaAccountNumber",
                    isaComptaAccountNumber !== undefined ? Json$Pos.encodeString(isaComptaAccountNumber) : Json$Pos.encodedNull
                  ],
                  [
                    "accountingExportTaxAccounts",
                    Json$Pos.encodeArray(Belt_Array.map(value.taxesAccounts, AccountingConfiguration$Pos.TaxAccount.encodeToJson))
                  ],
                  [
                    "breakdownOfConsumerSalesByCashRegisterDailyReport",
                    Json$Pos.encodeBoolean(value.breakdownOfConsumerSalesByCashRegisterDailyReport)
                  ]
                ]));
}

function make(getShopAccountingConfigurationRequest, value) {
  var bodyJson = encodeBodyJson(value);
  return Future.map(Future.flatMapOk(Future.flatMapOk(Future.mapError(Curry._1(getShopAccountingConfigurationRequest, value.shopId), undefined, (function (param) {
                            
                          })), undefined, (function (shopAccountingConfiguration) {
                        return Future.mapError(shopAccountingConfiguration !== undefined ? Request$Pos.make("PATCH", Caml_option.some(bodyJson), undefined, undefined, shopAccountingConfigurationRequestBaseUrl + value.shopId) : Request$Pos.make("POST", Caml_option.some(bodyJson), undefined, undefined, shopAccountingConfigurationRequestBaseUrl), undefined, (function (param) {
                                      
                                    }));
                      })), undefined, (function (param) {
                    return Curry._1(getShopAccountingConfigurationRequest, value.shopId);
                  })), undefined, (function (result) {
                if (result.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
                var response = result._0;
                if (response !== undefined) {
                  return {
                          TAG: /* Ok */0,
                          _0: Caml_option.valFromOption(response)
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
              }));
}

var CreateOrUpdateShopAccountingConfigurationRequest = {
  createEndpoint: createEndpoint,
  updateEndpoint: updateEndpoint,
  encodeBodyJson: encodeBodyJson,
  make: make
};

var Raw = {};

var query = Client.gql(["query QueryAllShopTaxes($filterBy: InputTaxQueryFilter)  {\ntaxes(filterBy: $filterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nvalue  \n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.taxes;
  var value$2 = value$1.edges;
  return {
          taxes: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              value: value$1.value
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.taxes;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.value;
          var value$3 = value$1.id;
          var value$4 = value$1.__typename;
          var node = {
            __typename: value$4,
            id: value$3,
            value: value$2
          };
          var value$5 = value.__typename;
          return {
                  __typename: value$5,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var taxes = {
    __typename: value$3,
    edges: edges
  };
  return {
          taxes: taxes
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputTaxQueryFilter(inp) {
  var a = inp.shopIds;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputTaxQueryFilter(a) : undefined
        };
}

function makeVariables(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectInputTaxQueryFilter(shopIds, param) {
  return {
          shopIds: shopIds
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputTaxQueryFilter: serializeInputObjectInputTaxQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputTaxQueryFilter: makeInputObjectInputTaxQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_use = include.use;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputTaxQueryFilter: serializeInputObjectInputTaxQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputTaxQueryFilter: makeInputObjectInputTaxQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: Query_use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function queryEdgesToItems(edges) {
  return Belt_SortArray.stableSortBy(Belt_Array.keepMap(edges, (function (param) {
                    var match = param.node;
                    var value = match.value;
                    return Belt_Option.map(Uuid$Pos.fromString(match.id), (function (id) {
                                  return {
                                          id: id,
                                          label: Intl$Pos.t("VAT rate") + " " + Intl$Pos.percentFormat(undefined, undefined, value / 100),
                                          rate: value
                                        };
                                }));
                  })), (function (param, param$1) {
                if ((param.rate | 0) < (param$1.rate | 0)) {
                  return 1;
                } else {
                  return -1;
                }
              }));
}

function make$1(shopId, apolloClient) {
  return Future.map(FuturePromise.fromPromise(Curry._6(apolloClient.rescript_query, {
                      query: query,
                      Raw: Raw,
                      parse: parse,
                      serialize: serialize,
                      serializeVariables: serializeVariables
                    }, undefined, undefined, /* NetworkOnly */2, undefined, {
                      filterBy: {
                        shopIds: {
                          _in: [shopId]
                        }
                      }
                    })), undefined, (function (value) {
                if (value.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
                var match = value._0;
                if (match.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: queryEdgesToItems(match._0.data.taxes.edges)
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: undefined
                        };
                }
              }));
}

var GetAllShopTaxesRequest = {
  Query: Query,
  queryEdgesToItems: queryEdgesToItems,
  make: make$1
};

function make$2(value) {
  return {
          value: value,
          touched: false,
          errorMessage: undefined
        };
}

function mapErrorMessage(param) {
  return param.errorMessage;
}

var nonEmptyStringErrorMessageStringLiteral = Intl$Pos.t("Please fulfill this field.");

function validateNonEmptyStringValue(param) {
  var value = param.value;
  return {
          value: value,
          touched: param.touched,
          errorMessage: value === "" ? nonEmptyStringErrorMessageStringLiteral : undefined
        };
}

var FormField = {
  make: make$2,
  mapErrorMessage: mapErrorMessage,
  nonEmptyStringErrorMessageStringLiteral: nonEmptyStringErrorMessageStringLiteral,
  validateNonEmptyStringValue: validateNonEmptyStringValue
};

function fromUuid(value) {
  return value;
}

function unsafeFromString(prim) {
  return prim;
}

function toString(prim) {
  return prim;
}

function equal(a, b) {
  return a === b;
}

var ID = {
  fromUuid: fromUuid,
  unsafeFromString: unsafeFromString,
  toString: toString,
  equal: equal
};

function isDeductibleTax(taxRate) {
  return taxRate !== 0;
}

function isErrored(param) {
  var deductibleTaxFormFields = param.deductibleTaxFormFields;
  var productsSoldFormFields = param.productsSoldFormFields;
  if (Belt_Option.isSome(Belt_Option.flatMap(param.isaComptaCodeFormField, mapErrorMessage)) || Belt_Option.isSome(mapErrorMessage(productsSoldFormFields.accountNumberFormField)) || Belt_Option.isSome(mapErrorMessage(productsSoldFormFields.labelFormField)) || Belt_Option.isSome(Belt_Option.flatMap(deductibleTaxFormFields, (function (param) {
                return mapErrorMessage(param.accountNumberFormField);
              })))) {
    return true;
  } else {
    return Belt_Option.isSome(Belt_Option.flatMap(deductibleTaxFormFields, (function (param) {
                      return mapErrorMessage(param.labelFormField);
                    })));
  }
}

function isPristine(param) {
  var productsSoldFormFields = param.productsSoldFormFields;
  if (Belt_Option.mapWithDefault(param.isaComptaCodeFormField, true, (function (param) {
            return !param.touched;
          })) && !productsSoldFormFields.accountNumberFormField.touched && !productsSoldFormFields.labelFormField.touched) {
    return Belt_Option.mapWithDefault(param.deductibleTaxFormFields, true, (function (param) {
                  if (param.accountNumberFormField.touched) {
                    return false;
                  } else {
                    return !param.labelFormField.touched;
                  }
                }));
  } else {
    return false;
  }
}

function fillWithStandard(taxAccountFormFieldset, vendor) {
  var standardTaxAccount = AccountingConfiguration$Pos.TaxAccount.fillWithStandard(taxAccountFormFieldset.rate, taxAccountFormFieldset.id);
  var match = standardTaxAccount.productsSold;
  return {
          id: taxAccountFormFieldset.id,
          rate: taxAccountFormFieldset.rate,
          label: taxAccountFormFieldset.label,
          isaComptaCodeFormField: vendor ? Belt_Option.map(standardTaxAccount.isaComptaCode, (function (value) {
                    return {
                            value: value,
                            touched: false,
                            errorMessage: undefined
                          };
                  })) : undefined,
          productsSoldFormFields: {
            accountNumberFormField: {
              value: match[0],
              touched: false,
              errorMessage: undefined
            },
            labelFormField: {
              value: match[1],
              touched: false,
              errorMessage: undefined
            }
          },
          deductibleTaxFormFields: taxAccountFormFieldset.rate !== 0 ? Belt_Option.map(standardTaxAccount.deductibleTax, (function (param) {
                    return {
                            accountNumberFormField: {
                              value: param[0],
                              touched: false,
                              errorMessage: undefined
                            },
                            labelFormField: {
                              value: param[1],
                              touched: false,
                              errorMessage: undefined
                            }
                          };
                  })) : undefined
        };
}

function changeFormFieldValue(fieldset, field, value) {
  var formFields = fieldset.productsSoldFormFields;
  var tmp;
  if (field !== 3) {
    tmp = formFields.accountNumberFormField;
  } else {
    var init = formFields.accountNumberFormField;
    tmp = {
      value: value,
      touched: init.touched,
      errorMessage: init.errorMessage
    };
  }
  var tmp$1;
  if (field >= 4) {
    var init$1 = formFields.labelFormField;
    tmp$1 = {
      value: value,
      touched: init$1.touched,
      errorMessage: init$1.errorMessage
    };
  } else {
    tmp$1 = formFields.labelFormField;
  }
  return {
          id: fieldset.id,
          rate: fieldset.rate,
          label: fieldset.label,
          isaComptaCodeFormField: field !== 0 ? fieldset.isaComptaCodeFormField : Belt_Option.map(fieldset.isaComptaCodeFormField, (function (formField) {
                    return {
                            value: value,
                            touched: formField.touched,
                            errorMessage: formField.errorMessage
                          };
                  })),
          productsSoldFormFields: {
            accountNumberFormField: tmp,
            labelFormField: tmp$1
          },
          deductibleTaxFormFields: Belt_Option.map(fieldset.deductibleTaxFormFields, (function (formFields) {
                  var tmp;
                  if (field !== 1) {
                    tmp = formFields.accountNumberFormField;
                  } else {
                    var init = formFields.accountNumberFormField;
                    tmp = {
                      value: value,
                      touched: init.touched,
                      errorMessage: init.errorMessage
                    };
                  }
                  var tmp$1;
                  if (field !== 2) {
                    tmp$1 = formFields.labelFormField;
                  } else {
                    var init$1 = formFields.labelFormField;
                    tmp$1 = {
                      value: value,
                      touched: init$1.touched,
                      errorMessage: init$1.errorMessage
                    };
                  }
                  return {
                          accountNumberFormField: tmp,
                          labelFormField: tmp$1
                        };
                }))
        };
}

function blurFormField(fieldset, field) {
  var formFields = fieldset.productsSoldFormFields;
  var tmp;
  if (field !== 3) {
    tmp = formFields.accountNumberFormField;
  } else {
    var init = formFields.accountNumberFormField;
    tmp = {
      value: init.value,
      touched: true,
      errorMessage: init.errorMessage
    };
  }
  var tmp$1;
  if (field >= 4) {
    var init$1 = formFields.labelFormField;
    tmp$1 = {
      value: init$1.value,
      touched: true,
      errorMessage: init$1.errorMessage
    };
  } else {
    tmp$1 = formFields.labelFormField;
  }
  return {
          id: fieldset.id,
          rate: fieldset.rate,
          label: fieldset.label,
          isaComptaCodeFormField: field !== 0 ? fieldset.isaComptaCodeFormField : Belt_Option.map(fieldset.isaComptaCodeFormField, (function (formField) {
                    return {
                            value: formField.value,
                            touched: true,
                            errorMessage: formField.errorMessage
                          };
                  })),
          productsSoldFormFields: {
            accountNumberFormField: tmp,
            labelFormField: tmp$1
          },
          deductibleTaxFormFields: Belt_Option.map(fieldset.deductibleTaxFormFields, (function (formFields) {
                  var tmp;
                  if (field !== 1) {
                    tmp = formFields.accountNumberFormField;
                  } else {
                    var init = formFields.accountNumberFormField;
                    tmp = {
                      value: init.value,
                      touched: true,
                      errorMessage: init.errorMessage
                    };
                  }
                  var tmp$1;
                  if (field !== 2) {
                    tmp$1 = formFields.labelFormField;
                  } else {
                    var init$1 = formFields.labelFormField;
                    tmp$1 = {
                      value: init$1.value,
                      touched: true,
                      errorMessage: init$1.errorMessage
                    };
                  }
                  return {
                          accountNumberFormField: tmp,
                          labelFormField: tmp$1
                        };
                }))
        };
}

function mapFormFieldValiation(value) {
  var productsSoldFormFields = value.productsSoldFormFields;
  return {
          id: value.id,
          rate: value.rate,
          label: value.label,
          isaComptaCodeFormField: Belt_Option.map(value.isaComptaCodeFormField, validateNonEmptyStringValue),
          productsSoldFormFields: {
            accountNumberFormField: validateNonEmptyStringValue(productsSoldFormFields.accountNumberFormField),
            labelFormField: validateNonEmptyStringValue(productsSoldFormFields.labelFormField)
          },
          deductibleTaxFormFields: Belt_Option.map(value.deductibleTaxFormFields, (function (param) {
                  return {
                          accountNumberFormField: validateNonEmptyStringValue(param.accountNumberFormField),
                          labelFormField: validateNonEmptyStringValue(param.labelFormField)
                        };
                }))
        };
}

var TaxAccountFormFieldset = {
  ID: ID,
  isDeductibleTax: isDeductibleTax,
  isErrored: isErrored,
  isPristine: isPristine,
  fillWithStandard: fillWithStandard,
  changeFormFieldValue: changeFormFieldValue,
  blurFormField: blurFormField,
  mapFormFieldValiation: mapFormFieldValiation
};

function isDataErrored(param) {
  if (Belt_Option.isSome(Belt_Option.flatMap(param.isaComptaAccountNumberFormField, (function (param) {
                return param.errorMessage;
              })))) {
    return true;
  } else {
    return Belt_Array.some(param.taxesAccountsFormArrayFieldset, isErrored);
  }
}

function mapDataFormFieldValiation(data) {
  var isaComptaAccountNumberFormField = Belt_Option.map(data.isaComptaAccountNumberFormField, validateNonEmptyStringValue);
  var taxesAccountsFormArrayFieldset = Belt_Array.map(data.taxesAccountsFormArrayFieldset, mapFormFieldValiation);
  return {
          submission: data.submission,
          initialAccountingConfiguration: data.initialAccountingConfiguration,
          vendor: data.vendor,
          fiscalYearOpeningMonth: data.fiscalYearOpeningMonth,
          breakdownOfConsumerSalesByCashRegisterDailyReport: data.breakdownOfConsumerSalesByCashRegisterDailyReport,
          isaComptaAccountNumberFormField: isaComptaAccountNumberFormField,
          taxesAccountsFormArrayFieldset: taxesAccountsFormArrayFieldset
        };
}

function isSubmitting(state) {
  if (typeof state === "number") {
    return false;
  }
  if (state.TAG === /* Reloading */0) {
    var match = state._0;
    if (match.TAG === /* Ok */0) {
      return AsyncData$Pos.isBusy(match._0.submission);
    } else {
      return false;
    }
  }
  var match$1 = state._0;
  if (match$1.TAG === /* Ok */0) {
    return AsyncData$Pos.isBusy(match$1._0.submission);
  } else {
    return false;
  }
}

function isPristine$1(state) {
  var data;
  if (typeof state === "number") {
    return true;
  }
  if (state.TAG === /* Reloading */0) {
    var data$1 = state._0;
    if (data$1.TAG !== /* Ok */0) {
      return true;
    }
    data = data$1._0;
  } else {
    var data$2 = state._0;
    if (data$2.TAG !== /* Ok */0) {
      return true;
    }
    data = data$2._0;
  }
  var initialAccountingConfiguration = data.initialAccountingConfiguration;
  var initialFiscalYearOpeningMonth = Belt_Option.mapWithDefault(initialAccountingConfiguration, /* January */0, (function (param) {
          return param.fiscalYearOpeningMonth;
        }));
  var initialBreakdownOfConsumerSalesByCashRegisterDailyReport = Belt_Option.mapWithDefault(initialAccountingConfiguration, true, (function (param) {
          return param.breakdownOfConsumerSalesByCashRegisterDailyReport;
        }));
  if (FiscalYearOpeningMonth$Pos.isEqual(initialFiscalYearOpeningMonth, data.fiscalYearOpeningMonth) && data.breakdownOfConsumerSalesByCashRegisterDailyReport === initialBreakdownOfConsumerSalesByCashRegisterDailyReport && Belt_Option.mapWithDefault(data.isaComptaAccountNumberFormField, true, (function (param) {
            return !param.touched;
          }))) {
    return Belt_Array.every(data.taxesAccountsFormArrayFieldset, isPristine);
  } else {
    return false;
  }
}

function hasBeenSubmitted(state) {
  if (typeof state === "number") {
    return false;
  }
  if (state.TAG === /* Reloading */0) {
    var match = state._0;
    if (match.TAG !== /* Ok */0) {
      return false;
    }
    var tmp = match._0.submission;
    if (typeof tmp === "number" || tmp.TAG !== /* Done */1) {
      return false;
    } else {
      return true;
    }
  }
  var match$1 = state._0;
  if (match$1.TAG !== /* Ok */0) {
    return false;
  }
  var tmp$1 = match$1._0.submission;
  if (typeof tmp$1 === "number" || tmp$1.TAG !== /* Done */1) {
    return false;
  } else {
    return true;
  }
}

function initialState(param) {
  return AsyncData$Pos.notAsked(undefined);
}

function initialStateDataFromOkResult(vendor, allShopTaxes, accountingConfiguration, param) {
  var tmp;
  if (vendor) {
    if (accountingConfiguration !== undefined) {
      var value = Belt_Option.getWithDefault(accountingConfiguration.isaComptaAccountNumber, "");
      tmp = {
        value: value,
        touched: false,
        errorMessage: undefined
      };
    } else {
      tmp = {
        value: "",
        touched: false,
        errorMessage: undefined
      };
    }
  } else {
    tmp = undefined;
  }
  return {
          submission: AsyncResult$Pos.notAsked(undefined),
          initialAccountingConfiguration: accountingConfiguration,
          vendor: vendor,
          fiscalYearOpeningMonth: Belt_Option.mapWithDefault(accountingConfiguration, /* January */0, (function (param) {
                  return param.fiscalYearOpeningMonth;
                })),
          breakdownOfConsumerSalesByCashRegisterDailyReport: Belt_Option.mapWithDefault(accountingConfiguration, true, (function (param) {
                  return param.breakdownOfConsumerSalesByCashRegisterDailyReport;
                })),
          isaComptaAccountNumberFormField: tmp,
          taxesAccountsFormArrayFieldset: Belt_Array.map(allShopTaxes, (function (shopTax) {
                  var shopTaxId = shopTax.id;
                  var initialTaxAccount = Belt_Option.flatMap(accountingConfiguration, (function (param) {
                          return Belt_Array.getBy(param.taxesAccounts, (function (param) {
                                        return param.taxId === shopTaxId;
                                      }));
                        }));
                  if (initialTaxAccount === undefined) {
                    return {
                            id: shopTaxId,
                            rate: shopTax.rate,
                            label: shopTax.label,
                            isaComptaCodeFormField: AccountingVendor$Pos.isIsaCompta(vendor) ? ({
                                  value: "",
                                  touched: false,
                                  errorMessage: undefined
                                }) : undefined,
                            productsSoldFormFields: {
                              accountNumberFormField: {
                                value: "",
                                touched: false,
                                errorMessage: undefined
                              },
                              labelFormField: {
                                value: "",
                                touched: false,
                                errorMessage: undefined
                              }
                            },
                            deductibleTaxFormFields: shopTax.rate !== 0 ? ({
                                  accountNumberFormField: {
                                    value: "",
                                    touched: false,
                                    errorMessage: undefined
                                  },
                                  labelFormField: {
                                    value: "",
                                    touched: false,
                                    errorMessage: undefined
                                  }
                                }) : undefined
                          };
                  }
                  var tmp;
                  if (AccountingVendor$Pos.isIsaCompta(vendor)) {
                    var value = Belt_Option.getWithDefault(initialTaxAccount.isaComptaCode, "");
                    tmp = {
                      value: value,
                      touched: false,
                      errorMessage: undefined
                    };
                  } else {
                    tmp = undefined;
                  }
                  var match = initialTaxAccount.productsSold;
                  var tmp$1;
                  if (shopTax.rate !== 0) {
                    var match$1 = initialTaxAccount.deductibleTax;
                    tmp$1 = match$1 !== undefined ? ({
                          accountNumberFormField: {
                            value: match$1[0],
                            touched: false,
                            errorMessage: undefined
                          },
                          labelFormField: {
                            value: match$1[1],
                            touched: false,
                            errorMessage: undefined
                          }
                        }) : ({
                          accountNumberFormField: {
                            value: "",
                            touched: false,
                            errorMessage: undefined
                          },
                          labelFormField: {
                            value: "",
                            touched: false,
                            errorMessage: undefined
                          }
                        });
                  } else {
                    tmp$1 = undefined;
                  }
                  return {
                          id: shopTaxId,
                          rate: shopTax.rate,
                          label: shopTax.label,
                          isaComptaCodeFormField: tmp,
                          productsSoldFormFields: {
                            accountNumberFormField: {
                              value: match[0],
                              touched: false,
                              errorMessage: undefined
                            },
                            labelFormField: {
                              value: match[1],
                              touched: false,
                              errorMessage: undefined
                            }
                          },
                          deductibleTaxFormFields: tmp$1
                        };
                }))
        };
}

function initialStateFromOkResult(vendor, allShopTaxes, accountingConfiguration, param) {
  return AsyncResult$Pos.doneOk(initialStateDataFromOkResult(vendor, allShopTaxes, accountingConfiguration, undefined));
}

function make$3(state, action) {
  var tmp;
  var exit = 0;
  if (typeof action === "number") {
    switch (action) {
      case /* AsyncDataLoading */0 :
          tmp = AsyncResult$Pos.toBusy(state);
          break;
      case /* AsyncDataDoneError */1 :
          tmp = AsyncResult$Pos.done({
                TAG: /* Error */1,
                _0: undefined
              });
          break;
      default:
        exit = 1;
    }
  } else if (action.TAG === /* AsyncDataDoneOk */0) {
    tmp = initialStateFromOkResult(action.vendor, action.allShopTaxes, action.accountingConfiguration, undefined);
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = AsyncResult$Pos.mapOk(state, (function (data) {
            if (typeof action === "number") {
              switch (action) {
                case /* SubmitButtonClicked */2 :
                    return {
                            submission: /* Loading */1,
                            initialAccountingConfiguration: data.initialAccountingConfiguration,
                            vendor: data.vendor,
                            fiscalYearOpeningMonth: data.fiscalYearOpeningMonth,
                            breakdownOfConsumerSalesByCashRegisterDailyReport: data.breakdownOfConsumerSalesByCashRegisterDailyReport,
                            isaComptaAccountNumberFormField: data.isaComptaAccountNumberFormField,
                            taxesAccountsFormArrayFieldset: data.taxesAccountsFormArrayFieldset
                          };
                case /* NotificationBannerCloseButtonClicked */3 :
                    return {
                            submission: /* NotAsked */0,
                            initialAccountingConfiguration: data.initialAccountingConfiguration,
                            vendor: data.vendor,
                            fiscalYearOpeningMonth: data.fiscalYearOpeningMonth,
                            breakdownOfConsumerSalesByCashRegisterDailyReport: data.breakdownOfConsumerSalesByCashRegisterDailyReport,
                            isaComptaAccountNumberFormField: data.isaComptaAccountNumberFormField,
                            taxesAccountsFormArrayFieldset: data.taxesAccountsFormArrayFieldset
                          };
                case /* AutoCompleteWithStandardTaxesAccounts */4 :
                    return {
                            submission: data.submission,
                            initialAccountingConfiguration: data.initialAccountingConfiguration,
                            vendor: data.vendor,
                            fiscalYearOpeningMonth: data.fiscalYearOpeningMonth,
                            breakdownOfConsumerSalesByCashRegisterDailyReport: data.breakdownOfConsumerSalesByCashRegisterDailyReport,
                            isaComptaAccountNumberFormField: data.isaComptaAccountNumberFormField,
                            taxesAccountsFormArrayFieldset: Belt_Array.map(data.taxesAccountsFormArrayFieldset, (function (taxAccountFormFieldset) {
                                    return fillWithStandard(taxAccountFormFieldset, data.vendor);
                                  }))
                          };
                case /* IsaComptaAccountNumberFormFieldBlured */5 :
                    return {
                            submission: data.submission,
                            initialAccountingConfiguration: data.initialAccountingConfiguration,
                            vendor: data.vendor,
                            fiscalYearOpeningMonth: data.fiscalYearOpeningMonth,
                            breakdownOfConsumerSalesByCashRegisterDailyReport: data.breakdownOfConsumerSalesByCashRegisterDailyReport,
                            isaComptaAccountNumberFormField: Belt_Option.map(data.isaComptaAccountNumberFormField, (function (field) {
                                    return {
                                            value: field.value,
                                            touched: true,
                                            errorMessage: field.errorMessage
                                          };
                                  })),
                            taxesAccountsFormArrayFieldset: data.taxesAccountsFormArrayFieldset
                          };
                default:
                  return data;
              }
            } else {
              switch (action.TAG | 0) {
                case /* SubmissionSucceeded */1 :
                    var init = initialStateDataFromOkResult(action.vendor, action.allShopTaxes, action.accountingConfiguration, undefined);
                    return {
                            submission: {
                              TAG: /* Done */1,
                              _0: {
                                TAG: /* Ok */0,
                                _0: undefined
                              }
                            },
                            initialAccountingConfiguration: init.initialAccountingConfiguration,
                            vendor: init.vendor,
                            fiscalYearOpeningMonth: init.fiscalYearOpeningMonth,
                            breakdownOfConsumerSalesByCashRegisterDailyReport: init.breakdownOfConsumerSalesByCashRegisterDailyReport,
                            isaComptaAccountNumberFormField: init.isaComptaAccountNumberFormField,
                            taxesAccountsFormArrayFieldset: init.taxesAccountsFormArrayFieldset
                          };
                case /* SubmissionFailed */2 :
                    return {
                            submission: {
                              TAG: /* Done */1,
                              _0: {
                                TAG: /* Error */1,
                                _0: action._0
                              }
                            },
                            initialAccountingConfiguration: data.initialAccountingConfiguration,
                            vendor: data.vendor,
                            fiscalYearOpeningMonth: data.fiscalYearOpeningMonth,
                            breakdownOfConsumerSalesByCashRegisterDailyReport: data.breakdownOfConsumerSalesByCashRegisterDailyReport,
                            isaComptaAccountNumberFormField: data.isaComptaAccountNumberFormField,
                            taxesAccountsFormArrayFieldset: data.taxesAccountsFormArrayFieldset
                          };
                case /* VendorChanged */3 :
                    var vendor = action._0;
                    return {
                            submission: data.submission,
                            initialAccountingConfiguration: data.initialAccountingConfiguration,
                            vendor: vendor,
                            fiscalYearOpeningMonth: data.fiscalYearOpeningMonth,
                            breakdownOfConsumerSalesByCashRegisterDailyReport: data.breakdownOfConsumerSalesByCashRegisterDailyReport,
                            isaComptaAccountNumberFormField: vendor ? ({
                                  value: "",
                                  touched: false,
                                  errorMessage: undefined
                                }) : undefined,
                            taxesAccountsFormArrayFieldset: Belt_Array.map(data.taxesAccountsFormArrayFieldset, (function (taxAccount) {
                                    return {
                                            id: taxAccount.id,
                                            rate: taxAccount.rate,
                                            label: taxAccount.label,
                                            isaComptaCodeFormField: vendor ? ({
                                                  value: "",
                                                  touched: false,
                                                  errorMessage: undefined
                                                }) : undefined,
                                            productsSoldFormFields: taxAccount.productsSoldFormFields,
                                            deductibleTaxFormFields: taxAccount.deductibleTaxFormFields
                                          };
                                  }))
                          };
                case /* FiscalYearOpeningMonthChanged */4 :
                    return {
                            submission: data.submission,
                            initialAccountingConfiguration: data.initialAccountingConfiguration,
                            vendor: data.vendor,
                            fiscalYearOpeningMonth: action._0,
                            breakdownOfConsumerSalesByCashRegisterDailyReport: data.breakdownOfConsumerSalesByCashRegisterDailyReport,
                            isaComptaAccountNumberFormField: data.isaComptaAccountNumberFormField,
                            taxesAccountsFormArrayFieldset: data.taxesAccountsFormArrayFieldset
                          };
                case /* IsaComptaAccountNumberFormFieldChanged */5 :
                    var value = action._0;
                    return {
                            submission: data.submission,
                            initialAccountingConfiguration: data.initialAccountingConfiguration,
                            vendor: data.vendor,
                            fiscalYearOpeningMonth: data.fiscalYearOpeningMonth,
                            breakdownOfConsumerSalesByCashRegisterDailyReport: data.breakdownOfConsumerSalesByCashRegisterDailyReport,
                            isaComptaAccountNumberFormField: Belt_Option.map(data.isaComptaAccountNumberFormField, (function (field) {
                                    return {
                                            value: value,
                                            touched: field.touched,
                                            errorMessage: field.errorMessage
                                          };
                                  })),
                            taxesAccountsFormArrayFieldset: data.taxesAccountsFormArrayFieldset
                          };
                case /* BreakdownOfConsumerSalesByCashRegisterDailyReportChanged */6 :
                    return {
                            submission: data.submission,
                            initialAccountingConfiguration: data.initialAccountingConfiguration,
                            vendor: data.vendor,
                            fiscalYearOpeningMonth: data.fiscalYearOpeningMonth,
                            breakdownOfConsumerSalesByCashRegisterDailyReport: action._0,
                            isaComptaAccountNumberFormField: data.isaComptaAccountNumberFormField,
                            taxesAccountsFormArrayFieldset: data.taxesAccountsFormArrayFieldset
                          };
                case /* TaxAccountFormFieldChanged */7 :
                    var value$1 = action._2;
                    var field = action._1;
                    var id = action._0;
                    return {
                            submission: data.submission,
                            initialAccountingConfiguration: data.initialAccountingConfiguration,
                            vendor: data.vendor,
                            fiscalYearOpeningMonth: data.fiscalYearOpeningMonth,
                            breakdownOfConsumerSalesByCashRegisterDailyReport: data.breakdownOfConsumerSalesByCashRegisterDailyReport,
                            isaComptaAccountNumberFormField: data.isaComptaAccountNumberFormField,
                            taxesAccountsFormArrayFieldset: Belt_Array.map(data.taxesAccountsFormArrayFieldset, (function (taxAccountFormFielset) {
                                    if (id === taxAccountFormFielset.id) {
                                      return changeFormFieldValue(taxAccountFormFielset, field, value$1);
                                    } else {
                                      return taxAccountFormFielset;
                                    }
                                  }))
                          };
                case /* TaxAccountFormFieldBlured */8 :
                    var field$1 = action._1;
                    var id$1 = action._0;
                    return {
                            submission: data.submission,
                            initialAccountingConfiguration: data.initialAccountingConfiguration,
                            vendor: data.vendor,
                            fiscalYearOpeningMonth: data.fiscalYearOpeningMonth,
                            breakdownOfConsumerSalesByCashRegisterDailyReport: data.breakdownOfConsumerSalesByCashRegisterDailyReport,
                            isaComptaAccountNumberFormField: data.isaComptaAccountNumberFormField,
                            taxesAccountsFormArrayFieldset: Belt_Array.map(data.taxesAccountsFormArrayFieldset, (function (taxAccountFormFielset) {
                                    if (id$1 === taxAccountFormFielset.id) {
                                      return blurFormField(taxAccountFormFielset, field$1);
                                    } else {
                                      return taxAccountFormFielset;
                                    }
                                  }))
                          };
                default:
                  return data;
              }
            }
          }));
  }
  return AsyncResult$Pos.mapOk(tmp, mapDataFormFieldValiation);
}

var Reducer = {
  isDataErrored: isDataErrored,
  mapDataFormFieldValiation: mapDataFormFieldValiation,
  isSubmitting: isSubmitting,
  isPristine: isPristine$1,
  hasBeenSubmitted: hasBeenSubmitted,
  initialState: initialState,
  initialStateDataFromOkResult: initialStateDataFromOkResult,
  initialStateFromOkResult: initialStateFromOkResult,
  make: make$3
};

function mapReducerValuesAndShopIdToAccountingConfiguration(shopId, values) {
  return {
          shopId: shopId,
          isaComptaAccountNumber: Belt_Option.map(values.isaComptaAccountNumberFormField, (function (param) {
                  return param.value;
                })),
          fiscalYearOpeningMonth: values.fiscalYearOpeningMonth,
          taxesAccounts: Belt_Array.map(values.taxesAccountsFormArrayFieldset, (function (taxAccountFormFieldset) {
                  var match = taxAccountFormFieldset.productsSoldFormFields;
                  return {
                          taxId: taxAccountFormFieldset.id,
                          isaComptaCode: Belt_Option.map(taxAccountFormFieldset.isaComptaCodeFormField, (function (param) {
                                  return param.value;
                                })),
                          productsSold: [
                            match.accountNumberFormField.value,
                            match.labelFormField.value
                          ],
                          deductibleTax: Belt_Option.map(taxAccountFormFieldset.deductibleTaxFormFields, (function (param) {
                                  return [
                                          param.accountNumberFormField.value,
                                          param.labelFormField.value
                                        ];
                                }))
                        };
                })),
          breakdownOfConsumerSalesByCashRegisterDailyReport: values.breakdownOfConsumerSalesByCashRegisterDailyReport
        };
}

function AccountingConfigurationPage(Props) {
  var getAllShopTaxesRequest = Props.getAllShopTaxesRequest;
  var getShopAccountingConfigurationRequest = Props.getShopAccountingConfigurationRequest;
  var createOrUpdateShopAccountingConfigurationRequest = Props.createOrUpdateShopAccountingConfigurationRequest;
  var shopId = Props.shopId;
  var vendor = Props.vendor;
  var apolloClient = ReasonMLCommunity__ApolloClient.React.useApolloClient(undefined);
  var match = Navigation$Pos.useGoBack(undefined);
  var handleGoBack = match[1];
  var shops = Auth$Pos.useShops(undefined);
  var shop = Belt_Array.getBy(shops, (function (shop) {
          return shop.id === shopId;
        }));
  var match$1 = React.useReducer(make$3, AsyncData$Pos.notAsked(undefined));
  var dispatch = match$1[1];
  var state = match$1[0];
  var submitting = isSubmitting(state);
  var hasBeenSubmitted$1 = hasBeenSubmitted(state);
  var isPristine$2 = isPristine$1(state);
  var handleLoadAsyncData = function (shopId) {
    Curry._1(dispatch, /* AsyncDataLoading */0);
    var futures = Future.all2([
          Curry._2(getAllShopTaxesRequest, shopId, apolloClient),
          Curry._1(getShopAccountingConfigurationRequest, shopId)
        ]);
    Future.get(futures, (function (param) {
            var shopAccountingConfiguration = param[1];
            var allShopTaxes = param[0];
            if (shopAccountingConfiguration.TAG !== /* Ok */0) {
              return Curry._1(dispatch, /* AsyncDataDoneError */1);
            }
            if (allShopTaxes.TAG !== /* Ok */0) {
              return ;
            }
            var accountingConfiguration = shopAccountingConfiguration._0;
            var allShopTaxes$1 = allShopTaxes._0;
            if (accountingConfiguration !== undefined) {
              return Curry._1(dispatch, {
                          TAG: /* AsyncDataDoneOk */0,
                          vendor: vendor,
                          allShopTaxes: allShopTaxes$1,
                          accountingConfiguration: accountingConfiguration
                        });
            } else {
              return Curry._1(dispatch, {
                          TAG: /* AsyncDataDoneOk */0,
                          vendor: /* Excel */0,
                          allShopTaxes: allShopTaxes$1
                        });
            }
          }));
    return futures;
  };
  React.useEffect((function () {
          var future = handleLoadAsyncData(shopId);
          return (function (param) {
                    Future.cancel(future);
                  });
        }), []);
  React.useEffect((function () {
          if (submitting) {
            if (typeof state === "number" || state.TAG !== /* Done */1) {
              Curry._1(dispatch, {
                    TAG: /* SubmissionFailed */2,
                    _0: Intl$Pos.t("An unexpected error occured. Please try again or contact the support.")
                  });
            } else {
              var data = state._0;
              if (data.TAG === /* Ok */0) {
                var data$1 = data._0;
                if (isDataErrored(data$1)) {
                  Curry._1(dispatch, {
                        TAG: /* SubmissionFailed */2,
                        _0: Intl$Pos.t("There are some errors in the form, please correct them before trying to send it again.")
                      });
                } else {
                  Future.get(Future.all2([
                            Curry._2(getAllShopTaxesRequest, shopId, apolloClient),
                            Curry._2(createOrUpdateShopAccountingConfigurationRequest, getShopAccountingConfigurationRequest, mapReducerValuesAndShopIdToAccountingConfiguration(shopId, data$1))
                          ]), (function (result) {
                          var allShopTaxes = result[0];
                          if (allShopTaxes.TAG !== /* Ok */0) {
                            return Curry._1(dispatch, {
                                        TAG: /* SubmissionFailed */2,
                                        _0: Intl$Pos.t("An unexpected error occured. Please try again or contact the support.")
                                      });
                          }
                          var accountingConfiguration = result[1];
                          if (accountingConfiguration.TAG === /* Ok */0) {
                            return Curry._1(dispatch, {
                                        TAG: /* SubmissionSucceeded */1,
                                        vendor: vendor,
                                        allShopTaxes: allShopTaxes._0,
                                        accountingConfiguration: accountingConfiguration._0
                                      });
                          } else {
                            return Curry._1(dispatch, {
                                        TAG: /* SubmissionFailed */2,
                                        _0: Intl$Pos.t("An unexpected error occured. Please try again or contact the support.")
                                      });
                          }
                        }));
                }
              } else {
                Curry._1(dispatch, {
                      TAG: /* SubmissionFailed */2,
                      _0: Intl$Pos.t("An unexpected error occured. Please try again or contact the support.")
                    });
              }
            }
          }
          
        }), [submitting]);
  var titlePage = Intl$Pos.t("Setup my accounting accounts");
  var titleSubtitle = shop.name;
  var exit = 0;
  var values;
  if (typeof state === "number") {
    exit = 2;
  } else if (state.TAG === /* Reloading */0) {
    var values$1 = state._0;
    if (values$1.TAG === /* Ok */0) {
      values = values$1._0;
      exit = 1;
    } else {
      exit = 2;
    }
  } else {
    var values$2 = state._0;
    if (values$2.TAG !== /* Ok */0) {
      return React.createElement(ResourceDetailsPage$Pos.make, {
                  title: titlePage,
                  subtitle: titleSubtitle,
                  children: React.createElement(Placeholder$Pos.make, {
                        status: /* Error */2
                      })
                });
    }
    values = values$2._0;
    exit = 1;
  }
  switch (exit) {
    case 1 :
        var isErrored = isDataErrored(values);
        var handleSubmit = function (param) {
          Curry._1(dispatch, /* SubmitButtonClicked */2);
        };
        var actionsBar = React.createElement(ResourceDetailsPage$Pos.ActionsBar.make, {
              items: [
                isPristine$2 ? React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Return to export interface"), "medium", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                              Curry._1(handleGoBack, undefined);
                            }), undefined, undefined, undefined)) : React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Cancel"), "medium", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                              handleLoadAsyncData(shopId);
                            }), undefined, undefined, undefined)),
                React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Save"), "medium", "success", isErrored ? "dark" : undefined, undefined, submitting, undefined, undefined, undefined, undefined, handleSubmit, undefined, undefined, undefined))
              ]
            });
        var onRequestCloseNotificationBanner = function (param) {
          Curry._1(dispatch, /* NotificationBannerCloseButtonClicked */3);
        };
        var match$2 = values.submission;
        var notificationBanner;
        if (typeof match$2 === "number" || match$2.TAG !== /* Done */1) {
          notificationBanner = null;
        } else {
          var errorMessage = match$2._0;
          notificationBanner = errorMessage.TAG === /* Ok */0 ? React.createElement(ResourceDetailsPage$Pos.NotificationBanner.make, {
                  value: {
                    TAG: /* Ok */0,
                    _0: Intl$Pos.t("The configuration has been successfully saved")
                  },
                  onRequestClose: onRequestCloseNotificationBanner
                }) : React.createElement(ResourceDetailsPage$Pos.NotificationBanner.make, {
                  value: {
                    TAG: /* Error */1,
                    _0: errorMessage._0
                  },
                  onRequestClose: onRequestCloseNotificationBanner
                });
        }
        var match$3 = values.isaComptaAccountNumberFormField;
        var tmp;
        if (match$3 !== undefined) {
          var tmp$1 = {
            label: Intl$Pos.t("IsaCompta file number"),
            required: true,
            value: match$3.value,
            onChange: (function (value) {
                Curry._1(dispatch, {
                      TAG: /* IsaComptaAccountNumberFormFieldChanged */5,
                      _0: value
                    });
              }),
            onBlur: (function (param) {
                Curry._1(dispatch, /* IsaComptaAccountNumberFormFieldBlured */5);
              })
          };
          var tmp$2 = match$3.touched || hasBeenSubmitted$1 ? match$3.errorMessage : undefined;
          if (tmp$2 !== undefined) {
            tmp$1.errorMessage = tmp$2;
          }
          tmp = React.createElement(InputTextField$Pos.make, tmp$1);
        } else {
          tmp = null;
        }
        return React.createElement(ResourceDetailsPage$Pos.make, {
                    title: titlePage,
                    subtitle: titleSubtitle,
                    actionsBar: actionsBar,
                    notificationBanner: notificationBanner,
                    children: React.createElement(Stack$Pos.make, {
                          children: null,
                          space: "large"
                        }, React.createElement(FieldsetLayoutPanel$Pos.make, {
                              title: Intl$Pos.t("accounting_configuration.settings_general.title"),
                              description: Intl$Pos.t("accounting_configuration.settings_general.description"),
                              children: null
                            }, React.createElement(InputSelectField$Pos.make, {
                                  label: Intl$Pos.t("Format"),
                                  required: true,
                                  onChange: (function (value) {
                                      Curry._1(dispatch, {
                                            TAG: /* VendorChanged */3,
                                            _0: value
                                          });
                                    }),
                                  value: values.vendor,
                                  sections: [{
                                      items: Belt_Array.map(AccountingVendor$Pos.values, AccountingVendor$Pos.toSelectItem)
                                    }]
                                }), React.createElement(Group$Pos.make, {
                                  children: null
                                }, tmp, React.createElement(InputSelectField$Pos.make, {
                                      label: Intl$Pos.t("Fiscal year (per month of opening)"),
                                      required: true,
                                      onChange: (function (value) {
                                          Curry._1(dispatch, {
                                                TAG: /* FiscalYearOpeningMonthChanged */4,
                                                _0: value
                                              });
                                        }),
                                      searchable: false,
                                      value: values.fiscalYearOpeningMonth,
                                      sections: [{
                                          items: Belt_Array.map(FiscalYearOpeningMonth$Pos.values, FiscalYearOpeningMonth$Pos.toSelectItem)
                                        }]
                                    })), React.createElement(InputCheckboxField$Pos.make, {
                                  label: Intl$Pos.t("Breakdown of sales to consumers"),
                                  text: Intl$Pos.t("Display of daily cash register reports instead of receipts"),
                                  value: !values.breakdownOfConsumerSalesByCashRegisterDailyReport,
                                  onChange: (function (value) {
                                      Curry._1(dispatch, {
                                            TAG: /* BreakdownOfConsumerSalesByCashRegisterDailyReportChanged */6,
                                            _0: !value
                                          });
                                    })
                                })), React.createElement(FieldsetLayoutPanel$Pos.make, {
                              title: Intl$Pos.t("accounting_configuration.setting_tax_rates.title"),
                              description: Intl$Pos.t("accounting_configuration.setting_tax_rates.description"),
                              children: null
                            }, Belt_Option.isNone(values.initialAccountingConfiguration) ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), undefined, undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Stack$Pos.make, {
                                        children: null,
                                        space: "normal",
                                        align: "start"
                                      }, React.createElement(TextStyle$Pos.make, {
                                            children: Intl$Pos.t("accounting.configuration_page.help_with_accouting_standard"),
                                            size: "small"
                                          }), React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Autocomplete with accounting standard"), "small", "primary", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                                  Curry._1(dispatch, /* AutoCompleteWithStandardTaxesAccounts */4);
                                                }), undefined, undefined, undefined))), React.createElement(Divider$Pos.make, {
                                        spaceTop: "medium",
                                        spaceBottom: "medium"
                                      }), React.createElement(TextAction$Pos.make, {
                                        text: Intl$Pos.t("Learn more about accounting entries"),
                                        onPress: (function (param) {
                                            HelpCenter$Pos.showArticle("");
                                          })
                                      })) : null, React.createElement(InputTextField$Pos.make, {
                                  label: Intl$Pos.t("Journal code"),
                                  required: true,
                                  disabled: true,
                                  value: "VE",
                                  onChange: (function (param) {
                                      
                                    }),
                                  onBlur: (function (param) {
                                      
                                    })
                                }), React.createElement(Divider$Pos.make, {}), Belt_Array.mapWithIndex(values.taxesAccountsFormArrayFieldset, (function (index, param) {
                                    var deductibleTaxFormFields = param.deductibleTaxFormFields;
                                    var productsSoldFormFields = param.productsSoldFormFields;
                                    var isaComptaCodeFormField = param.isaComptaCodeFormField;
                                    var id = param.id;
                                    var tmp;
                                    if (deductibleTaxFormFields !== undefined) {
                                      var match = deductibleTaxFormFields.accountNumberFormField;
                                      var tmp$1 = {
                                        label: Intl$Pos.t("Tax account"),
                                        required: true,
                                        value: match.value,
                                        onChange: (function (value) {
                                            Curry._1(dispatch, {
                                                  TAG: /* TaxAccountFormFieldChanged */7,
                                                  _0: id,
                                                  _1: /* DeductibleTaxAccountNumber */1,
                                                  _2: value
                                                });
                                          }),
                                        onBlur: (function (param) {
                                            Curry._1(dispatch, {
                                                  TAG: /* TaxAccountFormFieldBlured */8,
                                                  _0: id,
                                                  _1: /* DeductibleTaxAccountNumber */1
                                                });
                                          })
                                      };
                                      var tmp$2 = match.touched || hasBeenSubmitted$1 ? match.errorMessage : undefined;
                                      if (tmp$2 !== undefined) {
                                        tmp$1.errorMessage = tmp$2;
                                      }
                                      tmp = React.createElement(InputTextField$Pos.make, tmp$1);
                                    } else {
                                      tmp = React.createElement(InputTextField$Pos.make, {
                                            label: Intl$Pos.t("Tax account"),
                                            disabled: true,
                                            placeholder: Intl$Pos.t("Non-deductible VAT"),
                                            value: ""
                                          });
                                    }
                                    var tmp$3;
                                    if (deductibleTaxFormFields !== undefined) {
                                      var match$1 = deductibleTaxFormFields.labelFormField;
                                      var tmp$4 = {
                                        label: Intl$Pos.t("Associated label"),
                                        required: true,
                                        value: match$1.value,
                                        onChange: (function (value) {
                                            Curry._1(dispatch, {
                                                  TAG: /* TaxAccountFormFieldChanged */7,
                                                  _0: id,
                                                  _1: /* DeductibleTaxLabel */2,
                                                  _2: value
                                                });
                                          }),
                                        onBlur: (function (param) {
                                            Curry._1(dispatch, {
                                                  TAG: /* TaxAccountFormFieldBlured */8,
                                                  _0: id,
                                                  _1: /* DeductibleTaxLabel */2
                                                });
                                          })
                                      };
                                      var tmp$5 = match$1.touched || hasBeenSubmitted$1 ? match$1.errorMessage : undefined;
                                      if (tmp$5 !== undefined) {
                                        tmp$4.errorMessage = tmp$5;
                                      }
                                      tmp$3 = React.createElement(InputTextField$Pos.make, tmp$4);
                                    } else {
                                      tmp$3 = React.createElement(InputTextField$Pos.make, {
                                            label: Intl$Pos.t("Associated label"),
                                            disabled: true,
                                            value: "",
                                            onChange: (function (value) {
                                                
                                              })
                                          });
                                    }
                                    var match$2 = productsSoldFormFields.accountNumberFormField;
                                    var tmp$6 = {
                                      label: Intl$Pos.t("Goods account"),
                                      required: true,
                                      value: match$2.value,
                                      onChange: (function (value) {
                                          Curry._1(dispatch, {
                                                TAG: /* TaxAccountFormFieldChanged */7,
                                                _0: id,
                                                _1: /* ProductsSoldAccountNumber */3,
                                                _2: value
                                              });
                                        }),
                                      onBlur: (function (param) {
                                          Curry._1(dispatch, {
                                                TAG: /* TaxAccountFormFieldBlured */8,
                                                _0: id,
                                                _1: /* ProductsSoldAccountNumber */3
                                              });
                                        })
                                    };
                                    var tmp$7 = match$2.touched || hasBeenSubmitted$1 ? match$2.errorMessage : undefined;
                                    if (tmp$7 !== undefined) {
                                      tmp$6.errorMessage = tmp$7;
                                    }
                                    var match$3 = productsSoldFormFields.labelFormField;
                                    var tmp$8 = {
                                      label: Intl$Pos.t("Associated label"),
                                      required: true,
                                      value: match$3.value,
                                      onChange: (function (value) {
                                          Curry._1(dispatch, {
                                                TAG: /* TaxAccountFormFieldChanged */7,
                                                _0: id,
                                                _1: /* ProductsSoldLabel */4,
                                                _2: value
                                              });
                                        }),
                                      onBlur: (function (param) {
                                          Curry._1(dispatch, {
                                                TAG: /* TaxAccountFormFieldBlured */8,
                                                _0: id,
                                                _1: /* ProductsSoldLabel */4
                                              });
                                        })
                                    };
                                    var tmp$9 = match$3.touched || hasBeenSubmitted$1 ? match$3.errorMessage : undefined;
                                    if (tmp$9 !== undefined) {
                                      tmp$8.errorMessage = tmp$9;
                                    }
                                    var tmp$10;
                                    if (isaComptaCodeFormField !== undefined) {
                                      var tmp$11 = {
                                        label: Intl$Pos.t("IsaCompta code"),
                                        required: true,
                                        value: isaComptaCodeFormField.value,
                                        onChange: (function (value) {
                                            Curry._1(dispatch, {
                                                  TAG: /* TaxAccountFormFieldChanged */7,
                                                  _0: id,
                                                  _1: /* IsaComptaCode */0,
                                                  _2: value
                                                });
                                          }),
                                        onBlur: (function (param) {
                                            Curry._1(dispatch, {
                                                  TAG: /* TaxAccountFormFieldBlured */8,
                                                  _0: id,
                                                  _1: /* IsaComptaCode */0
                                                });
                                          })
                                      };
                                      var tmp$12 = isaComptaCodeFormField.touched || hasBeenSubmitted$1 ? isaComptaCodeFormField.errorMessage : undefined;
                                      if (tmp$12 !== undefined) {
                                        tmp$11.errorMessage = tmp$12;
                                      }
                                      tmp$10 = React.createElement(InputTextField$Pos.make, tmp$11);
                                    } else {
                                      tmp$10 = null;
                                    }
                                    return React.createElement(React.Fragment, {
                                                children: null,
                                                key: id
                                              }, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                                                children: null
                                                              }, React.createElement(TextStyle$Pos.make, {
                                                                    children: param.label + " ",
                                                                    weight: "semibold"
                                                                  }), Belt_Option.isNone(deductibleTaxFormFields) ? React.createElement(TooltipIcon$Pos.make, {
                                                                      children: Intl$Pos.t("Used for overseas sales"),
                                                                      variation: "alert"
                                                                    }) : null)), undefined, undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(Group$Pos.make, {
                                                    children: null
                                                  }, tmp, tmp$3, React.createElement(InputTextField$Pos.make, tmp$6), React.createElement(InputTextField$Pos.make, tmp$8), tmp$10), index < (values.taxesAccountsFormArrayFieldset.length - 1 | 0) ? React.createElement(Divider$Pos.make, {
                                                      spaceTop: "medium",
                                                      spaceBottom: "xsmall"
                                                    }) : null);
                                  }))), React.createElement(FieldsetLayoutPanel$Pos.make, {
                              title: Intl$Pos.t("accounting_configuration.setting_cashflow.title"),
                              description: Intl$Pos.t("accounting_configuration.setting_cashflow.description"),
                              children: null
                            }, React.createElement(InputTextField$Pos.make, {
                                  label: Intl$Pos.t("Journal code"),
                                  required: true,
                                  infotip: Intl$Pos.t("accounting_configuration.setting_cashflow.journal_code.infotip"),
                                  disabled: true,
                                  value: "Cx",
                                  onChange: (function (param) {
                                      
                                    }),
                                  onBlur: (function (param) {
                                      
                                    })
                                }), React.createElement(Divider$Pos.make, {}), React.createElement(Group$Pos.make, {
                                  children: null
                                }, React.createElement(InputTextField$Pos.make, {
                                      label: Intl$Pos.t("Cash flow accounting account"),
                                      required: true,
                                      disabled: true,
                                      value: "53000000",
                                      onChange: (function (param) {
                                          
                                        }),
                                      onBlur: (function (param) {
                                          
                                        })
                                    }), React.createElement(InputTextField$Pos.make, {
                                      label: Intl$Pos.t("Cash flow label account"),
                                      required: true,
                                      disabled: true,
                                      value: "Caisse",
                                      onChange: (function (param) {
                                          
                                        }),
                                      onBlur: (function (param) {
                                          
                                        })
                                    })), React.createElement(Group$Pos.make, {
                                  children: null
                                }, React.createElement(InputTextField$Pos.make, {
                                      label: Intl$Pos.t("Internal transfer account"),
                                      required: true,
                                      infotip: Intl$Pos.t("accounting_configuration.setting_cashflow.internal_transfer_account.infotip"),
                                      disabled: true,
                                      value: "58000000",
                                      onChange: (function (param) {
                                          
                                        }),
                                      onBlur: (function (param) {
                                          
                                        })
                                    }), React.createElement(InputTextField$Pos.make, {
                                      label: Intl$Pos.t("Internal transfer label account"),
                                      required: true,
                                      disabled: true,
                                      value: "Virements internes",
                                      onChange: (function (param) {
                                          
                                        }),
                                      onBlur: (function (param) {
                                          
                                        })
                                    })), React.createElement(Group$Pos.make, {
                                  children: null
                                }, React.createElement(InputTextField$Pos.make, {
                                      label: Intl$Pos.t("Negative cash difference account"),
                                      required: true,
                                      disabled: true,
                                      value: "65800000",
                                      onChange: (function (param) {
                                          
                                        }),
                                      onBlur: (function (param) {
                                          
                                        })
                                    }), React.createElement(InputTextField$Pos.make, {
                                      label: Intl$Pos.t("Negative cash difference label account"),
                                      required: true,
                                      disabled: true,
                                      value: "Charges div-gestion courante",
                                      onChange: (function (param) {
                                          
                                        }),
                                      onBlur: (function (param) {
                                          
                                        })
                                    })), React.createElement(Group$Pos.make, {
                                  children: null
                                }, React.createElement(InputTextField$Pos.make, {
                                      label: Intl$Pos.t("Positive cash difference account"),
                                      required: true,
                                      disabled: true,
                                      value: "75800000",
                                      onChange: (function (param) {
                                          
                                        }),
                                      onBlur: (function (param) {
                                          
                                        })
                                    }), React.createElement(InputTextField$Pos.make, {
                                      label: Intl$Pos.t("Positive cash difference label account"),
                                      required: true,
                                      disabled: true,
                                      value: "Produits div-gestion courante",
                                      onChange: (function (param) {
                                          
                                        }),
                                      onBlur: (function (param) {
                                          
                                        })
                                    }))))
                  });
    case 2 :
        return React.createElement(ResourceDetailsPage$Pos.make, {
                    title: titlePage,
                    subtitle: titleSubtitle,
                    children: React.createElement(Placeholder$Pos.make, {
                          status: /* Loading */0
                        })
                  });
    
  }
}

var getAllShopTaxesRequest = make$1;

var createOrUpdateShopAccountingConfigurationRequest = make;

var make$4 = AccountingConfigurationPage;

export {
  shopAccountingConfigurationRequestBaseUrl ,
  CreateOrUpdateShopAccountingConfigurationRequest ,
  GetAllShopTaxesRequest ,
  FormField ,
  TaxAccountFormFieldset ,
  Reducer ,
  getAllShopTaxesRequest ,
  createOrUpdateShopAccountingConfigurationRequest ,
  mapReducerValuesAndShopIdToAccountingConfiguration ,
  make$4 as make,
}
/* shopAccountingConfigurationRequestBaseUrl Not a pure module */
