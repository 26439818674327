// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Popover$Pos from "../overlays/Popover.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as DateHelpers$Pos from "../../helpers/DateHelpers.bs.js";
import * as DayPickerRange$Pos from "./DayPickerRange.bs.js";
import * as DateRangePicker$Pos from "./DateRangePicker.bs.js";
import * as OverlayTriggerView$Pos from "../overlays/OverlayTriggerView.bs.js";

function dateRangetoPartialDateRange(value) {
  if (value !== undefined) {
    return [
            Caml_option.some(value[0]),
            Caml_option.some(value[1])
          ];
  } else {
    return [
            undefined,
            undefined
          ];
  }
}

function formatDateRange(value) {
  return Intl$Pos.dateTimeFormat("medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, value[0]) + " → " + Intl$Pos.dateTimeFormat("medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, value[1]);
}

function isCustomOrNotDefined(value) {
  if (typeof value === "number") {
    return value >= 8;
  } else {
    return true;
  }
}

function toString(notDefinedPlaceholder, value) {
  if (typeof value === "number") {
    switch (value) {
      case /* Today */0 :
          return Intl$Pos.t("Today");
      case /* Yesterday */1 :
          return Intl$Pos.t("Yesterday");
      case /* CurrentWeek */2 :
          return Intl$Pos.t("Current week");
      case /* LastWeek */3 :
          return Intl$Pos.t("Last week");
      case /* CurrentMonth */4 :
          return Intl$Pos.t("Current month");
      case /* LastMonth */5 :
          return Intl$Pos.t("Last month");
      case /* CurrentYear */6 :
          return Intl$Pos.t("Current year");
      case /* LastYear */7 :
          return Intl$Pos.t("Last year");
      case /* NotDefined */8 :
          return notDefinedPlaceholder;
      
    }
  } else {
    var match = value._0;
    var startDate = match[0];
    if (startDate === undefined) {
      return Intl$Pos.t("Custom");
    }
    var endDate = match[1];
    var startDate$1 = Caml_option.valFromOption(startDate);
    if (endDate === undefined) {
      return Intl$Pos.dateTimeFormat("medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, startDate$1) + " →  …";
    }
    var endDate$1 = Caml_option.valFromOption(endDate);
    if (DateHelpers$Pos.isSameDay(startDate$1, endDate$1)) {
      return Intl$Pos.dateTimeFormat("medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, startDate$1);
    } else {
      return formatDateRange([
                  startDate$1,
                  endDate$1
                ]);
    }
  }
}

function toDateRange(preset) {
  var now = new Date(Date.now());
  if (typeof preset === "number") {
    switch (preset) {
      case /* Today */0 :
          return [
                  DateHelpers$Pos.startOfDay(now),
                  DateHelpers$Pos.endOfDay(now)
                ];
      case /* Yesterday */1 :
          return [
                  DateHelpers$Pos.subDays(DateHelpers$Pos.startOfDay(now), 1),
                  DateHelpers$Pos.endOfDay(DateHelpers$Pos.subDays(now, 1))
                ];
      case /* CurrentWeek */2 :
          return [
                  DateHelpers$Pos.startOfWeek(now),
                  DateHelpers$Pos.endOfDay(now)
                ];
      case /* LastWeek */3 :
          return [
                  DateHelpers$Pos.startOfWeek(DateHelpers$Pos.subWeeks(now, 1)),
                  DateHelpers$Pos.endOfWeek(DateHelpers$Pos.subWeeks(now, 1))
                ];
      case /* CurrentMonth */4 :
          return [
                  DateHelpers$Pos.startOfMonth(now),
                  DateHelpers$Pos.endOfDay(now)
                ];
      case /* LastMonth */5 :
          return [
                  DateHelpers$Pos.startOfMonth(DateHelpers$Pos.subMonths(now, 1)),
                  DateHelpers$Pos.endOfMonth(DateHelpers$Pos.subMonths(now, 1))
                ];
      case /* CurrentYear */6 :
          return [
                  DateHelpers$Pos.startOfYear(now),
                  DateHelpers$Pos.endOfDay(now)
                ];
      case /* LastYear */7 :
          return [
                  DateHelpers$Pos.startOfYear(DateHelpers$Pos.subYears(now, 1)),
                  DateHelpers$Pos.endOfYear(DateHelpers$Pos.subYears(now, 1))
                ];
      case /* NotDefined */8 :
          return ;
      
    }
  } else {
    var match = preset._0;
    var startDate = match[0];
    if (startDate === undefined) {
      return ;
    }
    var endDate = match[1];
    if (endDate !== undefined) {
      return [
              Caml_option.valFromOption(startDate),
              Caml_option.valFromOption(endDate)
            ];
    } else {
      return ;
    }
  }
}

function fromPartialDateRange(range, presets) {
  var startDateA = range[0];
  if (startDateA === undefined) {
    return /* NotDefined */8;
  }
  var endDateA = range[1];
  if (endDateA === undefined) {
    return /* Custom */{
            _0: range
          };
  }
  var endDateA$1 = Caml_option.valFromOption(endDateA);
  var startDateA$1 = Caml_option.valFromOption(startDateA);
  var preset = Belt_Array.getBy(presets, (function (preset) {
          var match = toDateRange(preset);
          if (match !== undefined && DateHelpers$Pos.isSameDay(startDateA$1, match[0])) {
            return DateHelpers$Pos.isSameDay(endDateA$1, match[1]);
          } else {
            return false;
          }
        }));
  return Belt_Option.getWithDefault(preset, /* Custom */{
              _0: range
            });
}

function isEqual(a, b) {
  if (typeof a !== "number") {
    if (typeof b === "number") {
      return false;
    } else {
      return true;
    }
  }
  switch (a) {
    case /* Today */0 :
        return b === 0;
    case /* Yesterday */1 :
        return b === 1;
    case /* CurrentWeek */2 :
        return b === 2;
    case /* LastWeek */3 :
        return b === 3;
    case /* CurrentMonth */4 :
        return b === 4;
    case /* LastMonth */5 :
        return b === 5;
    case /* CurrentYear */6 :
        return b === 6;
    case /* LastYear */7 :
        return b === 7;
    case /* NotDefined */8 :
        if (typeof b === "number") {
          return b >= 8;
        } else {
          return false;
        }
    
  }
}

var StandardPreset = {
  isCustomOrNotDefined: isCustomOrNotDefined,
  toString: toString,
  toDateRange: toDateRange,
  fromPartialDateRange: fromPartialDateRange,
  isEqual: isEqual
};

var standardPresetsArray = [
  /* Today */0,
  /* Yesterday */1,
  /* CurrentWeek */2,
  /* LastWeek */3,
  /* CurrentMonth */4,
  /* LastMonth */5,
  /* CurrentYear */6,
  /* LastYear */7
];

function SelectDateRangeFilter$Component(Props) {
  var value = Props.value;
  var label = Props.label;
  var placeholderOpt = Props.placeholder;
  var disabledResetButton = Props.disabledResetButton;
  var preset = Props.preset;
  var presets = Props.presets;
  var onChange = Props.onChange;
  var triggerLabelDisplay = Props.triggerLabelDisplay;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "Pick a date range";
  var mounted = React.useRef(false);
  var match = Popover$Pos.useTrigger(undefined, undefined);
  var popoverTriggerRef = match.ref;
  var popoverAriaProps = match.ariaProps;
  var popover = match.state;
  var match$1 = Hover$Pos.use(popoverTriggerRef, undefined);
  var match$2 = React.useState(function () {
        return DayPickerRange$Pos.defaultFocusedInput;
      });
  var setFocusedInput = match$2[1];
  var match$3 = React.useState(function () {
        return dateRangetoPartialDateRange(value);
      });
  var setPartialDateRange = match$3[1];
  var partialDateRange = match$3[0];
  var match$4 = React.useState(function () {
        return Curry._2(preset.fromPartialDateRange, partialDateRange, presets);
      });
  var setPreset = match$4[1];
  var preset$1 = match$4[0];
  var match$5 = React.useState(function () {
        var endDate = partialDateRange[1];
        if (endDate !== undefined) {
          return Caml_option.valFromOption(endDate);
        } else {
          return new Date();
        }
      });
  var setVisibleMonth = match$5[1];
  React.useEffect((function () {
          if (mounted.current) {
            var exit = 0;
            if (value !== undefined) {
              var rangeStartDate = partialDateRange[0];
              if (rangeStartDate !== undefined) {
                var rangeEndDate = partialDateRange[1];
                if (!(rangeEndDate !== undefined && value[0].getTime() === Caml_option.valFromOption(rangeStartDate).getTime() && value[1].getTime() === Caml_option.valFromOption(rangeEndDate).getTime())) {
                  exit = 1;
                }
                
              } else {
                exit = 1;
              }
            } else if (partialDateRange[0] !== undefined || partialDateRange[1] !== undefined) {
              exit = 1;
            }
            if (exit === 1) {
              Curry._1(setPartialDateRange, (function (param) {
                      return dateRangetoPartialDateRange(value);
                    }));
            }
            
          }
          
        }), [value]);
  React.useEffect((function () {
          if (mounted.current) {
            if (value !== undefined) {
              var startPartialDateRange = partialDateRange[0];
              if (startPartialDateRange !== undefined) {
                var endPartialDateRange = partialDateRange[1];
                if (endPartialDateRange !== undefined) {
                  var endPartialDateRange$1 = Caml_option.valFromOption(endPartialDateRange);
                  var startPartialDateRange$1 = Caml_option.valFromOption(startPartialDateRange);
                  if (value[0].getTime() !== startPartialDateRange$1.getTime() || value[1].getTime() !== endPartialDateRange$1.getTime()) {
                    Curry._1(onChange, [
                          startPartialDateRange$1,
                          endPartialDateRange$1
                        ]);
                  }
                  
                }
                
              } else if (partialDateRange[1] !== undefined) {
                
              } else {
                Curry._1(onChange, undefined);
              }
            } else {
              var startPartialDateRange$2 = partialDateRange[0];
              if (startPartialDateRange$2 !== undefined) {
                var endPartialDateRange$2 = partialDateRange[1];
                if (endPartialDateRange$2 !== undefined) {
                  Curry._1(onChange, [
                        Caml_option.valFromOption(startPartialDateRange$2),
                        Caml_option.valFromOption(endPartialDateRange$2)
                      ]);
                }
                
              }
              
            }
            var match = Curry._1(preset.toDateRange, preset$1);
            var exit = 0;
            if (match !== undefined) {
              var startB = partialDateRange[0];
              if (startB !== undefined) {
                var endB = partialDateRange[1];
                if (!(endB !== undefined && match[0].valueOf() === Caml_option.valFromOption(startB).valueOf() && match[1].valueOf() === Caml_option.valFromOption(endB).valueOf())) {
                  exit = 1;
                }
                
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
            if (exit === 1) {
              Curry._1(setPreset, (function (param) {
                      return Curry._2(preset.fromPartialDateRange, partialDateRange, presets);
                    }));
            }
            
          }
          
        }), [partialDateRange]);
  React.useEffect((function () {
          if (mounted.current) {
            var match = Curry._1(preset.toDateRange, preset$1);
            var startPartialDateRange = partialDateRange[0];
            var exit = 0;
            if (match !== undefined) {
              if (startPartialDateRange !== undefined) {
                var endPartialDateRange = partialDateRange[1];
                if (!(endPartialDateRange !== undefined && Caml_option.valFromOption(startPartialDateRange).getTime() === match[0].getTime() && Caml_option.valFromOption(endPartialDateRange).getTime() === match[1].getTime())) {
                  exit = 1;
                }
                
              } else {
                exit = 1;
              }
            }
            if (exit === 1) {
              Curry._1(setPartialDateRange, (function (param) {
                      return dateRangetoPartialDateRange(match);
                    }));
            }
            if (!Curry._1(preset.isCustomOrNotDefined, preset$1)) {
              Curry._1(popover.close, undefined);
            }
            
          }
          
        }), [preset$1]);
  React.useEffect((function () {
          mounted.current = true;
          return (function (param) {
                    mounted.current = false;
                  });
        }), []);
  var onDatesChange = function (param) {
    var endDate = param[1];
    var startDate = param[0];
    Curry._1(setPartialDateRange, (function (param) {
            return [
                    Belt_Option.map(startDate, DateHelpers$Pos.startOfDay),
                    Belt_Option.map(endDate, DateHelpers$Pos.endOfDay)
                  ];
          }));
  };
  var onFocusChange = function (focusedInput) {
    Curry._1(setFocusedInput, (function (param) {
            if (focusedInput == null) {
              return DayPickerRange$Pos.defaultFocusedInput;
            } else {
              return focusedInput;
            }
          }));
  };
  var onRequestReset = function (param) {
    Curry._1(setPartialDateRange, (function (param) {
            return [
                    undefined,
                    undefined
                  ];
          }));
  };
  var onPrevMonthClick = function (date) {
    Curry._1(setVisibleMonth, (function (param) {
            return date;
          }));
  };
  var onNextMonthClick = function (date) {
    Curry._1(setVisibleMonth, (function (param) {
            return date;
          }));
  };
  var onChangePreset = function (preset$2) {
    Curry._1(setPreset, (function (param) {
            return preset$2;
          }));
    var match = Curry._1(preset.toDateRange, preset$2);
    var endDate = partialDateRange[1];
    if (endDate === undefined) {
      return ;
    }
    if (match === undefined) {
      return ;
    }
    var nextEndDate = match[1];
    if (!DateHelpers$Pos.isSameMonth(Caml_option.valFromOption(endDate), nextEndDate)) {
      return Curry._1(setVisibleMonth, (function (param) {
                    return nextEndDate;
                  }));
    }
    
  };
  var tmp = {
    children: triggerLabelDisplay === "showDateRange" ? (
        value !== undefined ? formatDateRange(value) : placeholder
      ) : Curry._2(preset.toString, placeholder, preset$1),
    preset: "filter",
    icon: "calendar",
    hovered: match$1[1],
    active: popover.isOpen,
    focused: popover.isOpen
  };
  if (label !== undefined) {
    tmp.label = label;
  }
  var tmp$1;
  if (popover.isOpen) {
    var tmp$2 = {
      focusedInput: match$2[0],
      visibleMonth: match$5[0],
      activePreset: preset$1,
      presets: presets,
      formatPreset: (function (preset$2) {
          return Curry._2(preset.toString, placeholder, preset$2);
        }),
      isEqualPreset: preset.isEqual,
      range: partialDateRange,
      onChangePreset: onChangePreset,
      onRequestReset: onRequestReset,
      onDatesChange: onDatesChange,
      onFocusChange: onFocusChange,
      onPrevMonthClick: onPrevMonthClick,
      onNextMonthClick: onNextMonthClick
    };
    if (disabledResetButton !== undefined) {
      tmp$2.disabledResetButton = disabledResetButton;
    }
    tmp$1 = React.createElement(Popover$Pos.make, {
          children: React.createElement(Popover$Pos.Dialog.make, {
                children: React.createElement(DateRangePicker$Pos.make, tmp$2),
                ariaProps: popoverAriaProps.overlayProps
              }),
          triggerRef: popoverTriggerRef,
          state: {
            isOpen: popover.isOpen,
            close: popover.close,
            toggle: popover.toggle
          },
          placement: "bottom start",
          dismissable: false
        });
  } else {
    tmp$1 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(OverlayTriggerView$Pos.make, tmp), popoverAriaProps.triggerProps, undefined, undefined, undefined, undefined, (function (param) {
                        Curry._1(popover.toggle, undefined);
                      }), undefined, popoverTriggerRef, undefined)), tmp$1);
}

function SelectDateRangeFilter$Gen(Props) {
  var value = Props.value;
  var label = Props.label;
  var placeholder = Props.placeholder;
  var disabledResetButton = Props.disabledResetButton;
  var onChange = Props.onChange;
  var triggerLabelDisplay = Props.triggerLabelDisplay;
  var tmp = {
    preset: StandardPreset,
    presets: standardPresetsArray,
    onChange: onChange,
    triggerLabelDisplay: triggerLabelDisplay
  };
  if (value !== undefined) {
    tmp.value = Caml_option.valFromOption(value);
  }
  if (label !== undefined) {
    tmp.label = Caml_option.valFromOption(label);
  }
  if (placeholder !== undefined) {
    tmp.placeholder = Caml_option.valFromOption(placeholder);
  }
  if (disabledResetButton !== undefined) {
    tmp.disabledResetButton = Caml_option.valFromOption(disabledResetButton);
  }
  return React.createElement(SelectDateRangeFilter$Component, tmp);
}

var Gen = {
  make: SelectDateRangeFilter$Gen
};

function SelectDateRangeFilter(Props) {
  var value = Props.value;
  var label = Props.label;
  var placeholder = Props.placeholder;
  var disabledResetButton = Props.disabledResetButton;
  var preset = Props.preset;
  var presets = Props.presets;
  var onChange = Props.onChange;
  var triggerLabelDisplay = Props.triggerLabelDisplay;
  if (preset !== undefined && presets !== undefined) {
    var tmp = {
      preset: preset,
      presets: presets,
      onChange: onChange,
      triggerLabelDisplay: triggerLabelDisplay
    };
    if (value !== undefined) {
      tmp.value = Caml_option.valFromOption(value);
    }
    if (label !== undefined) {
      tmp.label = Caml_option.valFromOption(label);
    }
    if (placeholder !== undefined) {
      tmp.placeholder = Caml_option.valFromOption(placeholder);
    }
    if (disabledResetButton !== undefined) {
      tmp.disabledResetButton = Caml_option.valFromOption(disabledResetButton);
    }
    return React.createElement(SelectDateRangeFilter$Component, tmp);
  }
  var tmp$1 = {
    preset: StandardPreset,
    presets: standardPresetsArray,
    onChange: onChange,
    triggerLabelDisplay: triggerLabelDisplay
  };
  if (value !== undefined) {
    tmp$1.value = Caml_option.valFromOption(value);
  }
  if (label !== undefined) {
    tmp$1.label = Caml_option.valFromOption(label);
  }
  if (placeholder !== undefined) {
    tmp$1.placeholder = Caml_option.valFromOption(placeholder);
  }
  if (disabledResetButton !== undefined) {
    tmp$1.disabledResetButton = Caml_option.valFromOption(disabledResetButton);
  }
  return React.createElement(SelectDateRangeFilter$Component, tmp$1);
}

var defaultFocusedInput = DayPickerRange$Pos.defaultFocusedInput;

var make = SelectDateRangeFilter;

export {
  formatDateRange ,
  StandardPreset ,
  standardPresetsArray ,
  dateRangetoPartialDateRange ,
  defaultFocusedInput ,
  Gen ,
  make ,
}
/* react Not a pure module */
