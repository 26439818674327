// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Svg$Pos from "../../primitives/Svg.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Badge$Pos from "../../resources/feedback-indicators/Badge.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Tooltip$Pos from "../../resources/overlays/Tooltip.bs.js";
import * as Pastille$Pos from "../../resources/feedback-indicators/Pastille.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as InlineText$Pos from "../../resources/layout-and-structure/InlineText.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Stylex from "@stylexjs/stylex";
import * as CatalogProduct$Pos from "../Catalog/CatalogProduct.bs.js";

var styles = Stylex.create({
      wrapperTooltip: {
        backgroundColor: "#35334b",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        paddingBottom: "8px",
        paddingTop: "4px"
      },
      labelTooltip: {
        color: "#bdbdca",
        font: "normal 400 12px \"Libre Franklin\"",
        lineHeight: "2px",
        paddingRight: "4px"
      },
      textTooltip: {
        color: "#fefefe",
        font: "normal 400 12px \"Libre Franklin\""
      }
    });

function styleProps(param) {
  return Stylex.props(styles.wrapperTooltip);
}

function labelStyleProps(param) {
  return Stylex.props(styles.labelTooltip);
}

function textStyleProps(param) {
  return Stylex.props(styles.textTooltip);
}

function ProductReferenceTableCell$TooltipIconTrigger(Props) {
  var information = Props.information;
  var match = Hover$Pos.use(undefined, undefined);
  var hovered = match[1];
  var match$1 = Stylex.props(styles.wrapperTooltip);
  var match$2 = Stylex.props(styles.labelTooltip);
  var labelClassName = match$2.className;
  var labelStyle = match$2.style;
  var match$3 = Stylex.props(styles.textTooltip);
  var textClassName = match$3.className;
  var textStyle = match$3.style;
  var tmp = {};
  if (match$1.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match$1.className);
  }
  if (match$1.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match$1.style);
  }
  var tmp$1 = {};
  if (labelClassName !== undefined) {
    tmp$1.className = Caml_option.valFromOption(labelClassName);
  }
  if (labelStyle !== undefined) {
    tmp$1.style = Caml_option.valFromOption(labelStyle);
  }
  var tmp$2 = {};
  if (textClassName !== undefined) {
    tmp$2.className = Caml_option.valFromOption(textClassName);
  }
  if (textStyle !== undefined) {
    tmp$2.style = Caml_option.valFromOption(textStyle);
  }
  var tmp$3 = {};
  if (labelClassName !== undefined) {
    tmp$3.className = Caml_option.valFromOption(labelClassName);
  }
  if (labelStyle !== undefined) {
    tmp$3.style = Caml_option.valFromOption(labelStyle);
  }
  var tmp$4 = {};
  if (textClassName !== undefined) {
    tmp$4.className = Caml_option.valFromOption(textClassName);
  }
  if (textStyle !== undefined) {
    tmp$4.style = Caml_option.valFromOption(textStyle);
  }
  var overlayContent = React.createElement("div", tmp, React.createElement(InlineText$Pos.make, {
            children: null
          }, React.createElement("span", tmp$1, Intl$Pos.t("Name") + Intl$Pos.t(":")), React.createElement("span", tmp$2, information.productName + " | " + information.variantName)), Belt_Option.mapWithDefault(information.sku, null, (function (sku) {
              var tmp = {};
              if (labelClassName !== undefined) {
                tmp.className = Caml_option.valFromOption(labelClassName);
              }
              if (labelStyle !== undefined) {
                tmp.style = Caml_option.valFromOption(labelStyle);
              }
              var tmp$1 = {};
              if (textClassName !== undefined) {
                tmp$1.className = Caml_option.valFromOption(textClassName);
              }
              if (textStyle !== undefined) {
                tmp$1.style = Caml_option.valFromOption(textStyle);
              }
              return React.createElement(React.Fragment, undefined, React.createElement(InlineText$Pos.make, {
                              children: null
                            }, React.createElement("span", tmp, Intl$Pos.t("SKU code") + Intl$Pos.t(":")), React.createElement("span", tmp$1, sku)));
            })), Belt_Option.mapWithDefault(information.plu, null, (function (plu) {
              var tmp = {};
              if (labelClassName !== undefined) {
                tmp.className = Caml_option.valFromOption(labelClassName);
              }
              if (labelStyle !== undefined) {
                tmp.style = Caml_option.valFromOption(labelStyle);
              }
              var tmp$1 = {};
              if (textClassName !== undefined) {
                tmp$1.className = Caml_option.valFromOption(textClassName);
              }
              if (textStyle !== undefined) {
                tmp$1.style = Caml_option.valFromOption(textStyle);
              }
              return React.createElement(React.Fragment, undefined, React.createElement(InlineText$Pos.make, {
                              children: null
                            }, React.createElement("span", tmp, Intl$Pos.t("PLU code") + Intl$Pos.t(":")), React.createElement("span", tmp$1, plu)));
            })), Belt_Option.mapWithDefault(information.internalCode, null, (function (internalCode) {
              var tmp = {};
              if (labelClassName !== undefined) {
                tmp.className = Caml_option.valFromOption(labelClassName);
              }
              if (labelStyle !== undefined) {
                tmp.style = Caml_option.valFromOption(labelStyle);
              }
              var tmp$1 = {};
              if (textClassName !== undefined) {
                tmp$1.className = Caml_option.valFromOption(textClassName);
              }
              if (textStyle !== undefined) {
                tmp$1.style = Caml_option.valFromOption(textStyle);
              }
              return React.createElement(React.Fragment, undefined, React.createElement(InlineText$Pos.make, {
                              children: null
                            }, React.createElement("span", tmp, Intl$Pos.t("Internal code") + Intl$Pos.t(":")), React.createElement("span", tmp$1, internalCode)));
            })), React.createElement(Box$Pos.make, Box$Pos.makeProps(undefined, undefined, undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined)), Belt_Option.mapWithDefault(information.color, null, (function (color) {
              var tmp = {};
              if (labelClassName !== undefined) {
                tmp.className = Caml_option.valFromOption(labelClassName);
              }
              if (labelStyle !== undefined) {
                tmp.style = Caml_option.valFromOption(labelStyle);
              }
              var tmp$1 = {};
              if (textClassName !== undefined) {
                tmp$1.className = Caml_option.valFromOption(textClassName);
              }
              if (textStyle !== undefined) {
                tmp$1.style = Caml_option.valFromOption(textStyle);
              }
              return React.createElement(InlineText$Pos.make, {
                          children: null
                        }, React.createElement("span", tmp, Intl$Pos.t("Color") + Intl$Pos.t(":")), React.createElement("span", tmp$1, CatalogProduct$Pos.Color.toLabel(color)));
            })), Belt_Option.mapWithDefault(information.producerName, null, (function (producer) {
              var tmp = {};
              if (labelClassName !== undefined) {
                tmp.className = Caml_option.valFromOption(labelClassName);
              }
              if (labelStyle !== undefined) {
                tmp.style = Caml_option.valFromOption(labelStyle);
              }
              var tmp$1 = {};
              if (textClassName !== undefined) {
                tmp$1.className = Caml_option.valFromOption(textClassName);
              }
              if (textStyle !== undefined) {
                tmp$1.style = Caml_option.valFromOption(textStyle);
              }
              return React.createElement(InlineText$Pos.make, {
                          children: null
                        }, React.createElement("span", tmp, Intl$Pos.t("Producer") + Intl$Pos.t(":")), React.createElement("span", tmp$1, producer));
            })), Belt_Option.mapWithDefault(information.supplierName, null, (function (supplier) {
              var tmp = {};
              if (labelClassName !== undefined) {
                tmp.className = Caml_option.valFromOption(labelClassName);
              }
              if (labelStyle !== undefined) {
                tmp.style = Caml_option.valFromOption(labelStyle);
              }
              var tmp$1 = {};
              if (textClassName !== undefined) {
                tmp$1.className = Caml_option.valFromOption(textClassName);
              }
              if (textStyle !== undefined) {
                tmp$1.style = Caml_option.valFromOption(textStyle);
              }
              return React.createElement(InlineText$Pos.make, {
                          children: null
                        }, React.createElement("span", tmp, Intl$Pos.t("Supplier") + Intl$Pos.t(":")), React.createElement("span", tmp$1, supplier));
            })), Belt_Option.mapWithDefault(information.designation, null, (function (designation) {
              var tmp = {};
              if (labelClassName !== undefined) {
                tmp.className = Caml_option.valFromOption(labelClassName);
              }
              if (labelStyle !== undefined) {
                tmp.style = Caml_option.valFromOption(labelStyle);
              }
              var tmp$1 = {};
              if (textClassName !== undefined) {
                tmp$1.className = Caml_option.valFromOption(textClassName);
              }
              if (textStyle !== undefined) {
                tmp$1.style = Caml_option.valFromOption(textStyle);
              }
              return React.createElement(InlineText$Pos.make, {
                          children: null
                        }, React.createElement("span", tmp, Intl$Pos.t("Designation") + Intl$Pos.t(":")), React.createElement("span", tmp$1, designation));
            })), Belt_Option.mapWithDefault(information.productFamily, null, (function (productFamily) {
              var tmp = {};
              if (labelClassName !== undefined) {
                tmp.className = Caml_option.valFromOption(labelClassName);
              }
              if (labelStyle !== undefined) {
                tmp.style = Caml_option.valFromOption(labelStyle);
              }
              var tmp$1 = {};
              if (textClassName !== undefined) {
                tmp$1.className = Caml_option.valFromOption(textClassName);
              }
              if (textStyle !== undefined) {
                tmp$1.style = Caml_option.valFromOption(textStyle);
              }
              return React.createElement(InlineText$Pos.make, {
                          children: null
                        }, React.createElement("span", tmp, Intl$Pos.t("Family") + Intl$Pos.t(":")), React.createElement("span", tmp$1, productFamily));
            })), Belt_Option.mapWithDefault(information.beerType, null, (function (beerType) {
              var tmp = {};
              if (labelClassName !== undefined) {
                tmp.className = Caml_option.valFromOption(labelClassName);
              }
              if (labelStyle !== undefined) {
                tmp.style = Caml_option.valFromOption(labelStyle);
              }
              var tmp$1 = {};
              if (textClassName !== undefined) {
                tmp$1.className = Caml_option.valFromOption(textClassName);
              }
              if (textStyle !== undefined) {
                tmp$1.style = Caml_option.valFromOption(textStyle);
              }
              return React.createElement(InlineText$Pos.make, {
                          children: null
                        }, React.createElement("span", tmp, Intl$Pos.t("Beer type") + Intl$Pos.t(":")), React.createElement("span", tmp$1, beerType));
            })), Belt_Option.mapWithDefault(information.wineType, null, (function (wineType) {
              var tmp = {};
              if (labelClassName !== undefined) {
                tmp.className = Caml_option.valFromOption(labelClassName);
              }
              if (labelStyle !== undefined) {
                tmp.style = Caml_option.valFromOption(labelStyle);
              }
              var tmp$1 = {};
              if (textClassName !== undefined) {
                tmp$1.className = Caml_option.valFromOption(textClassName);
              }
              if (textStyle !== undefined) {
                tmp$1.style = Caml_option.valFromOption(textStyle);
              }
              return React.createElement(InlineText$Pos.make, {
                          children: null
                        }, React.createElement("span", tmp, Intl$Pos.t("Wine type") + Intl$Pos.t(":")), React.createElement("span", tmp$1, CatalogProduct$Pos.WineType.toLabel(wineType)));
            })), Belt_Option.mapWithDefault(information.whiteWineType, null, (function (whiteWineType) {
              var tmp = {};
              if (labelClassName !== undefined) {
                tmp.className = Caml_option.valFromOption(labelClassName);
              }
              if (labelStyle !== undefined) {
                tmp.style = Caml_option.valFromOption(labelStyle);
              }
              var tmp$1 = {};
              if (textClassName !== undefined) {
                tmp$1.className = Caml_option.valFromOption(textClassName);
              }
              if (textStyle !== undefined) {
                tmp$1.style = Caml_option.valFromOption(textStyle);
              }
              return React.createElement(InlineText$Pos.make, {
                          children: null
                        }, React.createElement("span", tmp, Intl$Pos.t("White wine type") + Intl$Pos.t(":")), React.createElement("span", tmp$1, CatalogProduct$Pos.WhiteWineType.toLabel(whiteWineType)));
            })), Belt_Option.mapWithDefault(information.region, null, (function (region) {
              var formattedRegion = region + ", " + information.country;
              var tmp = {};
              if (labelClassName !== undefined) {
                tmp.className = Caml_option.valFromOption(labelClassName);
              }
              if (labelStyle !== undefined) {
                tmp.style = Caml_option.valFromOption(labelStyle);
              }
              var tmp$1 = {};
              if (textClassName !== undefined) {
                tmp$1.className = Caml_option.valFromOption(textClassName);
              }
              if (textStyle !== undefined) {
                tmp$1.style = Caml_option.valFromOption(textStyle);
              }
              return React.createElement(InlineText$Pos.make, {
                          children: null
                        }, React.createElement("span", tmp, Intl$Pos.t("Region") + Intl$Pos.t(":")), React.createElement("span", tmp$1, formattedRegion));
            })), React.createElement(InlineText$Pos.make, {
            children: null
          }, React.createElement("span", tmp$3, Intl$Pos.t("Category") + Intl$Pos.t(":")), React.createElement("span", tmp$4, information.categoryName)), Belt_Option.mapWithDefault(information.alcoholVolume, null, (function (alcoholVolume) {
              var tmp = {};
              if (labelClassName !== undefined) {
                tmp.className = Caml_option.valFromOption(labelClassName);
              }
              if (labelStyle !== undefined) {
                tmp.style = Caml_option.valFromOption(labelStyle);
              }
              var tmp$1 = {};
              if (textClassName !== undefined) {
                tmp$1.className = Caml_option.valFromOption(textClassName);
              }
              if (textStyle !== undefined) {
                tmp$1.style = Caml_option.valFromOption(textStyle);
              }
              return React.createElement(InlineText$Pos.make, {
                          children: null
                        }, React.createElement("span", tmp, Intl$Pos.t("Alcohol volume") + Intl$Pos.t(":")), React.createElement("span", tmp$1, alcoholVolume));
            })));
  return React.createElement("div", {
              ref: match[0],
              style: {
                display: "flex"
              }
            }, React.createElement(Tooltip$Pos.make, {
                  children: React.createElement(Svg$Pos.make, {
                        children: null,
                        width: "20",
                        height: "14",
                        viewBox: "0 0 14 14",
                        style: {
                          height: "100%"
                        }
                      }, React.createElement(Svg$Pos.Path.make, {
                            d: "M6 11.2A5.2 5.2 0 1 0 .8 6v4.2a1 1 0 0 0 1 1H6z",
                            fill: hovered ? "#25243A" : "transparent",
                            transform: "translate(0, 0.5)"
                          }), React.createElement(Svg$Pos.Path.make, {
                            d: hovered ? "M6.99997 10C7.27611 10 7.49997 9.77614 7.49997 9.5L7.49998 7.49998L9.5 7.5C9.77614 7.5 10 7.27614 10 7C10 6.72386 9.77614 6.5 9.5 6.5L7.49999 6.5L7.50001 4.5C7.50001 4.22386 7.27615 4 7.00001 4C6.72387 4 6.50001 4.22386 6.50001 4.5L6.50001 6.5L4.5 6.5C4.22386 6.5 4 6.72382 4 6.99996C4 7.2761 4.22386 7.49996 4.5 7.49996L6.50001 7.49998L6.50001 9.5C6.50001 9.77614 6.72383 10 6.99997 10Z" : "M6.99997 11C7.27611 11 7.49997 10.7761 7.49997 10.5L7.49999 7.49998L10.5 7.5C10.7761 7.5 11 7.27614 11 7C11 6.72386 10.7761 6.5 10.5 6.5L7.49999 6.5L7.50001 3.5C7.50001 3.22386 7.27615 3 7.00001 3C6.72387 3 6.50001 3.22386 6.50001 3.5L6.50001 6.5L3.5 6.5C3.22386 6.5 3 6.72382 3 6.99996C3 7.2761 3.22386 7.49996 3.5 7.49996L6.50001 7.49998L6.50001 10.5C6.50001 10.7761 6.72383 11 6.99997 11Z",
                            fill: hovered ? "#FFF" : "#A2A1B0",
                            transform: "translate(-1, 0)"
                          })),
                  arrowed: false,
                  placement: "bottom start",
                  offset: 1,
                  crossOffset: 4,
                  content: {
                    TAG: /* Component */1,
                    _0: overlayContent
                  },
                  delay: 0,
                  immediateClose: true
                }));
}

var TooltipIconTrigger = {
  styles: styles,
  styleProps: styleProps,
  labelStyleProps: labelStyleProps,
  textStyleProps: textStyleProps,
  make: ProductReferenceTableCell$TooltipIconTrigger
};

var styles$1 = Stylex.create({
      root: {
        backgroundColor: "#f3f3f7",
        border: "1px solid #d7d7e0",
        borderRadius: "5px",
        boxShadow: "#f3f3f7 -8px 0 13px 10px",
        alignItems: "center",
        display: "flex",
        height: "24px",
        justifyContent: "center",
        margin: "2px 0",
        position: "absolute",
        right: "-30px",
        width: "21px"
      }
    });

function styleProps$1(hovered, contrasted) {
  return Stylex.props(styles$1.root, {
              opacity: hovered ? 1 : 0,
              transition: "width .15s ease, opacity .15s ease",
              width: hovered ? "21px" : "0px"
            }, {
              backgroundColor: contrasted ? "#fefefe" : "#f8f8fb",
              borderColor: contrasted ? "#d7d7e0" : "#bdbdca"
            });
}

function ProductReferenceTableCell$PressableIconIndicator(Props) {
  var contrasted = Props.contrasted;
  var hovered = Props.hovered;
  var contrasted$1 = !hovered && contrasted;
  var match = styleProps$1(hovered, contrasted$1);
  var tmp = {};
  if (match.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match.className);
  }
  if (match.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match.style);
  }
  return React.createElement("div", tmp, React.createElement(Svg$Pos.make, {
                  children: React.createElement(Svg$Pos.Path.make, {
                        d: "M 11.236 4.018 L 16.683 9.464 L 1.97 9.464 C 1.559 9.464 1.301 9.91 1.508 10.267 C 1.603 10.433 1.779 10.535 1.97 10.535 L 16.799 10.535 L 11.352 15.981 C 11.06 16.272 11.194 16.768 11.592 16.876 C 11.775 16.926 11.973 16.873 12.108 16.738 L 18.408 10.436 C 18.618 10.227 18.618 9.887 18.408 9.679 L 11.991 3.263 C 11.784 3.053 11.444 3.053 11.236 3.263 C 11.027 3.47 11.027 3.81 11.236 4.018 Z",
                        fill: "#25243A"
                      }),
                  width: "14",
                  height: "16",
                  viewBox: "0 0 20 20"
                }));
}

var PressableIconIndicator = {
  styles: styles$1,
  styleProps: styleProps$1,
  make: ProductReferenceTableCell$PressableIconIndicator
};

var styles$2 = Stylex.create({
      root: {
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        gap: "4px",
        height: "44px",
        marginTop: "1px",
        position: "relative",
        width: "100%"
      },
      pastille: {
        minWidth: "8px",
        paddingTop: "4.5px"
      },
      separator: {
        backgroundColor: "#a2a1b0",
        height: "15px",
        margin: "0 8px",
        minWidth: "2px"
      },
      primaryTextProductName: {
        color: "#25243a",
        font: "normal 600 13px \"Libre Franklin\"",
        lineHeight: "16px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        flex: "1",
        maxWidth: "fit-content",
        minWidth: "20px",
        overflow: "hidden"
      },
      primaryTextVariantName: {
        color: "#25243a",
        font: "normal 600 13px \"Libre Franklin\"",
        lineHeight: "16px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
      },
      primaryTextDisabled: {
        color: "#797885",
        fontWeight: 400,
        transform: "skewX(-12deg)"
      },
      secondaryTextDescription: {
        color: "#797885",
        font: "normal 400 11px \"Libre Franklin\"",
        lineHeight: "16px",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
      }
    });

function styleProps$2(disabled) {
  return Stylex.props(styles$2.root, disabled ? ({
                  cursor: "auto"
                }) : ({}));
}

function pastilleStyleProps(param) {
  return Stylex.props(styles$2.pastille);
}

function productNameStyleProps(disabled) {
  return Stylex.props(styles$2.primaryTextProductName, disabled ? styles$2.primaryTextDisabled : ({}));
}

function variantNameStyleProps(disabled) {
  return Stylex.props(styles$2.primaryTextVariantName, disabled ? styles$2.primaryTextDisabled : ({}));
}

function descriptionNameStyleProps(param) {
  return Stylex.props(styles$2.secondaryTextDescription);
}

function separatorStyleProps(disabled) {
  return Stylex.props(styles$2.separator, disabled ? ({
                  opacity: 0.7,
                  transform: "skewX(-12deg)",
                  marginRight: "6px"
                }) : ({}));
}

function ProductReferenceTableCell(Props) {
  var redirectRoute = Props.redirectRoute;
  var badge = Props.badge;
  var contrastedOpt = Props.contrasted;
  var disabledOpt = Props.disabled;
  var errorMessage = Props.errorMessage;
  var productKind = Props.productKind;
  var information = Props.information;
  var contrasted = contrastedOpt !== undefined ? contrastedOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var match = Hover$Pos.use(undefined, undefined);
  var match$1 = styleProps$2(disabled);
  var match$2 = Stylex.props(styles$2.pastille);
  var match$3 = separatorStyleProps(disabled);
  var match$4 = productNameStyleProps(disabled);
  var match$5 = variantNameStyleProps(disabled);
  var match$6 = Stylex.props(styles$2.secondaryTextDescription);
  var to = Belt_Option.mapWithDefault(redirectRoute, {
        TAG: /* Route */0,
        _0: "#"
      }, (function (route) {
          return {
                  TAG: /* Route */0,
                  _0: route
                };
        }));
  var formattedDescription = CatalogProduct$Pos.Information.formateDescription(productKind, information, undefined, undefined);
  var pastilleColor = Belt_Option.map(information.color, (function (color) {
          return CatalogProduct$Pos.Color.toColorSet(color, "pastille").foregroundColor;
        }));
  var tmp = {
    children: null,
    to: to,
    disabled: Belt_Option.isNone(redirectRoute) || disabled
  };
  if (match$1.className !== undefined) {
    tmp.className = match$1.className;
  }
  var tmp$1 = {};
  if (match$2.className !== undefined) {
    tmp$1.className = Caml_option.valFromOption(match$2.className);
  }
  if (match$2.style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(match$2.style);
  }
  var tmp$2 = {};
  if (pastilleColor !== undefined) {
    tmp$2.color = Caml_option.valFromOption(pastilleColor);
  }
  var tmp$3 = {
    title: information.productName
  };
  if (match$4.className !== undefined) {
    tmp$3.className = Caml_option.valFromOption(match$4.className);
  }
  if (match$4.style !== undefined) {
    tmp$3.style = Caml_option.valFromOption(match$4.style);
  }
  var tmp$4 = {};
  if (match$3.className !== undefined) {
    tmp$4.className = Caml_option.valFromOption(match$3.className);
  }
  if (match$3.style !== undefined) {
    tmp$4.style = Caml_option.valFromOption(match$3.style);
  }
  var tmp$5 = {};
  if (match$5.className !== undefined) {
    tmp$5.className = Caml_option.valFromOption(match$5.className);
  }
  if (match$5.style !== undefined) {
    tmp$5.style = Caml_option.valFromOption(match$5.style);
  }
  var tmp$6;
  if (errorMessage !== undefined) {
    tmp$6 = React.createElement(TextStyle$Pos.make, {
          children: errorMessage,
          variation: "negative",
          size: "tiny"
        });
  } else {
    var tmp$7 = {
      title: formattedDescription
    };
    if (match$6.className !== undefined) {
      tmp$7.className = Caml_option.valFromOption(match$6.className);
    }
    if (match$6.style !== undefined) {
      tmp$7.style = Caml_option.valFromOption(match$6.style);
    }
    tmp$6 = React.createElement(React.Fragment, undefined, React.createElement("span", tmp$7, formattedDescription), React.createElement(ProductReferenceTableCell$TooltipIconTrigger, {
              information: information
            }));
  }
  return React.createElement("div", {
              ref: match[0],
              style: {
                display: "contents",
                width: "100%"
              }
            }, React.createElement(Navigation$Pos.Link.make, tmp, React.createElement("div", tmp$1, React.createElement(Pastille$Pos.make, tmp$2)), React.createElement(Stack$Pos.make, {
                      children: null,
                      space: "none"
                    }, React.createElement(InlineText$Pos.make, {
                          children: null,
                          wrap: false
                        }, React.createElement("span", tmp$3, information.productName), React.createElement("div", tmp$4), React.createElement("span", tmp$5, information.variantName), badge !== undefined && !disabled ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Badge$Pos.make, {
                                            children: badge.text,
                                            size: "small",
                                            variation: badge.variation
                                          })), undefined, undefined, undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined)) : null), React.createElement(InlineText$Pos.make, {
                          children: tmp$6,
                          wrap: false
                        })), Belt_Option.isSome(redirectRoute) ? React.createElement(ProductReferenceTableCell$PressableIconIndicator, {
                        contrasted: contrasted,
                        hovered: match[1]
                      }) : null));
}

var make = ProductReferenceTableCell;

export {
  TooltipIconTrigger ,
  PressableIconIndicator ,
  styles$2 as styles,
  styleProps$2 as styleProps,
  pastilleStyleProps ,
  productNameStyleProps ,
  variantNameStyleProps ,
  descriptionNameStyleProps ,
  separatorStyleProps ,
  make ,
}
/* styles Not a pure module */
