// Generated by ReScript, PLEASE EDIT WITH CARE


function decode(slug, name) {
  switch (slug) {
    case "BOT" :
        return /* Bot */3;
    case "ERP" :
        return /* ERP */2;
    case "W" :
        return /* AppWinoFr */1;
    case "d" :
        return /* DashboardWinoFr */0;
    case "winopay" :
        return /* WinoPay */4;
    default:
      return /* CashRegister */{
              slug: slug,
              name: name
            };
  }
}

function format(name) {
  if (typeof name !== "number") {
    return "" + name.slug + " - " + name.name + "";
  }
  switch (name) {
    case /* DashboardWinoFr */0 :
        return "dashboard.wino.fr";
    case /* AppWinoFr */1 :
        return "app.wino.fr";
    case /* ERP */2 :
        return "ERP";
    case /* Bot */3 :
        return "BOT";
    case /* WinoPay */4 :
        return "WinoPay";
    
  }
}

export {
  decode ,
  format ,
}
/* No side effect */
