// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Icon$Pos from "../images-and-icons/Icon.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as ReactAria from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Tooltip$Pos from "../overlays/Tooltip.bs.js";
import * as ReactAria$Pos from "../../externals/ReactAria.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Stylex from "@stylexjs/stylex";
import * as AnimatedRender$Pos from "../../primitives/AnimatedRender.bs.js";

var styles = Stylex.create({
      root: {
        boxSizing: "border-box",
        border: "1px solid #d7d7e0",
        borderRadius: "5px",
        alignItems: "center",
        display: "flex",
        height: "40px",
        justifyContent: "center",
        width: "40px",
        ":hover": {
          borderColor: "#bdbdca"
        }
      },
      focused: {
        borderColor: "#a2a1b0",
        ":hover": {
          borderColor: "#a2a1b0"
        }
      },
      disabled: {
        backgroundColor: "#f3f3f7",
        borderColor: "#d7d7e0",
        ":hover": {
          borderColor: "#d7d7e0"
        }
      },
      statusLight: {
        backgroundColor: "#a01e72",
        borderRadius: "50%",
        outline: "2px solid white",
        height: "8px",
        position: "absolute",
        right: "0px",
        width: "8px"
      }
    });

function styleProps(focused, disabled, bordered) {
  return Stylex.props(styles.root, focused ? styles.focused : ({}), disabled ? styles.disabled : ({}), bordered ? ({}) : ({
                  border: "none",
                  width: "20px"
                }));
}

function iconStyleProps(pressed) {
  return Stylex.props({
              transform: pressed ? "scale3d(0.9, 0.9, 1)" : "initial",
              height: "20px",
              position: "relative"
            });
}

var make = React.forwardRef(function (Props, ref) {
      var name = Props.name;
      var disabledOpt = Props.disabled;
      var focusedOpt = Props.focused;
      var borderedOpt = Props.bordered;
      var statusLightOpt = Props.statusLight;
      var tooltip = Props.tooltip;
      var ariaProps = Props.ariaProps;
      var action = Props.action;
      var disabled = disabledOpt !== undefined ? disabledOpt : false;
      var focused = focusedOpt !== undefined ? focusedOpt : false;
      var bordered = borderedOpt !== undefined ? borderedOpt : true;
      var statusLight = statusLightOpt !== undefined ? statusLightOpt : false;
      var match = Hover$Pos.use((ref == null) ? undefined : Caml_option.some(ref), undefined);
      var ref$1 = match[0];
      var match$1 = React.useState(function () {
            return false;
          });
      var setPressed = match$1[1];
      var buttonProps_elementType = "div";
      var buttonProps_onPressStart = (function (param) {
          Curry._1(setPressed, (function (param) {
                  return true;
                }));
        });
      var buttonProps_onPressEnd = (function (param) {
          Curry._1(setPressed, (function (param) {
                  return false;
                }));
        });
      var buttonProps = {
        elementType: buttonProps_elementType,
        onPressStart: buttonProps_onPressStart,
        onPressEnd: buttonProps_onPressEnd
      };
      var match$2 = styleProps(focused, disabled, bordered);
      var className = match$2.className;
      var style = match$2.style;
      var match$3 = iconStyleProps(match$1[0]);
      var iconStyle = match$3.style;
      var match$4 = Stylex.props(styles.statusLight);
      var iconStatusLightClassName = match$4.className;
      var iconStatusLightStyle = match$4.style;
      var fill = !focused && !match[1] || disabled ? "#797885" : "#25243a";
      var ariaProps$1 = ReactAria.mergeProps({
            "aria-label": name
          }, ariaProps, buttonProps);
      var tmp;
      if (action.TAG === /* Callback */0) {
        var onPress = action._0;
        var tmp$1 = {};
        if (className !== undefined) {
          tmp$1.className = Caml_option.valFromOption(className);
        }
        if (style !== undefined) {
          tmp$1.style = Caml_option.valFromOption(style);
        }
        var tmp$2 = {};
        if (iconStyle !== undefined) {
          tmp$2.style = Caml_option.valFromOption(iconStyle);
        }
        var tmp$3 = {};
        if (iconStatusLightClassName !== undefined) {
          tmp$3.className = Caml_option.valFromOption(iconStatusLightClassName);
        }
        if (iconStatusLightStyle !== undefined) {
          tmp$3.style = Caml_option.valFromOption(iconStatusLightStyle);
        }
        tmp = React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement("div", tmp$1, React.createElement("div", tmp$2, React.createElement(AnimatedRender$Pos.make, {
                              children: React.createElement("div", tmp$3),
                              displayed: statusLight,
                              animation: "fadeBubbleTranslation",
                              duration: 500
                            }), React.createElement(Icon$Pos.make, {
                              name: name,
                              fill: fill
                            }))), ariaProps$1, disabled, undefined, undefined, undefined, (function (param) {
                    Curry._1(onPress, undefined);
                  }), undefined, ref$1, undefined));
      } else {
        var tmp$4 = {
          ref: ref$1
        };
        if (className !== undefined) {
          tmp$4.className = Caml_option.valFromOption(className);
        }
        if (style !== undefined) {
          tmp$4.style = Caml_option.valFromOption(style);
        }
        var tmp$5 = {};
        if (iconStyle !== undefined) {
          tmp$5.style = Caml_option.valFromOption(iconStyle);
        }
        var tmp$6 = {};
        if (iconStatusLightClassName !== undefined) {
          tmp$6.className = Caml_option.valFromOption(iconStatusLightClassName);
        }
        if (iconStatusLightStyle !== undefined) {
          tmp$6.style = Caml_option.valFromOption(iconStatusLightStyle);
        }
        tmp = React.createElement(ReactAria$Pos.Spread.make, {
              props: ariaProps$1,
              children: React.createElement(Navigation$Pos.Link.make, {
                    children: React.createElement("div", tmp$4, React.createElement("div", tmp$5, React.createElement(AnimatedRender$Pos.make, {
                                  children: React.createElement("div", tmp$6),
                                  displayed: statusLight,
                                  animation: "fadeBubbleTranslation",
                                  duration: 500
                                }), React.createElement(Icon$Pos.make, {
                                  name: name,
                                  fill: fill
                                }))),
                    to: action._0,
                    disabled: disabled
                  })
            });
      }
      return React.createElement(Tooltip$Pos.make, {
                  children: tmp,
                  arrowed: false,
                  placement: "top start",
                  content: {
                    TAG: /* Text */0,
                    _0: Belt_Option.getWithDefault(tooltip, "")
                  },
                  disabled: Belt_Option.isNone(tooltip)
                });
    });

function makeProps(prim0, prim1, prim2, prim3, prim4, prim5, prim6, prim7, prim8, prim9, prim10) {
  var tmp = {
    name: prim0,
    action: prim7
  };
  if (prim1 !== undefined) {
    tmp.disabled = Caml_option.valFromOption(prim1);
  }
  if (prim2 !== undefined) {
    tmp.focused = Caml_option.valFromOption(prim2);
  }
  if (prim3 !== undefined) {
    tmp.bordered = Caml_option.valFromOption(prim3);
  }
  if (prim4 !== undefined) {
    tmp.statusLight = Caml_option.valFromOption(prim4);
  }
  if (prim5 !== undefined) {
    tmp.tooltip = Caml_option.valFromOption(prim5);
  }
  if (prim6 !== undefined) {
    tmp.ariaProps = Caml_option.valFromOption(prim6);
  }
  if (prim8 !== undefined) {
    tmp.key = prim8;
  }
  if (prim9 !== undefined) {
    tmp.ref = Caml_option.valFromOption(prim9);
  }
  return tmp;
}

export {
  makeProps ,
  make ,
}
/* styles Not a pure module */
