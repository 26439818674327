// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as CartProduct$Pos from "../Cart/CartProduct.bs.js";
import * as DateHelpers$Pos from "../../helpers/DateHelpers.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as OrderEditForm$Pos from "./OrderEditForm.bs.js";
import * as AnimatedRender$Pos from "../../primitives/AnimatedRender.bs.js";
import * as CatalogProduct$Pos from "../Catalog/CatalogProduct.bs.js";
import * as SupplierSelect$Pos from "../Supplier/SupplierSelect.bs.js";
import * as ReactUpdateEffect$Pos from "../../primitives/ReactUpdateEffect.bs.js";
import * as CatalogProducerSelect$Pos from "../Catalog/CatalogProducerSelect.bs.js";
import * as ProductPriceTableCell$Pos from "../Product/ProductPriceTableCell.bs.js";
import * as ProductStockTableCell$Pos from "../Product/ProductStockTableCell.bs.js";
import * as CatalogStockRangeSelect$Pos from "../Catalog/CatalogStockRangeSelect.bs.js";
import * as ProductReferenceTableCell$Pos from "../Product/ProductReferenceTableCell.bs.js";
import * as CatalogCategorySelectFilter$Pos from "../Catalog/CatalogCategorySelectFilter.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query VariantsQuery($search: String, $after: String, $first: Int, $filterBy: InputVariantsQueryFilter)  {\nvariants(search: $search, after: $after, first: $first, filterBy: $filterBy)  {\n__typename  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \ncreatedAt  \nformattedName  \nname  \nproduct  {\n__typename  \nid  \nname  \nkind  \ncolor  \nwineType  \nwhiteWineType  \nbeerType  \nproducer  \ndesignation  \nfamily  \nregion  \ncountry  \ntax  {\n__typename  \nvalue  \n}\n\n}\n\nstockKeepingUnit  \npriceLookUpCode  \ninternalCode  \nsupplier  {\n__typename  \ncompanyName  \n}\n\nformattedCategory  \nalcoholVolume  \npurchasedPrice  \nformattedPurchasedPrice  \npackaging  \nbulk  \ncapacityPrecision  \ncapacityUnit  \nstock  {\n__typename  \nrawQuantity  \nformattedQuantity  \nstate  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variants;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var value$4 = value$2.hasNextPage;
  var value$5 = value$1.edges;
  return {
          variants: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              endCursor: !(value$3 == null) ? value$3 : undefined,
              hasNextPage: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.product;
                    var value$3 = value$2.kind;
                    var tmp;
                    switch (value$3) {
                      case "BEER" :
                          tmp = "BEER";
                          break;
                      case "SIMPLE" :
                          tmp = "SIMPLE";
                          break;
                      case "SPIRITUOUS" :
                          tmp = "SPIRITUOUS";
                          break;
                      case "WINE" :
                          tmp = "WINE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$4 = value$2.color;
                    var tmp$1;
                    if (value$4 == null) {
                      tmp$1 = undefined;
                    } else {
                      var tmp$2;
                      switch (value$4) {
                        case "AMBER" :
                            tmp$2 = "AMBER";
                            break;
                        case "BLACK" :
                            tmp$2 = "BLACK";
                            break;
                        case "BLOND" :
                            tmp$2 = "BLOND";
                            break;
                        case "DARK" :
                            tmp$2 = "DARK";
                            break;
                        case "ORANGE" :
                            tmp$2 = "ORANGE";
                            break;
                        case "RED" :
                            tmp$2 = "RED";
                            break;
                        case "ROSE" :
                            tmp$2 = "ROSE";
                            break;
                        case "WHITE" :
                            tmp$2 = "WHITE";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$1 = tmp$2;
                    }
                    var value$5 = value$2.wineType;
                    var tmp$3;
                    if (value$5 == null) {
                      tmp$3 = undefined;
                    } else {
                      var tmp$4;
                      switch (value$5) {
                        case "EFFERVESCENT" :
                            tmp$4 = "EFFERVESCENT";
                            break;
                        case "STILL" :
                            tmp$4 = "STILL";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$3 = tmp$4;
                    }
                    var value$6 = value$2.whiteWineType;
                    var tmp$5;
                    if (value$6 == null) {
                      tmp$5 = undefined;
                    } else {
                      var tmp$6;
                      switch (value$6) {
                        case "DRY" :
                            tmp$6 = "DRY";
                            break;
                        case "SEMIDRY" :
                            tmp$6 = "SEMIDRY";
                            break;
                        case "SOFT" :
                            tmp$6 = "SOFT";
                            break;
                        case "SWEET" :
                            tmp$6 = "SWEET";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$5 = tmp$6;
                    }
                    var value$7 = value$2.beerType;
                    var value$8 = value$2.producer;
                    var value$9 = value$2.designation;
                    var value$10 = value$2.family;
                    var value$11 = value$2.region;
                    var value$12 = value$2.country;
                    var value$13 = value$2.tax;
                    var value$14 = value$1.stockKeepingUnit;
                    var value$15 = value$1.priceLookUpCode;
                    var value$16 = value$1.internalCode;
                    var value$17 = value$1.supplier;
                    var value$18 = value$1.formattedCategory;
                    var value$19 = value$1.alcoholVolume;
                    var value$20 = value$1.purchasedPrice;
                    var value$21 = value$1.formattedPurchasedPrice;
                    var value$22 = value$1.packaging;
                    var value$23 = value$1.bulk;
                    var value$24 = value$1.capacityPrecision;
                    var value$25 = value$1.capacityUnit;
                    var value$26 = value$1.stock;
                    var value$27 = value$26.rawQuantity;
                    var value$28 = value$26.formattedQuantity;
                    var value$29 = value$26.state;
                    var tmp$7;
                    if (value$29 == null) {
                      tmp$7 = undefined;
                    } else {
                      var tmp$8;
                      switch (value$29) {
                        case "ALERT" :
                            tmp$8 = "ALERT";
                            break;
                        case "DANGER" :
                            tmp$8 = "DANGER";
                            break;
                        case "OK" :
                            tmp$8 = "OK";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$7 = tmp$8;
                    }
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              createdAt: Scalar$Pos.Datetime.parse(value$1.createdAt),
                              formattedName: value$1.formattedName,
                              name: value$1.name,
                              product: {
                                __typename: value$2.__typename,
                                id: value$2.id,
                                name: value$2.name,
                                kind: tmp,
                                color: tmp$1,
                                wineType: tmp$3,
                                whiteWineType: tmp$5,
                                beerType: !(value$7 == null) ? value$7 : undefined,
                                producer: !(value$8 == null) ? value$8 : undefined,
                                designation: !(value$9 == null) ? value$9 : undefined,
                                family: !(value$10 == null) ? value$10 : undefined,
                                region: !(value$11 == null) ? value$11 : undefined,
                                country: !(value$12 == null) ? value$12 : undefined,
                                tax: {
                                  __typename: value$13.__typename,
                                  value: value$13.value
                                }
                              },
                              stockKeepingUnit: !(value$14 == null) ? value$14 : undefined,
                              priceLookUpCode: !(value$15 == null) ? value$15 : undefined,
                              internalCode: !(value$16 == null) ? value$16 : undefined,
                              supplier: !(value$17 == null) ? ({
                                    __typename: value$17.__typename,
                                    companyName: value$17.companyName
                                  }) : undefined,
                              formattedCategory: !(value$18 == null) ? value$18 : undefined,
                              alcoholVolume: !(value$19 == null) ? value$19 : undefined,
                              purchasedPrice: !(value$20 == null) ? value$20 : undefined,
                              formattedPurchasedPrice: !(value$21 == null) ? value$21 : undefined,
                              packaging: !(value$22 == null) ? value$22 : undefined,
                              bulk: !(value$23 == null) ? value$23 : undefined,
                              capacityPrecision: !(value$24 == null) ? value$24 : undefined,
                              capacityUnit: !(value$25 == null) ? value$25 : undefined,
                              stock: {
                                __typename: value$26.__typename,
                                rawQuantity: !(value$27 == null) ? value$27 : undefined,
                                formattedQuantity: !(value$28 == null) ? value$28 : undefined,
                                state: tmp$7
                              }
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.variants;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.stock;
          var value$3 = value$2.state;
          var state = value$3 !== undefined ? (
              value$3 === "OK" ? "OK" : (
                  value$3 === "ALERT" ? "ALERT" : "DANGER"
                )
            ) : null;
          var value$4 = value$2.formattedQuantity;
          var formattedQuantity = value$4 !== undefined ? value$4 : null;
          var value$5 = value$2.rawQuantity;
          var rawQuantity = value$5 !== undefined ? value$5 : null;
          var value$6 = value$2.__typename;
          var stock = {
            __typename: value$6,
            rawQuantity: rawQuantity,
            formattedQuantity: formattedQuantity,
            state: state
          };
          var value$7 = value$1.capacityUnit;
          var capacityUnit = value$7 !== undefined ? value$7 : null;
          var value$8 = value$1.capacityPrecision;
          var capacityPrecision = value$8 !== undefined ? value$8 : null;
          var value$9 = value$1.bulk;
          var bulk = value$9 !== undefined ? value$9 : null;
          var value$10 = value$1.packaging;
          var packaging = value$10 !== undefined ? value$10 : null;
          var value$11 = value$1.formattedPurchasedPrice;
          var formattedPurchasedPrice = value$11 !== undefined ? value$11 : null;
          var value$12 = value$1.purchasedPrice;
          var purchasedPrice = value$12 !== undefined ? value$12 : null;
          var value$13 = value$1.alcoholVolume;
          var alcoholVolume = value$13 !== undefined ? value$13 : null;
          var value$14 = value$1.formattedCategory;
          var formattedCategory = value$14 !== undefined ? value$14 : null;
          var value$15 = value$1.supplier;
          var supplier;
          if (value$15 !== undefined) {
            var value$16 = value$15.companyName;
            var value$17 = value$15.__typename;
            supplier = {
              __typename: value$17,
              companyName: value$16
            };
          } else {
            supplier = null;
          }
          var value$18 = value$1.internalCode;
          var internalCode = value$18 !== undefined ? value$18 : null;
          var value$19 = value$1.priceLookUpCode;
          var priceLookUpCode = value$19 !== undefined ? value$19 : null;
          var value$20 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$20 !== undefined ? value$20 : null;
          var value$21 = value$1.product;
          var value$22 = value$21.tax;
          var value$23 = value$22.value;
          var value$24 = value$22.__typename;
          var tax = {
            __typename: value$24,
            value: value$23
          };
          var value$25 = value$21.country;
          var country = value$25 !== undefined ? value$25 : null;
          var value$26 = value$21.region;
          var region = value$26 !== undefined ? value$26 : null;
          var value$27 = value$21.family;
          var family = value$27 !== undefined ? value$27 : null;
          var value$28 = value$21.designation;
          var designation = value$28 !== undefined ? value$28 : null;
          var value$29 = value$21.producer;
          var producer = value$29 !== undefined ? value$29 : null;
          var value$30 = value$21.beerType;
          var beerType = value$30 !== undefined ? value$30 : null;
          var value$31 = value$21.whiteWineType;
          var whiteWineType = value$31 !== undefined ? (
              value$31 === "SWEET" ? "SWEET" : (
                  value$31 === "SEMIDRY" ? "SEMIDRY" : (
                      value$31 === "SOFT" ? "SOFT" : "DRY"
                    )
                )
            ) : null;
          var value$32 = value$21.wineType;
          var wineType = value$32 !== undefined ? (
              value$32 === "EFFERVESCENT" ? "EFFERVESCENT" : "STILL"
            ) : null;
          var value$33 = value$21.color;
          var color = value$33 !== undefined ? (
              value$33 === "ORANGE" ? "ORANGE" : (
                  value$33 === "RED" ? "RED" : (
                      value$33 === "AMBER" ? "AMBER" : (
                          value$33 === "DARK" ? "DARK" : (
                              value$33 === "BLACK" ? "BLACK" : (
                                  value$33 === "BLOND" ? "BLOND" : (
                                      value$33 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$34 = value$21.kind;
          var kind = value$34 === "SPIRITUOUS" ? "SPIRITUOUS" : (
              value$34 === "BEER" ? "BEER" : (
                  value$34 === "WINE" ? "WINE" : "SIMPLE"
                )
            );
          var value$35 = value$21.name;
          var value$36 = value$21.id;
          var value$37 = value$21.__typename;
          var product = {
            __typename: value$37,
            id: value$36,
            name: value$35,
            kind: kind,
            color: color,
            wineType: wineType,
            whiteWineType: whiteWineType,
            beerType: beerType,
            producer: producer,
            designation: designation,
            family: family,
            region: region,
            country: country,
            tax: tax
          };
          var value$38 = value$1.name;
          var value$39 = value$1.formattedName;
          var value$40 = value$1.createdAt;
          var value$41 = Scalar$Pos.Datetime.serialize(value$40);
          var value$42 = value$1.id;
          var value$43 = value$1.__typename;
          var node = {
            __typename: value$43,
            id: value$42,
            createdAt: value$41,
            formattedName: value$39,
            name: value$38,
            product: product,
            stockKeepingUnit: stockKeepingUnit,
            priceLookUpCode: priceLookUpCode,
            internalCode: internalCode,
            supplier: supplier,
            formattedCategory: formattedCategory,
            alcoholVolume: alcoholVolume,
            purchasedPrice: purchasedPrice,
            formattedPurchasedPrice: formattedPurchasedPrice,
            packaging: packaging,
            bulk: bulk,
            capacityPrecision: capacityPrecision,
            capacityUnit: capacityUnit,
            stock: stock
          };
          var value$44 = value.__typename;
          return {
                  __typename: value$44,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var hasNextPage = value$4 !== undefined ? value$4 : null;
  var value$5 = value$3.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$3.__typename;
  var pageInfo = {
    __typename: value$6,
    endCursor: endCursor,
    hasNextPage: hasNextPage
  };
  var value$7 = value$1.__typename;
  var variants = {
    __typename: value$7,
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          variants: variants
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectNumberRangeFilter(inp) {
  var a = inp._min;
  var a$1 = inp._max;
  var a$2 = inp._between;
  return {
          _min: a !== undefined ? a : undefined,
          _max: a$1 !== undefined ? a$1 : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectNullableStringEqualsFilter(inp) {
  var a = inp._equals;
  return {
          _equals: a !== undefined ? a : undefined
        };
}

function serializeInputObjectBooleanEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectStringEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectInputVariantsQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.stockKeepingUnit;
  var a$2 = inp.ean13;
  var a$3 = inp.active;
  var a$4 = inp.archived;
  var a$5 = inp.createdAt;
  var a$6 = inp.updatedAt;
  var a$7 = inp.categoryId;
  var a$8 = inp.supplierId;
  var a$9 = inp.stock;
  var a$10 = inp.producer;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          stockKeepingUnit: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          ean13: a$2 !== undefined ? ({
                _equals: a$2._equals
              }) : undefined,
          active: a$3 !== undefined ? ({
                _equals: a$3._equals
              }) : undefined,
          archived: a$4 !== undefined ? (
              a$4 === "ONLY" ? "ONLY" : (
                  a$4 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          createdAt: a$5 !== undefined ? serializeInputObjectDateFilter(a$5) : undefined,
          updatedAt: a$6 !== undefined ? serializeInputObjectDateFilter(a$6) : undefined,
          categoryId: a$7 !== undefined ? serializeInputObjectNullableStringEqualsFilter(a$7) : undefined,
          supplierId: a$8 !== undefined ? serializeInputObjectNullableStringEqualsFilter(a$8) : undefined,
          stock: a$9 !== undefined ? serializeInputObjectNumberRangeFilter(a$9) : undefined,
          producer: a$10 !== undefined ? ({
                _equals: a$10._equals
              }) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.after;
  var a$2 = inp.first;
  var a$3 = inp.filterBy;
  return {
          search: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined,
          first: a$2 !== undefined ? a$2 : undefined,
          filterBy: a$3 !== undefined ? serializeInputObjectInputVariantsQueryFilter(a$3) : undefined
        };
}

function makeVariables(search, after, first, filterBy, param) {
  return {
          search: search,
          after: after,
          first: first,
          filterBy: filterBy
        };
}

function makeInputObjectInputVariantsQueryFilter(shopIds, stockKeepingUnit, ean13, active, archived, createdAt, updatedAt, categoryId, supplierId, stock, producer, param) {
  return {
          shopIds: shopIds,
          stockKeepingUnit: stockKeepingUnit,
          ean13: ean13,
          active: active,
          archived: archived,
          createdAt: createdAt,
          updatedAt: updatedAt,
          categoryId: categoryId,
          supplierId: supplierId,
          stock: stock,
          producer: producer
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectBooleanEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeInputObjectNullableStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectNumberRangeFilter(_min, _max, _between, param) {
  return {
          _min: _min,
          _max: _max,
          _between: _between
        };
}

function makeDefaultVariables(param) {
  return {
          search: undefined,
          after: undefined,
          first: undefined,
          filterBy: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectNullableStringEqualsFilter: serializeInputObjectNullableStringEqualsFilter,
  serializeInputObjectNumberRangeFilter: serializeInputObjectNumberRangeFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectNullableStringEqualsFilter: makeInputObjectNullableStringEqualsFilter,
  makeInputObjectNumberRangeFilter: makeInputObjectNumberRangeFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectNullableStringEqualsFilter: serializeInputObjectNullableStringEqualsFilter,
  serializeInputObjectNumberRangeFilter: serializeInputObjectNumberRangeFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectNullableStringEqualsFilter: makeInputObjectNullableStringEqualsFilter,
  makeInputObjectNumberRangeFilter: makeInputObjectNumberRangeFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function rowsFromEdgesData(data) {
  return Belt_Array.map(data, (function (edge) {
                var match = edge.node;
                var match$1 = match.bulk;
                var tmp;
                if (match$1 !== undefined && match$1) {
                  var precision = match.capacityPrecision;
                  tmp = precision !== undefined ? precision : undefined;
                } else {
                  tmp = undefined;
                }
                return {
                        id: edge.node.id,
                        formattedName: edge.node.formattedName,
                        createdAt: edge.node.createdAt,
                        productKind: edge.node.product.kind,
                        purchasedPrice: Belt_Option.getWithDefault(edge.node.purchasedPrice, 0),
                        formattedPurchasedPrice: edge.node.formattedPurchasedPrice,
                        packaging: edge.node.packaging,
                        bulkPrecision: tmp,
                        capacityUnit: edge.node.capacityUnit,
                        tax: edge.node.product.tax.value,
                        stockQuantity: edge.node.stock.rawQuantity,
                        stockFormattedQuantity: edge.node.stock.formattedQuantity,
                        stockState: edge.node.stock.state,
                        information: {
                          productName: edge.node.product.name,
                          variantName: edge.node.name,
                          sku: edge.node.stockKeepingUnit,
                          plu: Belt_Option.map(edge.node.priceLookUpCode, (function (prim) {
                                  return String(prim);
                                })),
                          internalCode: edge.node.internalCode,
                          color: edge.node.product.color,
                          producerName: edge.node.product.producer,
                          designation: edge.node.product.designation,
                          productFamily: edge.node.product.family,
                          wineType: edge.node.product.wineType,
                          whiteWineType: edge.node.product.whiteWineType,
                          beerType: edge.node.product.beerType,
                          region: edge.node.product.region,
                          country: Belt_Option.getWithDefault(edge.node.product.country, "—"),
                          categoryName: Belt_Option.getWithDefault(edge.node.formattedCategory, "—"),
                          supplierName: Belt_Option.map(edge.node.supplier, (function (supplier) {
                                  return supplier.companyName;
                                })),
                          alcoholVolume: Belt_Option.map(edge.node.alcoholVolume, (function (alcoholVolume) {
                                  return Intl$Pos.decimalFormat(undefined, 1, alcoholVolume) + "°";
                                }))
                        }
                      };
              }));
}

var columns = [
  {
    key: "reference",
    name: Intl$Pos.t("Product"),
    layout: {
      width: {
        NAME: "pct",
        VAL: 65
      },
      margin: "xxlarge"
    },
    render: (function (param) {
        var product = param.data;
        var badgeNew = DateHelpers$Pos.diffInHours(new Date(), product.createdAt) <= 24 ? ({
              variation: "information",
              text: Intl$Pos.t("New")
            }) : undefined;
        var tmp = {
          disabled: param.disabled,
          productKind: product.productKind,
          information: product.information
        };
        if (badgeNew !== undefined) {
          tmp.badge = Caml_option.valFromOption(badgeNew);
        }
        return React.createElement(ProductReferenceTableCell$Pos.make, tmp);
      })
  },
  {
    key: "stock",
    name: Intl$Pos.t("Stock"),
    render: (function (param) {
        var product = param.data;
        if (param.disabled) {
          return React.createElement(TextStyle$Pos.make, {
                      children: Intl$Pos.t("Already added"),
                      variation: "subdued",
                      size: "small"
                    });
        }
        var tmp = {
          value: product.stockFormattedQuantity,
          size: "xsmall"
        };
        if (product.stockState !== undefined) {
          tmp.state = Caml_option.valFromOption(product.stockState);
        }
        return React.createElement(ProductStockTableCell$Pos.make, tmp);
      })
  },
  {
    key: "purchasedPrice",
    name: Intl$Pos.t("Purchase price"),
    layout: {
      width: {
        NAME: "px",
        VAL: 140
      }
    },
    render: (function (param) {
        if (param.disabled) {
          return null;
        } else {
          return React.createElement(ProductPriceTableCell$Pos.make, {
                      value: param.data.formattedPurchasedPrice,
                      size: "xsmall"
                    });
        }
      })
  }
];

function setDefaultOrderedQuantityValue(productInput) {
  if (productInput.TAG === /* Unit */0) {
    var product = productInput.product;
    return {
            TAG: /* Unit */0,
            product: {
              id: product.id,
              identifier: product.identifier,
              stockKeepingUnit: product.stockKeepingUnit,
              name: product.name,
              description: product.description,
              capacityUnit: product.capacityUnit,
              stock: product.stock,
              packaging: product.packaging,
              quantity: 1,
              expectedQuantity: product.expectedQuantity,
              unitPrice: product.unitPrice,
              fees: product.fees,
              discounts: product.discounts,
              tax: product.tax
            }
          };
  }
  var product$1 = productInput.product;
  return {
          TAG: /* Bulk */1,
          product: {
            id: product$1.id,
            identifier: product$1.identifier,
            stockKeepingUnit: product$1.stockKeepingUnit,
            name: product$1.name,
            description: product$1.description,
            capacityUnit: product$1.capacityUnit,
            stock: product$1.stock,
            packaging: product$1.packaging,
            quantity: 1,
            expectedQuantity: product$1.expectedQuantity,
            unitPrice: product$1.unitPrice,
            fees: product$1.fees,
            discounts: product$1.discounts,
            tax: product$1.tax
          },
          precision: productInput.precision
        };
}

var make = React.memo(function (Props) {
      var children = Props.children;
      var opened = Props.opened;
      var selectedProducts = Props.selectedProducts;
      var onCommit = Props.onCommit;
      var onRequestClose = Props.onRequestClose;
      var renderStartText = function (param) {
        var length = selectedProducts.length;
        var shouldBePrimaryView = length > 0;
        var formattedText = "" + String(length) + " " + Intl$Pos.t(Intl$Pos.isPlural(length) ? "selected products" : "selected product");
        return React.createElement(Inline$Pos.make, {
                    children: React.createElement(TextStyle$Pos.make, {
                          children: formattedText,
                          variation: shouldBePrimaryView ? "primary" : "neutral",
                          weight: shouldBePrimaryView ? "strong" : "regular"
                        })
                  });
      };
      var onCommit$1 = function (param) {
        Curry._1(onCommit, Belt_Array.map(selectedProducts, (function (row) {
                    return setDefaultOrderedQuantityValue(CartProduct$Pos.makeProductInput(row.id, row.formattedName, CatalogProduct$Pos.Information.formateDescription(row.productKind, row.information, true, undefined), undefined, row.stockQuantity, row.packaging, row.tax, row.purchasedPrice, undefined, row.bulkPrecision, row.capacityUnit, undefined));
                  })));
      };
      return React.createElement(Modal$Pos.make, {
                  children: children,
                  opened: opened,
                  title: Intl$Pos.t("Product append"),
                  variation: "secondary",
                  compact: false,
                  backgroundColor: "#fefefe",
                  renderStartText: renderStartText,
                  abortButtonText: Intl$Pos.t("Cancel"),
                  allowCommit: selectedProducts.length !== 0,
                  commitButtonVariation: "primary",
                  commitButtonText: Intl$Pos.t("Add products"),
                  commitButtonCallback: onCommit$1,
                  onRequestClose: onRequestClose
                });
    });

var PickerModal = {
  make: make
};

function makeVariables$1(search, filters, after, param) {
  var shopId = filters.shopId;
  var category = filters.category;
  var tmp;
  if (category !== undefined) {
    var categoryId = category.id;
    tmp = {
      _equals: (categoryId == null) ? null : categoryId
    };
  } else {
    tmp = undefined;
  }
  var supplier = filters.supplier;
  var tmp$1;
  if (supplier !== undefined) {
    var supplierId = supplier.id;
    tmp$1 = {
      _equals: (supplierId == null) ? null : supplierId
    };
  } else {
    tmp$1 = undefined;
  }
  var match = filters.stock;
  var producer = filters.producer;
  return makeVariables(search, after, 20, makeInputObjectInputVariantsQueryFilter(shopId !== undefined ? ({
                      _in: [shopId]
                    }) : undefined, undefined, undefined, undefined, undefined, undefined, undefined, tmp, tmp$1, match !== undefined ? ({
                      _min: match.min,
                      _max: match.max,
                      _between: undefined
                    }) : undefined, producer !== undefined ? ({
                      _equals: producer
                    }) : undefined, undefined), undefined);
}

function runScanEdges(fetcher, cursor, dataOpt, param) {
  var data = dataOpt !== undefined ? dataOpt : [];
  return Future.flatMapOk(Future.mapError(FuturePromise.fromPromise(Curry._1(fetcher, cursor)), undefined, (function (param) {
                    
                  })), undefined, (function (response) {
                if (response.TAG !== /* Ok */0) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: undefined
                            });
                }
                var match = response._0;
                var match$1 = match.data.variants;
                var match$2 = match$1.pageInfo;
                var match$3 = match$2.hasNextPage;
                if (match$3 !== undefined && match$3) {
                  if (match.error !== undefined) {
                    return Future.value({
                                TAG: /* Error */1,
                                _0: undefined
                              });
                  } else {
                    return runScanEdges(fetcher, match$2.endCursor, Belt_Array.concat(data, match$1.edges), undefined);
                  }
                }
                if (match.error !== undefined) {
                  return Future.value({
                              TAG: /* Error */1,
                              _0: undefined
                            });
                } else {
                  return Future.value({
                              TAG: /* Ok */0,
                              _0: Belt_Array.concat(data, match$1.edges)
                            });
                }
              }));
}

function initialState(filters) {
  return {
          searchQuery: "",
          filters: filters,
          selectedProducts: []
        };
}

function make$1(state, action) {
  switch (action.TAG | 0) {
    case /* Searched */0 :
        return {
                searchQuery: action._0,
                filters: state.filters,
                selectedProducts: state.selectedProducts
              };
    case /* FiltersUpdated */1 :
        return {
                searchQuery: state.searchQuery,
                filters: Curry._1(action._0, state.filters),
                selectedProducts: state.selectedProducts
              };
    case /* ProductSelectionUpdated */2 :
        var allProducts = action._1;
        var tableSelection = action._0;
        var prev = state.selectedProducts;
        var selectedProducts = tableSelection ? Belt_Array.keepMap(tableSelection._0, (function (selectedRowId) {
                  return Belt_Array.getBy(Belt_Array.concat(allProducts, prev), (function (row) {
                                return row.id === selectedRowId;
                              }));
                })) : prev;
        return {
                searchQuery: state.searchQuery,
                filters: state.filters,
                selectedProducts: selectedProducts
              };
    
  }
}

var Reducer = {
  initialState: initialState,
  make: make$1
};

function OrderProductFormPickerModalTable(Props) {
  var opened = Props.opened;
  var shopId = Props.shopId;
  var supplierId = Props.supplierId;
  var supplierNameOpt = Props.supplierName;
  var disabledIdsOpt = Props.disabledIds;
  var onCommit = Props.onCommit;
  var onRequestClose = Props.onRequestClose;
  var supplierName = supplierNameOpt !== undefined ? supplierNameOpt : "?";
  var disabledIds = disabledIdsOpt !== undefined ? disabledIdsOpt : [];
  var match = React.useReducer(make$1, {
        searchQuery: "",
        filters: {
          shopId: shopId
        },
        selectedProducts: []
      });
  var dispatch = match[1];
  var state = match[0];
  var query = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* NetworkOnly */3,
        /* CacheFirst */1,
        undefined,
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        Belt_Option.isNone(shopId),
        undefined,
        makeVariables$1(state.searchQuery, state.filters, undefined, undefined)
      ]);
  var asyncResult = ApolloHelpers$Pos.useQueryResultToAsyncResultWithVariablesReloading2(query, state.searchQuery, state.filters);
  var data = AsyncResult$Pos.mapOk(asyncResult, (function (data) {
          return rowsFromEdgesData(data.variants.edges);
        }));
  React.useEffect((function () {
          if (!opened && state.searchQuery !== "") {
            Curry._1(dispatch, {
                  TAG: /* Searched */0,
                  _0: ""
                });
          }
          
        }), [opened]);
  ReactUpdateEffect$Pos.use1((function (param) {
          Curry._1(dispatch, {
                TAG: /* FiltersUpdated */1,
                _0: (function (prev) {
                    var newrecord = Caml_obj.obj_dup(prev);
                    newrecord.shopId = shopId;
                    return newrecord;
                  })
              });
        }), [shopId]);
  React.useEffect((function () {
          var supplier = Belt_Option.flatMap(supplierId, (function (id) {
                  if (id !== "") {
                    return {
                            id: id,
                            name: supplierName
                          };
                  }
                  
                }));
          Curry._1(dispatch, {
                TAG: /* FiltersUpdated */1,
                _0: (function (prev) {
                    var newrecord = Caml_obj.obj_dup(prev);
                    newrecord.supplier = supplier;
                    return newrecord;
                  })
              });
        }), [supplierId]);
  var onLoadMore = React.useCallback((function (param) {
          if (typeof asyncResult === "number") {
            return ;
          }
          if (asyncResult.TAG !== /* Done */1) {
            return ;
          }
          var data = asyncResult._0;
          if (data.TAG !== /* Ok */0) {
            return ;
          }
          var data$1 = data._0;
          if (data$1.variants.pageInfo.hasNextPage === true) {
            Curry._5(query.fetchMore, undefined, undefined, makeVariables$1(state.searchQuery, state.filters, data$1.variants.pageInfo.endCursor, undefined), (function (prevResult, param) {
                    var fetchMoreResult = param.fetchMoreResult;
                    if (fetchMoreResult === undefined) {
                      return prevResult;
                    }
                    var newVariants = fetchMoreResult.variants;
                    return {
                            variants: {
                              __typename: newVariants.__typename,
                              pageInfo: newVariants.pageInfo,
                              edges: Belt_Array.concat(prevResult.variants.edges, newVariants.edges)
                            }
                          };
                  }), undefined);
            return ;
          }
          
        }), [asyncResult]);
  var onSelectChange = React.useCallback((function (selection) {
          var rowsData;
          if (typeof data === "number") {
            rowsData = data === /* NotAsked */0 ? [] : [];
          } else if (data.TAG === /* Reloading */0) {
            var data$1 = data._0;
            rowsData = data$1.TAG === /* Ok */0 ? data$1._0 : [];
          } else {
            var data$2 = data._0;
            rowsData = data$2.TAG === /* Ok */0 ? data$2._0 : [];
          }
          Curry._1(dispatch, {
                TAG: /* ProductSelectionUpdated */2,
                _0: selection,
                _1: rowsData
              });
        }), [
        data,
        disabledIds
      ]);
  var filters = [
    React.createElement(SupplierSelect$Pos.make, {
          size: "compact",
          label: Intl$Pos.t("Supplier"),
          preset: "filter",
          showDefaultItem: true,
          hideOverlayFooter: true,
          shopId: shopId,
          value: state.filters.supplier,
          onChange: (function (supplier) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */1,
                    _0: (function (prev) {
                        var newrecord = Caml_obj.obj_dup(prev);
                        newrecord.supplier = supplier;
                        return newrecord;
                      })
                  });
            })
        }),
    React.createElement(CatalogCategorySelectFilter$Pos.make, {
          shopId: shopId,
          value: state.filters.category,
          onChange: (function (category) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */1,
                    _0: (function (prev) {
                        var newrecord = Caml_obj.obj_dup(prev);
                        newrecord.category = category;
                        return newrecord;
                      })
                  });
            })
        }),
    React.createElement(CatalogProducerSelect$Pos.make, {
          shopId: shopId,
          value: state.filters.producer,
          onChange: (function (producer) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */1,
                    _0: (function (prev) {
                        var newrecord = Caml_obj.obj_dup(prev);
                        newrecord.producer = producer;
                        return newrecord;
                      })
                  });
            })
        }),
    React.createElement(CatalogStockRangeSelect$Pos.make, {
          disabled: Belt_Option.isNone(shopId),
          value: state.filters.stock,
          onChange: (function (stock) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */1,
                    _0: (function (prev) {
                        var newrecord = Caml_obj.obj_dup(prev);
                        newrecord.stock = stock;
                        return newrecord;
                      })
                  });
            })
        })
  ];
  var placeholderEmptyState = React.createElement(Placeholder$Pos.make, {
        status: Belt_Option.isNone(shopId) ? /* Pending */({
              title: Intl$Pos.t("Beware !"),
              message: Intl$Pos.t("Select the shop in charge of the order to start adding products."),
              illustration: undefined
            }) : /* NoDataAvailable */1,
        childComponent: (function (param) {
            return React.createElement(OrderEditForm$Pos.InputSelect.make, {
                        field: /* ShopId */7,
                        label: Intl$Pos.t("Shop"),
                        placeholder: Intl$Pos.t("Select a shop"),
                        sections: [{
                            items: Belt_Array.map(Auth$Pos.useShops(undefined), (function (shop) {
                                    return {
                                            key: shop.id,
                                            value: shop.id,
                                            label: shop.name
                                          };
                                  }))
                          }]
                      });
          })
      });
  return React.createElement(make, {
              children: React.createElement(AnimatedRender$Pos.make, {
                    children: React.createElement("div", {
                          style: {
                            display: "flex",
                            maxHeight: "70vh",
                            minHeight: "70vh"
                          }
                        }, React.createElement(TableView$Pos.make, {
                              data: data,
                              columns: columns,
                              keyExtractor: (function (row) {
                                  return row.id;
                                }),
                              disabledRowsKeys: disabledIds,
                              selectionEnabled: true,
                              selectAllEnabled: false,
                              hideReloadingPlaceholder: true,
                              compactRows: true,
                              placeholderEmptyState: placeholderEmptyState,
                              searchPlaceholder: Intl$Pos.t("Search product"),
                              filters: filters,
                              onSearchQueryChange: (function (value) {
                                  Curry._1(dispatch, {
                                        TAG: /* Searched */0,
                                        _0: value
                                      });
                                }),
                              onLoadMore: onLoadMore,
                              onSelectChange: onSelectChange
                            })),
                    displayed: opened,
                    animation: "fadePopinTranslation",
                    duration: 300
                  }),
              opened: opened,
              selectedProducts: state.selectedProducts,
              onCommit: onCommit,
              onRequestClose: onRequestClose
            });
}

var make$2 = React.memo(OrderProductFormPickerModalTable, (function (oldProps, newProps) {
        if (oldProps.opened === newProps.opened && oldProps.shopId === newProps.shopId) {
          return oldProps.supplierId === newProps.supplierId;
        } else {
          return false;
        }
      }));

export {
  Query ,
  rowsFromEdgesData ,
  columns ,
  setDefaultOrderedQuantityValue ,
  PickerModal ,
  makeVariables$1 as makeVariables,
  runScanEdges ,
  Reducer ,
  make$2 as make,
}
/* query Not a pure module */
