// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as DateHelpers$Pos from "../../helpers/DateHelpers.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as AnimatedRender$Pos from "../../primitives/AnimatedRender.bs.js";
import * as SupplierSelect$Pos from "../Supplier/SupplierSelect.bs.js";
import * as CatalogProducerSelect$Pos from "./CatalogProducerSelect.bs.js";
import * as ProductReferenceTableCell$Pos from "../Product/ProductReferenceTableCell.bs.js";
import * as CatalogCategorySelectFilter$Pos from "./CatalogCategorySelectFilter.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query VariantsQuery($search: String, $filterBy: InputVariantsQueryFilter, $after: String, $first: Int)  {\nvariants(search: $search, filterBy: $filterBy, after: $after, first: $first)  {\n__typename  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\ntotalCount  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \ncreatedAt  \nformattedName  \nname  \nproduct  {\n__typename  \nid  \nname  \nkind  \ncolor  \nwineType  \nwhiteWineType  \nbeerType  \nproducer  \ndesignation  \nfamily  \nregion  \ncountry  \ntax  {\n__typename  \nvalue  \n}\n\n}\n\nstockKeepingUnit  \npriceLookUpCode  \ninternalCode  \nsupplier  {\n__typename  \ncompanyName  \n}\n\nformattedCategory  \nalcoholVolume  \nvariantPrices(first: 50)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nvalueIncludingTax  \nfromQuantity  \nprice  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variants;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var value$4 = value$2.hasNextPage;
  var value$5 = value$1.edges;
  return {
          variants: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              endCursor: !(value$3 == null) ? value$3 : undefined,
              hasNextPage: !(value$4 == null) ? value$4 : undefined
            },
            totalCount: value$1.totalCount,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.product;
                    var value$3 = value$2.kind;
                    var tmp;
                    switch (value$3) {
                      case "BEER" :
                          tmp = "BEER";
                          break;
                      case "SIMPLE" :
                          tmp = "SIMPLE";
                          break;
                      case "SPIRITUOUS" :
                          tmp = "SPIRITUOUS";
                          break;
                      case "WINE" :
                          tmp = "WINE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$4 = value$2.color;
                    var tmp$1;
                    if (value$4 == null) {
                      tmp$1 = undefined;
                    } else {
                      var tmp$2;
                      switch (value$4) {
                        case "AMBER" :
                            tmp$2 = "AMBER";
                            break;
                        case "BLACK" :
                            tmp$2 = "BLACK";
                            break;
                        case "BLOND" :
                            tmp$2 = "BLOND";
                            break;
                        case "DARK" :
                            tmp$2 = "DARK";
                            break;
                        case "ORANGE" :
                            tmp$2 = "ORANGE";
                            break;
                        case "RED" :
                            tmp$2 = "RED";
                            break;
                        case "ROSE" :
                            tmp$2 = "ROSE";
                            break;
                        case "WHITE" :
                            tmp$2 = "WHITE";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$1 = tmp$2;
                    }
                    var value$5 = value$2.wineType;
                    var tmp$3;
                    if (value$5 == null) {
                      tmp$3 = undefined;
                    } else {
                      var tmp$4;
                      switch (value$5) {
                        case "EFFERVESCENT" :
                            tmp$4 = "EFFERVESCENT";
                            break;
                        case "STILL" :
                            tmp$4 = "STILL";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$3 = tmp$4;
                    }
                    var value$6 = value$2.whiteWineType;
                    var tmp$5;
                    if (value$6 == null) {
                      tmp$5 = undefined;
                    } else {
                      var tmp$6;
                      switch (value$6) {
                        case "DRY" :
                            tmp$6 = "DRY";
                            break;
                        case "SEMIDRY" :
                            tmp$6 = "SEMIDRY";
                            break;
                        case "SOFT" :
                            tmp$6 = "SOFT";
                            break;
                        case "SWEET" :
                            tmp$6 = "SWEET";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$5 = tmp$6;
                    }
                    var value$7 = value$2.beerType;
                    var value$8 = value$2.producer;
                    var value$9 = value$2.designation;
                    var value$10 = value$2.family;
                    var value$11 = value$2.region;
                    var value$12 = value$2.country;
                    var value$13 = value$2.tax;
                    var value$14 = value$1.stockKeepingUnit;
                    var value$15 = value$1.priceLookUpCode;
                    var value$16 = value$1.internalCode;
                    var value$17 = value$1.supplier;
                    var value$18 = value$1.formattedCategory;
                    var value$19 = value$1.alcoholVolume;
                    var value$20 = value$1.variantPrices;
                    var value$21 = value$20.edges;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              createdAt: Scalar$Pos.Datetime.parse(value$1.createdAt),
                              formattedName: value$1.formattedName,
                              name: value$1.name,
                              product: {
                                __typename: value$2.__typename,
                                id: value$2.id,
                                name: value$2.name,
                                kind: tmp,
                                color: tmp$1,
                                wineType: tmp$3,
                                whiteWineType: tmp$5,
                                beerType: !(value$7 == null) ? value$7 : undefined,
                                producer: !(value$8 == null) ? value$8 : undefined,
                                designation: !(value$9 == null) ? value$9 : undefined,
                                family: !(value$10 == null) ? value$10 : undefined,
                                region: !(value$11 == null) ? value$11 : undefined,
                                country: !(value$12 == null) ? value$12 : undefined,
                                tax: {
                                  __typename: value$13.__typename,
                                  value: value$13.value
                                }
                              },
                              stockKeepingUnit: !(value$14 == null) ? value$14 : undefined,
                              priceLookUpCode: !(value$15 == null) ? value$15 : undefined,
                              internalCode: !(value$16 == null) ? value$16 : undefined,
                              supplier: !(value$17 == null) ? ({
                                    __typename: value$17.__typename,
                                    companyName: value$17.companyName
                                  }) : undefined,
                              formattedCategory: !(value$18 == null) ? value$18 : undefined,
                              alcoholVolume: !(value$19 == null) ? value$19 : undefined,
                              variantPrices: {
                                __typename: value$20.__typename,
                                edges: Js_array.map((function (value) {
                                        var value$1 = value.node;
                                        var value$2 = value$1.fromQuantity;
                                        var value$3 = value$1.price;
                                        return {
                                                __typename: value.__typename,
                                                node: {
                                                  __typename: value$1.__typename,
                                                  valueIncludingTax: value$1.valueIncludingTax,
                                                  fromQuantity: !(value$2 == null) ? value$2 : undefined,
                                                  price: !(value$3 == null) ? ({
                                                        __typename: value$3.__typename,
                                                        id: value$3.id
                                                      }) : undefined
                                                }
                                              };
                                      }), value$21)
                              }
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.variants;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.variantPrices;
          var value$3 = value$2.edges;
          var edges = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.price;
                  var price;
                  if (value$2 !== undefined) {
                    var value$3 = value$2.id;
                    var value$4 = value$2.__typename;
                    price = {
                      __typename: value$4,
                      id: value$3
                    };
                  } else {
                    price = null;
                  }
                  var value$5 = value$1.fromQuantity;
                  var fromQuantity = value$5 !== undefined ? value$5 : null;
                  var value$6 = value$1.valueIncludingTax;
                  var value$7 = value$1.__typename;
                  var node = {
                    __typename: value$7,
                    valueIncludingTax: value$6,
                    fromQuantity: fromQuantity,
                    price: price
                  };
                  var value$8 = value.__typename;
                  return {
                          __typename: value$8,
                          node: node
                        };
                }), value$3);
          var value$4 = value$2.__typename;
          var variantPrices = {
            __typename: value$4,
            edges: edges
          };
          var value$5 = value$1.alcoholVolume;
          var alcoholVolume = value$5 !== undefined ? value$5 : null;
          var value$6 = value$1.formattedCategory;
          var formattedCategory = value$6 !== undefined ? value$6 : null;
          var value$7 = value$1.supplier;
          var supplier;
          if (value$7 !== undefined) {
            var value$8 = value$7.companyName;
            var value$9 = value$7.__typename;
            supplier = {
              __typename: value$9,
              companyName: value$8
            };
          } else {
            supplier = null;
          }
          var value$10 = value$1.internalCode;
          var internalCode = value$10 !== undefined ? value$10 : null;
          var value$11 = value$1.priceLookUpCode;
          var priceLookUpCode = value$11 !== undefined ? value$11 : null;
          var value$12 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$12 !== undefined ? value$12 : null;
          var value$13 = value$1.product;
          var value$14 = value$13.tax;
          var value$15 = value$14.value;
          var value$16 = value$14.__typename;
          var tax = {
            __typename: value$16,
            value: value$15
          };
          var value$17 = value$13.country;
          var country = value$17 !== undefined ? value$17 : null;
          var value$18 = value$13.region;
          var region = value$18 !== undefined ? value$18 : null;
          var value$19 = value$13.family;
          var family = value$19 !== undefined ? value$19 : null;
          var value$20 = value$13.designation;
          var designation = value$20 !== undefined ? value$20 : null;
          var value$21 = value$13.producer;
          var producer = value$21 !== undefined ? value$21 : null;
          var value$22 = value$13.beerType;
          var beerType = value$22 !== undefined ? value$22 : null;
          var value$23 = value$13.whiteWineType;
          var whiteWineType = value$23 !== undefined ? (
              value$23 === "SWEET" ? "SWEET" : (
                  value$23 === "SEMIDRY" ? "SEMIDRY" : (
                      value$23 === "SOFT" ? "SOFT" : "DRY"
                    )
                )
            ) : null;
          var value$24 = value$13.wineType;
          var wineType = value$24 !== undefined ? (
              value$24 === "EFFERVESCENT" ? "EFFERVESCENT" : "STILL"
            ) : null;
          var value$25 = value$13.color;
          var color = value$25 !== undefined ? (
              value$25 === "ORANGE" ? "ORANGE" : (
                  value$25 === "RED" ? "RED" : (
                      value$25 === "AMBER" ? "AMBER" : (
                          value$25 === "DARK" ? "DARK" : (
                              value$25 === "BLACK" ? "BLACK" : (
                                  value$25 === "BLOND" ? "BLOND" : (
                                      value$25 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$26 = value$13.kind;
          var kind = value$26 === "SPIRITUOUS" ? "SPIRITUOUS" : (
              value$26 === "BEER" ? "BEER" : (
                  value$26 === "WINE" ? "WINE" : "SIMPLE"
                )
            );
          var value$27 = value$13.name;
          var value$28 = value$13.id;
          var value$29 = value$13.__typename;
          var product = {
            __typename: value$29,
            id: value$28,
            name: value$27,
            kind: kind,
            color: color,
            wineType: wineType,
            whiteWineType: whiteWineType,
            beerType: beerType,
            producer: producer,
            designation: designation,
            family: family,
            region: region,
            country: country,
            tax: tax
          };
          var value$30 = value$1.name;
          var value$31 = value$1.formattedName;
          var value$32 = value$1.createdAt;
          var value$33 = Scalar$Pos.Datetime.serialize(value$32);
          var value$34 = value$1.id;
          var value$35 = value$1.__typename;
          var node = {
            __typename: value$35,
            id: value$34,
            createdAt: value$33,
            formattedName: value$31,
            name: value$30,
            product: product,
            stockKeepingUnit: stockKeepingUnit,
            priceLookUpCode: priceLookUpCode,
            internalCode: internalCode,
            supplier: supplier,
            formattedCategory: formattedCategory,
            alcoholVolume: alcoholVolume,
            variantPrices: variantPrices
          };
          var value$36 = value.__typename;
          return {
                  __typename: value$36,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.totalCount;
  var value$4 = value$1.pageInfo;
  var value$5 = value$4.hasNextPage;
  var hasNextPage = value$5 !== undefined ? value$5 : null;
  var value$6 = value$4.endCursor;
  var endCursor = value$6 !== undefined ? value$6 : null;
  var value$7 = value$4.__typename;
  var pageInfo = {
    __typename: value$7,
    endCursor: endCursor,
    hasNextPage: hasNextPage
  };
  var value$8 = value$1.__typename;
  var variants = {
    __typename: value$8,
    pageInfo: pageInfo,
    totalCount: value$3,
    edges: edges
  };
  return {
          variants: variants
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectNumberRangeFilter(inp) {
  var a = inp._min;
  var a$1 = inp._max;
  var a$2 = inp._between;
  return {
          _min: a !== undefined ? a : undefined,
          _max: a$1 !== undefined ? a$1 : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectNullableStringEqualsFilter(inp) {
  var a = inp._equals;
  return {
          _equals: a !== undefined ? a : undefined
        };
}

function serializeInputObjectStringEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectBooleanEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectInputVariantsQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.stockKeepingUnit;
  var a$2 = inp.ean13;
  var a$3 = inp.active;
  var a$4 = inp.archived;
  var a$5 = inp.createdAt;
  var a$6 = inp.updatedAt;
  var a$7 = inp.categoryId;
  var a$8 = inp.supplierId;
  var a$9 = inp.stock;
  var a$10 = inp.producer;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          stockKeepingUnit: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          ean13: a$2 !== undefined ? ({
                _equals: a$2._equals
              }) : undefined,
          active: a$3 !== undefined ? ({
                _equals: a$3._equals
              }) : undefined,
          archived: a$4 !== undefined ? (
              a$4 === "ONLY" ? "ONLY" : (
                  a$4 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          createdAt: a$5 !== undefined ? serializeInputObjectDateFilter(a$5) : undefined,
          updatedAt: a$6 !== undefined ? serializeInputObjectDateFilter(a$6) : undefined,
          categoryId: a$7 !== undefined ? serializeInputObjectNullableStringEqualsFilter(a$7) : undefined,
          supplierId: a$8 !== undefined ? serializeInputObjectNullableStringEqualsFilter(a$8) : undefined,
          stock: a$9 !== undefined ? serializeInputObjectNumberRangeFilter(a$9) : undefined,
          producer: a$10 !== undefined ? ({
                _equals: a$10._equals
              }) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.filterBy;
  var a$2 = inp.after;
  var a$3 = inp.first;
  return {
          search: a !== undefined ? a : undefined,
          filterBy: a$1 !== undefined ? serializeInputObjectInputVariantsQueryFilter(a$1) : undefined,
          after: a$2 !== undefined ? a$2 : undefined,
          first: a$3 !== undefined ? a$3 : undefined
        };
}

function makeVariables(search, filterBy, after, first, param) {
  return {
          search: search,
          filterBy: filterBy,
          after: after,
          first: first
        };
}

function makeInputObjectInputVariantsQueryFilter(shopIds, stockKeepingUnit, ean13, active, archived, createdAt, updatedAt, categoryId, supplierId, stock, producer, param) {
  return {
          shopIds: shopIds,
          stockKeepingUnit: stockKeepingUnit,
          ean13: ean13,
          active: active,
          archived: archived,
          createdAt: createdAt,
          updatedAt: updatedAt,
          categoryId: categoryId,
          supplierId: supplierId,
          stock: stock,
          producer: producer
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectBooleanEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeInputObjectNullableStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectNumberRangeFilter(_min, _max, _between, param) {
  return {
          _min: _min,
          _max: _max,
          _between: _between
        };
}

function makeDefaultVariables(param) {
  return {
          search: undefined,
          filterBy: undefined,
          after: undefined,
          first: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectNullableStringEqualsFilter: serializeInputObjectNullableStringEqualsFilter,
  serializeInputObjectNumberRangeFilter: serializeInputObjectNumberRangeFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectNullableStringEqualsFilter: makeInputObjectNullableStringEqualsFilter,
  makeInputObjectNumberRangeFilter: makeInputObjectNumberRangeFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectNullableStringEqualsFilter: serializeInputObjectNullableStringEqualsFilter,
  serializeInputObjectNumberRangeFilter: serializeInputObjectNumberRangeFilter,
  makeVariables: makeVariables,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectNullableStringEqualsFilter: makeInputObjectNullableStringEqualsFilter,
  makeInputObjectNumberRangeFilter: makeInputObjectNumberRangeFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function rowsFromEdgesData(data, priceId) {
  return Belt_Array.map(data, (function (edge) {
                var hasValidVariantPrice = Belt_Option.isSome(Belt_Array.getBy(edge.node.variantPrices.edges, (function (param) {
                            var node = param.node;
                            var match = node.fromQuantity;
                            if (match !== undefined && match !== 1) {
                              return false;
                            }
                            var match$1 = node.price;
                            if (match$1 !== undefined && match$1.id === priceId) {
                              return node.valueIncludingTax > 0;
                            } else {
                              return false;
                            }
                          })));
                return {
                        id: edge.node.id,
                        createdAt: edge.node.createdAt,
                        formattedName: edge.node.formattedName,
                        productKind: edge.node.product.kind,
                        information: {
                          productName: edge.node.product.name,
                          variantName: edge.node.name,
                          sku: edge.node.stockKeepingUnit,
                          plu: Belt_Option.map(edge.node.priceLookUpCode, (function (prim) {
                                  return String(prim);
                                })),
                          internalCode: edge.node.internalCode,
                          color: edge.node.product.color,
                          producerName: edge.node.product.producer,
                          designation: edge.node.product.designation,
                          productFamily: edge.node.product.family,
                          wineType: edge.node.product.wineType,
                          whiteWineType: edge.node.product.whiteWineType,
                          beerType: edge.node.product.beerType,
                          region: edge.node.product.region,
                          country: Belt_Option.getWithDefault(edge.node.product.country, "—"),
                          categoryName: Belt_Option.getWithDefault(edge.node.formattedCategory, "—"),
                          supplierName: Belt_Option.map(edge.node.supplier, (function (supplier) {
                                  return supplier.companyName;
                                })),
                          alcoholVolume: Belt_Option.map(edge.node.alcoholVolume, (function (alcoholVolume) {
                                  return Intl$Pos.decimalFormat(undefined, 1, alcoholVolume) + "°";
                                }))
                        },
                        hasValidVariantPrice: hasValidVariantPrice
                      };
              }));
}

var columns = [{
    key: "product",
    name: Intl$Pos.t("Product"),
    render: (function (param) {
        var disabled = param.disabled;
        var data = param.data;
        var badgeNew = DateHelpers$Pos.diffInHours(new Date(), data.createdAt) <= 24 ? ({
              variation: "information",
              text: Intl$Pos.t("New")
            }) : undefined;
        var match = data.hasValidVariantPrice;
        var tmp = {
          disabled: disabled,
          productKind: data.productKind,
          information: data.information
        };
        if (badgeNew !== undefined) {
          tmp.badge = Caml_option.valFromOption(badgeNew);
        }
        var tmp$1 = disabled && !match ? Intl$Pos.t("No retail prices found with the selected price list") : undefined;
        if (tmp$1 !== undefined) {
          tmp.errorMessage = Caml_option.valFromOption(tmp$1);
        }
        return React.createElement(ProductReferenceTableCell$Pos.make, tmp);
      })
  }];

function setDefaultOrderedQuantityValue(productInput) {
  if (productInput.TAG === /* Unit */0) {
    var product = productInput.product;
    return {
            TAG: /* Unit */0,
            product: {
              id: product.id,
              identifier: product.identifier,
              stockKeepingUnit: product.stockKeepingUnit,
              name: product.name,
              description: product.description,
              capacityUnit: product.capacityUnit,
              stock: product.stock,
              packaging: product.packaging,
              quantity: 1,
              expectedQuantity: product.expectedQuantity,
              unitPrice: product.unitPrice,
              fees: product.fees,
              discounts: product.discounts,
              tax: product.tax
            }
          };
  }
  var product$1 = productInput.product;
  return {
          TAG: /* Bulk */1,
          product: {
            id: product$1.id,
            identifier: product$1.identifier,
            stockKeepingUnit: product$1.stockKeepingUnit,
            name: product$1.name,
            description: product$1.description,
            capacityUnit: product$1.capacityUnit,
            stock: product$1.stock,
            packaging: product$1.packaging,
            quantity: 1,
            expectedQuantity: product$1.expectedQuantity,
            unitPrice: product$1.unitPrice,
            fees: product$1.fees,
            discounts: product$1.discounts,
            tax: product$1.tax
          },
          precision: productInput.precision
        };
}

var make = React.memo(function (Props) {
      var children = Props.children;
      var opened = Props.opened;
      var selectedProducts = Props.selectedProducts;
      var onCommit = Props.onCommit;
      var onRequestClose = Props.onRequestClose;
      var renderStartText = function (param) {
        var length = selectedProducts.length;
        var shouldBePrimaryView = length > 0;
        var formattedText = "" + String(length) + " " + Intl$Pos.t(Intl$Pos.isPlural(length) ? "selected products" : "selected product");
        return React.createElement(Inline$Pos.make, {
                    children: React.createElement(TextStyle$Pos.make, {
                          children: formattedText,
                          variation: shouldBePrimaryView ? "primary" : "neutral",
                          weight: shouldBePrimaryView ? "strong" : "regular"
                        })
                  });
      };
      var onCommit$1 = function (param) {
        Curry._1(onCommit, selectedProducts);
      };
      return React.createElement(Modal$Pos.make, {
                  children: children,
                  opened: opened,
                  title: Intl$Pos.t("Product append"),
                  variation: "secondary",
                  compact: false,
                  backgroundColor: "#fefefe",
                  renderStartText: renderStartText,
                  abortButtonText: Intl$Pos.t("Cancel"),
                  allowCommit: selectedProducts.length !== 0,
                  commitButtonVariation: "primary",
                  commitButtonText: Intl$Pos.t("Add products"),
                  commitButtonCallback: onCommit$1,
                  onRequestClose: onRequestClose
                });
    });

var PickerModal = {
  make: make
};

function initialState(filters) {
  return {
          searchQuery: "",
          filters: filters,
          selectedProducts: []
        };
}

function make$1(state, action) {
  switch (action.TAG | 0) {
    case /* Searched */0 :
        return {
                searchQuery: action._0,
                filters: state.filters,
                selectedProducts: state.selectedProducts
              };
    case /* FiltersUpdated */1 :
        return {
                searchQuery: state.searchQuery,
                filters: Curry._1(action._0, state.filters),
                selectedProducts: state.selectedProducts
              };
    case /* ProductSelectionUpdated */2 :
        var allProducts = action._1;
        var tableSelection = action._0;
        var prev = state.selectedProducts;
        var selectedProducts = tableSelection ? Belt_Array.keepMap(tableSelection._0, (function (selectedRowId) {
                  return Belt_Array.getBy(Belt_Array.concat(allProducts, prev), (function (row) {
                                return row.id === selectedRowId;
                              }));
                })) : prev;
        return {
                searchQuery: state.searchQuery,
                filters: state.filters,
                selectedProducts: selectedProducts
              };
    
  }
}

var Reducer = {
  initialState: initialState,
  make: make$1
};

function makeVariables$1(search, filters, after, param) {
  var id = filters.shopId;
  var category = filters.category;
  var tmp;
  if (category !== undefined) {
    var categoryId = category.id;
    tmp = {
      _equals: (categoryId == null) ? null : categoryId
    };
  } else {
    tmp = undefined;
  }
  var supplier = filters.supplier;
  var tmp$1;
  if (supplier !== undefined) {
    var supplierId = supplier.id;
    tmp$1 = {
      _equals: (supplierId == null) ? null : supplierId
    };
  } else {
    tmp$1 = undefined;
  }
  var producer = filters.producer;
  return makeVariables(search, makeInputObjectInputVariantsQueryFilter(id !== undefined ? ({
                      _in: [id]
                    }) : undefined, undefined, undefined, {
                  _equals: true
                }, undefined, undefined, undefined, tmp, tmp$1, undefined, producer !== undefined ? ({
                      _equals: producer
                    }) : undefined, undefined), after, 20, undefined);
}

function CatalogLabelProductsPickerModalTable(Props) {
  var opened = Props.opened;
  var shopId = Props.shopId;
  var priceId = Props.priceId;
  var disabledIdsOpt = Props.disabledIds;
  var onCommit = Props.onCommit;
  var onRequestClose = Props.onRequestClose;
  var disabledIds = disabledIdsOpt !== undefined ? disabledIdsOpt : [];
  var match = React.useReducer(make$1, {
        searchQuery: "",
        filters: {
          shopId: shopId
        },
        selectedProducts: []
      });
  var dispatch = match[1];
  var state = match[0];
  var query = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* NetworkOnly */3,
        /* CacheFirst */1,
        undefined,
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        Belt_Option.isNone(shopId),
        undefined,
        makeVariables$1(state.searchQuery, state.filters, undefined, undefined)
      ]);
  var asyncResult = ApolloHelpers$Pos.useQueryResultToAsyncResultWithVariablesReloading2(query, state.searchQuery, shopId);
  var data = AsyncResult$Pos.mapOk(asyncResult, (function (data) {
          return rowsFromEdgesData(data.variants.edges, priceId);
        }));
  React.useEffect((function () {
          if (!opened && state.searchQuery !== "") {
            Curry._1(dispatch, {
                  TAG: /* Searched */0,
                  _0: ""
                });
          }
          
        }), [opened]);
  var onLoadMore = React.useCallback((function (param) {
          if (typeof asyncResult === "number") {
            return ;
          }
          if (asyncResult.TAG !== /* Done */1) {
            return ;
          }
          var data = asyncResult._0;
          if (data.TAG !== /* Ok */0) {
            return ;
          }
          var data$1 = data._0;
          if (data$1.variants.pageInfo.hasNextPage === true) {
            Curry._5(query.fetchMore, undefined, undefined, makeVariables$1(state.searchQuery, state.filters, data$1.variants.pageInfo.endCursor, undefined), (function (prevResult, param) {
                    var fetchMoreResult = param.fetchMoreResult;
                    if (fetchMoreResult === undefined) {
                      return prevResult;
                    }
                    var newVariants = fetchMoreResult.variants;
                    return {
                            variants: {
                              __typename: newVariants.__typename,
                              pageInfo: newVariants.pageInfo,
                              totalCount: newVariants.totalCount,
                              edges: Belt_Array.concat(prevResult.variants.edges, newVariants.edges)
                            }
                          };
                  }), undefined);
            return ;
          }
          
        }), [asyncResult]);
  var onSelectChange = React.useCallback((function (selection) {
          var rowsData;
          if (typeof data === "number") {
            rowsData = data === /* NotAsked */0 ? [] : [];
          } else if (data.TAG === /* Reloading */0) {
            var data$1 = data._0;
            rowsData = data$1.TAG === /* Ok */0 ? data$1._0 : [];
          } else {
            var data$2 = data._0;
            rowsData = data$2.TAG === /* Ok */0 ? data$2._0 : [];
          }
          Curry._1(dispatch, {
                TAG: /* ProductSelectionUpdated */2,
                _0: selection,
                _1: rowsData
              });
        }), [
        data,
        disabledIds
      ]);
  var disabledRowsKeys;
  if (typeof data === "number" || data.TAG !== /* Done */1) {
    disabledRowsKeys = disabledIds;
  } else {
    var rows = data._0;
    disabledRowsKeys = rows.TAG === /* Ok */0 ? Belt_Array.concat(disabledIds, Belt_Array.keepMap(rows._0, (function (row) {
                  if (!row.hasValidVariantPrice) {
                    return row.id;
                  }
                  
                }))) : disabledIds;
  }
  var filters = [
    React.createElement(SupplierSelect$Pos.make, {
          size: "compact",
          label: Intl$Pos.t("Supplier"),
          preset: "filter",
          showDefaultItem: true,
          hideOverlayFooter: true,
          shopId: shopId,
          value: state.filters.supplier,
          onChange: (function (supplier) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */1,
                    _0: (function (prev) {
                        var newrecord = Caml_obj.obj_dup(prev);
                        newrecord.supplier = supplier;
                        return newrecord;
                      })
                  });
            })
        }),
    React.createElement(CatalogCategorySelectFilter$Pos.make, {
          shopId: shopId,
          value: state.filters.category,
          onChange: (function (category) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */1,
                    _0: (function (prev) {
                        var newrecord = Caml_obj.obj_dup(prev);
                        newrecord.category = category;
                        return newrecord;
                      })
                  });
            })
        }),
    React.createElement(CatalogProducerSelect$Pos.make, {
          shopId: shopId,
          value: state.filters.producer,
          onChange: (function (producer) {
              Curry._1(dispatch, {
                    TAG: /* FiltersUpdated */1,
                    _0: (function (prev) {
                        var newrecord = Caml_obj.obj_dup(prev);
                        newrecord.producer = producer;
                        return newrecord;
                      })
                  });
            })
        })
  ];
  return React.createElement(make, {
              children: React.createElement(AnimatedRender$Pos.make, {
                    children: React.createElement("div", {
                          style: {
                            display: "flex",
                            maxHeight: "70vh",
                            minHeight: "70vh"
                          }
                        }, React.createElement(TableView$Pos.make, {
                              data: data,
                              columns: columns,
                              keyExtractor: (function (row) {
                                  return row.id;
                                }),
                              disabledRowsKeys: disabledRowsKeys,
                              selectionEnabled: true,
                              selectAllEnabled: false,
                              hideReloadingPlaceholder: true,
                              compactRows: true,
                              searchPlaceholder: Intl$Pos.t("Search product"),
                              filters: filters,
                              onSearchQueryChange: (function (value) {
                                  Curry._1(dispatch, {
                                        TAG: /* Searched */0,
                                        _0: value
                                      });
                                }),
                              onLoadMore: onLoadMore,
                              onSelectChange: onSelectChange
                            })),
                    displayed: opened,
                    animation: "fadePopinTranslation",
                    duration: 300
                  }),
              opened: opened,
              selectedProducts: state.selectedProducts,
              onCommit: onCommit,
              onRequestClose: onRequestClose
            });
}

var make$2 = React.memo(CatalogLabelProductsPickerModalTable, (function (oldProps, newProps) {
        return oldProps.opened === newProps.opened;
      }));

export {
  Query ,
  rowsFromEdgesData ,
  columns ,
  setDefaultOrderedQuantityValue ,
  PickerModal ,
  Reducer ,
  makeVariables$1 as makeVariables,
  make$2 as make,
}
/* query Not a pure module */
