// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query OrderSuppliesQuery($cku: CKU!, $first: Int, $filterBy: InputOrderProductsByVariantCkuQueryFilter)  {\norderProductsByVariantCku(cku: $cku, first: $first, filterBy: $filterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nquantity  \ntotalAmountIncludingTaxes  \ntotalAmountExcludingTaxes  \norder  {\n__typename  \nshopName  \nid  \nname  \nreceptionFinishedAt  \nsupplierCompanyName  \nsupplier  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\npageInfo  {\n__typename  \nhasNextPage  \n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.orderProductsByVariantCku;
  var value$2 = value$1.edges;
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  return {
          orderProductsByVariantCku: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.order;
                    var tmp;
                    if (value$2 == null) {
                      tmp = undefined;
                    } else {
                      var value$3 = value$2.name;
                      var value$4 = value$2.receptionFinishedAt;
                      var value$5 = value$2.supplier;
                      tmp = {
                        __typename: value$2.__typename,
                        shopName: value$2.shopName,
                        id: value$2.id,
                        name: !(value$3 == null) ? value$3 : undefined,
                        receptionFinishedAt: !(value$4 == null) ? Caml_option.some(Scalar$Pos.Datetime.parse(value$4)) : undefined,
                        supplierCompanyName: value$2.supplierCompanyName,
                        supplier: !(value$5 == null) ? ({
                              __typename: value$5.__typename,
                              id: value$5.id
                            }) : undefined
                      };
                    }
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              quantity: value$1.quantity,
                              totalAmountIncludingTaxes: value$1.totalAmountIncludingTaxes,
                              totalAmountExcludingTaxes: value$1.totalAmountExcludingTaxes,
                              order: tmp
                            }
                          };
                  }), value$2),
            pageInfo: {
              __typename: value$3.__typename,
              hasNextPage: !(value$4 == null) ? value$4 : undefined
            }
          }
        };
}

function serialize(value) {
  var value$1 = value.orderProductsByVariantCku;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.hasNextPage;
  var hasNextPage = value$3 !== undefined ? value$3 : null;
  var value$4 = value$2.__typename;
  var pageInfo = {
    __typename: value$4,
    hasNextPage: hasNextPage
  };
  var value$5 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.order;
          var order;
          if (value$2 !== undefined) {
            var value$3 = value$2.supplier;
            var supplier;
            if (value$3 !== undefined) {
              var value$4 = value$3.id;
              var value$5 = value$3.__typename;
              supplier = {
                __typename: value$5,
                id: value$4
              };
            } else {
              supplier = null;
            }
            var value$6 = value$2.supplierCompanyName;
            var value$7 = value$2.receptionFinishedAt;
            var receptionFinishedAt = value$7 !== undefined ? Scalar$Pos.Datetime.serialize(Caml_option.valFromOption(value$7)) : null;
            var value$8 = value$2.name;
            var name = value$8 !== undefined ? value$8 : null;
            var value$9 = value$2.id;
            var value$10 = value$2.shopName;
            var value$11 = value$2.__typename;
            order = {
              __typename: value$11,
              shopName: value$10,
              id: value$9,
              name: name,
              receptionFinishedAt: receptionFinishedAt,
              supplierCompanyName: value$6,
              supplier: supplier
            };
          } else {
            order = null;
          }
          var value$12 = value$1.totalAmountExcludingTaxes;
          var value$13 = value$1.totalAmountIncludingTaxes;
          var value$14 = value$1.quantity;
          var value$15 = value$1.__typename;
          var node = {
            __typename: value$15,
            quantity: value$14,
            totalAmountIncludingTaxes: value$13,
            totalAmountExcludingTaxes: value$12,
            order: order
          };
          var value$16 = value.__typename;
          return {
                  __typename: value$16,
                  node: node
                };
        }), value$5);
  var value$6 = value$1.__typename;
  var orderProductsByVariantCku = {
    __typename: value$6,
    edges: edges,
    pageInfo: pageInfo
  };
  return {
          orderProductsByVariantCku: orderProductsByVariantCku
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectInputOrderProductsByVariantCkuQueryFilter(inp) {
  var a = inp.archived;
  var a$1 = inp.receptionFinishedAt;
  var a$2 = inp.shopIds;
  return {
          archived: a !== undefined ? (
              a === "ONLY" ? "ONLY" : (
                  a === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          receptionFinishedAt: a$1 !== undefined ? serializeInputObjectDateFilter(a$1) : undefined,
          shopIds: a$2 !== undefined ? serializeInputObjectInFilter(a$2) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.first;
  var a$1 = inp.filterBy;
  return {
          cku: inp.cku,
          first: a !== undefined ? a : undefined,
          filterBy: a$1 !== undefined ? serializeInputObjectInputOrderProductsByVariantCkuQueryFilter(a$1) : undefined
        };
}

function makeVariables(cku, first, filterBy, param) {
  return {
          cku: cku,
          first: first,
          filterBy: filterBy
        };
}

function makeInputObjectInputOrderProductsByVariantCkuQueryFilter(archived, receptionFinishedAt, shopIds, param) {
  return {
          archived: archived,
          receptionFinishedAt: receptionFinishedAt,
          shopIds: shopIds
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

var OrderSuppliesQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputOrderProductsByVariantCkuQueryFilter: serializeInputObjectInputOrderProductsByVariantCkuQueryFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputOrderProductsByVariantCkuQueryFilter: makeInputObjectInputOrderProductsByVariantCkuQueryFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectInFilter: makeInputObjectInFilter
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var OrderSuppliesQuery_refetchQueryDescription = include.refetchQueryDescription;

var OrderSuppliesQuery_use = include.use;

var OrderSuppliesQuery_useLazy = include.useLazy;

var OrderSuppliesQuery_useLazyWithVariables = include.useLazyWithVariables;

var OrderSuppliesQuery = {
  OrderSuppliesQuery_inner: OrderSuppliesQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputOrderProductsByVariantCkuQueryFilter: serializeInputObjectInputOrderProductsByVariantCkuQueryFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputOrderProductsByVariantCkuQueryFilter: makeInputObjectInputOrderProductsByVariantCkuQueryFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  refetchQueryDescription: OrderSuppliesQuery_refetchQueryDescription,
  use: OrderSuppliesQuery_use,
  useLazy: OrderSuppliesQuery_useLazy,
  useLazyWithVariables: OrderSuppliesQuery_useLazyWithVariables
};

var edgesPerFetch = 5;

export {
  edgesPerFetch ,
  OrderSuppliesQuery ,
}
/* query Not a pure module */
