// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as StockTransferForm$Pos from "./StockTransferForm.bs.js";

function StockTransferFormSenderInformationCard(Props) {
  var shops = Auth$Pos.useShops(undefined);
  var formState = Curry._1(StockTransferForm$Pos.useFormState, undefined);
  var items = Belt_Array.map(shops, (function (shop) {
          return {
                  key: shop.id,
                  value: shop.id,
                  label: shop.name,
                  disabled: shop.id === formState.values.recipientShopId
                };
        }));
  var sections = [{
      items: items,
      title: Intl$Pos.t("Shops")
    }];
  return React.createElement(Card$Pos.make, {
              children: React.createElement(Stack$Pos.make, {
                    children: React.createElement(StockTransferForm$Pos.InputSelect.make, {
                          field: /* SenderShopId */0,
                          label: Intl$Pos.t("Sender"),
                          placeholder: Intl$Pos.t("Select the sending shop"),
                          infotip: Intl$Pos.t("Changing the sender will reset the transfer basket."),
                          sections: sections
                        }),
                    space: "large"
                  }),
              title: Intl$Pos.t("Sender information")
            });
}

var make = React.memo(StockTransferFormSenderInformationCard);

export {
  make ,
}
/* make Not a pure module */
