// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as Client from "@apollo/client";
import * as ApolloConfig$Pos from "../../core/ApolloConfig.bs.js";
import * as ReactUpdateEffect$Pos from "../../primitives/ReactUpdateEffect.bs.js";
import * as InputTextAreaField$Pos from "../../resources/selection-and-input/InputTextAreaField.bs.js";
import * as ReactUpdateDebounced$Pos from "../../primitives/ReactUpdateDebounced.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = Client.gql(["mutation updateVariant_tastingNote($id: ID!, $input: InputUpdateVariant!)  {\nupdateVariant(id: $id, input: $input)  {\n__typename  \nid  \ntastingNote  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.tastingNote;
  return {
          updateVariant: {
            __typename: value$1.__typename,
            id: value$1.id,
            tastingNote: !(value$2 == null) ? Scalar$Pos.$$Text.parse(value$2) : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.tastingNote;
  var tastingNote = value$2 !== undefined ? Scalar$Pos.$$Text.serialize(value$2) : null;
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var updateVariant = {
    __typename: value$4,
    id: value$3,
    tastingNote: tastingNote
  };
  return {
          updateVariant: updateVariant
        };
}

function serializeInputObjectInputUpdateVariantMetaData(inp) {
  var a = inp.shopifyVariantId;
  return {
          shopifyVariantId: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInputUpdateVariant(inp) {
  var a = inp.stockKeepingUnit;
  var a$1 = inp.name;
  var a$2 = inp.purchasedPrice;
  var a$3 = inp.year;
  var a$4 = inp.capacityValue;
  var a$5 = inp.capacityUnit;
  var a$6 = inp.capacityPrecision;
  var a$7 = inp.alcoholVolume;
  var a$8 = inp.ean13;
  var a$9 = inp.internalNote;
  var a$10 = inp.metadata;
  var a$11 = inp.tastingNote;
  var a$12 = inp.packaging;
  var a$13 = inp.internalCode;
  var a$14 = inp.priceLookUpCode;
  var a$15 = inp.supplierId;
  var a$16 = inp.minStockThreshold;
  var a$17 = inp.maxStockThreshold;
  var a$18 = inp.stockOrderTriggerThreshold;
  return {
          stockKeepingUnit: a !== undefined ? a : undefined,
          name: a$1 !== undefined ? a$1 : undefined,
          purchasedPrice: a$2 !== undefined ? a$2 : undefined,
          year: a$3 !== undefined ? a$3 : undefined,
          capacityValue: a$4 !== undefined ? a$4 : undefined,
          capacityUnit: a$5 !== undefined ? a$5 : undefined,
          capacityPrecision: a$6 !== undefined ? a$6 : undefined,
          alcoholVolume: a$7 !== undefined ? a$7 : undefined,
          ean13: a$8 !== undefined ? a$8 : undefined,
          internalNote: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          metadata: a$10 !== undefined ? serializeInputObjectInputUpdateVariantMetaData(a$10) : undefined,
          tastingNote: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          packaging: a$12 !== undefined ? a$12 : undefined,
          internalCode: a$13 !== undefined ? a$13 : undefined,
          priceLookUpCode: a$14 !== undefined ? a$14 : undefined,
          supplierId: a$15 !== undefined ? a$15 : undefined,
          minStockThreshold: a$16 !== undefined ? a$16 : undefined,
          maxStockThreshold: a$17 !== undefined ? a$17 : undefined,
          stockOrderTriggerThreshold: a$18 !== undefined ? a$18 : undefined
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id,
          input: serializeInputObjectInputUpdateVariant(inp.input)
        };
}

function makeVariables(id, input, param) {
  return {
          id: id,
          input: input
        };
}

function makeInputObjectInputUpdateVariant(stockKeepingUnit, name, purchasedPrice, year, capacityValue, capacityUnit, capacityPrecision, alcoholVolume, ean13, internalNote, metadata, tastingNote, packaging, internalCode, priceLookUpCode, supplierId, minStockThreshold, maxStockThreshold, stockOrderTriggerThreshold, param) {
  return {
          stockKeepingUnit: stockKeepingUnit,
          name: name,
          purchasedPrice: purchasedPrice,
          year: year,
          capacityValue: capacityValue,
          capacityUnit: capacityUnit,
          capacityPrecision: capacityPrecision,
          alcoholVolume: alcoholVolume,
          ean13: ean13,
          internalNote: internalNote,
          metadata: metadata,
          tastingNote: tastingNote,
          packaging: packaging,
          internalCode: internalCode,
          priceLookUpCode: priceLookUpCode,
          supplierId: supplierId,
          minStockThreshold: minStockThreshold,
          maxStockThreshold: maxStockThreshold,
          stockOrderTriggerThreshold: stockOrderTriggerThreshold
        };
}

function makeInputObjectInputUpdateVariantMetaData(shopifyVariantId, param) {
  return {
          shopifyVariantId: shopifyVariantId
        };
}

var UpdateVariantTastingNoteMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var UpdateVariantTastingNoteMutation_useWithVariables = include.useWithVariables;

var UpdateVariantTastingNoteMutation = {
  UpdateVariantTastingNoteMutation_inner: UpdateVariantTastingNoteMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData,
  use: use,
  useWithVariables: UpdateVariantTastingNoteMutation_useWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["mutation updateVariant_internalNote($id: ID!, $input: InputUpdateVariant!)  {\nupdateVariant(id: $id, input: $input)  {\n__typename  \nid  \ninternalNote  \n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.internalNote;
  return {
          updateVariant: {
            __typename: value$1.__typename,
            id: value$1.id,
            internalNote: !(value$2 == null) ? Scalar$Pos.$$Text.parse(value$2) : undefined
          }
        };
}

function serialize$1(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.internalNote;
  var internalNote = value$2 !== undefined ? Scalar$Pos.$$Text.serialize(value$2) : null;
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var updateVariant = {
    __typename: value$4,
    id: value$3,
    internalNote: internalNote
  };
  return {
          updateVariant: updateVariant
        };
}

function serializeInputObjectInputUpdateVariantMetaData$1(inp) {
  var a = inp.shopifyVariantId;
  return {
          shopifyVariantId: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInputUpdateVariant$1(inp) {
  var a = inp.stockKeepingUnit;
  var a$1 = inp.name;
  var a$2 = inp.purchasedPrice;
  var a$3 = inp.year;
  var a$4 = inp.capacityValue;
  var a$5 = inp.capacityUnit;
  var a$6 = inp.capacityPrecision;
  var a$7 = inp.alcoholVolume;
  var a$8 = inp.ean13;
  var a$9 = inp.internalNote;
  var a$10 = inp.metadata;
  var a$11 = inp.tastingNote;
  var a$12 = inp.packaging;
  var a$13 = inp.internalCode;
  var a$14 = inp.priceLookUpCode;
  var a$15 = inp.supplierId;
  var a$16 = inp.minStockThreshold;
  var a$17 = inp.maxStockThreshold;
  var a$18 = inp.stockOrderTriggerThreshold;
  return {
          stockKeepingUnit: a !== undefined ? a : undefined,
          name: a$1 !== undefined ? a$1 : undefined,
          purchasedPrice: a$2 !== undefined ? a$2 : undefined,
          year: a$3 !== undefined ? a$3 : undefined,
          capacityValue: a$4 !== undefined ? a$4 : undefined,
          capacityUnit: a$5 !== undefined ? a$5 : undefined,
          capacityPrecision: a$6 !== undefined ? a$6 : undefined,
          alcoholVolume: a$7 !== undefined ? a$7 : undefined,
          ean13: a$8 !== undefined ? a$8 : undefined,
          internalNote: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          metadata: a$10 !== undefined ? serializeInputObjectInputUpdateVariantMetaData$1(a$10) : undefined,
          tastingNote: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          packaging: a$12 !== undefined ? a$12 : undefined,
          internalCode: a$13 !== undefined ? a$13 : undefined,
          priceLookUpCode: a$14 !== undefined ? a$14 : undefined,
          supplierId: a$15 !== undefined ? a$15 : undefined,
          minStockThreshold: a$16 !== undefined ? a$16 : undefined,
          maxStockThreshold: a$17 !== undefined ? a$17 : undefined,
          stockOrderTriggerThreshold: a$18 !== undefined ? a$18 : undefined
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id,
          input: serializeInputObjectInputUpdateVariant$1(inp.input)
        };
}

function makeVariables$1(id, input, param) {
  return {
          id: id,
          input: input
        };
}

function makeInputObjectInputUpdateVariant$1(stockKeepingUnit, name, purchasedPrice, year, capacityValue, capacityUnit, capacityPrecision, alcoholVolume, ean13, internalNote, metadata, tastingNote, packaging, internalCode, priceLookUpCode, supplierId, minStockThreshold, maxStockThreshold, stockOrderTriggerThreshold, param) {
  return {
          stockKeepingUnit: stockKeepingUnit,
          name: name,
          purchasedPrice: purchasedPrice,
          year: year,
          capacityValue: capacityValue,
          capacityUnit: capacityUnit,
          capacityPrecision: capacityPrecision,
          alcoholVolume: alcoholVolume,
          ean13: ean13,
          internalNote: internalNote,
          metadata: metadata,
          tastingNote: tastingNote,
          packaging: packaging,
          internalCode: internalCode,
          priceLookUpCode: priceLookUpCode,
          supplierId: supplierId,
          minStockThreshold: minStockThreshold,
          maxStockThreshold: maxStockThreshold,
          stockOrderTriggerThreshold: stockOrderTriggerThreshold
        };
}

function makeInputObjectInputUpdateVariantMetaData$1(shopifyVariantId, param) {
  return {
          shopifyVariantId: shopifyVariantId
        };
}

var UpdateVariantInternalNoteMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant$1,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData$1,
  makeVariables: makeVariables$1,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant$1,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData$1
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var UpdateVariantInternalNoteMutation_useWithVariables = include$1.useWithVariables;

var UpdateVariantInternalNoteMutation = {
  UpdateVariantInternalNoteMutation_inner: UpdateVariantInternalNoteMutation_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant$1,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData$1,
  makeVariables: makeVariables$1,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant$1,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData$1,
  use: use$1,
  useWithVariables: UpdateVariantInternalNoteMutation_useWithVariables
};

function CatalogVariantNotesCard(Props) {
  var shopsNotes = Props.shopsNotes;
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var match = Belt_Option.mapWithDefault(Belt_Array.getBy(shopsNotes, (function (productNotes) {
              return Caml_obj.equal(productNotes.shopId, Belt_Option.map(activeShop, (function (param) {
                                return param.id;
                              })));
            })), [
        "",
        "",
        ""
      ], (function (notes) {
          return [
                  notes.variantId,
                  notes.tastingNote,
                  notes.internalNote
                ];
        }));
  var initialInternalNote = match[2];
  var initialTastingNote = match[1];
  var variantId = match[0];
  var match$1 = React.useState(function () {
        return initialTastingNote;
      });
  var setTastingNote = match$1[1];
  var tastingNote = match$1[0];
  var debouncedTastingNote = ReactUpdateDebounced$Pos.use(tastingNote, ApolloConfig$Pos.mutationDebounceInterval);
  var match$2 = React.useState(function () {
        return initialInternalNote;
      });
  var setInternalNote = match$2[1];
  var internalNote = match$2[0];
  var debouncedInternalNote = ReactUpdateDebounced$Pos.use(internalNote, ApolloConfig$Pos.mutationDebounceInterval);
  var match$3 = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutateTastingNote = match$3[0];
  var match$4 = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutateInternalNote = match$4[0];
  var match$5 = React.useState(function () {
        return /* NoError */0;
      });
  var setError = match$5[1];
  var error = match$5[0];
  var onMutateTastingNoteRequested = React.useCallback((function (tastingNote) {
          var input = makeInputObjectInputUpdateVariant(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Scalar$Pos.$$Text.serialize(tastingNote)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
          Future.get(FuturePromise.fromPromise(Curry._8(mutateTastingNote, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                        id: Belt_Option.getWithDefault(variantId, ""),
                        input: input
                      })), (function (response) {
                  if (response.TAG === /* Ok */0) {
                    var match = response._0;
                    if (match.TAG === /* Ok */0 && match._0.error === undefined) {
                      return Curry._1(setError, (function (param) {
                                    return /* NoError */0;
                                  }));
                    }
                    
                  }
                  Curry._1(setTastingNote, (function (param) {
                          return initialTastingNote;
                        }));
                  Curry._1(setError, (function (param) {
                          return {
                                  TAG: /* TastingNoteUpdatingError */0,
                                  _0: Intl$Pos.t("Couldn't update the tasting note.")
                                };
                        }));
                }));
        }), [variantId]);
  var onMutateInternalNoteRequested = React.useCallback((function (internalNote) {
          var input = makeInputObjectInputUpdateVariant$1(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Scalar$Pos.$$Text.serialize(internalNote)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
          Future.get(FuturePromise.fromPromise(Curry._8(mutateInternalNote, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                        id: Belt_Option.getWithDefault(variantId, ""),
                        input: input
                      })), (function (response) {
                  if (response.TAG === /* Ok */0) {
                    var match = response._0;
                    if (match.TAG === /* Ok */0 && match._0.error === undefined) {
                      return Curry._1(setError, (function (param) {
                                    return /* NoError */0;
                                  }));
                    }
                    
                  }
                  Curry._1(setInternalNote, (function (param) {
                          return initialInternalNote;
                        }));
                  Curry._1(setError, (function (param) {
                          return {
                                  TAG: /* InternalNoteUpdatingError */1,
                                  _0: Intl$Pos.t("Couldn't update the internal note.")
                                };
                        }));
                }));
        }), [variantId]);
  ReactUpdateEffect$Pos.use1((function (param) {
          Curry._1(onMutateTastingNoteRequested, debouncedTastingNote);
        }), [debouncedTastingNote]);
  ReactUpdateEffect$Pos.use1((function (param) {
          Curry._1(onMutateInternalNoteRequested, debouncedInternalNote);
        }), [debouncedInternalNote]);
  var match$6 = Auth$Pos.useScope(undefined);
  var tmp;
  var exit = 0;
  if (match$6.TAG === /* Organisation */0 && match$6.activeShop === undefined) {
    tmp = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                      textStatus: {
                        TAG: /* Info */3,
                        _0: Intl$Pos.t("Filter the product page on a single shop to visualize and update its tasting note or internal memo.")
                      },
                      compact: false
                    })), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    var tmp$1;
    tmp$1 = typeof error === "number" || error.TAG !== /* TastingNoteUpdatingError */0 ? undefined : error._0;
    var tmp$2 = {
      label: Intl$Pos.t("Tasting note"),
      value: tastingNote,
      onChange: (function (value) {
          Curry._1(setTastingNote, (function (param) {
                  return value;
                }));
        })
    };
    if (tmp$1 !== undefined) {
      tmp$2.errorMessage = tmp$1;
    }
    var tmp$3;
    tmp$3 = typeof error === "number" || error.TAG === /* TastingNoteUpdatingError */0 ? undefined : error._0;
    var tmp$4 = {
      label: Intl$Pos.t("Internal memo"),
      value: internalNote,
      onChange: (function (value) {
          Curry._1(setInternalNote, (function (param) {
                  return value;
                }));
        })
    };
    if (tmp$3 !== undefined) {
      tmp$4.errorMessage = tmp$3;
    }
    tmp = React.createElement(Stack$Pos.make, {
          children: null,
          space: "xlarge"
        }, React.createElement(InputTextAreaField$Pos.make, tmp$2), React.createElement(InputTextAreaField$Pos.make, tmp$4));
  }
  return React.createElement(Card$Pos.make, {
              children: tmp,
              title: "Notes"
            });
}

var make = React.memo(CatalogVariantNotesCard);

export {
  UpdateVariantTastingNoteMutation ,
  UpdateVariantInternalNoteMutation ,
  make ,
}
/* query Not a pure module */
