// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as DateHelpers$Pos from "../../helpers/DateHelpers.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as AnimatedRender$Pos from "../../primitives/AnimatedRender.bs.js";
import * as CatalogProduct$Pos from "../Catalog/CatalogProduct.bs.js";
import * as ProductReferenceTableCell$Pos from "../Product/ProductReferenceTableCell.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as PromotionEditDiscountedProduct$Pos from "./PromotionEditDiscountedProduct.bs.js";

var Raw = {};

var query = Client.gql(["query variantsDistinctOnCku($search: String, $after: String, $first: Int)  {\nvariantsDistinctOnCku(search: $search, after: $after, first: $first)  {\n__typename  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \ncku  \ncreatedAt  \nformattedName  \nname  \nproduct  {\n__typename  \nid  \nname  \nkind  \ncolor  \nwineType  \nwhiteWineType  \nbeerType  \nproducer  \ndesignation  \nfamily  \nregion  \ncountry  \ntax  {\n__typename  \nvalue  \n}\n\n}\n\nstockKeepingUnit  \ninternalCode  \npriceLookUpCode  \nsupplier  {\n__typename  \ncompanyName  \n}\n\nformattedCategory  \nalcoholVolume  \ncapacityUnit  \nbulk  \npurchasedPrice  \nvariantPrices(first: 50)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nvalueIncludingTax  \nprice  {\n__typename  \nname  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.variantsDistinctOnCku;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var value$4 = value$2.hasNextPage;
  var value$5 = value$1.edges;
  return {
          variantsDistinctOnCku: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              endCursor: !(value$3 == null) ? value$3 : undefined,
              hasNextPage: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.product;
                    var value$3 = value$2.kind;
                    var tmp;
                    switch (value$3) {
                      case "BEER" :
                          tmp = "BEER";
                          break;
                      case "SIMPLE" :
                          tmp = "SIMPLE";
                          break;
                      case "SPIRITUOUS" :
                          tmp = "SPIRITUOUS";
                          break;
                      case "WINE" :
                          tmp = "WINE";
                          break;
                      default:
                        throw {
                              RE_EXN_ID: "Not_found",
                              Error: new Error()
                            };
                    }
                    var value$4 = value$2.color;
                    var tmp$1;
                    if (value$4 == null) {
                      tmp$1 = undefined;
                    } else {
                      var tmp$2;
                      switch (value$4) {
                        case "AMBER" :
                            tmp$2 = "AMBER";
                            break;
                        case "BLACK" :
                            tmp$2 = "BLACK";
                            break;
                        case "BLOND" :
                            tmp$2 = "BLOND";
                            break;
                        case "DARK" :
                            tmp$2 = "DARK";
                            break;
                        case "ORANGE" :
                            tmp$2 = "ORANGE";
                            break;
                        case "RED" :
                            tmp$2 = "RED";
                            break;
                        case "ROSE" :
                            tmp$2 = "ROSE";
                            break;
                        case "WHITE" :
                            tmp$2 = "WHITE";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$1 = tmp$2;
                    }
                    var value$5 = value$2.wineType;
                    var tmp$3;
                    if (value$5 == null) {
                      tmp$3 = undefined;
                    } else {
                      var tmp$4;
                      switch (value$5) {
                        case "EFFERVESCENT" :
                            tmp$4 = "EFFERVESCENT";
                            break;
                        case "STILL" :
                            tmp$4 = "STILL";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$3 = tmp$4;
                    }
                    var value$6 = value$2.whiteWineType;
                    var tmp$5;
                    if (value$6 == null) {
                      tmp$5 = undefined;
                    } else {
                      var tmp$6;
                      switch (value$6) {
                        case "DRY" :
                            tmp$6 = "DRY";
                            break;
                        case "SEMIDRY" :
                            tmp$6 = "SEMIDRY";
                            break;
                        case "SOFT" :
                            tmp$6 = "SOFT";
                            break;
                        case "SWEET" :
                            tmp$6 = "SWEET";
                            break;
                        default:
                          throw {
                                RE_EXN_ID: "Not_found",
                                Error: new Error()
                              };
                      }
                      tmp$5 = tmp$6;
                    }
                    var value$7 = value$2.beerType;
                    var value$8 = value$2.producer;
                    var value$9 = value$2.designation;
                    var value$10 = value$2.family;
                    var value$11 = value$2.region;
                    var value$12 = value$2.country;
                    var value$13 = value$2.tax;
                    var value$14 = value$1.stockKeepingUnit;
                    var value$15 = value$1.internalCode;
                    var value$16 = value$1.priceLookUpCode;
                    var value$17 = value$1.supplier;
                    var value$18 = value$1.formattedCategory;
                    var value$19 = value$1.alcoholVolume;
                    var value$20 = value$1.capacityUnit;
                    var value$21 = value$1.bulk;
                    var value$22 = value$1.purchasedPrice;
                    var value$23 = value$1.variantPrices;
                    var value$24 = value$23.edges;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              cku: Scalar$Pos.CKU.parse(value$1.cku),
                              createdAt: Scalar$Pos.Datetime.parse(value$1.createdAt),
                              formattedName: value$1.formattedName,
                              name: value$1.name,
                              product: {
                                __typename: value$2.__typename,
                                id: value$2.id,
                                name: value$2.name,
                                kind: tmp,
                                color: tmp$1,
                                wineType: tmp$3,
                                whiteWineType: tmp$5,
                                beerType: !(value$7 == null) ? value$7 : undefined,
                                producer: !(value$8 == null) ? value$8 : undefined,
                                designation: !(value$9 == null) ? value$9 : undefined,
                                family: !(value$10 == null) ? value$10 : undefined,
                                region: !(value$11 == null) ? value$11 : undefined,
                                country: !(value$12 == null) ? value$12 : undefined,
                                tax: {
                                  __typename: value$13.__typename,
                                  value: value$13.value
                                }
                              },
                              stockKeepingUnit: !(value$14 == null) ? value$14 : undefined,
                              internalCode: !(value$15 == null) ? value$15 : undefined,
                              priceLookUpCode: !(value$16 == null) ? value$16 : undefined,
                              supplier: !(value$17 == null) ? ({
                                    __typename: value$17.__typename,
                                    companyName: value$17.companyName
                                  }) : undefined,
                              formattedCategory: !(value$18 == null) ? value$18 : undefined,
                              alcoholVolume: !(value$19 == null) ? value$19 : undefined,
                              capacityUnit: !(value$20 == null) ? value$20 : undefined,
                              bulk: !(value$21 == null) ? value$21 : undefined,
                              purchasedPrice: !(value$22 == null) ? value$22 : undefined,
                              variantPrices: {
                                __typename: value$23.__typename,
                                edges: Js_array.map((function (value) {
                                        var value$1 = value.node;
                                        var value$2 = value$1.price;
                                        return {
                                                __typename: value.__typename,
                                                node: {
                                                  __typename: value$1.__typename,
                                                  valueIncludingTax: value$1.valueIncludingTax,
                                                  price: !(value$2 == null) ? ({
                                                        __typename: value$2.__typename,
                                                        name: value$2.name
                                                      }) : undefined
                                                }
                                              };
                                      }), value$24)
                              }
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.variantsDistinctOnCku;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.variantPrices;
          var value$3 = value$2.edges;
          var edges = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.price;
                  var price;
                  if (value$2 !== undefined) {
                    var value$3 = value$2.name;
                    var value$4 = value$2.__typename;
                    price = {
                      __typename: value$4,
                      name: value$3
                    };
                  } else {
                    price = null;
                  }
                  var value$5 = value$1.valueIncludingTax;
                  var value$6 = value$1.__typename;
                  var node = {
                    __typename: value$6,
                    valueIncludingTax: value$5,
                    price: price
                  };
                  var value$7 = value.__typename;
                  return {
                          __typename: value$7,
                          node: node
                        };
                }), value$3);
          var value$4 = value$2.__typename;
          var variantPrices = {
            __typename: value$4,
            edges: edges
          };
          var value$5 = value$1.purchasedPrice;
          var purchasedPrice = value$5 !== undefined ? value$5 : null;
          var value$6 = value$1.bulk;
          var bulk = value$6 !== undefined ? value$6 : null;
          var value$7 = value$1.capacityUnit;
          var capacityUnit = value$7 !== undefined ? value$7 : null;
          var value$8 = value$1.alcoholVolume;
          var alcoholVolume = value$8 !== undefined ? value$8 : null;
          var value$9 = value$1.formattedCategory;
          var formattedCategory = value$9 !== undefined ? value$9 : null;
          var value$10 = value$1.supplier;
          var supplier;
          if (value$10 !== undefined) {
            var value$11 = value$10.companyName;
            var value$12 = value$10.__typename;
            supplier = {
              __typename: value$12,
              companyName: value$11
            };
          } else {
            supplier = null;
          }
          var value$13 = value$1.priceLookUpCode;
          var priceLookUpCode = value$13 !== undefined ? value$13 : null;
          var value$14 = value$1.internalCode;
          var internalCode = value$14 !== undefined ? value$14 : null;
          var value$15 = value$1.stockKeepingUnit;
          var stockKeepingUnit = value$15 !== undefined ? value$15 : null;
          var value$16 = value$1.product;
          var value$17 = value$16.tax;
          var value$18 = value$17.value;
          var value$19 = value$17.__typename;
          var tax = {
            __typename: value$19,
            value: value$18
          };
          var value$20 = value$16.country;
          var country = value$20 !== undefined ? value$20 : null;
          var value$21 = value$16.region;
          var region = value$21 !== undefined ? value$21 : null;
          var value$22 = value$16.family;
          var family = value$22 !== undefined ? value$22 : null;
          var value$23 = value$16.designation;
          var designation = value$23 !== undefined ? value$23 : null;
          var value$24 = value$16.producer;
          var producer = value$24 !== undefined ? value$24 : null;
          var value$25 = value$16.beerType;
          var beerType = value$25 !== undefined ? value$25 : null;
          var value$26 = value$16.whiteWineType;
          var whiteWineType = value$26 !== undefined ? (
              value$26 === "SWEET" ? "SWEET" : (
                  value$26 === "SEMIDRY" ? "SEMIDRY" : (
                      value$26 === "SOFT" ? "SOFT" : "DRY"
                    )
                )
            ) : null;
          var value$27 = value$16.wineType;
          var wineType = value$27 !== undefined ? (
              value$27 === "EFFERVESCENT" ? "EFFERVESCENT" : "STILL"
            ) : null;
          var value$28 = value$16.color;
          var color = value$28 !== undefined ? (
              value$28 === "ORANGE" ? "ORANGE" : (
                  value$28 === "RED" ? "RED" : (
                      value$28 === "AMBER" ? "AMBER" : (
                          value$28 === "DARK" ? "DARK" : (
                              value$28 === "BLACK" ? "BLACK" : (
                                  value$28 === "BLOND" ? "BLOND" : (
                                      value$28 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : null;
          var value$29 = value$16.kind;
          var kind = value$29 === "SPIRITUOUS" ? "SPIRITUOUS" : (
              value$29 === "BEER" ? "BEER" : (
                  value$29 === "WINE" ? "WINE" : "SIMPLE"
                )
            );
          var value$30 = value$16.name;
          var value$31 = value$16.id;
          var value$32 = value$16.__typename;
          var product = {
            __typename: value$32,
            id: value$31,
            name: value$30,
            kind: kind,
            color: color,
            wineType: wineType,
            whiteWineType: whiteWineType,
            beerType: beerType,
            producer: producer,
            designation: designation,
            family: family,
            region: region,
            country: country,
            tax: tax
          };
          var value$33 = value$1.name;
          var value$34 = value$1.formattedName;
          var value$35 = value$1.createdAt;
          var value$36 = Scalar$Pos.Datetime.serialize(value$35);
          var value$37 = value$1.cku;
          var value$38 = Scalar$Pos.CKU.serialize(value$37);
          var value$39 = value$1.id;
          var value$40 = value$1.__typename;
          var node = {
            __typename: value$40,
            id: value$39,
            cku: value$38,
            createdAt: value$36,
            formattedName: value$34,
            name: value$33,
            product: product,
            stockKeepingUnit: stockKeepingUnit,
            internalCode: internalCode,
            priceLookUpCode: priceLookUpCode,
            supplier: supplier,
            formattedCategory: formattedCategory,
            alcoholVolume: alcoholVolume,
            capacityUnit: capacityUnit,
            bulk: bulk,
            purchasedPrice: purchasedPrice,
            variantPrices: variantPrices
          };
          var value$41 = value.__typename;
          return {
                  __typename: value$41,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var hasNextPage = value$4 !== undefined ? value$4 : null;
  var value$5 = value$3.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$3.__typename;
  var pageInfo = {
    __typename: value$6,
    endCursor: endCursor,
    hasNextPage: hasNextPage
  };
  var value$7 = value$1.__typename;
  var variantsDistinctOnCku = {
    __typename: value$7,
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          variantsDistinctOnCku: variantsDistinctOnCku
        };
}

function serializeVariables(inp) {
  var a = inp.search;
  var a$1 = inp.after;
  var a$2 = inp.first;
  return {
          search: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined,
          first: a$2 !== undefined ? a$2 : undefined
        };
}

function makeVariables(search, after, first, param) {
  return {
          search: search,
          after: after,
          first: first
        };
}

function makeDefaultVariables(param) {
  return {
          search: undefined,
          after: undefined,
          first: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function keyExtractor(row) {
  return row.cku;
}

var Row = {
  keyExtractor: keyExtractor
};

function rowsFromEdgesData(data) {
  return Belt_Array.map(data, (function (edge) {
                var match = edge.node.capacityUnit;
                var match$1 = Belt_Option.getWithDefault(edge.node.bulk, false);
                return {
                        id: edge.node.id,
                        cku: edge.node.cku,
                        createdAt: edge.node.createdAt,
                        formattedName: edge.node.formattedName,
                        productKind: edge.node.product.kind,
                        information: {
                          productName: edge.node.product.name,
                          variantName: edge.node.name,
                          sku: edge.node.stockKeepingUnit,
                          plu: Belt_Option.map(edge.node.priceLookUpCode, (function (prim) {
                                  return String(prim);
                                })),
                          internalCode: edge.node.internalCode,
                          color: edge.node.product.color,
                          producerName: edge.node.product.producer,
                          designation: edge.node.product.designation,
                          productFamily: edge.node.product.family,
                          wineType: edge.node.product.wineType,
                          whiteWineType: edge.node.product.whiteWineType,
                          beerType: edge.node.product.beerType,
                          region: edge.node.product.region,
                          country: Belt_Option.getWithDefault(edge.node.product.country, "—"),
                          categoryName: Belt_Option.getWithDefault(edge.node.formattedCategory, "—"),
                          supplierName: Belt_Option.map(edge.node.supplier, (function (supplier) {
                                  return supplier.companyName;
                                })),
                          alcoholVolume: Belt_Option.map(edge.node.alcoholVolume, (function (alcoholVolume) {
                                  return Intl$Pos.decimalFormat(undefined, 1, alcoholVolume) + "°";
                                }))
                        },
                        bulkUnit: match !== undefined && match$1 ? match : undefined,
                        purchasePrice: Belt_Option.getWithDefault(edge.node.purchasedPrice, 0),
                        retailPrices: Belt_Array.keepMap(edge.node.variantPrices.edges, (function (param) {
                                var variantPrice = param.node;
                                var price = variantPrice.price;
                                if (price !== undefined) {
                                  return {
                                          priceName: price.name,
                                          valueIncludingTax: variantPrice.valueIncludingTax
                                        };
                                }
                                
                              }))
                      };
              }));
}

var columns = [{
    key: "reference",
    name: Intl$Pos.t("Product"),
    render: (function (param) {
        var product = param.data;
        var badgeNew = DateHelpers$Pos.diffInHours(new Date(), product.createdAt) <= 24 ? ({
              variation: "information",
              text: Intl$Pos.t("New")
            }) : undefined;
        var tmp = {
          disabled: param.disabled,
          productKind: product.productKind,
          information: product.information
        };
        if (badgeNew !== undefined) {
          tmp.badge = Caml_option.valFromOption(badgeNew);
        }
        if (param.errorMessage !== undefined) {
          tmp.errorMessage = Caml_option.valFromOption(param.errorMessage);
        }
        return React.createElement(ProductReferenceTableCell$Pos.make, tmp);
      })
  }];

var make = React.memo(function (Props) {
      var children = Props.children;
      var opened = Props.opened;
      var selectedPriceName = Props.selectedPriceName;
      var selectedProducts = Props.selectedProducts;
      var onCommit = Props.onCommit;
      var onRequestClose = Props.onRequestClose;
      var renderStartText = function (param) {
        var length = selectedProducts.length;
        var shouldBePrimaryView = length > 0;
        var formattedText = "" + String(length) + " " + Intl$Pos.t(Intl$Pos.isPlural(length) ? "selected products" : "selected product");
        return React.createElement(Inline$Pos.make, {
                    children: React.createElement(TextStyle$Pos.make, {
                          children: formattedText,
                          variation: shouldBePrimaryView ? "primary" : "neutral",
                          weight: shouldBePrimaryView ? "strong" : "regular"
                        })
                  });
      };
      var onCommit$1 = function (param) {
        Curry._1(onCommit, Belt_Array.map(selectedProducts, (function (row) {
                    return PromotionEditDiscountedProduct$Pos.make(row.id, row.cku, row.formattedName, CatalogProduct$Pos.Information.formateDescription(row.productKind, row.information, true, undefined), row.information.sku, row.purchasePrice, row.bulkUnit, Belt_Array.map(row.retailPrices, (function (param) {
                                      return [
                                              param.priceName,
                                              param.valueIncludingTax
                                            ];
                                    })), selectedPriceName);
                  })));
      };
      return React.createElement(Modal$Pos.make, {
                  children: children,
                  opened: opened,
                  title: Intl$Pos.t("Product append"),
                  variation: "secondary",
                  compact: false,
                  backgroundColor: "#fefefe",
                  renderStartText: renderStartText,
                  abortButtonText: Intl$Pos.t("Cancel"),
                  allowCommit: selectedProducts.length !== 0,
                  commitButtonVariation: "primary",
                  commitButtonText: Intl$Pos.t("Add products"),
                  commitButtonCallback: onCommit$1,
                  onRequestClose: onRequestClose
                });
    });

var PickerModal = {
  make: make
};

function makeVariables$1(search, after, param) {
  return {
          search: search,
          after: after,
          first: 20
        };
}

function PromotionEditDiscountedProductPickerModalTable(Props) {
  var opened = Props.opened;
  var selectedPriceName = Props.selectedPriceName;
  var disabledIdsOpt = Props.disabledIds;
  var onCommit = Props.onCommit;
  var onRequestClose = Props.onRequestClose;
  var disabledIds = disabledIdsOpt !== undefined ? disabledIdsOpt : [];
  var match = React.useState(function () {
        return [];
      });
  var setSelectedProducts = match[1];
  var match$1 = React.useState(function () {
        return "";
      });
  var setSearchQuery = match$1[1];
  var searchQuery = match$1[0];
  var query = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* NetworkOnly */3,
        /* CacheFirst */1,
        undefined,
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        makeVariables$1(searchQuery, undefined, undefined)
      ]);
  var asyncResult = ApolloHelpers$Pos.useQueryResultToAsyncResultWithVariablesReloading1(query, searchQuery);
  var data = AsyncResult$Pos.mapOk(asyncResult, (function (data) {
          return rowsFromEdgesData(data.variantsDistinctOnCku.edges);
        }));
  React.useEffect((function () {
          if (!opened && searchQuery !== "") {
            Curry._1(setSearchQuery, (function (param) {
                    return "";
                  }));
          }
          
        }), [opened]);
  var onLoadMore = React.useCallback((function (param) {
          if (typeof asyncResult === "number") {
            return ;
          }
          if (asyncResult.TAG !== /* Done */1) {
            return ;
          }
          var data = asyncResult._0;
          if (data.TAG !== /* Ok */0) {
            return ;
          }
          var data$1 = data._0;
          if (data$1.variantsDistinctOnCku.pageInfo.hasNextPage === true) {
            Curry._5(query.fetchMore, undefined, undefined, makeVariables$1(searchQuery, data$1.variantsDistinctOnCku.pageInfo.endCursor, undefined), (function (prevResult, param) {
                    var fetchMoreResult = param.fetchMoreResult;
                    if (fetchMoreResult === undefined) {
                      return prevResult;
                    }
                    var newVariants = fetchMoreResult.variantsDistinctOnCku;
                    return {
                            variantsDistinctOnCku: {
                              __typename: newVariants.__typename,
                              pageInfo: newVariants.pageInfo,
                              edges: Belt_Array.concat(prevResult.variantsDistinctOnCku.edges, newVariants.edges)
                            }
                          };
                  }), undefined);
            return ;
          }
          
        }), [asyncResult]);
  var onSelectChange = React.useCallback((function (selection) {
          Curry._1(setSelectedProducts, (function (prev) {
                  var rowsData;
                  if (typeof data === "number") {
                    rowsData = data === /* NotAsked */0 ? [] : [];
                  } else if (data.TAG === /* Reloading */0) {
                    var data$1 = data._0;
                    rowsData = data$1.TAG === /* Ok */0 ? data$1._0 : [];
                  } else {
                    var data$2 = data._0;
                    rowsData = data$2.TAG === /* Ok */0 ? data$2._0 : [];
                  }
                  if (selection) {
                    return Belt_Array.keepMap(selection._0, (function (selectedRowCku) {
                                  return Belt_Array.getBy(Belt_Array.concat(rowsData, prev), (function (row) {
                                                return row.cku === selectedRowCku;
                                              }));
                                }));
                  } else {
                    return prev;
                  }
                }));
        }), [
        data,
        disabledIds
      ]);
  return React.createElement(make, {
              children: React.createElement(AnimatedRender$Pos.make, {
                    children: React.createElement("div", {
                          style: {
                            display: "flex",
                            maxHeight: "70vh",
                            minHeight: "70vh"
                          }
                        }, React.createElement(TableView$Pos.make, {
                              data: data,
                              columns: columns,
                              keyExtractor: keyExtractor,
                              disabledRowsKeys: disabledIds,
                              selectionEnabled: true,
                              selectAllEnabled: false,
                              hideReloadingPlaceholder: true,
                              compactRows: true,
                              searchPlaceholder: Intl$Pos.t("Search product"),
                              onSearchQueryChange: (function (value) {
                                  Curry._1(setSearchQuery, (function (param) {
                                          return value;
                                        }));
                                }),
                              onLoadMore: onLoadMore,
                              onSelectChange: onSelectChange
                            })),
                    displayed: opened,
                    animation: "fadePopinTranslation",
                    duration: 300
                  }),
              opened: opened,
              selectedPriceName: selectedPriceName,
              selectedProducts: match[0],
              onCommit: onCommit,
              onRequestClose: onRequestClose
            });
}

var make$1 = React.memo(PromotionEditDiscountedProductPickerModalTable, (function (oldProps, newProps) {
        return oldProps.opened === newProps.opened;
      }));

export {
  Query ,
  Row ,
  rowsFromEdgesData ,
  columns ,
  PickerModal ,
  makeVariables$1 as makeVariables,
  make$1 as make,
}
/* query Not a pure module */
