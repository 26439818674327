// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query OrderQuery($id: ID!)  {\norder(id: $id)  {\n__typename  \nid  \nupdatedAt  \nformattedName  \nformattedStatus  \ncart  \nnote  \ncondition  \nnoteForSupplier  \nsupplier  {\n__typename  \nid  \ncompanyName  \nlocations  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \naddress  \npostalCode  \ncity  \ncountry  \n}\n\n}\n\n}\n\n}\n\nissueDate  \nestimatedReceptionDate  \nreceptionFinishedAt  \nshop  {\n__typename  \nid  \n}\n\ndevice  {\n__typename  \nid  \n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.order;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.formattedStatus;
    var value$3 = value$1.note;
    var value$4 = value$1.condition;
    var value$5 = value$1.noteForSupplier;
    var value$6 = value$1.supplier;
    var tmp$1;
    if (value$6 == null) {
      tmp$1 = undefined;
    } else {
      var value$7 = value$6.locations;
      var value$8 = value$7.edges;
      tmp$1 = {
        __typename: value$6.__typename,
        id: value$6.id,
        companyName: value$6.companyName,
        locations: {
          __typename: value$7.__typename,
          edges: Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.address;
                  var value$3 = value$1.postalCode;
                  var value$4 = value$1.city;
                  var value$5 = value$1.country;
                  return {
                          __typename: value.__typename,
                          node: {
                            __typename: value$1.__typename,
                            address: !(value$2 == null) ? value$2 : undefined,
                            postalCode: !(value$3 == null) ? value$3 : undefined,
                            city: !(value$4 == null) ? value$4 : undefined,
                            country: !(value$5 == null) ? value$5 : undefined
                          }
                        };
                }), value$8)
        }
      };
    }
    var value$9 = value$1.receptionFinishedAt;
    var value$10 = value$1.shop;
    var value$11 = value$1.device;
    tmp = {
      __typename: value$1.__typename,
      id: value$1.id,
      updatedAt: Scalar$Pos.Datetime.parse(value$1.updatedAt),
      formattedName: value$1.formattedName,
      formattedStatus: Js_array.map((function (value) {
              switch (value) {
                case "ACCEPTED" :
                    return "ACCEPTED";
                case "ARCHIVED" :
                    return "ARCHIVED";
                case "DRAFT" :
                    return "DRAFT";
                case "FINALIZED" :
                    return "FINALIZED";
                case "NOT_RECEIVED" :
                    return "NOT_RECEIVED";
                case "PAID" :
                    return "PAID";
                case "RECEIVED" :
                    return "RECEIVED";
                case "RECEIVING" :
                    return "RECEIVING";
                case "REFUSED" :
                    return "REFUSED";
                case "SENT" :
                    return "SENT";
                case "TO_PAY" :
                    return "TO_PAY";
                default:
                  throw {
                        RE_EXN_ID: "Not_found",
                        Error: new Error()
                      };
              }
            }), value$2),
      cart: Scalar$Pos.$$Text.parse(value$1.cart),
      note: !(value$3 == null) ? Scalar$Pos.$$Text.parse(value$3) : undefined,
      condition: !(value$4 == null) ? Scalar$Pos.$$Text.parse(value$4) : undefined,
      noteForSupplier: !(value$5 == null) ? Scalar$Pos.$$Text.parse(value$5) : undefined,
      supplier: tmp$1,
      issueDate: Scalar$Pos.Datetime.parse(value$1.issueDate),
      estimatedReceptionDate: Scalar$Pos.Datetime.parse(value$1.estimatedReceptionDate),
      receptionFinishedAt: !(value$9 == null) ? Caml_option.some(Scalar$Pos.Datetime.parse(value$9)) : undefined,
      shop: {
        __typename: value$10.__typename,
        id: value$10.id
      },
      device: {
        __typename: value$11.__typename,
        id: value$11.id
      }
    };
  }
  return {
          order: tmp
        };
}

function serialize(value) {
  var value$1 = value.order;
  var order;
  if (value$1 !== undefined) {
    var value$2 = value$1.device;
    var value$3 = value$2.id;
    var value$4 = value$2.__typename;
    var device = {
      __typename: value$4,
      id: value$3
    };
    var value$5 = value$1.shop;
    var value$6 = value$5.id;
    var value$7 = value$5.__typename;
    var shop = {
      __typename: value$7,
      id: value$6
    };
    var value$8 = value$1.receptionFinishedAt;
    var receptionFinishedAt = value$8 !== undefined ? Scalar$Pos.Datetime.serialize(Caml_option.valFromOption(value$8)) : null;
    var value$9 = value$1.estimatedReceptionDate;
    var value$10 = Scalar$Pos.Datetime.serialize(value$9);
    var value$11 = value$1.issueDate;
    var value$12 = Scalar$Pos.Datetime.serialize(value$11);
    var value$13 = value$1.supplier;
    var supplier;
    if (value$13 !== undefined) {
      var value$14 = value$13.locations;
      var value$15 = value$14.edges;
      var edges = Js_array.map((function (value) {
              var value$1 = value.node;
              var value$2 = value$1.country;
              var country = value$2 !== undefined ? value$2 : null;
              var value$3 = value$1.city;
              var city = value$3 !== undefined ? value$3 : null;
              var value$4 = value$1.postalCode;
              var postalCode = value$4 !== undefined ? value$4 : null;
              var value$5 = value$1.address;
              var address = value$5 !== undefined ? value$5 : null;
              var value$6 = value$1.__typename;
              var node = {
                __typename: value$6,
                address: address,
                postalCode: postalCode,
                city: city,
                country: country
              };
              var value$7 = value.__typename;
              return {
                      __typename: value$7,
                      node: node
                    };
            }), value$15);
      var value$16 = value$14.__typename;
      var locations = {
        __typename: value$16,
        edges: edges
      };
      var value$17 = value$13.companyName;
      var value$18 = value$13.id;
      var value$19 = value$13.__typename;
      supplier = {
        __typename: value$19,
        id: value$18,
        companyName: value$17,
        locations: locations
      };
    } else {
      supplier = null;
    }
    var value$20 = value$1.noteForSupplier;
    var noteForSupplier = value$20 !== undefined ? Scalar$Pos.$$Text.serialize(value$20) : null;
    var value$21 = value$1.condition;
    var condition = value$21 !== undefined ? Scalar$Pos.$$Text.serialize(value$21) : null;
    var value$22 = value$1.note;
    var note = value$22 !== undefined ? Scalar$Pos.$$Text.serialize(value$22) : null;
    var value$23 = value$1.cart;
    var value$24 = Scalar$Pos.$$Text.serialize(value$23);
    var value$25 = value$1.formattedStatus;
    var formattedStatus = Js_array.map((function (value) {
            if (value === "TO_PAY") {
              return "TO_PAY";
            } else if (value === "DRAFT") {
              return "DRAFT";
            } else if (value === "NOT_RECEIVED") {
              return "NOT_RECEIVED";
            } else if (value === "REFUSED") {
              return "REFUSED";
            } else if (value === "FINALIZED") {
              return "FINALIZED";
            } else if (value === "ACCEPTED") {
              return "ACCEPTED";
            } else if (value === "PAID") {
              return "PAID";
            } else if (value === "RECEIVING") {
              return "RECEIVING";
            } else if (value === "SENT") {
              return "SENT";
            } else if (value === "RECEIVED") {
              return "RECEIVED";
            } else {
              return "ARCHIVED";
            }
          }), value$25);
    var value$26 = value$1.formattedName;
    var value$27 = value$1.updatedAt;
    var value$28 = Scalar$Pos.Datetime.serialize(value$27);
    var value$29 = value$1.id;
    var value$30 = value$1.__typename;
    order = {
      __typename: value$30,
      id: value$29,
      updatedAt: value$28,
      formattedName: value$26,
      formattedStatus: formattedStatus,
      cart: value$24,
      note: note,
      condition: condition,
      noteForSupplier: noteForSupplier,
      supplier: supplier,
      issueDate: value$12,
      estimatedReceptionDate: value$10,
      receptionFinishedAt: receptionFinishedAt,
      shop: shop,
      device: device
    };
  } else {
    order = null;
  }
  return {
          order: order
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id
        };
}

function makeVariables(id, param) {
  return {
          id: id
        };
}

var OrderQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var OrderQuery_refetchQueryDescription = include.refetchQueryDescription;

var OrderQuery_use = include.use;

var OrderQuery_useLazy = include.useLazy;

var OrderQuery_useLazyWithVariables = include.useLazyWithVariables;

var OrderQuery = {
  OrderQuery_inner: OrderQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  refetchQueryDescription: OrderQuery_refetchQueryDescription,
  use: OrderQuery_use,
  useLazy: OrderQuery_useLazy,
  useLazyWithVariables: OrderQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query OrderVariantsStockQuery($id: ID!, $first: Int, $after: String)  {\norder(id: $id)  {\n__typename  \nproducts(first: $first, after: $after)  {\n__typename  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \nvariant  {\n__typename  \nstock  {\n__typename  \nrawQuantity  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.order;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.products;
    var value$3 = value$2.pageInfo;
    var value$4 = value$3.endCursor;
    var value$5 = value$3.hasNextPage;
    var value$6 = value$2.edges;
    tmp = {
      __typename: value$1.__typename,
      products: {
        __typename: value$2.__typename,
        pageInfo: {
          __typename: value$3.__typename,
          endCursor: !(value$4 == null) ? value$4 : undefined,
          hasNextPage: !(value$5 == null) ? value$5 : undefined
        },
        edges: Js_array.map((function (value) {
                var value$1 = value.node;
                var value$2 = value$1.variant;
                var tmp;
                if (value$2 == null) {
                  tmp = undefined;
                } else {
                  var value$3 = value$2.stock;
                  var value$4 = value$3.rawQuantity;
                  tmp = {
                    __typename: value$2.__typename,
                    stock: {
                      __typename: value$3.__typename,
                      rawQuantity: !(value$4 == null) ? value$4 : undefined
                    }
                  };
                }
                return {
                        __typename: value.__typename,
                        node: {
                          __typename: value$1.__typename,
                          id: value$1.id,
                          variant: tmp
                        }
                      };
              }), value$6)
      }
    };
  }
  return {
          order: tmp
        };
}

function serialize$1(value) {
  var value$1 = value.order;
  var order;
  if (value$1 !== undefined) {
    var value$2 = value$1.products;
    var value$3 = value$2.edges;
    var edges = Js_array.map((function (value) {
            var value$1 = value.node;
            var value$2 = value$1.variant;
            var variant;
            if (value$2 !== undefined) {
              var value$3 = value$2.stock;
              var value$4 = value$3.rawQuantity;
              var rawQuantity = value$4 !== undefined ? value$4 : null;
              var value$5 = value$3.__typename;
              var stock = {
                __typename: value$5,
                rawQuantity: rawQuantity
              };
              var value$6 = value$2.__typename;
              variant = {
                __typename: value$6,
                stock: stock
              };
            } else {
              variant = null;
            }
            var value$7 = value$1.id;
            var value$8 = value$1.__typename;
            var node = {
              __typename: value$8,
              id: value$7,
              variant: variant
            };
            var value$9 = value.__typename;
            return {
                    __typename: value$9,
                    node: node
                  };
          }), value$3);
    var value$4 = value$2.pageInfo;
    var value$5 = value$4.hasNextPage;
    var hasNextPage = value$5 !== undefined ? value$5 : null;
    var value$6 = value$4.endCursor;
    var endCursor = value$6 !== undefined ? value$6 : null;
    var value$7 = value$4.__typename;
    var pageInfo = {
      __typename: value$7,
      endCursor: endCursor,
      hasNextPage: hasNextPage
    };
    var value$8 = value$2.__typename;
    var products = {
      __typename: value$8,
      pageInfo: pageInfo,
      edges: edges
    };
    var value$9 = value$1.__typename;
    order = {
      __typename: value$9,
      products: products
    };
  } else {
    order = null;
  }
  return {
          order: order
        };
}

function serializeVariables$1(inp) {
  var a = inp.first;
  var a$1 = inp.after;
  return {
          id: inp.id,
          first: a !== undefined ? a : undefined,
          after: a$1 !== undefined ? a$1 : undefined
        };
}

function makeVariables$1(id, first, after, param) {
  return {
          id: id,
          first: first,
          after: after
        };
}

var OrderVariantsStockQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use = include$1.use;

var OrderVariantsStockQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var OrderVariantsStockQuery_useLazy = include$1.useLazy;

var OrderVariantsStockQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var OrderVariantsStockQuery = {
  OrderVariantsStockQuery_inner: OrderVariantsStockQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  refetchQueryDescription: OrderVariantsStockQuery_refetchQueryDescription,
  use: use,
  useLazy: OrderVariantsStockQuery_useLazy,
  useLazyWithVariables: OrderVariantsStockQuery_useLazyWithVariables
};

function use$1(skip, ready, id) {
  var terminated = React.useRef(false);
  React.useEffect((function () {
          if (ready && skip) {
            terminated.current = true;
          }
          
        }), [
        ready,
        skip
      ]);
  var query = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        terminated.current || ready ? skip : true,
        undefined,
        {
          id: Belt_Option.getWithDefault(id, ""),
          first: 5,
          after: undefined
        }
      ]);
  var match = query.data;
  if (match !== undefined) {
    var order = match.order;
    if (order !== undefined && query.networkStatus === 5) {
      var match$1 = order.products.pageInfo.endCursor;
      var match$2 = order.products.pageInfo.hasNextPage;
      var exit = 0;
      if (match$1 !== undefined) {
        if (match$2 !== undefined) {
          if (match$2) {
            Curry._5(query.fetchMore, undefined, undefined, {
                  id: Belt_Option.getWithDefault(id, ""),
                  first: 5,
                  after: match$1
                }, (function (prevResult, param) {
                    var fetchMoreResult = param.fetchMoreResult;
                    var prevOrder = prevResult.order;
                    if (prevOrder === undefined) {
                      return prevResult;
                    }
                    if (fetchMoreResult === undefined) {
                      return prevResult;
                    }
                    var newOrder = fetchMoreResult.order;
                    if (newOrder === undefined) {
                      return prevResult;
                    }
                    var init = newOrder.products;
                    return {
                            order: {
                              __typename: newOrder.__typename,
                              products: {
                                __typename: init.__typename,
                                pageInfo: init.pageInfo,
                                edges: Belt_Array.concat(prevOrder.products.edges, newOrder.products.edges)
                              }
                            }
                          };
                  }), undefined);
          } else {
            exit = 1;
          }
        }
        
      } else {
        exit = 1;
      }
      if (exit === 1) {
        if (match$2 !== undefined && !match$2) {
          terminated.current = true;
        }
        
      }
      
    }
    
  }
  var match$3 = query.networkStatus;
  var match$4 = terminated.current;
  return {
          called: query.called,
          client: query.client,
          data: query.data,
          previousData: query.previousData,
          error: query.error,
          loading: !terminated.current,
          networkStatus: match$3 !== 0 || !match$4 ? query.networkStatus : /* Ready */5,
          fetchMore: query.fetchMore,
          refetch: query.refetch,
          startPolling: query.startPolling,
          stopPolling: query.stopPolling,
          subscribeToMore: query.subscribeToMore,
          updateQuery: query.updateQuery
        };
}

var OrderVariantsStockQueryHook = {
  use: use$1
};

export {
  OrderQuery ,
  OrderVariantsStockQuery ,
  OrderVariantsStockQueryHook ,
}
/* query Not a pure module */
