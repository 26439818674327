// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Env$Pos from "./core/Env.bs.js";
import * as Nav$Pos from "./resources/navigation/Nav.bs.js";
import * as Auth$Pos from "./bundles/Auth/Auth.bs.js";
import * as Icon$Pos from "./resources/images-and-icons/Icon.bs.js";
import * as Intl$Pos from "./primitives/Intl.bs.js";
import * as Badge$Pos from "./resources/feedback-indicators/Badge.bs.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Inline$Pos from "./resources/layout-and-structure/Inline.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Divider$Pos from "./resources/layout-and-structure/Divider.bs.js";
import * as AppLayout$Pos from "./resources/layout-and-structure/AppLayout.bs.js";
import * as AuthRouter$Pos from "./app/Auth/AuthRouter.bs.js";
import * as AuthRoutes$Pos from "./app/Auth/AuthRoutes.bs.js";
import * as BugTracker$Pos from "./core/BugTracker.bs.js";
import * as HelpCenter$Pos from "./core/HelpCenter.bs.js";
import * as Navigation$Pos from "./primitives/Navigation.bs.js";
import * as AdminRouter$Pos from "./app/Admin/AdminRouter.bs.js";
import * as LoadingPage$Pos from "./app/Loading/LoadingPage.bs.js";
import * as OrderRouter$Pos from "./app/Order/OrderRouter.bs.js";
import * as SalesRouter$Pos from "./app/Sales/SalesRouter.bs.js";
import * as Stylex from "@stylexjs/stylex";
import * as LegacyRouter$Pos from "./core/LegacyRouter.bs.js";
import * as NotFoundPage$Pos from "./app/Error/NotFoundPage.bs.js";
import * as CatalogRouter$Pos from "./app/Catalog/CatalogRouter.bs.js";
import * as LoadingRouter$Pos from "./app/Loading/LoadingRouter.bs.js";
import * as BillingAccount$Pos from "./modules/Billing/BillingAccount.bs.js";
import * as CustomerRouter$Pos from "./app/Customer/CustomerRouter.bs.js";
import * as SessionTracker$Pos from "./core/SessionTracker.bs.js";
import * as SettingsRouter$Pos from "./app/Settings/SettingsRouter.bs.js";
import * as SettingsRoutes$Pos from "./app/Settings/SettingsRoutes.bs.js";
import * as SupplierRouter$Pos from "./app/Supplier/SupplierRouter.bs.js";
import * as SupplierRoutes$Pos from "./app/Supplier/SupplierRoutes.bs.js";
import * as AnalyticsRouter$Pos from "./app/Analytics/AnalyticsRouter.bs.js";
import * as MaintenancePage$Pos from "./app/Error/MaintenancePage.bs.js";
import * as PromotionRouter$Pos from "./app/Promotion/PromotionRouter.bs.js";
import * as AccountingRouter$Pos from "./app/Accounting/AccountingRouter.bs.js";
import * as StockActivityRouter$Pos from "./app/StockActivity/StockActivityRouter.bs.js";
import * as StockTransferRouter$Pos from "./app/StockTransfer/StockTransferRouter.bs.js";

var styles = Stylex.create({
      container: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        padding: "12px",
        paddingRight: "24px"
      },
      content: {
        alignItems: "flex-end",
        display: "flex",
        flex: "1",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        paddingLeft: "12px"
      },
      title: {
        fontSize: "15px",
        fontWeight: 900,
        marginRight: "6px"
      },
      subtitle: {
        fontSize: "14px",
        fontWeight: 400,
        marginRight: "12px"
      },
      textLink: {
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 500,
        ":hover": {
          textDecorationLine: "underline"
        }
      }
    });

function containerStyle(variation) {
  return Stylex.props(styles.container, variation === "danger" ? ({
                  color: "#ffffffFF",
                  backgroundColor: "#e61e5a"
                }) : ({
                  color: "#ab4319",
                  backgroundColor: "#ffe1Ca"
                }));
}

var contentStyle = Stylex.props(styles.content);

var titleStyle = Stylex.props(styles.title);

var subtitleStyle = Stylex.props(styles.subtitle);

function textLinkStyle(variation) {
  return Stylex.props(styles.textLink, variation === "danger" ? ({
                  color: "#fefefe"
                }) : ({
                  color: "#ab4319"
                }));
}

function App$AlertBar(Props) {
  var variation = Props.variation;
  var title = Props.title;
  var subtitle = Props.subtitle;
  var link = Props.link;
  var textLink = Props.textLink;
  var onPress = Props.onPress;
  var match = containerStyle(variation);
  var match$1 = textLinkStyle(variation);
  var tmp = {};
  if (match.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match.className);
  }
  if (match.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match.style);
  }
  var tmp$1 = {};
  if (contentStyle.className !== undefined) {
    tmp$1.className = Caml_option.valFromOption(contentStyle.className);
  }
  if (contentStyle.style !== undefined) {
    tmp$1.style = Caml_option.valFromOption(contentStyle.style);
  }
  var tmp$2 = {};
  if (titleStyle.className !== undefined) {
    tmp$2.className = Caml_option.valFromOption(titleStyle.className);
  }
  if (titleStyle.style !== undefined) {
    tmp$2.style = Caml_option.valFromOption(titleStyle.style);
  }
  var tmp$3 = {};
  if (subtitleStyle.className !== undefined) {
    tmp$3.className = Caml_option.valFromOption(subtitleStyle.className);
  }
  if (subtitleStyle.style !== undefined) {
    tmp$3.style = Caml_option.valFromOption(subtitleStyle.style);
  }
  var tmp$4 = {};
  if (match$1.className !== undefined) {
    tmp$4.className = Caml_option.valFromOption(match$1.className);
  }
  if (match$1.style !== undefined) {
    tmp$4.style = Caml_option.valFromOption(match$1.style);
  }
  var tmp$5 = {
    children: React.createElement("span", tmp$4, textLink),
    to: link
  };
  if (onPress !== undefined) {
    tmp$5.onPress = Caml_option.valFromOption(onPress);
  }
  return React.createElement("div", tmp, React.createElement(Icon$Pos.make, {
                  name: variation === "danger" ? "danger" : "warning",
                  fill: variation === "danger" ? "#ffffffFF" : "#ab4319"
                }), React.createElement("div", tmp$1, React.createElement("span", undefined, React.createElement("span", tmp$2, title + Intl$Pos.t(":")), React.createElement("span", tmp$3, subtitle)), React.createElement(Inline$Pos.make, {
                      children: null,
                      space: "small"
                    }, React.createElement(Navigation$Pos.Link.make, tmp$5), React.createElement("span", undefined, "➔"))));
}

var make = React.memo(App$AlertBar);

var AlertBar = {
  styles: styles,
  containerStyle: containerStyle,
  contentStyle: contentStyle,
  titleStyle: titleStyle,
  subtitleStyle: subtitleStyle,
  textLinkStyle: textLinkStyle,
  make: make
};

function App$NavBar(Props) {
  var userOrganizationName = Props.userOrganizationName;
  var userProfilePictureUri = Props.userProfilePictureUri;
  var userName = Props.userName;
  var userCanUseImpersonation = Props.userCanUseImpersonation;
  var userImpersonating = Props.userImpersonating;
  var activeBaseRoute = Props.activeBaseRoute;
  var activeRoute = Props.activeRoute;
  var navigate = Navigation$Pos.useNavigate(undefined);
  var scope = Auth$Pos.useScope(undefined);
  var testMode = Env$Pos.context(undefined) !== "production";
  var badge;
  if (!testMode && userCanUseImpersonation) {
    badge = Caml_option.some(React.createElement(Badge$Pos.make, {
              children: Intl$Pos.t("Support"),
              variation: "primary"
            }));
  } else if (!testMode && userImpersonating) {
    badge = Caml_option.some(React.createElement(Badge$Pos.make, {
              children: "⚠️ " + Intl$Pos.t("Impersonation"),
              variation: "important"
            }));
  } else if (testMode && userCanUseImpersonation) {
    badge = Caml_option.some(React.createElement(Badge$Pos.make, {
              children: Intl$Pos.t("Support") + " — Test mode",
              variation: "primary"
            }));
  } else if (testMode && userImpersonating) {
    var stringLiteral = "⚠️ " + Intl$Pos.t("Impersonation — Test mode");
    badge = Caml_option.some(React.createElement(Badge$Pos.make, {
              children: stringLiteral,
              variation: "important"
            }));
  } else {
    badge = testMode ? Caml_option.some(React.createElement(Badge$Pos.make, {
                children: "Test mode"
              })) : undefined;
  }
  var onRequestLogout = function (param) {
    if (userImpersonating) {
      return Navigation$Pos.closeBrowserTab(undefined);
    } else {
      return Curry._3(navigate, undefined, undefined, AuthRoutes$Pos.logoutRoute);
    }
  };
  var tmp = {
    children: null,
    userOrganizationName: userOrganizationName,
    userName: userName,
    userImpersonating: userImpersonating,
    userSettingsRoute: SettingsRoutes$Pos.userRoute,
    helpCenterUrl: new URL("https://help.wino.fr"),
    legacyDashboardUrl: new URL("https://dashboard.wino.fr"),
    legacyDashboardText: "dashboard.wino.fr",
    onToggleHelpCenter: HelpCenter$Pos.showMessages,
    onRequestLogout: onRequestLogout
  };
  if (userProfilePictureUri !== undefined) {
    tmp.userProfilePictureUri = userProfilePictureUri;
  }
  if (badge !== undefined) {
    tmp.badge = Caml_option.valFromOption(badge);
  }
  var tmp$1;
  tmp$1 = scope.TAG === /* Organisation */0 ? React.createElement(Nav$Pos.Link.make, {
          to: {
            TAG: /* Route */0,
            _0: AnalyticsRouter$Pos.shopsRoute
          },
          label: Intl$Pos.t("Performances per shop"),
          active: activeRoute === AnalyticsRouter$Pos.shopsRoute
        }) : null;
  var tmp$2;
  tmp$2 = scope.TAG === /* Organisation */0 ? React.createElement(Nav$Pos.Link.make, {
          to: {
            TAG: /* Route */0,
            _0: StockTransferRouter$Pos.baseRoute
          },
          label: Intl$Pos.t("Stock transfers"),
          active: activeBaseRoute === StockTransferRouter$Pos.baseRoute
        }) : null;
  var tmp$3;
  var exit = 0;
  if (scope.TAG === /* Organisation */0 || scope._0.kind !== "WAREHOUSE") {
    exit = 1;
  } else {
    tmp$3 = null;
  }
  if (exit === 1) {
    tmp$3 = React.createElement(Nav$Pos.Section.make, {
          children: null,
          title: Intl$Pos.t("Sales"),
          active: activeBaseRoute === LegacyRouter$Pos.routeToPathname(/* Promotion */3),
          icon: "sales_bold"
        }, React.createElement(Nav$Pos.Link.make, {
              to: {
                TAG: /* Route */0,
                _0: SalesRouter$Pos.baseRoute
              },
              label: Intl$Pos.t("Receipts & Invoices"),
              active: activeBaseRoute === SalesRouter$Pos.baseRoute
            }), React.createElement(Nav$Pos.Link.make, {
              to: {
                TAG: /* Route */0,
                _0: AccountingRouter$Pos.baseRoute
              },
              label: Intl$Pos.t("Accounting entries"),
              active: activeBaseRoute === AccountingRouter$Pos.baseRoute,
              badgeNew: true
            }), React.createElement(Nav$Pos.Link.make, {
              to: {
                TAG: /* Route */0,
                _0: LegacyRouter$Pos.routeToPathname(/* Promotion */3)
              },
              label: Intl$Pos.t("Promotional campaigns"),
              active: activeBaseRoute === LegacyRouter$Pos.routeToPathname(/* Promotion */3)
            }));
  }
  var tmp$4;
  tmp$4 = scope.TAG === /* Organisation */0 ? Intl$Pos.t("Shops") : Intl$Pos.t("Shop");
  return React.createElement(Nav$Pos.make, tmp, React.createElement(Nav$Pos.Section.make, {
                  children: null,
                  title: Intl$Pos.t("Activity reports"),
                  active: activeBaseRoute === AnalyticsRouter$Pos.baseRoute,
                  icon: "analytics_bold"
                }, React.createElement(Nav$Pos.Link.make, {
                      to: {
                        TAG: /* Route */0,
                        _0: AnalyticsRouter$Pos.overviewRoute
                      },
                      label: Intl$Pos.t("General performances"),
                      active: activeRoute === AnalyticsRouter$Pos.overviewRoute
                    }), tmp$1, React.createElement(Nav$Pos.Link.make, {
                      to: {
                        TAG: /* Route */0,
                        _0: AnalyticsRouter$Pos.topPerformingProductsRoute
                      },
                      label: Intl$Pos.t("Top sold products"),
                      active: activeRoute === AnalyticsRouter$Pos.topPerformingProductsRoute
                    }), React.createElement(Nav$Pos.Link.make, {
                      to: {
                        TAG: /* Route */0,
                        _0: AnalyticsRouter$Pos.cashFlowRoute
                      },
                      label: Intl$Pos.t("Cash flow"),
                      active: activeRoute === AnalyticsRouter$Pos.cashFlowRoute,
                      badgeNew: true
                    })), React.createElement(Nav$Pos.Section.make, {
                  children: null,
                  title: Intl$Pos.t("Products"),
                  active: activeBaseRoute === LegacyRouter$Pos.routeToPathname(/* Catalog */2) || activeBaseRoute === LegacyRouter$Pos.routeToPathname(/* Order */0) || activeBaseRoute === StockActivityRouter$Pos.baseRoute || activeBaseRoute === StockTransferRouter$Pos.baseRoute,
                  icon: "products_bold"
                }, React.createElement(Nav$Pos.Link.make, {
                      to: {
                        TAG: /* Route */0,
                        _0: LegacyRouter$Pos.routeToPathname(/* Catalog */2)
                      },
                      label: Intl$Pos.t("Catalog"),
                      active: activeBaseRoute === LegacyRouter$Pos.routeToPathname(/* Catalog */2)
                    }), React.createElement(Nav$Pos.Link.make, {
                      to: {
                        TAG: /* Route */0,
                        _0: LegacyRouter$Pos.routeToPathname(/* Order */0)
                      },
                      label: Intl$Pos.t("Orders"),
                      active: activeBaseRoute === LegacyRouter$Pos.routeToPathname(/* Order */0)
                    }), React.createElement(Nav$Pos.Link.make, {
                      to: {
                        TAG: /* Route */0,
                        _0: StockActivityRouter$Pos.baseRoute
                      },
                      label: Intl$Pos.t("Stock activities"),
                      active: activeBaseRoute === StockActivityRouter$Pos.baseRoute
                    }), tmp$2), tmp$3, React.createElement(Nav$Pos.Section.make, {
                  children: null,
                  title: Intl$Pos.t("Contacts"),
                  active: activeBaseRoute === SupplierRoutes$Pos.baseRoute,
                  icon: "contacts_bold"
                }, React.createElement(Nav$Pos.Link.make, {
                      to: {
                        TAG: /* Route */0,
                        _0: SupplierRoutes$Pos.baseRoute
                      },
                      label: Intl$Pos.t("Suppliers"),
                      active: activeBaseRoute === SupplierRoutes$Pos.baseRoute
                    }), React.createElement(Nav$Pos.Link.make, {
                      to: {
                        TAG: /* Route */0,
                        _0: CustomerRouter$Pos.baseRoute
                      },
                      label: Intl$Pos.t("Customers"),
                      active: activeBaseRoute === CustomerRouter$Pos.baseRoute
                    })), React.createElement(Nav$Pos.Section.make, {
                  children: null,
                  title: Intl$Pos.t("Settings"),
                  active: activeBaseRoute === SettingsRoutes$Pos.baseRoute,
                  icon: "settings_bold"
                }, React.createElement(Nav$Pos.Link.make, {
                      to: {
                        TAG: /* Route */0,
                        _0: SettingsRoutes$Pos.shopsRoute
                      },
                      label: tmp$4,
                      active: activeRoute === SettingsRoutes$Pos.shopsRoute
                    }), React.createElement(Nav$Pos.Link.make, {
                      to: {
                        TAG: /* Route */0,
                        _0: SettingsRoutes$Pos.labelPrinterRoute
                      },
                      label: Intl$Pos.t("Label printer"),
                      active: activeRoute === SettingsRoutes$Pos.labelPrinterRoute
                    }), React.createElement(Nav$Pos.Link.make, {
                      to: {
                        TAG: /* Route */0,
                        _0: SettingsRoutes$Pos.billingAccountShowRoute(undefined, undefined)
                      },
                      label: Intl$Pos.t("Subscription and billing"),
                      active: activeRoute === SettingsRoutes$Pos.billingAccountShowRoute(undefined, undefined)
                    })), userCanUseImpersonation ? React.createElement(React.Fragment, undefined, React.createElement(Divider$Pos.make, {
                        spaceY: "medium"
                      }), React.createElement(Nav$Pos.Section.make, {
                        children: null,
                        title: Intl$Pos.t("Customer support"),
                        active: activeBaseRoute === AdminRouter$Pos.baseRoute,
                        icon: "customer_support"
                      }, React.createElement(Nav$Pos.Link.make, {
                            to: {
                              TAG: /* Route */0,
                              _0: AdminRouter$Pos.impersonationRoute
                            },
                            label: Intl$Pos.t("Impersonation"),
                            active: activeRoute === AdminRouter$Pos.impersonationRoute
                          }), React.createElement(Nav$Pos.Link.make, {
                            to: {
                              TAG: /* Route */0,
                              _0: AdminRouter$Pos.shopsRoute
                            },
                            label: Intl$Pos.t("Shops"),
                            active: activeRoute === AdminRouter$Pos.shopsRoute
                          }))) : null);
}

var NavBar = {
  make: App$NavBar
};

function App(Props) {
  var navigate = Navigation$Pos.useNavigate(undefined);
  var url = Navigation$Pos.useUrl(undefined);
  var urlPath = Belt_List.fromArray(url.path);
  var route = Navigation$Pos.urlToRoute(url);
  var auth = Auth$Pos.useState(undefined);
  var captureEvent = SessionTracker$Pos.useCaptureEvent(undefined);
  var match = React.useState(function () {
        return route;
      });
  var setRouteAfterSessionExpiration = match[1];
  var routeAfterSessionExpiration = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setBillingIssues = match$1[1];
  var billingIssues = match$1[0];
  React.useEffect((function () {
          HelpCenter$Pos.setup(Env$Pos.intercomAppID(undefined));
        }), []);
  var requestBillingStatuses = function (param) {
    return Future.map(Future.tapOk(BillingAccount$Pos.StatusesRequest.make(undefined), (function (statuses) {
                      Curry._1(setBillingIssues, (function (param) {
                              return statuses;
                            }));
                    })), undefined, (function (result) {
                  if (result.TAG === /* Ok */0) {
                    return {
                            TAG: /* Ok */0,
                            _0: undefined
                          };
                  } else {
                    return {
                            TAG: /* Error */1,
                            _0: undefined
                          };
                  }
                }));
  };
  var reloadAlertBar = function (param) {
    return requestBillingStatuses(undefined);
  };
  React.useEffect((function () {
          if (typeof auth === "number" || auth.TAG === /* Logging */0) {
            HelpCenter$Pos.updateUserFields(Intl$Pos.t("Guest"), undefined, undefined, undefined);
          } else {
            var match = auth._0;
            var match$1 = match.user;
            var email = match$1.username;
            var userId = match$1.id;
            if (match$1.impersonating) {
              HelpCenter$Pos.updateUserFields(Intl$Pos.t("Imposter"), undefined, undefined, undefined);
            } else {
              var match$2 = match.activeShop;
              var exit = 0;
              if (match$2 !== undefined) {
                var name = match$2.legalRepresentative;
                if (name !== undefined) {
                  HelpCenter$Pos.updateUserFields(name, userId, email, undefined);
                } else {
                  exit = 1;
                }
              } else {
                exit = 1;
              }
              if (exit === 1) {
                HelpCenter$Pos.updateUserFields(match$1.name, userId, email, undefined);
              }
              
            }
          }
          if (typeof auth !== "number" && auth.TAG !== /* Logging */0) {
            var match$3 = auth._0;
            var match$4 = match$3.activeShop;
            var match$5 = match$3.user;
            var userName = match$5.name;
            var userId$1 = match$5.id;
            if (match$4 !== undefined) {
              BugTracker$Pos.updateUserFields(userId$1, userName, match$4.id, match$4.name, match$4.activeWebDeviceId, undefined);
            } else {
              BugTracker$Pos.updateUserFields(userId$1, userName, undefined, undefined, undefined, undefined);
            }
          }
          if (typeof auth !== "number" && auth.TAG !== /* Logging */0) {
            var match$6 = auth._0.user;
            if (match$6.impersonating) {
              
            } else {
              SessionTracker$Pos.updateUserFields(match$6.id, match$6.name, match$6.username);
              SessionTracker$Pos.onSession(BugTracker$Pos.updateSession);
            }
          }
          if (typeof auth !== "number" && auth.TAG !== /* Logging */0) {
            requestBillingStatuses(undefined);
          }
          
        }), [auth]);
  React.useEffect((function () {
          if (typeof auth === "number") {
            if (route === AuthRoutes$Pos.logoutRoute || !route.startsWith(AuthRoutes$Pos.baseRoute)) {
              Curry._3(navigate, undefined, undefined, AuthRoutes$Pos.loginRoute);
            } else if (route === AuthRoutes$Pos.logoutSessionExpiredRoute) {
              Curry._3(navigate, undefined, undefined, AuthRoutes$Pos.loginSessionExpiredRoute);
            } else if (route === AuthRoutes$Pos.logoutImpersonationFailureRoute) {
              Curry._3(navigate, undefined, undefined, AuthRoutes$Pos.loginImpersonationFailureRoute);
            }
            
          } else if (auth.TAG !== /* Logging */0) {
            if (route === "/" || route.startsWith(AuthRoutes$Pos.loginRoute)) {
              if (!routeAfterSessionExpiration.startsWith(AuthRoutes$Pos.baseRoute) && route === AuthRoutes$Pos.loginSessionExpiredRoute) {
                Curry._3(navigate, undefined, undefined, routeAfterSessionExpiration);
              } else {
                Curry._3(navigate, undefined, undefined, AnalyticsRouter$Pos.overviewRoute);
              }
            }
            
          }
          
        }), [
        route,
        auth
      ]);
  React.useEffect((function () {
          if (!route.startsWith(AuthRoutes$Pos.baseRoute)) {
            Curry._1(setRouteAfterSessionExpiration, (function (param) {
                    return route;
                  }));
          }
          
        }), [route]);
  if (urlPath) {
    var subUrlPath = urlPath.tl;
    var basePathnam = urlPath.hd;
    if (basePathnam === AuthRoutes$Pos.basePathname) {
      return React.createElement(AuthRouter$Pos.make, {
                  subUrlPath: subUrlPath,
                  appIndexRoute: AnalyticsRouter$Pos.overviewRoute
                });
    }
    if (typeof auth !== "number" && auth.TAG !== /* Logging */0) {
      var user = auth._0.user;
      var baseRoute = "/" + basePathnam;
      var tmp = {
        userOrganizationName: user.organizationName,
        userName: user.name,
        userCanUseImpersonation: user.canUseImpersonation,
        userImpersonating: user.impersonating,
        activeBaseRoute: baseRoute,
        activeRoute: route
      };
      if (user.profilePictureUri !== undefined) {
        tmp.userProfilePictureUri = Caml_option.valFromOption(user.profilePictureUri);
      }
      var navBar = React.createElement(App$NavBar, tmp);
      var alertBar;
      if (billingIssues !== undefined) {
        var match$2 = BillingAccount$Pos.BillingIssue.mostImportantShopIssue(billingIssues);
        if (match$2 !== undefined) {
          var shopId = match$2.shopId;
          switch (match$2.issue) {
            case /* MissingPaymentMethod */0 :
                alertBar = React.createElement(make, {
                      variation: "danger",
                      title: Intl$Pos.t("No valid payment method is associated with your account"),
                      subtitle: Intl$Pos.t("please add or update your payment information to avoid any service interruption."),
                      link: {
                        TAG: /* Route */0,
                        _0: SettingsRoutes$Pos.billingAccountShowRouteWithModal(shopId, /* EditPaymentMethod */0)
                      },
                      textLink: Intl$Pos.t("Add a payment method"),
                      onPress: (function (param) {
                          Curry._1(captureEvent, "alert_bar_click_add_payment_method");
                        })
                    });
                break;
            case /* InvalidBillingMandate */1 :
                alertBar = React.createElement(make, {
                      variation: "warning",
                      title: Intl$Pos.t("Your confirmation of SEPA direct debit authorization is required"),
                      subtitle: Intl$Pos.t("go to the 'Subscription and Billing' page to complete this step."),
                      link: {
                        TAG: /* Route */0,
                        _0: SettingsRoutes$Pos.billingAccountShowRouteWithModal(shopId, /* ConfirmMandate */1)
                      },
                      textLink: Intl$Pos.t("Confirm SEPA mandate"),
                      onPress: (function (param) {
                          Curry._1(captureEvent, "alert_bar_click_validate_mandate");
                        })
                    });
                break;
            case /* InvoicePaymentOverdue */2 :
                alertBar = React.createElement(make, {
                      variation: "danger",
                      title: Intl$Pos.t("One or more of your invoices are overdue"),
                      subtitle: Intl$Pos.t("please settle your account as soon as possible to avoid suspension of your account"),
                      link: {
                        TAG: /* Route */0,
                        _0: SettingsRoutes$Pos.billingAccountShowRoute(shopId, undefined)
                      },
                      textLink: Intl$Pos.t("Pay overdue invoice"),
                      onPress: (function (param) {
                          Curry._1(captureEvent, "alert_bar_click_payment_overdue");
                        })
                    });
                break;
            
          }
        } else {
          alertBar = null;
        }
      } else {
        alertBar = null;
      }
      return React.createElement(AppLayout$Pos.make, {
                  children: baseRoute === AnalyticsRouter$Pos.baseRoute ? React.createElement(AnalyticsRouter$Pos.make, {
                          subUrlPath: subUrlPath
                        }) : (
                      baseRoute === StockActivityRouter$Pos.baseRoute ? React.createElement(StockActivityRouter$Pos.make, {
                              subUrlPath: subUrlPath
                            }) : (
                          baseRoute === LoadingRouter$Pos.baseRoute(undefined, undefined) ? React.createElement(LoadingRouter$Pos.make, {
                                  subUrlPath: subUrlPath
                                }) : (
                              baseRoute === StockTransferRouter$Pos.baseRoute ? React.createElement(StockTransferRouter$Pos.make, {
                                      subUrlPath: subUrlPath
                                    }) : (
                                  baseRoute === SettingsRoutes$Pos.baseRoute ? React.createElement(SettingsRouter$Pos.make, {
                                          subUrlPath: subUrlPath,
                                          appIndexRoute: AnalyticsRouter$Pos.overviewRoute,
                                          reloadAlertBar: reloadAlertBar
                                        }) : (
                                      baseRoute === AdminRouter$Pos.baseRoute ? React.createElement(AdminRouter$Pos.make, {
                                              subUrlPath: subUrlPath,
                                              appIndexRoute: AnalyticsRouter$Pos.overviewRoute
                                            }) : (
                                          baseRoute === SupplierRoutes$Pos.baseRoute ? React.createElement(SupplierRouter$Pos.make, {
                                                  subUrlPath: subUrlPath
                                                }) : (
                                              baseRoute === AccountingRouter$Pos.baseRoute ? React.createElement(AccountingRouter$Pos.make, {
                                                      subUrlPath: subUrlPath
                                                    }) : (
                                                  baseRoute === CustomerRouter$Pos.baseRoute ? React.createElement(CustomerRouter$Pos.make, {
                                                          subUrlPath: subUrlPath
                                                        }) : (
                                                      baseRoute === SalesRouter$Pos.baseRoute ? React.createElement(SalesRouter$Pos.make, {
                                                              subUrlPath: subUrlPath
                                                            }) : (
                                                          baseRoute === LegacyRouter$Pos.routeToPathname(/* Order */0) ? React.createElement(OrderRouter$Pos.make, {}) : (
                                                              baseRoute === LegacyRouter$Pos.routeToPathname(/* Promotion */3) ? React.createElement(PromotionRouter$Pos.make, {}) : (
                                                                  baseRoute === LegacyRouter$Pos.routeToPathname(/* Catalog */2) ? React.createElement(CatalogRouter$Pos.make, {}) : React.createElement(NotFoundPage$Pos.make, {})
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    ),
                  navBar: navBar,
                  alertBar: alertBar
                });
    }
    
  } else if (typeof auth !== "number") {
    auth.TAG === /* Logging */0;
  }
  return React.createElement(LoadingPage$Pos.make, {});
}

var maintenance = false;

var appIndexRoute = AnalyticsRouter$Pos.overviewRoute;

var make$1 = App;

export {
  maintenance ,
  AlertBar ,
  NavBar ,
  appIndexRoute ,
  make$1 as make,
}
/* styles Not a pure module */
