// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as ReactUpdateEffect$Pos from "../../primitives/ReactUpdateEffect.bs.js";
import * as CatalogDuplicationForm$Pos from "./CatalogDuplicationForm.bs.js";
import * as ReasonMLCommunity__ApolloClient from "rescript-apollo-client/src/ReasonMLCommunity__ApolloClient.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as CatalogDuplicationSelectionTable$Pos from "./CatalogDuplicationSelectionTable.bs.js";

var CatalogDuplication_ProductIdNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogDuplicationFormSelectionTableCard-Pos.CatalogDuplication_ProductIdNotFound");

var Raw = {};

var query = Client.gql(["query products($filterBy: InputProductsQueryFilter)  {\nproducts(first: 50, filterBy: $filterBy)  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nshop  {\n__typename  \nid  \nname  \n}\n\ncategory  {\n__typename  \nid  \nformattedName  \nname  \nparent  {\n__typename  \nname  \n}\n\n}\n\nvariants(filterBy: {archived: INCLUDED})  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \ncku  \n}\n\n}\n\n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.products;
  var value$2 = value$1.edges;
  return {
          products: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.shop;
                    var value$3 = value$1.category;
                    var tmp;
                    if (value$3 == null) {
                      tmp = undefined;
                    } else {
                      var value$4 = value$3.parent;
                      tmp = {
                        __typename: value$3.__typename,
                        id: value$3.id,
                        formattedName: value$3.formattedName,
                        name: value$3.name,
                        parent: !(value$4 == null) ? ({
                              __typename: value$4.__typename,
                              name: value$4.name
                            }) : undefined
                      };
                    }
                    var value$5 = value$1.variants;
                    var value$6 = value$5.edges;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              shop: {
                                __typename: value$2.__typename,
                                id: value$2.id,
                                name: value$2.name
                              },
                              category: tmp,
                              variants: {
                                __typename: value$5.__typename,
                                edges: Js_array.map((function (value) {
                                        var value$1 = value.node;
                                        return {
                                                __typename: value.__typename,
                                                node: {
                                                  __typename: value$1.__typename,
                                                  cku: Scalar$Pos.CKU.parse(value$1.cku)
                                                }
                                              };
                                      }), value$6)
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize(value) {
  var value$1 = value.products;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.variants;
          var value$3 = value$2.edges;
          var edges = Js_array.map((function (value) {
                  var value$1 = value.node;
                  var value$2 = value$1.cku;
                  var value$3 = Scalar$Pos.CKU.serialize(value$2);
                  var value$4 = value$1.__typename;
                  var node = {
                    __typename: value$4,
                    cku: value$3
                  };
                  var value$5 = value.__typename;
                  return {
                          __typename: value$5,
                          node: node
                        };
                }), value$3);
          var value$4 = value$2.__typename;
          var variants = {
            __typename: value$4,
            edges: edges
          };
          var value$5 = value$1.category;
          var category;
          if (value$5 !== undefined) {
            var value$6 = value$5.parent;
            var parent;
            if (value$6 !== undefined) {
              var value$7 = value$6.name;
              var value$8 = value$6.__typename;
              parent = {
                __typename: value$8,
                name: value$7
              };
            } else {
              parent = null;
            }
            var value$9 = value$5.name;
            var value$10 = value$5.formattedName;
            var value$11 = value$5.id;
            var value$12 = value$5.__typename;
            category = {
              __typename: value$12,
              id: value$11,
              formattedName: value$10,
              name: value$9,
              parent: parent
            };
          } else {
            category = null;
          }
          var value$13 = value$1.shop;
          var value$14 = value$13.name;
          var value$15 = value$13.id;
          var value$16 = value$13.__typename;
          var shop = {
            __typename: value$16,
            id: value$15,
            name: value$14
          };
          var value$17 = value$1.id;
          var value$18 = value$1.__typename;
          var node = {
            __typename: value$18,
            id: value$17,
            shop: shop,
            category: category,
            variants: variants
          };
          var value$19 = value.__typename;
          return {
                  __typename: value$19,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var products = {
    __typename: value$3,
    edges: edges
  };
  return {
          products: products
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectProductKindEqualsFilter(inp) {
  var a = inp._equals;
  return {
          _equals: a === "SPIRITUOUS" ? "SPIRITUOUS" : (
              a === "BEER" ? "BEER" : (
                  a === "WINE" ? "WINE" : "SIMPLE"
                )
            )
        };
}

function serializeInputObjectProductColorEqualsFilter(inp) {
  var a = inp._equals;
  return {
          _equals: a === "ORANGE" ? "ORANGE" : (
              a === "RED" ? "RED" : (
                  a === "AMBER" ? "AMBER" : (
                      a === "DARK" ? "DARK" : (
                          a === "BLACK" ? "BLACK" : (
                              a === "BLOND" ? "BLOND" : (
                                  a === "ROSE" ? "ROSE" : "WHITE"
                                )
                            )
                        )
                    )
                )
            )
        };
}

function serializeInputObjectStringEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectInputProductsQueryFilter(inp) {
  var a = inp.name;
  var a$1 = inp.kind;
  var a$2 = inp.producer;
  var a$3 = inp.color;
  var a$4 = inp.variantCkus;
  var a$5 = inp.shopIds;
  var a$6 = inp.archived;
  return {
          name: a !== undefined ? ({
                _equals: a._equals
              }) : undefined,
          kind: a$1 !== undefined ? serializeInputObjectProductKindEqualsFilter(a$1) : undefined,
          producer: a$2 !== undefined ? ({
                _equals: a$2._equals
              }) : undefined,
          color: a$3 !== undefined ? serializeInputObjectProductColorEqualsFilter(a$3) : undefined,
          variantCkus: a$4 !== undefined ? serializeInputObjectInFilter(a$4) : undefined,
          shopIds: a$5 !== undefined ? serializeInputObjectInFilter(a$5) : undefined,
          archived: a$6 !== undefined ? (
              a$6 === "ONLY" ? "ONLY" : (
                  a$6 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputProductsQueryFilter(a) : undefined
        };
}

function makeVariables(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectInputProductsQueryFilter(name, kind, producer, color, variantCkus, shopIds, archived, param) {
  return {
          name: name,
          kind: kind,
          producer: producer,
          color: color,
          variantCkus: variantCkus,
          shopIds: shopIds,
          archived: archived
        };
}

function makeInputObjectStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectProductKindEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectProductColorEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined
        };
}

var ProductsQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputProductsQueryFilter: serializeInputObjectInputProductsQueryFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectProductKindEqualsFilter: serializeInputObjectProductKindEqualsFilter,
  serializeInputObjectProductColorEqualsFilter: serializeInputObjectProductColorEqualsFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputProductsQueryFilter: makeInputObjectInputProductsQueryFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectProductKindEqualsFilter: makeInputObjectProductKindEqualsFilter,
  makeInputObjectProductColorEqualsFilter: makeInputObjectProductColorEqualsFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var useLazy = include.useLazy;

var ProductsQuery_refetchQueryDescription = include.refetchQueryDescription;

var ProductsQuery_use = include.use;

var ProductsQuery_useLazyWithVariables = include.useLazyWithVariables;

var ProductsQuery = {
  ProductsQuery_inner: ProductsQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputProductsQueryFilter: serializeInputObjectInputProductsQueryFilter,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectProductKindEqualsFilter: serializeInputObjectProductKindEqualsFilter,
  serializeInputObjectProductColorEqualsFilter: serializeInputObjectProductColorEqualsFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputProductsQueryFilter: makeInputObjectInputProductsQueryFilter,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectProductKindEqualsFilter: makeInputObjectProductKindEqualsFilter,
  makeInputObjectProductColorEqualsFilter: makeInputObjectProductColorEqualsFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: ProductsQuery_refetchQueryDescription,
  use: ProductsQuery_use,
  useLazy: useLazy,
  useLazyWithVariables: ProductsQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query CategoriesQuery($filterByShopIds: InFilter, $filterByName: StringEqualsFilter, $filterByParentName: StringEqualsFilter)  {\ncategories(first: 1, filterBy: {archived: EXCLUDED, hasChildren: false, shopIds: $filterByShopIds, name: $filterByName, parentName: $filterByParentName})  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \nformattedName  \nshop  {\n__typename  \nid  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.categories;
  var value$2 = value$1.edges;
  return {
          categories: {
            __typename: value$1.__typename,
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    var value$2 = value$1.shop;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              formattedName: value$1.formattedName,
                              shop: {
                                __typename: value$2.__typename,
                                id: value$2.id
                              }
                            }
                          };
                  }), value$2)
          }
        };
}

function serialize$1(value) {
  var value$1 = value.categories;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.shop;
          var value$3 = value$2.id;
          var value$4 = value$2.__typename;
          var shop = {
            __typename: value$4,
            id: value$3
          };
          var value$5 = value$1.formattedName;
          var value$6 = value$1.id;
          var value$7 = value$1.__typename;
          var node = {
            __typename: value$7,
            id: value$6,
            formattedName: value$5,
            shop: shop
          };
          var value$8 = value.__typename;
          return {
                  __typename: value$8,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.__typename;
  var categories = {
    __typename: value$3,
    edges: edges
  };
  return {
          categories: categories
        };
}

function serializeInputObjectInFilter$1(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectStringEqualsFilter$1(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeVariables$1(inp) {
  var a = inp.filterByShopIds;
  var a$1 = inp.filterByName;
  var a$2 = inp.filterByParentName;
  return {
          filterByShopIds: a !== undefined ? serializeInputObjectInFilter$1(a) : undefined,
          filterByName: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          filterByParentName: a$2 !== undefined ? ({
                _equals: a$2._equals
              }) : undefined
        };
}

function makeVariables$1(filterByShopIds, filterByName, filterByParentName, param) {
  return {
          filterByShopIds: filterByShopIds,
          filterByName: filterByName,
          filterByParentName: filterByParentName
        };
}

function makeInputObjectInFilter$1(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectStringEqualsFilter$1(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeDefaultVariables$1(param) {
  return {
          filterByShopIds: undefined,
          filterByName: undefined,
          filterByParentName: undefined
        };
}

var CategoriesQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInFilter: serializeInputObjectInFilter$1,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter$1,
  makeVariables: makeVariables$1,
  makeInputObjectInFilter: makeInputObjectInFilter$1,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter$1,
  makeDefaultVariables: makeDefaultVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var CategoriesQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var CategoriesQuery_use = include$1.use;

var CategoriesQuery_useLazy = include$1.useLazy;

var CategoriesQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var CategoriesQuery = {
  CategoriesQuery_inner: CategoriesQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInFilter: serializeInputObjectInFilter$1,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter$1,
  makeVariables: makeVariables$1,
  makeInputObjectInFilter: makeInputObjectInFilter$1,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter$1,
  makeDefaultVariables: makeDefaultVariables$1,
  refetchQueryDescription: CategoriesQuery_refetchQueryDescription,
  use: CategoriesQuery_use,
  useLazy: CategoriesQuery_useLazy,
  useLazyWithVariables: CategoriesQuery_useLazyWithVariables
};

function useCategoriesUpdater(onSuccess) {
  var client = ReasonMLCommunity__ApolloClient.React.useApolloClient(undefined);
  var match = React.useState(function () {
        return /* Idle */0;
      });
  var setStatus = match[1];
  var executeAll = React.useCallback((function (requestsVariables) {
          Curry._1(setStatus, (function (param) {
                  return /* Loading */1;
                }));
          Future.get(Future.all(Belt_Array.map(requestsVariables, (function (variables) {
                          return Future.map(FuturePromise.fromPromise(Curry._6(client.rescript_query, {
                                              query: query$1,
                                              Raw: Raw$1,
                                              parse: parse$1,
                                              serialize: serialize$1,
                                              serializeVariables: serializeVariables$1
                                            }, undefined, undefined, /* NetworkOnly */2, undefined, variables)), undefined, (function (response) {
                                        if (response.TAG !== /* Ok */0) {
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: Request$Pos.serverErrorMessage
                                                };
                                        }
                                        var match = response._0;
                                        if (match.TAG !== /* Ok */0) {
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: Request$Pos.serverErrorMessage
                                                };
                                        }
                                        var match$1 = match._0;
                                        if (match$1.error !== undefined) {
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: Request$Pos.serverErrorMessage
                                                };
                                        } else {
                                          return {
                                                  TAG: /* Ok */0,
                                                  _0: match$1.data.categories
                                                };
                                        }
                                      }));
                        }))), (function (results) {
                  Belt_Array.forEach(results, (function (result) {
                          if (result.TAG === /* Ok */0) {
                            return Curry._1(onSuccess, result._0);
                          } else {
                            return Curry._1(setStatus, (function (param) {
                                          return /* Error */2;
                                        }));
                          }
                        }));
                  Curry._1(setStatus, (function (current) {
                          if (current !== 2) {
                            return /* Done */3;
                          } else {
                            return /* Error */2;
                          }
                        }));
                }));
        }), []);
  return [
          executeAll,
          match[0]
        ];
}

var Fetcher = {
  useCategoriesUpdater: useCategoriesUpdater
};

function CatalogDuplicationFormSelectionTableCard(Props) {
  var reference = Props.reference;
  var duplicationMode = Props.duplicationMode;
  var onError = Props.onError;
  var match = Curry.app(useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* CacheAndNetwork */0,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var queryResults = match[1];
  var executeQuery = match[0];
  var match$1 = Curry.app(useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        /* NoCache */4,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var queryFilteringResults = match$1[1];
  var executeFilteringQuery = match$1[0];
  var match$2 = React.useState(function () {
        return [];
      });
  var setRowsCategoryData = match$2[1];
  var rowsCategoryData = match$2[0];
  var state = Curry._1(CatalogDuplicationForm$Pos.useFormState, undefined);
  var dispatch = Curry._1(CatalogDuplicationForm$Pos.useFormDispatch, undefined);
  var shops = Auth$Pos.useShops(undefined);
  var match$3 = state.values.source;
  var sourceShopId;
  if (match$3 !== undefined) {
    var shopId = match$3._0.shopId;
    sourceShopId = shopId !== undefined ? shopId : undefined;
  } else {
    sourceShopId = undefined;
  }
  React.useEffect((function () {
          if (reference !== undefined) {
            var productProducer = reference.productProducer;
            var productColor = reference.productColor;
            Curry._3(executeQuery, undefined, undefined, {
                  filterBy: makeInputObjectInputProductsQueryFilter({
                        _equals: reference.productName
                      }, {
                        _equals: reference.productKind
                      }, productProducer !== undefined ? ({
                            _equals: productProducer
                          }) : undefined, productColor !== undefined ? ({
                            _equals: productColor
                          }) : undefined, undefined, undefined, "INCLUDED", undefined)
                });
            Curry._3(executeFilteringQuery, undefined, undefined, {
                  filterBy: makeInputObjectInputProductsQueryFilter(undefined, undefined, undefined, undefined, {
                        _in: reference.variantsCku
                      }, undefined, "INCLUDED", undefined)
                });
          }
          
        }), [sourceShopId]);
  var shopList = React.useMemo((function () {
          if (queryResults.TAG !== /* Executed */0) {
            return [];
          }
          var match = queryResults._0.data;
          if (match === undefined) {
            return [];
          }
          if (queryFilteringResults.TAG !== /* Executed */0) {
            return [];
          }
          var match$1 = queryFilteringResults._0.data;
          if (match$1 === undefined) {
            return [];
          }
          var products = Belt_Array.concat(match.products.edges, match$1.products.edges);
          return Belt_Array.map(shops, (function (shop) {
                        var match = Belt_Array.getBy(products, (function (param) {
                                return param.node.shop.id === shop.id;
                              }));
                        var tmp;
                        if (duplicationMode) {
                          if (match !== undefined) {
                            var match$1 = Belt_Array.getBy(match.node.variants.edges, (function (param) {
                                    return param.node.cku === state.id;
                                  }));
                            tmp = match$1 !== undefined ? /* ExistsAlready */1 : /* Duplicable */0;
                          } else {
                            tmp = /* MissingProduct */2;
                          }
                        } else {
                          tmp = match !== undefined ? /* ExistsAlready */1 : /* Duplicable */0;
                        }
                        return {
                                dataId: shop.id,
                                dataName: shop.name,
                                duplicability: tmp
                              };
                      }));
        }), [
        queryResults,
        queryFilteringResults
      ]);
  var availableShopList = React.useMemo((function () {
          return Belt_Option.mapWithDefault(sourceShopId, [], (function (sourceShopId) {
                        return Belt_Array.keep(shopList, (function (shop) {
                                      return shop.dataId !== sourceShopId;
                                    }));
                      }));
        }), [
        shopList,
        sourceShopId
      ]);
  ReactUpdateEffect$Pos.use1((function (param) {
          var match = availableShopList.length !== 0;
          var match$1 = Belt_Array.every(availableShopList, (function (shop) {
                  return shop.duplicability !== /* Duplicable */0;
                }));
          var match$2 = Belt_Array.some(availableShopList, (function (shop) {
                  return shop.duplicability === /* MissingProduct */2;
                }));
          if (match) {
            if (match$2) {
              Curry._1(onError, /* MissingProduct */2);
            } else if (match$1) {
              Curry._1(onError, /* ExistsAlready */1);
            }
            
          }
          
        }), [availableShopList]);
  var onRequestCategoryUpdate = React.useCallback((function (newCategory) {
          Curry._1(setRowsCategoryData, (function (categories) {
                  var index = Belt_Array.getIndexBy(categories, (function (param) {
                          return param.shopId === newCategory.shopId;
                        }));
                  if (index !== undefined) {
                    return Belt_Array.mapWithIndex(categories, (function (currentIndex, category) {
                                  if (currentIndex === index) {
                                    return newCategory;
                                  } else {
                                    return category;
                                  }
                                }));
                  } else {
                    return Belt_Array.concat(categories, [newCategory]);
                  }
                }));
        }), []);
  var match$4 = useCategoriesUpdater(function (categories) {
        Belt_Option.map(Belt_Array.get(categories.edges, 0), (function (param) {
                var node = param.node;
                Curry._1(onRequestCategoryUpdate, {
                      id: node.id,
                      name: node.formattedName,
                      shopId: node.shop.id
                    });
              }));
      });
  var executeCategoriesUpdate = match$4[0];
  var productsData = React.useMemo((function () {
          if (queryResults.TAG !== /* Executed */0) {
            return [];
          }
          var match = queryResults._0.data;
          if (match === undefined) {
            return [];
          }
          if (queryFilteringResults.TAG !== /* Executed */0) {
            return [];
          }
          var match$1 = queryFilteringResults._0.data;
          if (match$1 !== undefined) {
            return Belt_Array.map(Belt_Array.concat(match.products.edges, match$1.products.edges), (function (param) {
                          var product = param.node;
                          return {
                                  productId: product.id,
                                  productShopId: product.shop.id,
                                  productCategoryFormattedName: Belt_Option.getWithDefault(Belt_Option.map(product.category, (function (param) {
                                              return param.formattedName;
                                            })), Intl$Pos.t("Not classified")),
                                  productCategoryName: Belt_Option.map(product.category, (function (param) {
                                          return param.name;
                                        })),
                                  productCategoryParentName: Belt_Option.map(Belt_Option.flatMap(product.category, (function (param) {
                                              return param.parent;
                                            })), (function (param) {
                                          return param.name;
                                        }))
                                };
                        }));
          } else {
            return [];
          }
        }), [
        queryResults,
        queryFilteringResults
      ]);
  ReactUpdateEffect$Pos.use3((function (param) {
          if (productsData.length !== 0) {
            var sourceProductData = Belt_Array.getBy(productsData, (function (param) {
                    return Caml_obj.equal(param.productShopId, sourceShopId);
                  }));
            Curry._1(executeCategoriesUpdate, Belt_Array.keepMap(availableShopList, (function (shop) {
                        var match = shop.duplicability;
                        if (duplicationMode) {
                          return ;
                        }
                        if (match !== 0) {
                          return ;
                        }
                        if (sourceProductData === undefined) {
                          return ;
                        }
                        var categoryName = sourceProductData.productCategoryName;
                        if (categoryName === undefined) {
                          return ;
                        }
                        var productCategoryParentName = sourceProductData.productCategoryParentName;
                        return {
                                filterByShopIds: {
                                  _in: [shop.dataId]
                                },
                                filterByName: {
                                  _equals: categoryName
                                },
                                filterByParentName: productCategoryParentName !== undefined ? ({
                                      _equals: productCategoryParentName
                                    }) : undefined
                              };
                      })));
          }
          
        }), [
        productsData,
        availableShopList,
        sourceShopId
      ]);
  var columns = React.useMemo((function () {
          var everyShopsDuplicable = availableShopList.length !== 0 && Belt_Array.every(availableShopList, (function (shop) {
                  return shop.duplicability === /* Duplicable */0;
                }));
          return CatalogDuplicationSelectionTable$Pos.tableColumns(everyShopsDuplicable, duplicationMode === /* Variant */1, onRequestCategoryUpdate);
        }), [
        availableShopList,
        sourceShopId
      ]);
  var rows = React.useMemo((function () {
          return Belt_Array.keepMap(availableShopList, (function (shop) {
                        var match = shop.duplicability;
                        var note;
                        switch (match) {
                          case /* Duplicable */0 :
                              note = undefined;
                              break;
                          case /* ExistsAlready */1 :
                              note = Intl$Pos.t("Reference already existing");
                              break;
                          case /* MissingProduct */2 :
                              note = Intl$Pos.t("Reference not duplicable, missing product");
                              break;
                          
                        }
                        var match$1 = Belt_Array.getBy(productsData, (function (param) {
                                return param.productShopId === shop.dataId;
                              }));
                        if (duplicationMode) {
                          return {
                                  id: Belt_Option.map(match$1, (function (param) {
                                          return {
                                                  TAG: /* Product */1,
                                                  _0: {
                                                    id: param.productId,
                                                    shopId: shop.dataId
                                                  }
                                                };
                                        })),
                                  shopId: shop.dataId,
                                  shopName: shop.dataName,
                                  categoryName: undefined,
                                  note: note
                                };
                        } else {
                          return {
                                  id: {
                                    TAG: /* Product */1,
                                    _0: {
                                      id: "",
                                      shopId: shop.dataId
                                    }
                                  },
                                  shopId: shop.dataId,
                                  shopName: shop.dataName,
                                  categoryName: Belt_Option.map(match$1, (function (param) {
                                          return param.productCategoryFormattedName;
                                        })),
                                  note: note
                                };
                        }
                      }));
        }), [
        availableShopList,
        sourceShopId
      ]);
  var rows$1 = React.useMemo((function () {
          if (duplicationMode) {
            return rows;
          } else {
            return Belt_Array.map(rows, (function (row) {
                          var match = Belt_Array.getBy(rowsCategoryData, (function (param) {
                                  return param.shopId === row.shopId;
                                }));
                          if (match === undefined) {
                            return row;
                          }
                          var id = match.id;
                          if (id !== undefined) {
                            return {
                                    id: {
                                      TAG: /* Category */0,
                                      _0: {
                                        id: id,
                                        shopId: match.shopId
                                      }
                                    },
                                    shopId: row.shopId,
                                    shopName: row.shopName,
                                    categoryName: match.name,
                                    note: row.note
                                  };
                          } else {
                            return {
                                    id: {
                                      TAG: /* Product */1,
                                      _0: {
                                        id: "",
                                        shopId: match.shopId
                                      }
                                    },
                                    shopId: row.shopId,
                                    shopName: row.shopName,
                                    categoryName: match.name,
                                    note: row.note
                                  };
                          }
                        }));
          }
        }), [
        rows,
        rowsCategoryData
      ]);
  var disabledRowsKeys = React.useMemo((function () {
          return Belt_Array.keepMap(shopList, (function (shop) {
                        var match = shop.duplicability;
                        if (match !== 0) {
                          return shop.dataId;
                        }
                        
                      }));
        }), [availableShopList]);
  var match$5 = state.values;
  var placeholderEmptyState;
  var exit = 0;
  var exit$1 = 0;
  if (match$5.source !== undefined && Caml_obj.equal(rows$1, [])) {
    placeholderEmptyState = React.createElement(Placeholder$Pos.make, {
          status: /* Loading */0
        });
  } else {
    exit$1 = 2;
  }
  if (exit$1 === 2) {
    var exit$2 = 0;
    if (queryResults.TAG === /* Executed */0 && queryResults._0.loading) {
      exit = 1;
    } else {
      exit$2 = 3;
    }
    if (exit$2 === 3) {
      if ((match$4[1] - 1 >>> 0) > 1) {
        placeholderEmptyState = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                          children: React.createElement(TextStyle$Pos.make, {
                                children: Intl$Pos.t("Please select the shop catalog to duplicate from in order to select the targeted catalogs."),
                                align: "center",
                                variation: "normal",
                                size: "small"
                              }),
                          align: "center"
                        })), "large", "xlarge", undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#f8f8fb", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined));
      } else {
        exit = 1;
      }
    }
    
  }
  if (exit === 1) {
    placeholderEmptyState = React.createElement(Placeholder$Pos.make, {
          status: /* Loading */0
        });
  }
  var onSelectChange = React.useCallback((function (selectedRowsKeys) {
          Curry._1(dispatch, {
                TAG: /* FieldValueChanged */2,
                _0: /* Destinations */1,
                _1: (function (param) {
                    if (selectedRowsKeys) {
                      return Belt_Array.keepMap(selectedRowsKeys._0, (function (shopId) {
                                    return Belt_Option.flatMap(Belt_Array.getBy(rows$1, (function (row) {
                                                      return row.shopId === shopId;
                                                    })), (function (row) {
                                                  return row.id;
                                                }));
                                  }));
                    } else {
                      return Belt_Array.keepMap(rows$1, (function (row) {
                                    if (!Belt_Array.some(disabledRowsKeys, (function (shopId) {
                                              return shopId === row.shopId;
                                            }))) {
                                      return row.id;
                                    }
                                    
                                  }));
                    }
                  })
              });
        }), [rows$1]);
  return React.createElement(Card$Pos.make, {
              children: React.createElement(CatalogDuplicationSelectionTable$Pos.make, CatalogDuplicationSelectionTable$Pos.makeProps({
                        TAG: /* Done */1,
                        _0: {
                          TAG: /* Ok */0,
                          _0: rows$1
                        }
                      }, columns, CatalogDuplicationSelectionTable$Pos.Row.keyExtractor, disabledRowsKeys, undefined, true, undefined, true, true, undefined, undefined, undefined, undefined, undefined, Caml_option.some(placeholderEmptyState), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, onSelectChange, undefined, undefined, undefined, undefined)),
              variation: "table"
            });
}

var make = React.memo(CatalogDuplicationFormSelectionTableCard);

var SelectionTable;

export {
  SelectionTable ,
  CatalogDuplication_ProductIdNotFound ,
  ProductsQuery ,
  CategoriesQuery ,
  Fetcher ,
  make ,
}
/* query Not a pure module */
