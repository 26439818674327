// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsonCodec$Pos from "../primitives/JsonCodec.bs.js";
import * as Navigation$Pos from "../primitives/Navigation.bs.js";
import * as QueryString$Pos from "../primitives/QueryString.bs.js";

function totalPages(totalCount, edgesPerPage) {
  if (edgesPerPage <= 0 || totalCount <= 0) {
    return 1;
  } else if (Caml_int32.mod_(totalCount, edgesPerPage) === 0) {
    return Caml_int32.div(totalCount, edgesPerPage);
  } else {
    return Caml_int32.div(totalCount, edgesPerPage) + 1 | 0;
  }
}

function generateCursor(page, edgesPerPage, totalCount) {
  var maxPage = totalPages(totalCount, edgesPerPage);
  if (!(page >= 1 && page <= maxPage)) {
    return {
            TAG: /* Error */1,
            _0: undefined
          };
  }
  var index = Math.imul(page - 1 | 0, edgesPerPage);
  var cursor = "9252fe5d140e19d308f2037404a0536a" + String(index);
  return {
          TAG: /* Ok */0,
          _0: btoa(cursor)
        };
}

function connectionArguments(currentPage, previousPage, totalCount, cursors, edgesPerPageOpt, param) {
  var edgesPerPage = edgesPerPageOpt !== undefined ? edgesPerPageOpt : 50;
  if (currentPage === 1) {
    return {
            first: edgesPerPage
          };
  }
  var startCursor = cursors[0];
  var exit = 0;
  if (startCursor !== undefined) {
    if (currentPage === (previousPage - 1 | 0)) {
      return {
              last: edgesPerPage,
              before: startCursor
            };
    }
    if (currentPage === (previousPage - 2 | 0)) {
      var startCursor$1 = generateCursor(currentPage, edgesPerPage, totalCount);
      if (startCursor$1.TAG === /* Ok */0) {
        return {
                last: edgesPerPage,
                before: startCursor$1._0
              };
      } else {
        return ;
      }
    }
    exit = 2;
  } else {
    exit = 2;
  }
  if (exit === 2) {
    var endCursor = cursors[1];
    if (endCursor !== undefined) {
      if (currentPage === (previousPage + 1 | 0)) {
        return {
                first: edgesPerPage,
                after: endCursor
              };
      }
      if (currentPage === (previousPage + 2 | 0)) {
        var endCursor$1 = generateCursor(currentPage, edgesPerPage, totalCount);
        if (endCursor$1.TAG === /* Ok */0) {
          return {
                  first: edgesPerPage,
                  after: endCursor$1._0
                };
        } else {
          return ;
        }
      }
      
    }
    
  }
  if (currentPage === totalPages(totalCount, edgesPerPage)) {
    return {
            last: edgesPerPage
          };
  }
  
}

function initialState(firstOpt, filters, sorting, param) {
  var first = firstOpt !== undefined ? firstOpt : 50;
  return {
          currentPage: 1,
          previousPage: -1,
          filters: filters,
          sorting: sorting,
          connectionArguments: {
            first: first
          }
        };
}

function reducer(state, action) {
  var match = state.connectionArguments;
  var edgesPerPage = match.first;
  var edgesPerPage$1;
  if (edgesPerPage !== undefined) {
    edgesPerPage$1 = edgesPerPage;
  } else {
    var edgesPerPage$2 = match.last;
    edgesPerPage$1 = edgesPerPage$2 !== undefined ? edgesPerPage$2 : 50;
  }
  switch (action.TAG | 0) {
    case /* Navigated */0 :
        var nextPage = action.nextPage;
        var previousPage = state.currentPage;
        var maybeConnectionArguments = connectionArguments(nextPage, previousPage, action.totalCount, action.cursors, edgesPerPage$1, undefined);
        if (maybeConnectionArguments === undefined) {
          return state;
        }
        var newrecord = Caml_obj.obj_dup(state);
        newrecord.connectionArguments = maybeConnectionArguments;
        newrecord.previousPage = previousPage;
        newrecord.currentPage = nextPage;
        return newrecord;
    case /* Searched */1 :
        var searchQuery = action._0;
        var tmp = searchQuery === "" ? undefined : searchQuery;
        return {
                currentPage: 1,
                previousPage: -1,
                searchQuery: tmp,
                filters: state.filters,
                sorting: state.sorting,
                connectionArguments: {
                  first: edgesPerPage$1
                }
              };
    case /* FiltersUpdated */2 :
        return {
                currentPage: 1,
                previousPage: -1,
                searchQuery: state.searchQuery,
                filters: Curry._1(action._0, state.filters),
                sorting: state.sorting,
                connectionArguments: {
                  first: edgesPerPage$1
                }
              };
    case /* SortingUpdated */3 :
        return {
                currentPage: 1,
                previousPage: -1,
                searchQuery: state.searchQuery,
                filters: state.filters,
                sorting: action._0,
                connectionArguments: {
                  first: edgesPerPage$1
                }
              };
    case /* Reset */4 :
        return action._0;
    
  }
}

function nextPage(state, action, totalPages) {
  var match = state.currentPage;
  if ((match === 0 || match === 1) && (totalPages === 0 || totalPages === 1)) {
    return ;
  }
  switch (action) {
    case /* First */0 :
        if (match >= 1) {
          return 1;
        } else {
          return ;
        }
    case /* PrevPrev */1 :
        if (match >= 1) {
          return match - 2 | 0;
        } else {
          return ;
        }
    case /* Prev */2 :
        if (match > totalPages) {
          return totalPages;
        } else if (match > 1) {
          return match - 1 | 0;
        } else {
          return ;
        }
    case /* Next */3 :
        if (match > totalPages) {
          return totalPages;
        } else if (match >= 1 && match < totalPages) {
          return match + 1 | 0;
        } else {
          return ;
        }
    case /* NextNext */4 :
        if (match >= 1 && match < (totalPages - 1 | 0)) {
          return match + 2 | 0;
        } else {
          return ;
        }
    case /* Last */5 :
        if (match >= 1) {
          return totalPages;
        } else {
          return ;
        }
    
  }
}

function encoder(param) {
  var connectionArguments = param.connectionArguments;
  return [
          param.currentPage,
          param.previousPage,
          param.searchQuery,
          Caml_option.some(param.filters),
          Caml_option.some(param.sorting),
          connectionArguments.first,
          connectionArguments.last,
          connectionArguments.after,
          connectionArguments.before
        ];
}

function value(filtersJsonCodec, sortingJsonCodec, initialFilters, initialSorts) {
  return JsonCodec$Pos.object9(encoder, (function (param) {
                return {
                        TAG: /* Ok */0,
                        _0: {
                          currentPage: Belt_Option.getWithDefault(param[0], 1),
                          previousPage: Belt_Option.getWithDefault(param[1], -1),
                          searchQuery: param[2],
                          filters: Belt_Option.getWithDefault(param[3], initialFilters),
                          sorting: Belt_Option.getWithDefault(param[4], initialSorts),
                          connectionArguments: {
                            first: param[5],
                            last: param[6],
                            after: param[7],
                            before: param[8]
                          }
                        }
                      };
              }), JsonCodec$Pos.optional(JsonCodec$Pos.field("page", JsonCodec$Pos.$$int)), JsonCodec$Pos.optional(JsonCodec$Pos.field("previousPage", JsonCodec$Pos.$$int)), JsonCodec$Pos.optional(JsonCodec$Pos.field("search", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("filters", filtersJsonCodec)), JsonCodec$Pos.optional(JsonCodec$Pos.field("sorting", sortingJsonCodec)), JsonCodec$Pos.optional(JsonCodec$Pos.field("first", JsonCodec$Pos.$$int)), JsonCodec$Pos.optional(JsonCodec$Pos.field("last", JsonCodec$Pos.$$int)), JsonCodec$Pos.optional(JsonCodec$Pos.field("after", JsonCodec$Pos.string)), JsonCodec$Pos.optional(JsonCodec$Pos.field("before", JsonCodec$Pos.string)));
}

function use(initialState, filtersJsonCodec, sortingJsonCodec) {
  var codec = value(filtersJsonCodec, sortingJsonCodec, initialState.filters, initialState.sorting);
  var navigate = Navigation$Pos.useNavigate(undefined);
  var url = Navigation$Pos.useUrl(undefined);
  var initialState$1 = React.useMemo((function () {
          var urlState = JsonCodec$Pos.decodeWith(QueryString$Pos.parse(url.query), codec);
          var match = url.query;
          if (urlState.TAG === /* Ok */0 && match !== "") {
            return urlState._0;
          } else {
            return initialState;
          }
        }), []);
  var match = React.useReducer(Curry.__2(reducer), initialState$1);
  var state = match[0];
  React.useEffect((function () {
          var query = QueryString$Pos.stringify(JsonCodec$Pos.encodeWith(state, codec));
          var route = url.pathname + "?" + query;
          if (query !== url.query) {
            Curry._3(navigate, true, undefined, route);
          }
          
        }), [state]);
  return [
          state,
          match[1]
        ];
}

var defaultEdgesPerPage = 50;

export {
  defaultEdgesPerPage ,
  totalPages ,
  connectionArguments ,
  initialState ,
  reducer ,
  nextPage ,
  use ,
}
/* react Not a pure module */
