// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Icon$Pos from "../../resources/images-and-icons/Icon.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Uuid$Pos from "../../externals/Uuid.bs.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Column$Pos from "../../resources/layout-and-structure/Column.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Select$Pos from "../../resources/selection-and-input/Select.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Columns$Pos from "../../resources/layout-and-structure/Columns.bs.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as Tooltip$Pos from "../../resources/overlays/Tooltip.bs.js";
import * as InfoBlock$Pos from "../../resources/layout-and-structure/InfoBlock.bs.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as Client from "@apollo/client";
import * as HelpCenter$Pos from "../../core/HelpCenter.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as TextAction$Pos from "../../resources/actions/TextAction.bs.js";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as RoundButton$Pos from "../../resources/actions/RoundButton.bs.js";
import * as TooltipIcon$Pos from "../../resources/overlays/TooltipIcon.bs.js";
import * as CatalogLabel$Pos from "../../modules/Catalog/CatalogLabel.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as CatalogRoutes$Pos from "./CatalogRoutes.bs.js";
import * as CatalogProduct$Pos from "../../modules/Catalog/CatalogProduct.bs.js";
import * as TextIconButton$Pos from "../../resources/actions/TextIconButton.bs.js";
import * as TriggerDownload$Pos from "../../primitives/TriggerDownload.bs.js";
import * as InputNumberField$Pos from "../../resources/selection-and-input/InputNumberField.bs.js";
import * as OverlayTriggerView$Pos from "../../resources/overlays/OverlayTriggerView.bs.js";
import * as ResourceDetailsPage$Pos from "../../core/ResourceDetailsPage.bs.js";
import * as InputRadioGroupField$Pos from "../../resources/selection-and-input/InputRadioGroupField.bs.js";
import * as InputToggleSwitchField$Pos from "../../resources/selection-and-input/InputToggleSwitchField.bs.js";
import * as CatalogPlaceholderShopPicker$Pos from "../../modules/Catalog/CatalogPlaceholderShopPicker.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as LegacyProductReferenceTableCell$Pos from "../../modules/Product/LegacyProductReferenceTableCell.bs.js";
import * as CatalogLabelProductsPickerModalTable$Pos from "../../modules/Catalog/CatalogLabelProductsPickerModalTable.bs.js";

var initial = /* Picked */{
  _0: []
};

var CatalogLabelCreateProductsValue = {
  initial: initial
};

function initialValues(priceId, productBarcodeDisplayedOpt, missingProductBarcodeGeneratedOpt, priceDisplayedOpt, producerDisplayedOpt, alcoholVolumeDisplayedOpt, productCodeOpt, labelFormatOpt, printOffsetOpt, borderEnabledOpt, sortOpt, param) {
  var productBarcodeDisplayed = productBarcodeDisplayedOpt !== undefined ? productBarcodeDisplayedOpt : false;
  var missingProductBarcodeGenerated = missingProductBarcodeGeneratedOpt !== undefined ? missingProductBarcodeGeneratedOpt : false;
  var priceDisplayed = priceDisplayedOpt !== undefined ? priceDisplayedOpt : true;
  var producerDisplayed = producerDisplayedOpt !== undefined ? producerDisplayedOpt : false;
  var alcoholVolumeDisplayed = alcoholVolumeDisplayedOpt !== undefined ? alcoholVolumeDisplayedOpt : false;
  var productCode = productCodeOpt !== undefined ? productCodeOpt : /* Hidden */0;
  var labelFormat = labelFormatOpt !== undefined ? labelFormatOpt : /* Grid21 */0;
  var printOffset = printOffsetOpt !== undefined ? printOffsetOpt : 1;
  var borderEnabled = borderEnabledOpt !== undefined ? borderEnabledOpt : false;
  var sort = sortOpt !== undefined ? sortOpt : /* Unsorted */0;
  return {
          priceId: priceId,
          productBarcodeDisplayed: productBarcodeDisplayed,
          missingProductBarcodeGenerated: missingProductBarcodeGenerated,
          priceDisplayed: priceDisplayed,
          producerDisplayed: producerDisplayed,
          alcoholVolumeDisplayed: alcoholVolumeDisplayed,
          productCode: productCode,
          labelFormat: labelFormat,
          printOffset: printOffset,
          borderEnabled: borderEnabled,
          sort: sort,
          productValues: initial
        };
}

function errorMessagesFromValues(values) {
  var productValues = values.productValues;
  var printOffset = values.printOffset;
  var labelFormat = values.labelFormat;
  var maxOffset = Curry._1(CatalogLabel$Pos.Sheet.LabelFormat.toMaxOffset, labelFormat);
  return {
          printOffset: labelFormat ? (
              printOffset > maxOffset || printOffset < 1 ? Intl$Pos.template(Intl$Pos.t("This number must range between {{min}} and {{max}} inclusive."), {
                      min: 1,
                      max: maxOffset
                    }, undefined) : undefined
            ) : (
              printOffset > maxOffset || printOffset < 1 ? Intl$Pos.template(Intl$Pos.t("This number must range between {{min}} and {{max}} inclusive."), {
                      min: 1,
                      max: maxOffset
                    }, undefined) : undefined
            ),
          productValues: productValues && productValues._0.length === 0 ? Intl$Pos.t("Please select at least one product.") : undefined
        };
}

var CatalogLabelCreateLabelSheetForm = {
  initialValues: initialValues,
  errorMessagesFromValues: errorMessagesFromValues
};

function initialValues$1(priceId, productBarcodeDisplayedOpt, missingProductBarcodeGeneratedOpt, priceDisplayedOpt, producerDisplayedOpt, alcoholVolumeDisplayedOpt, productCodeOpt, labelFormatOpt, param) {
  var productBarcodeDisplayed = productBarcodeDisplayedOpt !== undefined ? productBarcodeDisplayedOpt : false;
  var missingProductBarcodeGenerated = missingProductBarcodeGeneratedOpt !== undefined ? missingProductBarcodeGeneratedOpt : false;
  var priceDisplayed = priceDisplayedOpt !== undefined ? priceDisplayedOpt : true;
  var producerDisplayed = producerDisplayedOpt !== undefined ? producerDisplayedOpt : false;
  var alcoholVolumeDisplayed = alcoholVolumeDisplayedOpt !== undefined ? alcoholVolumeDisplayedOpt : false;
  var productCode = productCodeOpt !== undefined ? productCodeOpt : /* Hidden */0;
  var labelFormat = labelFormatOpt !== undefined ? labelFormatOpt : /* Label31x22 */0;
  return {
          priceId: priceId,
          productBarcodeDisplayed: productBarcodeDisplayed,
          missingProductBarcodeGenerated: missingProductBarcodeGenerated,
          priceDisplayed: priceDisplayed,
          producerDisplayed: producerDisplayed,
          alcoholVolumeDisplayed: alcoholVolumeDisplayed,
          productCode: productCode,
          labelFormat: labelFormat,
          productValues: initial
        };
}

function errorMessagesFromValues$1(values) {
  var match = values.productValues;
  return {
          productValues: match && match._0.length === 0 ? Intl$Pos.t("Please select at least one product.") : undefined
        };
}

var CatalogLabelCreateLabelPrintForm = {
  initialValues: initialValues$1,
  errorMessagesFromValues: errorMessagesFromValues$1
};

var validationErrorText = Intl$Pos.t("There are some errors in the form, please correct them before trying to send it again.");

function isErrored(state) {
  var match = state.form;
  if (match.TAG === /* LabelSheet */0) {
    var match$1 = match._0;
    var match$2 = match$1.submitResult;
    if (typeof match$2 === "number") {
      if (match$2 === /* NotAsked */0) {
        return false;
      }
      
    } else {
      if (match$2.TAG === /* Reloading */0) {
        return false;
      }
      if (match$2._0.TAG === /* Ok */0) {
        return false;
      }
      
    }
    var errorMessages = match$1.errorMessages;
    if (Belt_Option.isSome(errorMessages.printOffset)) {
      return true;
    } else {
      return Belt_Option.isSome(errorMessages.productValues);
    }
  }
  var match$3 = match._0;
  if (match$3.settingsOnly !== undefined) {
    return false;
  }
  var match$4 = match$3.submitResult;
  if (typeof match$4 === "number") {
    if (match$4 === /* NotAsked */0) {
      return false;
    } else {
      return Belt_Option.isSome(match$3.errorMessages.productValues);
    }
  } else if (match$4.TAG === /* Reloading */0 || match$4._0.TAG === /* Ok */0) {
    return false;
  } else {
    return Belt_Option.isSome(match$3.errorMessages.productValues);
  }
}

function isPrintMode(state) {
  var match = state.form;
  if (match.TAG === /* LabelSheet */0) {
    return false;
  } else {
    return true;
  }
}

function isSettingsOnlyMode(state) {
  var match = state.form;
  if (match.TAG === /* LabelSheet */0) {
    return false;
  } else {
    return Belt_Option.isSome(match._0.settingsOnly);
  }
}

function defaultPriceIdFromPriceList(priceList) {
  var defaultPriceItem = Belt_Array.getBy(priceList, (function (priceItem) {
          return priceItem.default;
        }));
  var match = Belt_Array.get(priceList, 0);
  if (defaultPriceItem !== undefined) {
    return Caml_option.some(defaultPriceItem.id);
  } else if (match !== undefined) {
    return Caml_option.some(match.id);
  } else {
    return ;
  }
}

function initializeStateLabelSheet(priceList, priceId, productBarcodeDisplayed, missingProductBarcodeGenerated, priceDisplayed, producerDisplayed, alcoholVolumeDisplayed, productCode, labelFormat, printOffset, borderEnabled, sort, param) {
  var priceId$1 = Belt_Option.getWithDefault(Belt_Option.orElse(priceId, defaultPriceIdFromPriceList(priceList)), Uuid.v4());
  return {
          priceList: priceList,
          form: {
            TAG: /* LabelSheet */0,
            _0: {
              submitResult: /* NotAsked */0,
              values: initialValues(priceId$1, productBarcodeDisplayed, missingProductBarcodeGenerated, priceDisplayed, producerDisplayed, alcoholVolumeDisplayed, productCode, labelFormat, printOffset, borderEnabled, sort, undefined),
              errorMessages: {}
            }
          }
        };
}

function initializeStateLabelPrint(settingsOnlyOpt, priceList, priceId, productBarcodeDisplayed, missingProductBarcodeGenerated, priceDisplayed, producerDisplayed, alcoholVolumeDisplayed, productCode, labelFormat, param) {
  var settingsOnly = settingsOnlyOpt !== undefined ? Caml_option.valFromOption(settingsOnlyOpt) : undefined;
  var priceId$1 = Belt_Option.getWithDefault(Belt_Option.orElse(priceId, defaultPriceIdFromPriceList(priceList)), Uuid.v4());
  return {
          priceList: priceList,
          form: {
            TAG: /* LabelPrint */1,
            _0: {
              settingsOnly: settingsOnly,
              submitResult: /* NotAsked */0,
              values: initialValues$1(priceId$1, productBarcodeDisplayed, missingProductBarcodeGenerated, priceDisplayed, producerDisplayed, alcoholVolumeDisplayed, productCode, labelFormat, undefined),
              errorMessages: {}
            }
          }
        };
}

function toLabelSheetFormValues(values) {
  var match = initialValues(values.priceId, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  return {
          priceId: values.priceId,
          productBarcodeDisplayed: values.productBarcodeDisplayed,
          missingProductBarcodeGenerated: values.missingProductBarcodeGenerated,
          priceDisplayed: values.priceDisplayed,
          producerDisplayed: values.producerDisplayed,
          alcoholVolumeDisplayed: values.alcoholVolumeDisplayed,
          productCode: values.productCode,
          labelFormat: match.labelFormat,
          printOffset: match.printOffset,
          borderEnabled: match.borderEnabled,
          sort: match.sort,
          productValues: values.productValues
        };
}

function toLabelPrintFormValues(values) {
  var match = initialValues$1(values.priceId, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  return {
          priceId: values.priceId,
          productBarcodeDisplayed: values.productBarcodeDisplayed,
          missingProductBarcodeGenerated: values.missingProductBarcodeGenerated,
          priceDisplayed: match.priceDisplayed,
          producerDisplayed: match.producerDisplayed,
          alcoholVolumeDisplayed: match.alcoholVolumeDisplayed,
          productCode: values.productCode,
          labelFormat: match.labelFormat,
          productValues: values.productValues
        };
}

function reducer(state, action) {
  if (typeof action === "number") {
    var form = state.form;
    if (form.TAG === /* LabelSheet */0) {
      return initializeStateLabelPrint(undefined, state.priceList, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
    }
    var form$1 = form._0;
    return {
            priceList: state.priceList,
            form: {
              TAG: /* LabelPrint */1,
              _0: {
                settingsOnly: form$1.settingsOnly,
                submitResult: /* Loading */1,
                values: form$1.values,
                errorMessages: form$1.errorMessages
              }
            }
          };
  }
  switch (action.TAG | 0) {
    case /* LabelSheetEdited */0 :
        var values = action._0;
        var match = state.form;
        if (match.TAG !== /* LabelSheet */0) {
          return initializeStateLabelSheet(state.priceList, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
        }
        var prevValues = match._0.values;
        var nextValues_priceId = values.priceId;
        var nextValues_productBarcodeDisplayed = values.missingProductBarcodeGenerated && !prevValues.productBarcodeDisplayed ? true : values.productBarcodeDisplayed;
        var nextValues_missingProductBarcodeGenerated = !values.productBarcodeDisplayed && prevValues.missingProductBarcodeGenerated ? false : values.missingProductBarcodeGenerated;
        var nextValues_priceDisplayed = values.priceDisplayed;
        var nextValues_producerDisplayed = values.producerDisplayed;
        var nextValues_alcoholVolumeDisplayed = values.alcoholVolumeDisplayed;
        var nextValues_productCode = values.productCode;
        var nextValues_labelFormat = values.labelFormat;
        var nextValues_printOffset = values.printOffset;
        var nextValues_borderEnabled = values.borderEnabled;
        var nextValues_sort = values.sort;
        var nextValues_productValues = values.productValues;
        var nextValues = {
          priceId: nextValues_priceId,
          productBarcodeDisplayed: nextValues_productBarcodeDisplayed,
          missingProductBarcodeGenerated: nextValues_missingProductBarcodeGenerated,
          priceDisplayed: nextValues_priceDisplayed,
          producerDisplayed: nextValues_producerDisplayed,
          alcoholVolumeDisplayed: nextValues_alcoholVolumeDisplayed,
          productCode: nextValues_productCode,
          labelFormat: nextValues_labelFormat,
          printOffset: nextValues_printOffset,
          borderEnabled: nextValues_borderEnabled,
          sort: nextValues_sort,
          productValues: nextValues_productValues
        };
        return {
                priceList: state.priceList,
                form: {
                  TAG: /* LabelSheet */0,
                  _0: {
                    submitResult: /* NotAsked */0,
                    values: nextValues,
                    errorMessages: errorMessagesFromValues(nextValues)
                  }
                }
              };
    case /* LabelPrintEdited */1 :
        var values$1 = action._0;
        var match$1 = state.form;
        if (match$1.TAG === /* LabelSheet */0) {
          return initializeStateLabelPrint(undefined, state.priceList, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
        }
        var match$2 = match$1._0;
        var prevValues$1 = match$2.values;
        var nextValues_priceId$1 = values$1.priceId;
        var nextValues_productBarcodeDisplayed$1 = values$1.missingProductBarcodeGenerated && !prevValues$1.productBarcodeDisplayed ? true : values$1.productBarcodeDisplayed;
        var nextValues_missingProductBarcodeGenerated$1 = !values$1.productBarcodeDisplayed && prevValues$1.missingProductBarcodeGenerated ? false : values$1.missingProductBarcodeGenerated;
        var nextValues_priceDisplayed$1 = values$1.priceDisplayed;
        var nextValues_producerDisplayed$1 = values$1.producerDisplayed;
        var nextValues_alcoholVolumeDisplayed$1 = values$1.alcoholVolumeDisplayed;
        var nextValues_productCode$1 = values$1.productCode;
        var nextValues_labelFormat$1 = values$1.labelFormat;
        var nextValues_productValues$1 = values$1.productValues;
        var nextValues$1 = {
          priceId: nextValues_priceId$1,
          productBarcodeDisplayed: nextValues_productBarcodeDisplayed$1,
          missingProductBarcodeGenerated: nextValues_missingProductBarcodeGenerated$1,
          priceDisplayed: nextValues_priceDisplayed$1,
          producerDisplayed: nextValues_producerDisplayed$1,
          alcoholVolumeDisplayed: nextValues_alcoholVolumeDisplayed$1,
          productCode: nextValues_productCode$1,
          labelFormat: nextValues_labelFormat$1,
          productValues: nextValues_productValues$1
        };
        return {
                priceList: state.priceList,
                form: {
                  TAG: /* LabelPrint */1,
                  _0: {
                    settingsOnly: match$2.settingsOnly,
                    submitResult: /* NotAsked */0,
                    values: nextValues$1,
                    errorMessages: errorMessagesFromValues$1(nextValues$1)
                  }
                }
              };
    case /* GenerateLabelsSubmitted */2 :
        var request = action._0;
        var form$2 = state.form;
        if (form$2.TAG !== /* LabelSheet */0) {
          return initializeStateLabelSheet(state.priceList, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
        }
        var form$3 = form$2._0;
        var validatedState_priceList = state.priceList;
        var validatedState_form = {
          TAG: /* LabelSheet */0,
          _0: {
            submitResult: request,
            values: form$3.values,
            errorMessages: errorMessagesFromValues(form$3.values)
          }
        };
        var validatedState = {
          priceList: validatedState_priceList,
          form: validatedState_form
        };
        var request$1 = isErrored(validatedState) ? ({
              TAG: /* Done */1,
              _0: {
                TAG: /* Error */1,
                _0: Intl$Pos.t(validationErrorText)
              }
            }) : request;
        return {
                priceList: state.priceList,
                form: {
                  TAG: /* LabelSheet */0,
                  _0: {
                    submitResult: request$1,
                    values: form$3.values,
                    errorMessages: errorMessagesFromValues(form$3.values)
                  }
                }
              };
    case /* PrintLabelsSubmitted */3 :
        var request$2 = action._0;
        var form$4 = state.form;
        if (form$4.TAG === /* LabelSheet */0) {
          return initializeStateLabelPrint(undefined, state.priceList, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
        }
        var form$5 = form$4._0;
        var validatedState_priceList$1 = state.priceList;
        var validatedState_form$1 = {
          TAG: /* LabelPrint */1,
          _0: {
            settingsOnly: form$5.settingsOnly,
            submitResult: request$2,
            values: form$5.values,
            errorMessages: errorMessagesFromValues$1(form$5.values)
          }
        };
        var validatedState$1 = {
          priceList: validatedState_priceList$1,
          form: validatedState_form$1
        };
        var request$3 = isErrored(validatedState$1) ? ({
              TAG: /* Done */1,
              _0: {
                TAG: /* Error */1,
                _0: Intl$Pos.t(validationErrorText)
              }
            }) : request$2;
        return {
                priceList: state.priceList,
                form: {
                  TAG: /* LabelPrint */1,
                  _0: {
                    settingsOnly: form$5.settingsOnly,
                    submitResult: request$3,
                    values: form$5.values,
                    errorMessages: errorMessagesFromValues$1(form$5.values)
                  }
                }
              };
    
  }
}

var CatalogLabelCreateReducer = {
  validationErrorText: validationErrorText,
  isErrored: isErrored,
  isPrintMode: isPrintMode,
  isSettingsOnlyMode: isSettingsOnlyMode,
  defaultPriceIdFromPriceList: defaultPriceIdFromPriceList,
  initializeStateLabelSheet: initializeStateLabelSheet,
  initializeStateLabelPrint: initializeStateLabelPrint,
  toLabelSheetFormValues: toLabelSheetFormValues,
  toLabelPrintFormValues: toLabelPrintFormValues,
  reducer: reducer
};

var make = React.memo(function (Props) {
      var priceList = Props.priceList;
      var values = Props.values;
      var onChange = Props.onChange;
      var productValues = values.productValues;
      var hasProductsPicked = productValues ? productValues._0.length !== 0 : false;
      return React.createElement(Card$Pos.make, {
                  children: null,
                  title: Intl$Pos.t("Label information"),
                  stretch: true
                }, React.createElement(Tooltip$Pos.make, {
                      children: React.createElement(Select$Pos.make, {
                            label: Intl$Pos.t("Price list"),
                            disabled: hasProductsPicked,
                            sections: [{
                                items: Belt_Array.map(priceList, (function (item) {
                                        return {
                                                key: item.id,
                                                value: item.id,
                                                label: item.name
                                              };
                                      }))
                              }],
                            preset: {
                              NAME: "inputField",
                              VAL: {
                                required: false
                              }
                            },
                            value: values.priceId,
                            onChange: (function (value) {
                                Curry._1(onChange, {
                                      priceId: value,
                                      productBarcodeDisplayed: values.productBarcodeDisplayed,
                                      missingProductBarcodeGenerated: values.missingProductBarcodeGenerated,
                                      priceDisplayed: values.priceDisplayed,
                                      producerDisplayed: values.producerDisplayed,
                                      alcoholVolumeDisplayed: values.alcoholVolumeDisplayed,
                                      productCode: values.productCode,
                                      labelFormat: values.labelFormat,
                                      printOffset: values.printOffset,
                                      borderEnabled: values.borderEnabled,
                                      sort: values.sort,
                                      productValues: values.productValues
                                    });
                              })
                          }),
                      placement: "end",
                      offset: 4,
                      crossOffset: 11,
                      content: {
                        TAG: /* Text */0,
                        _0: Intl$Pos.t("Modification of the price list is only allowed when there is no selected products.")
                      },
                      disabled: !hasProductsPicked,
                      delay: 0
                    }), React.createElement(InfoBlock$Pos.make, {
                      children: React.createElement(Stack$Pos.make, {
                            children: null,
                            space: "medium"
                          }, React.createElement(Stack$Pos.make, {
                                children: null,
                                space: "small"
                              }, React.createElement(InputToggleSwitchField$Pos.make, {
                                    label: Intl$Pos.t("Display barcode"),
                                    required: false,
                                    value: values.productBarcodeDisplayed,
                                    onChange: (function (value) {
                                        Curry._1(onChange, {
                                              priceId: values.priceId,
                                              productBarcodeDisplayed: value,
                                              missingProductBarcodeGenerated: values.missingProductBarcodeGenerated,
                                              priceDisplayed: values.priceDisplayed,
                                              producerDisplayed: values.producerDisplayed,
                                              alcoholVolumeDisplayed: values.alcoholVolumeDisplayed,
                                              productCode: values.productCode,
                                              labelFormat: values.labelFormat,
                                              printOffset: values.printOffset,
                                              borderEnabled: values.borderEnabled,
                                              sort: values.sort,
                                              productValues: values.productValues
                                            });
                                      })
                                  }), React.createElement(InputToggleSwitchField$Pos.make, {
                                    label: Intl$Pos.t("Automatically generate missing barcodes"),
                                    required: false,
                                    value: values.missingProductBarcodeGenerated,
                                    onChange: (function (value) {
                                        Curry._1(onChange, {
                                              priceId: values.priceId,
                                              productBarcodeDisplayed: values.productBarcodeDisplayed,
                                              missingProductBarcodeGenerated: value,
                                              priceDisplayed: values.priceDisplayed,
                                              producerDisplayed: values.producerDisplayed,
                                              alcoholVolumeDisplayed: values.alcoholVolumeDisplayed,
                                              productCode: values.productCode,
                                              labelFormat: values.labelFormat,
                                              printOffset: values.printOffset,
                                              borderEnabled: values.borderEnabled,
                                              sort: values.sort,
                                              productValues: values.productValues
                                            });
                                      })
                                  }), React.createElement(InputToggleSwitchField$Pos.make, {
                                    label: Intl$Pos.t("Display retail price"),
                                    required: false,
                                    value: values.priceDisplayed,
                                    onChange: (function (value) {
                                        Curry._1(onChange, {
                                              priceId: values.priceId,
                                              productBarcodeDisplayed: values.productBarcodeDisplayed,
                                              missingProductBarcodeGenerated: values.missingProductBarcodeGenerated,
                                              priceDisplayed: value,
                                              producerDisplayed: values.producerDisplayed,
                                              alcoholVolumeDisplayed: values.alcoholVolumeDisplayed,
                                              productCode: values.productCode,
                                              labelFormat: values.labelFormat,
                                              printOffset: values.printOffset,
                                              borderEnabled: values.borderEnabled,
                                              sort: values.sort,
                                              productValues: values.productValues
                                            });
                                      })
                                  }), React.createElement(InputToggleSwitchField$Pos.make, {
                                    label: Intl$Pos.t("Display producer"),
                                    required: false,
                                    value: values.producerDisplayed,
                                    onChange: (function (value) {
                                        Curry._1(onChange, {
                                              priceId: values.priceId,
                                              productBarcodeDisplayed: values.productBarcodeDisplayed,
                                              missingProductBarcodeGenerated: values.missingProductBarcodeGenerated,
                                              priceDisplayed: values.priceDisplayed,
                                              producerDisplayed: value,
                                              alcoholVolumeDisplayed: values.alcoholVolumeDisplayed,
                                              productCode: values.productCode,
                                              labelFormat: values.labelFormat,
                                              printOffset: values.printOffset,
                                              borderEnabled: values.borderEnabled,
                                              sort: values.sort,
                                              productValues: values.productValues
                                            });
                                      })
                                  }), React.createElement(InputToggleSwitchField$Pos.make, {
                                    label: Intl$Pos.t("Display alcohol volume"),
                                    required: false,
                                    value: values.alcoholVolumeDisplayed,
                                    onChange: (function (value) {
                                        Curry._1(onChange, {
                                              priceId: values.priceId,
                                              productBarcodeDisplayed: values.productBarcodeDisplayed,
                                              missingProductBarcodeGenerated: values.missingProductBarcodeGenerated,
                                              priceDisplayed: values.priceDisplayed,
                                              producerDisplayed: values.producerDisplayed,
                                              alcoholVolumeDisplayed: value,
                                              productCode: values.productCode,
                                              labelFormat: values.labelFormat,
                                              printOffset: values.printOffset,
                                              borderEnabled: values.borderEnabled,
                                              sort: values.sort,
                                              productValues: values.productValues
                                            });
                                      })
                                  })), React.createElement(InputRadioGroupField$Pos.make, {
                                label: Intl$Pos.t("Additional product code"),
                                value: values.productCode,
                                required: false,
                                onChange: (function (value) {
                                    Curry._1(onChange, {
                                          priceId: values.priceId,
                                          productBarcodeDisplayed: values.productBarcodeDisplayed,
                                          missingProductBarcodeGenerated: values.missingProductBarcodeGenerated,
                                          priceDisplayed: values.priceDisplayed,
                                          producerDisplayed: values.producerDisplayed,
                                          alcoholVolumeDisplayed: values.alcoholVolumeDisplayed,
                                          productCode: value,
                                          labelFormat: values.labelFormat,
                                          printOffset: values.printOffset,
                                          borderEnabled: values.borderEnabled,
                                          sort: values.sort,
                                          productValues: values.productValues
                                        });
                                  }),
                                options: CatalogLabel$Pos.ProductCode.values,
                                optionToText: CatalogLabel$Pos.ProductCode.toLabel
                              })),
                      title: Intl$Pos.t("Options")
                    }));
    });

var LabelSheet = {
  make: make
};

var make$1 = React.memo(function (Props) {
      var priceList = Props.priceList;
      var values = Props.values;
      var onChange = Props.onChange;
      var productValues = values.productValues;
      var hasProductsPicked = productValues ? productValues._0.length !== 0 : false;
      return React.createElement(Card$Pos.make, {
                  children: null,
                  title: Intl$Pos.t("Label information"),
                  stretch: true
                }, React.createElement(Tooltip$Pos.make, {
                      children: React.createElement(Select$Pos.make, {
                            label: Intl$Pos.t("Price list"),
                            disabled: hasProductsPicked,
                            sections: [{
                                items: Belt_Array.map(priceList, (function (item) {
                                        return {
                                                key: item.id,
                                                value: item.id,
                                                label: item.name
                                              };
                                      }))
                              }],
                            preset: {
                              NAME: "inputField",
                              VAL: {
                                required: false
                              }
                            },
                            value: values.priceId,
                            onChange: (function (value) {
                                Curry._1(onChange, {
                                      priceId: value,
                                      productBarcodeDisplayed: values.productBarcodeDisplayed,
                                      missingProductBarcodeGenerated: values.missingProductBarcodeGenerated,
                                      priceDisplayed: values.priceDisplayed,
                                      producerDisplayed: values.producerDisplayed,
                                      alcoholVolumeDisplayed: values.alcoholVolumeDisplayed,
                                      productCode: values.productCode,
                                      labelFormat: values.labelFormat,
                                      productValues: values.productValues
                                    });
                              })
                          }),
                      placement: "end",
                      offset: 4,
                      crossOffset: 11,
                      content: {
                        TAG: /* Text */0,
                        _0: Intl$Pos.t("Modification of the price list is only allowed when there is no selected products.")
                      },
                      disabled: !hasProductsPicked,
                      delay: 0
                    }), React.createElement(InfoBlock$Pos.make, {
                      children: React.createElement(Stack$Pos.make, {
                            children: null,
                            space: "medium"
                          }, React.createElement(Stack$Pos.make, {
                                children: null,
                                space: "small"
                              }, React.createElement(InputToggleSwitchField$Pos.make, {
                                    label: Intl$Pos.t("Display barcode"),
                                    required: false,
                                    value: values.productBarcodeDisplayed,
                                    onChange: (function (value) {
                                        Curry._1(onChange, {
                                              priceId: values.priceId,
                                              productBarcodeDisplayed: value,
                                              missingProductBarcodeGenerated: values.missingProductBarcodeGenerated,
                                              priceDisplayed: values.priceDisplayed,
                                              producerDisplayed: values.producerDisplayed,
                                              alcoholVolumeDisplayed: values.alcoholVolumeDisplayed,
                                              productCode: values.productCode,
                                              labelFormat: values.labelFormat,
                                              productValues: values.productValues
                                            });
                                      })
                                  }), React.createElement(InputToggleSwitchField$Pos.make, {
                                    label: Intl$Pos.t("Automatically generate missing barcodes"),
                                    required: false,
                                    value: values.missingProductBarcodeGenerated,
                                    onChange: (function (value) {
                                        Curry._1(onChange, {
                                              priceId: values.priceId,
                                              productBarcodeDisplayed: values.productBarcodeDisplayed,
                                              missingProductBarcodeGenerated: value,
                                              priceDisplayed: values.priceDisplayed,
                                              producerDisplayed: values.producerDisplayed,
                                              alcoholVolumeDisplayed: values.alcoholVolumeDisplayed,
                                              productCode: values.productCode,
                                              labelFormat: values.labelFormat,
                                              productValues: values.productValues
                                            });
                                      })
                                  }), React.createElement(InputToggleSwitchField$Pos.make, {
                                    label: Intl$Pos.t("Display retail price"),
                                    required: false,
                                    value: values.priceDisplayed,
                                    onChange: (function (value) {
                                        Curry._1(onChange, {
                                              priceId: values.priceId,
                                              productBarcodeDisplayed: values.productBarcodeDisplayed,
                                              missingProductBarcodeGenerated: values.missingProductBarcodeGenerated,
                                              priceDisplayed: value,
                                              producerDisplayed: values.producerDisplayed,
                                              alcoholVolumeDisplayed: values.alcoholVolumeDisplayed,
                                              productCode: values.productCode,
                                              labelFormat: values.labelFormat,
                                              productValues: values.productValues
                                            });
                                      })
                                  }), React.createElement(InputToggleSwitchField$Pos.make, {
                                    label: Intl$Pos.t("Display producer"),
                                    required: false,
                                    value: values.producerDisplayed,
                                    onChange: (function (value) {
                                        Curry._1(onChange, {
                                              priceId: values.priceId,
                                              productBarcodeDisplayed: values.productBarcodeDisplayed,
                                              missingProductBarcodeGenerated: values.missingProductBarcodeGenerated,
                                              priceDisplayed: values.priceDisplayed,
                                              producerDisplayed: value,
                                              alcoholVolumeDisplayed: values.alcoholVolumeDisplayed,
                                              productCode: values.productCode,
                                              labelFormat: values.labelFormat,
                                              productValues: values.productValues
                                            });
                                      })
                                  }), React.createElement(InputToggleSwitchField$Pos.make, {
                                    label: Intl$Pos.t("Display alcohol volume"),
                                    required: false,
                                    value: values.alcoholVolumeDisplayed,
                                    onChange: (function (value) {
                                        Curry._1(onChange, {
                                              priceId: values.priceId,
                                              productBarcodeDisplayed: values.productBarcodeDisplayed,
                                              missingProductBarcodeGenerated: values.missingProductBarcodeGenerated,
                                              priceDisplayed: values.priceDisplayed,
                                              producerDisplayed: values.producerDisplayed,
                                              alcoholVolumeDisplayed: value,
                                              productCode: values.productCode,
                                              labelFormat: values.labelFormat,
                                              productValues: values.productValues
                                            });
                                      })
                                  })), React.createElement(InputRadioGroupField$Pos.make, {
                                label: Intl$Pos.t("Additional product code"),
                                value: values.productCode,
                                required: false,
                                onChange: (function (value) {
                                    Curry._1(onChange, {
                                          priceId: values.priceId,
                                          productBarcodeDisplayed: values.productBarcodeDisplayed,
                                          missingProductBarcodeGenerated: values.missingProductBarcodeGenerated,
                                          priceDisplayed: values.priceDisplayed,
                                          producerDisplayed: values.producerDisplayed,
                                          alcoholVolumeDisplayed: values.alcoholVolumeDisplayed,
                                          productCode: value,
                                          labelFormat: values.labelFormat,
                                          productValues: values.productValues
                                        });
                                  }),
                                options: CatalogLabel$Pos.ProductCode.values,
                                optionToText: CatalogLabel$Pos.ProductCode.toLabel
                              })),
                      title: Intl$Pos.t("Options")
                    }));
    });

var LabelPrint = {
  make: make$1
};

var CatalogLabelCreateInformationCard = {
  LabelSheet: LabelSheet,
  LabelPrint: LabelPrint
};

var values = [
  /* Label31x22 */2,
  /* Grid21 */0,
  /* Grid64 */1
];

function isStarFormat(value) {
  return value >= 2;
}

function toLabel(value) {
  switch (value) {
    case /* Grid21 */0 :
        return Curry._1(CatalogLabel$Pos.Sheet.LabelFormat.toLabel, /* Grid21 */0);
    case /* Grid64 */1 :
        return Curry._1(CatalogLabel$Pos.Sheet.LabelFormat.toLabel, /* Grid64 */1);
    case /* Label31x22 */2 :
        return Curry._1(CatalogLabel$Pos.Print.LabelFormat.toLabel, /* Label31x22 */0);
    case /* Label57x19 */3 :
        return Curry._1(CatalogLabel$Pos.Print.LabelFormat.toLabel, /* Label57x19 */1);
    
  }
}

var GlobalLabelFormat = {
  values: values,
  isStarFormat: isStarFormat,
  toLabel: toLabel
};

var make$2 = React.memo(function (Props) {
      var printerStatus = Props.printerStatus;
      var values$1 = Props.values;
      var printOffsetErrorMessage = Props.printOffsetErrorMessage;
      var onChange = Props.onChange;
      var onChangeLabelFormat = Props.onChangeLabelFormat;
      var labelFormat = values$1.labelFormat;
      var maxPrintOffset = Curry._1(CatalogLabel$Pos.Sheet.LabelFormat.toMaxOffset, labelFormat);
      var labelFormat$1 = labelFormat ? /* Grid64 */1 : /* Grid21 */0;
      var renderTemplateSelectionItemContent = React.useCallback((function (item) {
              var templateLabel = toLabel(item.value);
              var starFormat = item.value >= 2;
              var tmp;
              tmp = printerStatus.TAG === /* Ok */0 ? (
                  starFormat ? React.createElement(TooltipIcon$Pos.make, {
                          children: Intl$Pos.t("Format suitable for Star label printers."),
                          variation: "info"
                        }) : null
                ) : (
                  starFormat ? React.createElement(TooltipIcon$Pos.make, {
                          children: Intl$Pos.t("No StarPrinter printers registered in this shop."),
                          variation: "alert"
                        }) : null
                );
              return React.createElement(Inline$Pos.make, {
                          children: null,
                          space: "xsmall"
                        }, templateLabel, tmp);
            }), [printerStatus]);
      var items = Belt_Array.map(values, (function (value) {
              return {
                      key: toLabel(value),
                      value: value,
                      label: toLabel(value),
                      disabled: value >= 2 && Belt_Result.isError(printerStatus)
                    };
            }));
      var tmp = {
        value: values$1.printOffset,
        minValue: 1,
        maxValue: maxPrintOffset,
        precision: 0,
        label: Intl$Pos.t("Offset of the first printed label"),
        required: false,
        onChange: (function (value) {
            Curry._1(onChange, {
                  priceId: values$1.priceId,
                  productBarcodeDisplayed: values$1.productBarcodeDisplayed,
                  missingProductBarcodeGenerated: values$1.missingProductBarcodeGenerated,
                  priceDisplayed: values$1.priceDisplayed,
                  producerDisplayed: values$1.producerDisplayed,
                  alcoholVolumeDisplayed: values$1.alcoholVolumeDisplayed,
                  productCode: values$1.productCode,
                  labelFormat: values$1.labelFormat,
                  printOffset: value | 0,
                  borderEnabled: values$1.borderEnabled,
                  sort: values$1.sort,
                  productValues: values$1.productValues
                });
          })
      };
      if (printOffsetErrorMessage !== undefined) {
        tmp.errorMessage = printOffsetErrorMessage;
      }
      return React.createElement(Card$Pos.make, {
                  children: React.createElement(Stack$Pos.make, {
                        children: null,
                        space: "xlarge"
                      }, React.createElement(Stack$Pos.make, {
                            children: null,
                            space: "medium"
                          }, React.createElement(Select$Pos.make, {
                                label: Intl$Pos.t("Label template"),
                                sections: [{
                                    items: items
                                  }],
                                preset: {
                                  NAME: "inputField",
                                  VAL: {
                                    required: false
                                  }
                                },
                                renderItemContent: renderTemplateSelectionItemContent,
                                value: labelFormat$1,
                                onChange: onChangeLabelFormat
                              }), React.createElement(InputNumberField$Pos.make, tmp)), React.createElement(InfoBlock$Pos.make, {
                            children: React.createElement(Stack$Pos.make, {
                                  children: null,
                                  space: "medium"
                                }, React.createElement(InputToggleSwitchField$Pos.make, {
                                      label: Intl$Pos.t("Display border"),
                                      required: false,
                                      value: values$1.borderEnabled,
                                      onChange: (function (value) {
                                          Curry._1(onChange, {
                                                priceId: values$1.priceId,
                                                productBarcodeDisplayed: values$1.productBarcodeDisplayed,
                                                missingProductBarcodeGenerated: values$1.missingProductBarcodeGenerated,
                                                priceDisplayed: values$1.priceDisplayed,
                                                producerDisplayed: values$1.producerDisplayed,
                                                alcoholVolumeDisplayed: values$1.alcoholVolumeDisplayed,
                                                productCode: values$1.productCode,
                                                labelFormat: values$1.labelFormat,
                                                printOffset: values$1.printOffset,
                                                borderEnabled: value,
                                                sort: values$1.sort,
                                                productValues: values$1.productValues
                                              });
                                        })
                                    }), React.createElement(InputRadioGroupField$Pos.make, {
                                      label: Intl$Pos.t("Sorting labels"),
                                      value: values$1.sort,
                                      required: false,
                                      onChange: (function (value) {
                                          Curry._1(onChange, {
                                                priceId: values$1.priceId,
                                                productBarcodeDisplayed: values$1.productBarcodeDisplayed,
                                                missingProductBarcodeGenerated: values$1.missingProductBarcodeGenerated,
                                                priceDisplayed: values$1.priceDisplayed,
                                                producerDisplayed: values$1.producerDisplayed,
                                                alcoholVolumeDisplayed: values$1.alcoholVolumeDisplayed,
                                                productCode: values$1.productCode,
                                                labelFormat: values$1.labelFormat,
                                                printOffset: values$1.printOffset,
                                                borderEnabled: values$1.borderEnabled,
                                                sort: value,
                                                productValues: values$1.productValues
                                              });
                                        }),
                                      options: CatalogLabel$Pos.Sheet.LabelSort.values,
                                      optionToText: CatalogLabel$Pos.Sheet.LabelSort.toLabel
                                    }), React.createElement(Stack$Pos.make, {
                                      children: null,
                                      space: "normal"
                                    }, React.createElement(TextStyle$Pos.make, {
                                          children: Intl$Pos.t("The printing is done on an A4 laser printer.") + "\n" + Intl$Pos.t("If required, you can order adhesive label sheets or rolls online.") + "\n",
                                          variation: "normal",
                                          size: "small"
                                        }), React.createElement(TextAction$Pos.make, {
                                          text: Intl$Pos.t("Order label holders online."),
                                          highlighted: true,
                                          onPress: (function (param) {
                                              HelpCenter$Pos.showArticle(HelpCenter$Pos.orderLabelSheets);
                                            })
                                        }))),
                            title: Intl$Pos.t("Options")
                          })),
                  title: Intl$Pos.t("Print setup"),
                  stretch: true
                });
    });

var LabelSheet$1 = {
  make: make$2
};

var make$3 = React.memo(function (Props) {
      var printerStatus = Props.printerStatus;
      var settingsOnly = Props.settingsOnly;
      var values$1 = Props.values;
      var onChangeLabelFormat = Props.onChangeLabelFormat;
      var labelFormat = values$1.labelFormat ? /* Label57x19 */3 : /* Label31x22 */2;
      var renderTemplateSelectionItemContent = React.useCallback((function (item) {
              var templateLabel = toLabel(item.value);
              var starFormat = item.value >= 2;
              var tmp;
              tmp = printerStatus.TAG === /* Ok */0 ? (
                  starFormat ? React.createElement(TooltipIcon$Pos.make, {
                          children: Intl$Pos.t("Format suitable for Star label printers."),
                          variation: "info"
                        }) : null
                ) : (
                  starFormat ? React.createElement(TooltipIcon$Pos.make, {
                          children: Intl$Pos.t("No StarPrinter printers registered in this shop."),
                          variation: "alert"
                        }) : null
                );
              return React.createElement(Inline$Pos.make, {
                          children: null,
                          space: "xsmall"
                        }, templateLabel, tmp);
            }), [printerStatus]);
      var items = Belt_Array.map(Belt_Array.keep(values, (function (value) {
                  if (settingsOnly) {
                    if (settingsOnly) {
                      return value >= 2;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                })), (function (value) {
              return {
                      key: toLabel(value),
                      value: value,
                      label: toLabel(value),
                      disabled: value >= 2 && Belt_Result.isError(printerStatus)
                    };
            }));
      return React.createElement(Card$Pos.make, {
                  children: React.createElement(Stack$Pos.make, {
                        children: null,
                        space: "medium"
                      }, React.createElement(Select$Pos.make, {
                            label: Intl$Pos.t("Label template"),
                            sections: [{
                                items: items
                              }],
                            preset: {
                              NAME: "inputField",
                              VAL: {
                                required: false
                              }
                            },
                            renderItemContent: renderTemplateSelectionItemContent,
                            value: labelFormat,
                            onChange: onChangeLabelFormat
                          }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Stack$Pos.make, {
                                        children: null,
                                        space: "medium"
                                      }, React.createElement(Stack$Pos.make, {
                                            children: null,
                                            space: "xsmall"
                                          }, React.createElement(TextStyle$Pos.make, {
                                                children: Intl$Pos.t("Printing is done on a Star mC-Label3 thermal connected printer."),
                                                variation: "normal",
                                                size: "small"
                                              }), React.createElement(TextAction$Pos.make, {
                                                text: Intl$Pos.t("Find out more about the printer."),
                                                highlighted: true,
                                                onPress: (function (param) {
                                                    HelpCenter$Pos.showArticle(HelpCenter$Pos.getLabelPrintFeature);
                                                  })
                                              })), React.createElement(Stack$Pos.make, {
                                            children: null,
                                            space: "xsmall"
                                          }, React.createElement(TextStyle$Pos.make, {
                                                children: Intl$Pos.t("If required, you can order adhesive label sheets or rolls online.") + "\n",
                                                variation: "normal",
                                                size: "small"
                                              }), React.createElement(TextAction$Pos.make, {
                                                text: Intl$Pos.t("Order label holders online."),
                                                highlighted: true,
                                                onPress: (function (param) {
                                                    HelpCenter$Pos.showArticle(HelpCenter$Pos.orderLabelSheets);
                                                  })
                                              })))), undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))),
                  title: Intl$Pos.t("Print setup"),
                  stretch: true
                });
    });

var LabelPrint$1 = {
  make: make$3
};

var CatalogLabelCreatePrintSettingsCard = {
  GlobalLabelFormat: GlobalLabelFormat,
  LabelSheet: LabelSheet$1,
  LabelPrint: LabelPrint$1
};

var style = Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "#f8f8fb", "#e7e7ee", undefined, undefined, undefined, undefined, 1, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "solid", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "center", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(23)), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

var make$4 = React.memo(function (Props) {
      var message = Props.message;
      var errorMessage = Props.errorMessage;
      return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(null), Caml_option.some(style), undefined, undefined, undefined), React.createElement(TextStyle$Pos.make, {
                      children: message,
                      variation: "normal",
                      size: "small"
                    }), errorMessage !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                    children: errorMessage,
                                    variation: "negative",
                                    size: "xsmall"
                                  })), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null);
    });

var TableEmptyState = {
  style: style,
  make: make$4
};

function reducer$1(state, action) {
  if (typeof action === "number") {
    if (action === /* Reset */0) {
      return /* Picked */{
              _0: []
            };
    } else {
      return /* All */0;
    }
  }
  switch (action.TAG | 0) {
    case /* RowsProductsAdded */0 :
        if (!state) {
          return /* All */0;
        }
        var currentProducts = state._0;
        var filteredAddedProducts = Belt_Array.keep(action._0, (function (addedProduct) {
                return !Belt_Array.some(currentProducts, (function (currentProduct) {
                              return currentProduct.productVariantId === addedProduct.productVariantId;
                            }));
              }));
        return /* Picked */{
                _0: Belt_Array.concat(currentProducts, filteredAddedProducts)
              };
    case /* RowProductUpdated */1 :
        if (!state) {
          return /* All */0;
        }
        var printingQuantity = action._1;
        var updatedProductId = action._0;
        var updatedProducts = Belt_Array.map(state._0, (function (currentProduct) {
                if (currentProduct.productVariantId === updatedProductId) {
                  return {
                          productVariantId: currentProduct.productVariantId,
                          printingQuantity: printingQuantity,
                          productInformation: currentProduct.productInformation
                        };
                } else {
                  return currentProduct;
                }
              }));
        return /* Picked */{
                _0: updatedProducts
              };
    case /* RowProductRemoved */2 :
        if (!state) {
          return /* All */0;
        }
        var removedProductId = action._0;
        var updatedProducts$1 = Belt_Array.keep(state._0, (function (currentProduct) {
                return currentProduct.productVariantId !== removedProductId;
              }));
        return /* Picked */{
                _0: updatedProducts$1
              };
    
  }
}

function makeTableColumns(dispatch, hasProductsAllPicked) {
  return [
          {
            key: "line",
            name: Intl$Pos.t("Line"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 65
              },
              width: {
                NAME: "px",
                VAL: 65
              }
            },
            render: (function (param) {
                var value = param.index + 1 | 0;
                var string = String(value);
                var length = 4 - string.length | 0;
                var leading = $$String.make(length, /* '0' */48);
                var formattedLineNumber = leading + string;
                return React.createElement(TextStyle$Pos.make, {
                            children: formattedLineNumber,
                            variation: "normal",
                            size: "xsmall"
                          });
              })
          },
          {
            key: "reference",
            name: Intl$Pos.t("Product"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 250
              },
              width: {
                NAME: "px",
                VAL: 450
              }
            },
            render: (function (param) {
                var match = param.data;
                var match$1 = match.productInformation;
                return React.createElement(LegacyProductReferenceTableCell$Pos.make, {
                            id: match.productVariantId,
                            name: match$1.name,
                            description: match$1.description,
                            openNewTab: true
                          });
              })
          },
          {
            key: "printing-quantity",
            name: Intl$Pos.t("Copies"),
            layout: {
              hidden: hasProductsAllPicked,
              width: {
                NAME: "px",
                VAL: 80
              }
            },
            render: (function (param) {
                var match = param.data;
                var productVariantId = match.productVariantId;
                return React.createElement(InputNumberField$Pos.make, {
                            value: match.printingQuantity,
                            minValue: 1,
                            precision: 0,
                            onChange: (function (value) {
                                Curry._1(dispatch, {
                                      TAG: /* RowProductUpdated */1,
                                      _0: productVariantId,
                                      _1: value | 0
                                    });
                              })
                          });
              })
          },
          {
            key: "actions",
            render: (function (param) {
                var productVariantId = param.data.productVariantId;
                return React.createElement(Columns$Pos.make, {
                            children: React.createElement(Column$Pos.make, {
                                  children: React.createElement(RoundButton$Pos.make, RoundButton$Pos.makeProps(undefined, "delete_light", undefined, undefined, undefined, (function (param) {
                                              Curry._1(dispatch, hasProductsAllPicked ? /* Reset */0 : ({
                                                        TAG: /* RowProductRemoved */2,
                                                        _0: productVariantId
                                                      }));
                                            }), undefined, undefined, undefined)),
                                  width: "fluid"
                                }),
                            align: "end"
                          });
              })
          }
        ];
}

function CatalogLabelCreatePage$CatalogLabelCreateProductsCard$AddProductModalButton(Props) {
  var onRequestModalOpen = Props.onRequestModalOpen;
  var match = Hover$Pos.use(undefined, undefined);
  return React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(OverlayTriggerView$Pos.make, {
                      children: React.createElement(TextStyle$Pos.make, {
                            children: Intl$Pos.t("Add product"),
                            variation: "normal"
                          }),
                      preset: {
                        NAME: "inputField",
                        VAL: {
                          required: false
                        }
                      },
                      icon: "search",
                      hovered: match[1]
                    }), undefined, undefined, undefined, undefined, undefined, (function (param) {
                    Curry._1(onRequestModalOpen, undefined);
                  }), undefined, match[0], undefined));
}

var AddProductModalButton = {
  make: CatalogLabelCreatePage$CatalogLabelCreateProductsCard$AddProductModalButton
};

var make$5 = React.memo(function (Props) {
      var shopId = Props.shopId;
      var priceId = Props.priceId;
      var printMode = Props.printMode;
      var productsTotalCount = Props.productsTotalCount;
      var products = Props.products;
      var errorMessage = Props.errorMessage;
      var onChange = Props.onChange;
      var match = React.useState(function () {
            return false;
          });
      var setPickerOpened = match[1];
      var dispatch = function (action) {
        Curry._1(onChange, reducer$1(products, action));
      };
      React.useEffect((function () {
              if (printMode && !products) {
                Curry._1(onChange, /* Picked */{
                      _0: []
                    });
              }
              
            }), [printMode]);
      var hasProductsAllPicked = products ? false : true;
      var columns = makeTableColumns(dispatch, hasProductsAllPicked);
      var onPickProducts = function (products$1) {
        var rows = Belt_Array.map(products$1, (function (product) {
                return {
                        productVariantId: product.id,
                        printingQuantity: 1,
                        productInformation: {
                          name: product.formattedName,
                          description: CatalogProduct$Pos.Information.formateDescription(product.productKind, product.information, true, undefined),
                          internalCode: product.information.internalCode,
                          stockKeepingUnit: product.information.sku
                        }
                      };
              }));
        Curry._1(onChange, reducer$1(products, {
                  TAG: /* RowsProductsAdded */0,
                  _0: rows
                }));
      };
      var tooManyProductsPicked = productsTotalCount > 1000;
      var pickAllProductsButtonDisabled = printMode || tooManyProductsPicked || hasProductsAllPicked;
      var resetButtonDisabled = products ? products._0.length === 0 : false;
      var tableProductsRows = products ? products._0 : [{
            productVariantId: "all",
            printingQuantity: 0,
            productInformation: {
              name: String(productsTotalCount) + " " + Intl$Pos.t("added products"),
              description: Intl$Pos.t("All non-archived catalog products"),
              internalCode: undefined,
              stockKeepingUnit: undefined
            }
          }];
      var pickerModalProductsDisabledIds = products ? Belt_Array.map(products._0, (function (row) {
                return row.productVariantId;
              })) : [];
      var tmp = {
        message: printMode ? Intl$Pos.t("Add products to print labels") : Intl$Pos.t("Add products to generate a PDF sheet of labels")
      };
      if (errorMessage !== undefined) {
        tmp.errorMessage = Caml_option.valFromOption(errorMessage);
      }
      return React.createElement(React.Fragment, undefined, React.createElement(Card$Pos.make, {
                      children: null,
                      variation: "table"
                    }, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Columns$Pos.make, {
                                      children: null,
                                      space: "xlarge"
                                    }, React.createElement(Column$Pos.make, {
                                          children: React.createElement(CatalogLabelCreatePage$CatalogLabelCreateProductsCard$AddProductModalButton, {
                                                onRequestModalOpen: (function (param) {
                                                    Curry._1(setPickerOpened, (function (param) {
                                                            return true;
                                                          }));
                                                  })
                                              }),
                                          width: "half"
                                        }), React.createElement(Column$Pos.make, {
                                          children: React.createElement(Inline$Pos.make, {
                                                children: null
                                              }, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextIconButton$Pos.make, TextIconButton$Pos.makeProps(Intl$Pos.t("Add all products"), undefined, undefined, pickAllProductsButtonDisabled, "bordered_plus_light", printMode ? Intl$Pos.t("This feature only works for PDF sheet of labels generation.") : (
                                                                      tooManyProductsPicked ? Intl$Pos.t("This feature only works for catalogs\nwith less than 1,000 products") : undefined
                                                                    ), (function (param) {
                                                                      Curry._1(onChange, /* All */0);
                                                                    }), undefined, undefined, undefined))), undefined, undefined, "small", undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined)), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextIconButton$Pos.make, TextIconButton$Pos.makeProps(Intl$Pos.t("Reset"), undefined, undefined, resetButtonDisabled, "delete_light", undefined, (function (param) {
                                                                      Curry._1(onChange, /* Picked */{
                                                                            _0: []
                                                                          });
                                                                    }), undefined, undefined, undefined))), undefined, undefined, "small", undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined))),
                                          width: "half"
                                        }))), "large", undefined, undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined)), React.createElement(TableView$Pos.make, {
                          data: {
                            TAG: /* Done */1,
                            _0: {
                              TAG: /* Ok */0,
                              _0: tableProductsRows
                            }
                          },
                          columns: columns,
                          keyExtractor: (function (row) {
                              return row.productVariantId;
                            }),
                          hideCard: true,
                          placeholderEmptyState: React.createElement(make$4, tmp)
                        })), React.createElement(CatalogLabelProductsPickerModalTable$Pos.make, {
                      opened: match[0],
                      shopId: shopId,
                      priceId: priceId,
                      disabledIds: pickerModalProductsDisabledIds,
                      onCommit: onPickProducts,
                      onRequestClose: (function (param) {
                          Curry._1(setPickerOpened, (function (param) {
                                  return false;
                                }));
                        })
                    }));
    });

var CatalogLabelCreateProductsCard = {
  TableEmptyState: TableEmptyState,
  reducer: reducer$1,
  makeTableColumns: makeTableColumns,
  AddProductModalButton: AddProductModalButton,
  make: make$5
};

function formateDetails(productValues, invalidBarcodesVariants) {
  if (!productValues) {
    return Belt_Array.map(invalidBarcodesVariants, (function (variant) {
                  return variant.productName + " - " + variant.name;
                }));
  }
  var items = productValues._0;
  return Belt_Array.keepMap(invalidBarcodesVariants, (function (variant) {
                var match = Belt_Array.getBy(items, (function (item) {
                        return item.productVariantId === variant.id;
                      }));
                if (match === undefined) {
                  return ;
                }
                var match$1 = match.productInformation;
                var stockKeepingUnit = match$1.stockKeepingUnit;
                var name = match$1.name;
                if (stockKeepingUnit !== undefined) {
                  return "" + name + " (" + stockKeepingUnit + ")";
                } else {
                  return name;
                }
              }));
}

var make$6 = React.memo(function (Props) {
      var productValues = Props.productValues;
      var invalidBarcodesVariants = Props.invalidBarcodesVariants;
      var onRequestClose = Props.onRequestClose;
      var details = formateDetails(productValues, invalidBarcodesVariants);
      if (details.length !== 0) {
        return React.createElement(Banner$Pos.make, {
                    textStatus: {
                      TAG: /* Warning */2,
                      _0: Intl$Pos.t("The label sheet has been generated, some incorrect barcodes cannot be displayed.")
                    },
                    details: details,
                    onRequestClose: onRequestClose
                  });
      } else {
        return null;
      }
    });

var CatalogLabelCreatePageInvalidBarcodesBanner = {
  formateDetails: formateDetails,
  make: make$6
};

var make$7 = React.memo(function (Props) {
      var firstPersistence = Props.firstPersistence;
      var shop = Props.shop;
      var organisation = Props.organisation;
      var printerStatus = Props.printerStatus;
      var productsTotalCount = Props.productsTotalCount;
      var initialState = Props.initialState;
      var match = React.useState(function () {
            
          });
      var setNotification = match[1];
      var notification = match[0];
      var match$1 = React.useState(function () {
            
          });
      var setInvalidBarcodes = match$1[1];
      var invalidBarcodes = match$1[0];
      var match$2 = React.useReducer(reducer, initialState);
      var dispatch = match$2[1];
      var state = match$2[0];
      var navigate = Navigation$Pos.useNavigate(undefined);
      var url = Navigation$Pos.useUrl(undefined);
      var getPriceName = function (priceId) {
        return Belt_Option.mapWithDefault(Belt_Array.getBy(state.priceList, (function (price) {
                          return price.id === priceId;
                        })), "", (function (price) {
                      return price.name;
                    }));
      };
      var match$3 = state.form;
      var persisted;
      if (match$3.TAG === /* LabelSheet */0) {
        var values = match$3._0.values;
        persisted = CatalogLabel$Pos.StateSettingsStorage.usePersistOnChange(isErrored(state), values.priceId, getPriceName(values.priceId), values.productBarcodeDisplayed, values.missingProductBarcodeGenerated, values.priceDisplayed, values.producerDisplayed, values.alcoholVolumeDisplayed, values.productCode, {
              TAG: /* LabelSheet */0,
              _0: values.labelFormat
            }, values.printOffset, values.borderEnabled, values.sort);
      } else {
        var match$4 = match$3._0;
        if (match$4.settingsOnly !== undefined) {
          persisted = false;
        } else {
          var values$1 = match$4.values;
          persisted = CatalogLabel$Pos.StateSettingsStorage.usePersistOnChange(isErrored(state), values$1.priceId, getPriceName(values$1.priceId), values$1.productBarcodeDisplayed, values$1.missingProductBarcodeGenerated, values$1.priceDisplayed, values$1.producerDisplayed, values$1.alcoholVolumeDisplayed, values$1.productCode, {
                TAG: /* LabelPrint */1,
                _0: values$1.labelFormat
              }, 1, false, /* Unsorted */0);
        }
      }
      var initialStateSettingsBanner = firstPersistence && !isSettingsOnlyMode(state) ? React.createElement(Banner$Pos.make, {
              textStatus: {
                TAG: /* Info */3,
                _0: Intl$Pos.t("Don't worry! Print settings are automatically saved on the browser from which you configure them.")
              }
            }) : null;
      var text = persisted ? "Settings saved on your web browser, a few moments ago." : "Settings unsaved.";
      var color = persisted ? "#1dbb7a" : "#a2a1b0";
      var persistedSettingsIconTooltip = React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Tooltip$Pos.make, {
                        children: React.createElement(Icon$Pos.make, {
                              name: "cloud_save",
                              fill: color
                            }),
                        arrowed: false,
                        placement: "top start",
                        content: {
                          TAG: /* Text */0,
                          _0: Intl$Pos.t(text)
                        },
                        immediateClose: true
                      })), Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.some(Style$Pos.dp(-7)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "absolute", undefined, undefined, Caml_option.some(Style$Pos.dp(-21)), undefined, undefined, undefined)), undefined, undefined, undefined));
      React.useEffect((function () {
              var requestBarcodeCompletion = CatalogLabel$Pos.BarcodeCompletionRequest.make;
              if (isErrored(state)) {
                return ;
              }
              var match = state.form;
              if (match.TAG === /* LabelSheet */0) {
                var match$1 = match._0;
                var values = match$1.values;
                if (AsyncResult$Pos.isIdle(match$1.submitResult)) {
                  return ;
                }
                var requestLabelsGenerating = CatalogLabel$Pos.Sheet.LabelsRequest.make;
                var combineSheetRequests = CatalogLabel$Pos.Sheet.CombineRequests.make;
                var products = values.productValues;
                var future = Curry.app(combineSheetRequests, [
                      requestBarcodeCompletion,
                      requestLabelsGenerating,
                      products ? /* Picked */({
                            _0: Belt_Array.map(products._0, (function (product) {
                                    return {
                                            variantId: product.productVariantId,
                                            repetitions: product.printingQuantity
                                          };
                                  }))
                          }) : /* All */0,
                      values.priceId,
                      values.missingProductBarcodeGenerated,
                      values.productBarcodeDisplayed,
                      values.priceDisplayed,
                      values.producerDisplayed,
                      values.alcoholVolumeDisplayed,
                      values.productCode,
                      values.labelFormat,
                      values.printOffset,
                      values.borderEnabled,
                      values.sort,
                      shop.id
                    ]);
                Future.get(Future.map(future, undefined, (function (result) {
                            var tmp;
                            if (result.TAG === /* Ok */0) {
                              var url = result._0;
                              tmp = url !== undefined ? ({
                                    TAG: /* Ok */0,
                                    _0: url
                                  }) : ({
                                    TAG: /* Error */1,
                                    _0: Request$Pos.serverErrorMessage
                                  });
                            } else {
                              tmp = {
                                TAG: /* Error */1,
                                _0: result._0
                              };
                            }
                            return AsyncResult$Pos.done(tmp);
                          })), (function (requestResult) {
                        Curry._1(dispatch, {
                              TAG: /* GenerateLabelsSubmitted */2,
                              _0: requestResult
                            });
                      }));
                return (function (param) {
                          Future.cancel(future);
                        });
              }
              var match$2 = match._0;
              var match$3 = match$2.settingsOnly;
              if (match$3 !== undefined) {
                var match$4 = match$2.submitResult;
                if (typeof match$4 !== "number") {
                  return ;
                }
                if (match$4 === 0) {
                  return ;
                }
                var values$1 = match$2.values;
                var fromPathname = match$3.fromPathname;
                var editRedirectionQueryString = CatalogRoutes$Pos.CatalogLabelQueryString.encode({
                      variantIdfromLabelEditSettings: match$3.variantIdFromCatalogRedirection,
                      fromPathname: url.pathname
                    });
                CatalogLabel$Pos.StateSettingsStorage.store(values$1.priceId, getPriceName(values$1.priceId), values$1.productBarcodeDisplayed, values$1.missingProductBarcodeGenerated, values$1.priceDisplayed, values$1.producerDisplayed, values$1.alcoholVolumeDisplayed, values$1.productCode, {
                      TAG: /* LabelPrint */1,
                      _0: values$1.labelFormat
                    }, 1, false, /* Unsorted */0);
                if (fromPathname !== url.pathname) {
                  Curry._3(navigate, undefined, undefined, fromPathname + "?" + editRedirectionQueryString);
                } else {
                  Curry._3(navigate, undefined, undefined, CatalogRoutes$Pos.baseRoute);
                }
                return ;
              }
              var values$2 = match$2.values;
              if (AsyncResult$Pos.isIdle(match$2.submitResult)) {
                return ;
              }
              var requestLabelsPrinting = CatalogLabel$Pos.Print.LabelsRequest.make;
              var combinePrintRequests = CatalogLabel$Pos.Print.CombineRequests.make;
              var products$1 = values$2.productValues;
              var future$1 = Curry.app(combinePrintRequests, [
                    requestBarcodeCompletion,
                    requestLabelsPrinting,
                    values$2.priceId,
                    getPriceName(values$2.priceId),
                    values$2.productCode,
                    values$2.productBarcodeDisplayed,
                    products$1 ? /* Picked */({
                          _0: Belt_Array.map(products$1._0, (function (product) {
                                  return {
                                          variantId: product.productVariantId,
                                          repetitions: product.printingQuantity
                                        };
                                }))
                        }) : /* All */0,
                    values$2.labelFormat,
                    values$2.missingProductBarcodeGenerated,
                    values$2.priceDisplayed,
                    values$2.producerDisplayed,
                    values$2.alcoholVolumeDisplayed,
                    shop.id
                  ]);
              Future.get(Future.map(future$1, undefined, (function (result) {
                          var tmp;
                          tmp = result.TAG === /* Ok */0 ? ({
                                TAG: /* Ok */0,
                                _0: undefined
                              }) : ({
                                TAG: /* Error */1,
                                _0: result._0
                              });
                          return AsyncResult$Pos.done(tmp);
                        })), (function (requestResult) {
                      Curry._1(dispatch, {
                            TAG: /* PrintLabelsSubmitted */3,
                            _0: requestResult
                          });
                    }));
              return (function (param) {
                        Future.cancel(future$1);
                      });
            }), [state.form]);
      React.useEffect((function () {
              var match = state.form;
              var exit = 0;
              var errorMessage;
              if (match.TAG === /* LabelSheet */0) {
                var match$1 = match._0.submitResult;
                if (typeof match$1 !== "number" && match$1.TAG === /* Done */1) {
                  var errorMessage$1 = match$1._0;
                  if (errorMessage$1.TAG === /* Ok */0) {
                    var match$2 = errorMessage$1._0;
                    var invalidBarcodesVariants = match$2.invalidBarcodesVariants;
                    if (invalidBarcodesVariants.length !== 0) {
                      Curry._1(setInvalidBarcodes, (function (param) {
                              return invalidBarcodesVariants;
                            }));
                    } else {
                      Curry._1(setInvalidBarcodes, (function (param) {
                              
                            }));
                    }
                    Future.get(Future.mapError(Future.mapOk(TriggerDownload$Pos.fromUrl(undefined, match$2.url), undefined, (function (param) {
                                    return Intl$Pos.t("The label sheet has been successfully generated.");
                                  })), undefined, (function (param) {
                                return Intl$Pos.t("An issue when attempting downloading the file occurred.");
                              })), (function (message) {
                            if (invalidBarcodesVariants.length === 0) {
                              return Curry._1(setNotification, (function (param) {
                                            return message;
                                          }));
                            }
                            
                          }));
                  } else {
                    errorMessage = errorMessage$1._0;
                    exit = 1;
                  }
                }
                
              } else {
                var match$3 = match._0.submitResult;
                if (typeof match$3 !== "number" && match$3.TAG === /* Done */1) {
                  var errorMessage$2 = match$3._0;
                  if (errorMessage$2.TAG === /* Ok */0) {
                    Curry._1(setNotification, (function (param) {
                            return {
                                    TAG: /* Ok */0,
                                    _0: Intl$Pos.t("Label printing is in progress.") + Intl$Pos.template(Intl$Pos.t("[Monitor printing from your StarPrinter Online account]({{link}})"), {
                                          link: "https://portal.starprinter.online/Dashboard"
                                        }, undefined)
                                  };
                          }));
                    Curry._1(setInvalidBarcodes, (function (param) {
                            
                          }));
                  } else {
                    errorMessage = errorMessage$2._0;
                    exit = 1;
                  }
                }
                
              }
              if (exit === 1) {
                Curry._1(setNotification, (function (param) {
                        return {
                                TAG: /* Error */1,
                                _0: errorMessage
                              };
                      }));
                Curry._1(setInvalidBarcodes, (function (param) {
                        
                      }));
              }
              
            }), [state.form]);
      React.useEffect((function () {
              var match = state.form;
              if (match.TAG !== /* LabelSheet */0 && printerStatus.TAG !== /* Ok */0) {
                Curry._1(setNotification, (function (param) {
                        return {
                                TAG: /* Error */1,
                                _0: Intl$Pos.t("No StarPrinter printers registered in this shop.")
                              };
                      }));
              }
              
            }), [printerStatus]);
      var match$5 = state.form;
      var submissionLoading;
      submissionLoading = match$5.TAG === /* LabelSheet */0 ? AsyncResult$Pos.isBusy(match$5._0.submitResult) : AsyncResult$Pos.isBusy(match$5._0.submitResult);
      var pageTitle = isSettingsOnlyMode(state) ? Intl$Pos.t("Set labels settings") : Intl$Pos.t("Print the labels");
      var pageSubtitle = organisation ? shop.name : undefined;
      var printMode = isPrintMode(state);
      var settingsOnlyMode = isSettingsOnlyMode(state);
      var match$6 = printMode ? (
          settingsOnlyMode ? [
              /* SaveSettingsSubmitted */0,
              Intl$Pos.t("Save settings"),
              "success"
            ] : [
              {
                TAG: /* PrintLabelsSubmitted */3,
                _0: /* Loading */1
              },
              Intl$Pos.t("Print labels"),
              "primary"
            ]
        ) : [
          {
            TAG: /* GenerateLabelsSubmitted */2,
            _0: /* Loading */1
          },
          Intl$Pos.t("Generate labels"),
          "primary"
        ];
      var action = match$6[0];
      var actionsBar = React.createElement(Button$Pos.make, Button$Pos.makeProps(match$6[1], undefined, match$6[2], undefined, undefined, submissionLoading, undefined, undefined, undefined, undefined, (function (param) {
                  Curry._1(dispatch, action);
                }), undefined, undefined, undefined));
      var notificationBanner;
      if (notification !== undefined) {
        var onRequestClose = function (param) {
          Curry._1(setNotification, (function (param) {
                  
                }));
        };
        notificationBanner = React.createElement(ResourceDetailsPage$Pos.NotificationBanner.make, {
              value: notification,
              onRequestClose: onRequestClose
            });
      } else {
        notificationBanner = null;
      }
      var match$7 = state.form;
      var invalidBarcodesBanner;
      if (match$7.TAG === /* LabelSheet */0 && invalidBarcodes !== undefined) {
        var onRequestClose$1 = function (param) {
          Curry._1(setInvalidBarcodes, (function (param) {
                  
                }));
        };
        invalidBarcodesBanner = React.createElement(make$6, {
              productValues: match$7._0.values.productValues,
              invalidBarcodesVariants: invalidBarcodes,
              onRequestClose: onRequestClose$1
            });
      } else {
        invalidBarcodesBanner = null;
      }
      var match$8 = state.form;
      var tmp;
      tmp = match$8.TAG === /* LabelSheet */0 ? React.createElement(make, {
              priceList: state.priceList,
              values: match$8._0.values,
              onChange: (function (values) {
                  Curry._1(dispatch, {
                        TAG: /* LabelSheetEdited */0,
                        _0: values
                      });
                })
            }) : React.createElement(make$1, {
              priceList: state.priceList,
              values: match$8._0.values,
              onChange: (function (values) {
                  Curry._1(dispatch, {
                        TAG: /* LabelPrintEdited */1,
                        _0: values
                      });
                })
            });
      var match$9 = state.form;
      var tmp$1;
      if (match$9.TAG === /* LabelSheet */0) {
        var match$10 = match$9._0;
        var values$2 = match$10.values;
        tmp$1 = React.createElement(make$2, {
              printerStatus: printerStatus,
              values: values$2,
              printOffsetErrorMessage: match$10.errorMessages.printOffset,
              onChange: (function (values) {
                  Curry._1(dispatch, {
                        TAG: /* LabelSheetEdited */0,
                        _0: values
                      });
                }),
              onChangeLabelFormat: (function (labelFormat) {
                  var tmp;
                  switch (labelFormat) {
                    case /* Grid21 */0 :
                        tmp = {
                          TAG: /* LabelSheetEdited */0,
                          _0: {
                            priceId: values$2.priceId,
                            productBarcodeDisplayed: values$2.productBarcodeDisplayed,
                            missingProductBarcodeGenerated: values$2.missingProductBarcodeGenerated,
                            priceDisplayed: values$2.priceDisplayed,
                            producerDisplayed: values$2.producerDisplayed,
                            alcoholVolumeDisplayed: values$2.alcoholVolumeDisplayed,
                            productCode: values$2.productCode,
                            labelFormat: /* Grid21 */0,
                            printOffset: values$2.printOffset,
                            borderEnabled: values$2.borderEnabled,
                            sort: values$2.sort,
                            productValues: values$2.productValues
                          }
                        };
                        break;
                    case /* Grid64 */1 :
                        tmp = {
                          TAG: /* LabelSheetEdited */0,
                          _0: {
                            priceId: values$2.priceId,
                            productBarcodeDisplayed: values$2.productBarcodeDisplayed,
                            missingProductBarcodeGenerated: values$2.missingProductBarcodeGenerated,
                            priceDisplayed: values$2.priceDisplayed,
                            producerDisplayed: values$2.producerDisplayed,
                            alcoholVolumeDisplayed: values$2.alcoholVolumeDisplayed,
                            productCode: values$2.productCode,
                            labelFormat: /* Grid64 */1,
                            printOffset: values$2.printOffset,
                            borderEnabled: values$2.borderEnabled,
                            sort: values$2.sort,
                            productValues: values$2.productValues
                          }
                        };
                        break;
                    case /* Label31x22 */2 :
                        var init = toLabelPrintFormValues(values$2);
                        tmp = {
                          TAG: /* LabelPrintEdited */1,
                          _0: {
                            priceId: init.priceId,
                            productBarcodeDisplayed: init.productBarcodeDisplayed,
                            missingProductBarcodeGenerated: init.missingProductBarcodeGenerated,
                            priceDisplayed: init.priceDisplayed,
                            producerDisplayed: init.producerDisplayed,
                            alcoholVolumeDisplayed: init.alcoholVolumeDisplayed,
                            productCode: init.productCode,
                            labelFormat: /* Label31x22 */0,
                            productValues: init.productValues
                          }
                        };
                        break;
                    case /* Label57x19 */3 :
                        var init$1 = toLabelPrintFormValues(values$2);
                        tmp = {
                          TAG: /* LabelPrintEdited */1,
                          _0: {
                            priceId: init$1.priceId,
                            productBarcodeDisplayed: init$1.productBarcodeDisplayed,
                            missingProductBarcodeGenerated: init$1.missingProductBarcodeGenerated,
                            priceDisplayed: init$1.priceDisplayed,
                            producerDisplayed: init$1.producerDisplayed,
                            alcoholVolumeDisplayed: init$1.alcoholVolumeDisplayed,
                            productCode: init$1.productCode,
                            labelFormat: /* Label57x19 */1,
                            productValues: init$1.productValues
                          }
                        };
                        break;
                    
                  }
                  Curry._1(dispatch, tmp);
                })
            });
      } else {
        var match$11 = match$9._0;
        var values$3 = match$11.values;
        tmp$1 = React.createElement(make$3, {
              printerStatus: printerStatus,
              settingsOnly: Belt_Option.isSome(match$11.settingsOnly),
              values: values$3,
              onChangeLabelFormat: (function (labelFormat) {
                  switch (labelFormat) {
                    case /* Grid21 */0 :
                        var init = toLabelSheetFormValues(values$3);
                        return Curry._1(dispatch, {
                                    TAG: /* LabelSheetEdited */0,
                                    _0: {
                                      priceId: init.priceId,
                                      productBarcodeDisplayed: init.productBarcodeDisplayed,
                                      missingProductBarcodeGenerated: init.missingProductBarcodeGenerated,
                                      priceDisplayed: init.priceDisplayed,
                                      producerDisplayed: init.producerDisplayed,
                                      alcoholVolumeDisplayed: init.alcoholVolumeDisplayed,
                                      productCode: init.productCode,
                                      labelFormat: /* Grid21 */0,
                                      printOffset: init.printOffset,
                                      borderEnabled: init.borderEnabled,
                                      sort: init.sort,
                                      productValues: init.productValues
                                    }
                                  });
                    case /* Grid64 */1 :
                        var init$1 = toLabelSheetFormValues(values$3);
                        return Curry._1(dispatch, {
                                    TAG: /* LabelSheetEdited */0,
                                    _0: {
                                      priceId: init$1.priceId,
                                      productBarcodeDisplayed: init$1.productBarcodeDisplayed,
                                      missingProductBarcodeGenerated: init$1.missingProductBarcodeGenerated,
                                      priceDisplayed: init$1.priceDisplayed,
                                      producerDisplayed: init$1.producerDisplayed,
                                      alcoholVolumeDisplayed: init$1.alcoholVolumeDisplayed,
                                      productCode: init$1.productCode,
                                      labelFormat: /* Grid64 */1,
                                      printOffset: init$1.printOffset,
                                      borderEnabled: init$1.borderEnabled,
                                      sort: init$1.sort,
                                      productValues: init$1.productValues
                                    }
                                  });
                    case /* Label31x22 */2 :
                        return Curry._1(dispatch, {
                                    TAG: /* LabelPrintEdited */1,
                                    _0: {
                                      priceId: values$3.priceId,
                                      productBarcodeDisplayed: values$3.productBarcodeDisplayed,
                                      missingProductBarcodeGenerated: values$3.missingProductBarcodeGenerated,
                                      priceDisplayed: values$3.priceDisplayed,
                                      producerDisplayed: values$3.producerDisplayed,
                                      alcoholVolumeDisplayed: values$3.alcoholVolumeDisplayed,
                                      productCode: values$3.productCode,
                                      labelFormat: /* Label31x22 */0,
                                      productValues: values$3.productValues
                                    }
                                  });
                    case /* Label57x19 */3 :
                        return Curry._1(dispatch, {
                                    TAG: /* LabelPrintEdited */1,
                                    _0: {
                                      priceId: values$3.priceId,
                                      productBarcodeDisplayed: values$3.productBarcodeDisplayed,
                                      missingProductBarcodeGenerated: values$3.missingProductBarcodeGenerated,
                                      priceDisplayed: values$3.priceDisplayed,
                                      producerDisplayed: values$3.producerDisplayed,
                                      alcoholVolumeDisplayed: values$3.alcoholVolumeDisplayed,
                                      productCode: values$3.productCode,
                                      labelFormat: /* Label57x19 */1,
                                      productValues: values$3.productValues
                                    }
                                  });
                    
                  }
                })
            });
      }
      var match$12 = state.form;
      var tmp$2;
      if (match$12.TAG === /* LabelSheet */0) {
        var match$13 = match$12._0;
        var values$4 = match$13.values;
        tmp$2 = React.createElement(make$5, {
              shopId: shop.id,
              priceId: values$4.priceId,
              printMode: false,
              productsTotalCount: productsTotalCount,
              products: values$4.productValues,
              errorMessage: isErrored(state) ? match$13.errorMessages.productValues : undefined,
              onChange: (function (products) {
                  Curry._1(dispatch, {
                        TAG: /* LabelSheetEdited */0,
                        _0: {
                          priceId: values$4.priceId,
                          productBarcodeDisplayed: values$4.productBarcodeDisplayed,
                          missingProductBarcodeGenerated: values$4.missingProductBarcodeGenerated,
                          priceDisplayed: values$4.priceDisplayed,
                          producerDisplayed: values$4.producerDisplayed,
                          alcoholVolumeDisplayed: values$4.alcoholVolumeDisplayed,
                          productCode: values$4.productCode,
                          labelFormat: values$4.labelFormat,
                          printOffset: values$4.printOffset,
                          borderEnabled: values$4.borderEnabled,
                          sort: values$4.sort,
                          productValues: products
                        }
                      });
                })
            });
      } else {
        var match$14 = match$12._0;
        if (match$14.settingsOnly !== undefined) {
          tmp$2 = null;
        } else {
          var values$5 = match$14.values;
          tmp$2 = React.createElement(make$5, {
                shopId: shop.id,
                priceId: values$5.priceId,
                printMode: true,
                productsTotalCount: productsTotalCount,
                products: values$5.productValues,
                errorMessage: isErrored(state) ? match$14.errorMessages.productValues : undefined,
                onChange: (function (products) {
                    Curry._1(dispatch, {
                          TAG: /* LabelPrintEdited */1,
                          _0: {
                            priceId: values$5.priceId,
                            productBarcodeDisplayed: values$5.productBarcodeDisplayed,
                            missingProductBarcodeGenerated: values$5.missingProductBarcodeGenerated,
                            priceDisplayed: values$5.priceDisplayed,
                            producerDisplayed: values$5.producerDisplayed,
                            alcoholVolumeDisplayed: values$5.alcoholVolumeDisplayed,
                            productCode: values$5.productCode,
                            labelFormat: values$5.labelFormat,
                            productValues: products
                          }
                        });
                  })
              });
        }
      }
      var tmp$3 = {
        variation: isSettingsOnlyMode(state) ? "compact" : "standard",
        title: pageTitle,
        actionsBar: actionsBar,
        notificationBanner: notificationBanner,
        statusBadge: persistedSettingsIconTooltip,
        children: React.createElement(Stack$Pos.make, {
              children: null,
              space: "medium"
            }, invalidBarcodesBanner, initialStateSettingsBanner, React.createElement(Stack$Pos.make, {
                  children: null,
                  space: "medium"
                }, React.createElement(Columns$Pos.make, {
                      children: null,
                      space: "medium"
                    }, React.createElement(Column$Pos.make, {
                          children: tmp,
                          width: "half",
                          height: "fluid"
                        }), React.createElement(Column$Pos.make, {
                          children: tmp$1,
                          width: "half",
                          height: "fluid"
                        })), tmp$2))
      };
      if (pageSubtitle !== undefined) {
        tmp$3.subtitle = pageSubtitle;
      }
      return React.createElement(ResourceDetailsPage$Pos.make, tmp$3);
    });

var CatalogLabelCreatePageLoaded = {
  make: make$7
};

var Raw = {};

var query = Client.gql(["query PricesQuery($filterBy: InputPricesQueryFilter)  {\nprices(first: 50, filterBy: $filterBy)  {\n__typename  \npageInfo  {\n__typename  \nendCursor  \nhasNextPage  \n}\n\nedges  {\n__typename  \nnode  {\n__typename  \nid  \nname  \nenableByDefault  \n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.prices;
  var value$2 = value$1.pageInfo;
  var value$3 = value$2.endCursor;
  var value$4 = value$2.hasNextPage;
  var value$5 = value$1.edges;
  return {
          prices: {
            __typename: value$1.__typename,
            pageInfo: {
              __typename: value$2.__typename,
              endCursor: !(value$3 == null) ? value$3 : undefined,
              hasNextPage: !(value$4 == null) ? value$4 : undefined
            },
            edges: Js_array.map((function (value) {
                    var value$1 = value.node;
                    return {
                            __typename: value.__typename,
                            node: {
                              __typename: value$1.__typename,
                              id: value$1.id,
                              name: value$1.name,
                              enableByDefault: value$1.enableByDefault
                            }
                          };
                  }), value$5)
          }
        };
}

function serialize(value) {
  var value$1 = value.prices;
  var value$2 = value$1.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.enableByDefault;
          var value$3 = value$1.name;
          var value$4 = value$1.id;
          var value$5 = value$1.__typename;
          var node = {
            __typename: value$5,
            id: value$4,
            name: value$3,
            enableByDefault: value$2
          };
          var value$6 = value.__typename;
          return {
                  __typename: value$6,
                  node: node
                };
        }), value$2);
  var value$3 = value$1.pageInfo;
  var value$4 = value$3.hasNextPage;
  var hasNextPage = value$4 !== undefined ? value$4 : null;
  var value$5 = value$3.endCursor;
  var endCursor = value$5 !== undefined ? value$5 : null;
  var value$6 = value$3.__typename;
  var pageInfo = {
    __typename: value$6,
    endCursor: endCursor,
    hasNextPage: hasNextPage
  };
  var value$7 = value$1.__typename;
  var prices = {
    __typename: value$7,
    pageInfo: pageInfo,
    edges: edges
  };
  return {
          prices: prices
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputPricesQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.archived;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined,
          archived: a$1 !== undefined ? (
              a$1 === "ONLY" ? "ONLY" : (
                  a$1 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputPricesQueryFilter(a) : undefined
        };
}

function makeVariables(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectInputPricesQueryFilter(shopIds, archived, param) {
  return {
          shopIds: shopIds,
          archived: archived
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined
        };
}

var PricesQuery_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputPricesQueryFilter: serializeInputObjectInputPricesQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputPricesQueryFilter: makeInputObjectInputPricesQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var PricesQuery_refetchQueryDescription = include.refetchQueryDescription;

var PricesQuery_useLazy = include.useLazy;

var PricesQuery_useLazyWithVariables = include.useLazyWithVariables;

var PricesQuery = {
  PricesQuery_inner: PricesQuery_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputPricesQueryFilter: serializeInputObjectInputPricesQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputPricesQueryFilter: makeInputObjectInputPricesQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: PricesQuery_refetchQueryDescription,
  use: use,
  useLazy: PricesQuery_useLazy,
  useLazyWithVariables: PricesQuery_useLazyWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["query QueryVariantsTotalCount($filterBy: InputVariantsQueryFilter)  {\nvariants(filterBy: $filterBy)  {\n__typename  \ntotalCount  \n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.variants;
  return {
          variants: {
            __typename: value$1.__typename,
            totalCount: value$1.totalCount
          }
        };
}

function serialize$1(value) {
  var value$1 = value.variants;
  var value$2 = value$1.totalCount;
  var value$3 = value$1.__typename;
  var variants = {
    __typename: value$3,
    totalCount: value$2
  };
  return {
          variants: variants
        };
}

function serializeInputObjectNullableStringEqualsFilter(inp) {
  var a = inp._equals;
  return {
          _equals: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInFilter$1(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectNumberRangeFilter(inp) {
  var a = inp._min;
  var a$1 = inp._max;
  var a$2 = inp._between;
  return {
          _min: a !== undefined ? a : undefined,
          _max: a$1 !== undefined ? a$1 : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectDateFilter(inp) {
  var a = inp._after;
  var a$1 = inp._before;
  var a$2 = inp._between;
  return {
          _after: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _before: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _between: a$2 !== undefined ? Js_array.map((function (b) {
                    return b;
                  }), a$2) : undefined
        };
}

function serializeInputObjectBooleanEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectStringEqualsFilter(inp) {
  return {
          _equals: inp._equals
        };
}

function serializeInputObjectInputVariantsQueryFilter(inp) {
  var a = inp.shopIds;
  var a$1 = inp.stockKeepingUnit;
  var a$2 = inp.ean13;
  var a$3 = inp.active;
  var a$4 = inp.archived;
  var a$5 = inp.createdAt;
  var a$6 = inp.updatedAt;
  var a$7 = inp.categoryId;
  var a$8 = inp.supplierId;
  var a$9 = inp.stock;
  var a$10 = inp.producer;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter$1(a) : undefined,
          stockKeepingUnit: a$1 !== undefined ? ({
                _equals: a$1._equals
              }) : undefined,
          ean13: a$2 !== undefined ? ({
                _equals: a$2._equals
              }) : undefined,
          active: a$3 !== undefined ? ({
                _equals: a$3._equals
              }) : undefined,
          archived: a$4 !== undefined ? (
              a$4 === "ONLY" ? "ONLY" : (
                  a$4 === "INCLUDED" ? "INCLUDED" : "EXCLUDED"
                )
            ) : undefined,
          createdAt: a$5 !== undefined ? serializeInputObjectDateFilter(a$5) : undefined,
          updatedAt: a$6 !== undefined ? serializeInputObjectDateFilter(a$6) : undefined,
          categoryId: a$7 !== undefined ? serializeInputObjectNullableStringEqualsFilter(a$7) : undefined,
          supplierId: a$8 !== undefined ? serializeInputObjectNullableStringEqualsFilter(a$8) : undefined,
          stock: a$9 !== undefined ? serializeInputObjectNumberRangeFilter(a$9) : undefined,
          producer: a$10 !== undefined ? ({
                _equals: a$10._equals
              }) : undefined
        };
}

function serializeVariables$1(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputVariantsQueryFilter(a) : undefined
        };
}

function makeVariables$1(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectInputVariantsQueryFilter(shopIds, stockKeepingUnit, ean13, active, archived, createdAt, updatedAt, categoryId, supplierId, stock, producer, param) {
  return {
          shopIds: shopIds,
          stockKeepingUnit: stockKeepingUnit,
          ean13: ean13,
          active: active,
          archived: archived,
          createdAt: createdAt,
          updatedAt: updatedAt,
          categoryId: categoryId,
          supplierId: supplierId,
          stock: stock,
          producer: producer
        };
}

function makeInputObjectInFilter$1(_in, param) {
  return {
          _in: _in
        };
}

function makeInputObjectStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectBooleanEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectDateFilter(_after, _before, _between, param) {
  return {
          _after: _after,
          _before: _before,
          _between: _between
        };
}

function makeInputObjectNullableStringEqualsFilter(_equals, param) {
  return {
          _equals: _equals
        };
}

function makeInputObjectNumberRangeFilter(_min, _max, _between, param) {
  return {
          _min: _min,
          _max: _max,
          _between: _between
        };
}

function makeDefaultVariables$1(param) {
  return {
          filterBy: undefined
        };
}

var VariantsCountQuery_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter$1,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectNullableStringEqualsFilter: serializeInputObjectNullableStringEqualsFilter,
  serializeInputObjectNumberRangeFilter: serializeInputObjectNumberRangeFilter,
  makeVariables: makeVariables$1,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter$1,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectNullableStringEqualsFilter: makeInputObjectNullableStringEqualsFilter,
  makeInputObjectNumberRangeFilter: makeInputObjectNumberRangeFilter,
  makeDefaultVariables: makeDefaultVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var VariantsCountQuery_refetchQueryDescription = include$1.refetchQueryDescription;

var VariantsCountQuery_useLazy = include$1.useLazy;

var VariantsCountQuery_useLazyWithVariables = include$1.useLazyWithVariables;

var VariantsCountQuery = {
  VariantsCountQuery_inner: VariantsCountQuery_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputVariantsQueryFilter: serializeInputObjectInputVariantsQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter$1,
  serializeInputObjectStringEqualsFilter: serializeInputObjectStringEqualsFilter,
  serializeInputObjectBooleanEqualsFilter: serializeInputObjectBooleanEqualsFilter,
  serializeInputObjectDateFilter: serializeInputObjectDateFilter,
  serializeInputObjectNullableStringEqualsFilter: serializeInputObjectNullableStringEqualsFilter,
  serializeInputObjectNumberRangeFilter: serializeInputObjectNumberRangeFilter,
  makeVariables: makeVariables$1,
  makeInputObjectInputVariantsQueryFilter: makeInputObjectInputVariantsQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter$1,
  makeInputObjectStringEqualsFilter: makeInputObjectStringEqualsFilter,
  makeInputObjectBooleanEqualsFilter: makeInputObjectBooleanEqualsFilter,
  makeInputObjectDateFilter: makeInputObjectDateFilter,
  makeInputObjectNullableStringEqualsFilter: makeInputObjectNullableStringEqualsFilter,
  makeInputObjectNumberRangeFilter: makeInputObjectNumberRangeFilter,
  makeDefaultVariables: makeDefaultVariables$1,
  refetchQueryDescription: VariantsCountQuery_refetchQueryDescription,
  use: use$1,
  useLazy: VariantsCountQuery_useLazy,
  useLazyWithVariables: VariantsCountQuery_useLazyWithVariables
};

function CatalogLabelCreatePage(Props) {
  var settingsOnlyOpt = Props.settingsOnly;
  var settingsOnly = settingsOnlyOpt !== undefined ? Caml_option.valFromOption(settingsOnlyOpt) : undefined;
  var shop = Auth$Pos.useScope(undefined);
  var match;
  match = shop.TAG === /* Organisation */0 ? [
      shop.activeShop,
      true
    ] : [
      shop._0,
      false
    ];
  var shop$1 = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setPrinterStatusResult = match$1[1];
  var printerStatusResult = match$1[0];
  var priceListResult = AsyncResult$Pos.mapOk(ApolloHelpers$Pos.queryResultToAsyncResult(Curry.app(use, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                Belt_Option.isNone(shop$1),
                undefined,
                {
                  filterBy: {
                    shopIds: {
                      _in: [Belt_Option.mapWithDefault(shop$1, "", (function (shop) {
                                return shop.id;
                              }))]
                    },
                    archived: undefined
                  }
                }
              ])), (function (param) {
          return Belt_Array.map(param.prices.edges, (function (edge) {
                        return {
                                id: Uuid$Pos.fromString(edge.node.id),
                                name: edge.node.name,
                                default: edge.node.enableByDefault
                              };
                      }));
        }));
  var productsTotalCountResult = AsyncResult$Pos.mapOk(ApolloHelpers$Pos.queryResultToAsyncResult(Curry.app(use$1, [
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                Belt_Option.isNone(shop$1),
                undefined,
                {
                  filterBy: makeInputObjectInputVariantsQueryFilter({
                        _in: [Belt_Option.mapWithDefault(shop$1, "", (function (shop) {
                                  return shop.id;
                                }))]
                      }, undefined, undefined, {
                        _equals: true
                      }, "EXCLUDED", undefined, undefined, undefined, undefined, undefined, undefined, undefined)
                }
              ])), (function (param) {
          return param.variants.totalCount;
        }));
  React.useEffect((function () {
          if (shop$1 === undefined) {
            return ;
          }
          var future = Curry._1(CatalogLabel$Pos.Print.DefaultPrinterRequest.make, shop$1.id);
          Future.map(future, undefined, (function (result) {
                  Curry._1(setPrinterStatusResult, (function (param) {
                          return result;
                        }));
                }));
          return (function (param) {
                    Future.cancel(future);
                  });
        }), [shop$1]);
  var persistedStateSettings = CatalogLabel$Pos.StateSettingsStorage.useRead(undefined);
  var firstPersistence = Belt_Option.isNone(persistedStateSettings);
  var initialState = function (priceList) {
    if (persistedStateSettings === undefined) {
      if (Belt_Option.isSome(settingsOnly)) {
        return initializeStateLabelPrint(Caml_option.some(settingsOnly), priceList, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
      } else {
        return initializeStateLabelSheet(priceList, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
      }
    }
    var labelFormat = persistedStateSettings.labelFormat;
    if (labelFormat.TAG === /* LabelSheet */0) {
      if (Belt_Option.isSome(settingsOnly)) {
        return initializeStateLabelPrint(Caml_option.some(settingsOnly), priceList, Caml_option.some(persistedStateSettings.priceId), persistedStateSettings.productBarcodeDisplayed, persistedStateSettings.missingProductBarcodeGenerated, persistedStateSettings.priceDisplayed, persistedStateSettings.producerDisplayed, persistedStateSettings.alcoholVolumeDisplayed, persistedStateSettings.productCode, undefined, undefined);
      } else {
        return initializeStateLabelSheet(priceList, Caml_option.some(persistedStateSettings.priceId), persistedStateSettings.productBarcodeDisplayed, persistedStateSettings.missingProductBarcodeGenerated, persistedStateSettings.priceDisplayed, persistedStateSettings.producerDisplayed, persistedStateSettings.alcoholVolumeDisplayed, persistedStateSettings.productCode, labelFormat._0, persistedStateSettings.printOffset, persistedStateSettings.borderEnabled, persistedStateSettings.sort, undefined);
      }
    } else {
      return initializeStateLabelPrint(Caml_option.some(settingsOnly), priceList, Caml_option.some(persistedStateSettings.priceId), persistedStateSettings.productBarcodeDisplayed, persistedStateSettings.missingProductBarcodeGenerated, persistedStateSettings.priceDisplayed, persistedStateSettings.producerDisplayed, persistedStateSettings.alcoholVolumeDisplayed, persistedStateSettings.productCode, labelFormat._0, undefined);
    }
  };
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  var exit$2 = 0;
  if (printerStatusResult !== undefined && !(printerStatusResult.TAG === /* Ok */0 || !printerStatusResult._0)) {
    exit = 2;
  } else {
    exit$2 = 4;
  }
  if (exit$2 === 4) {
    if (typeof priceListResult === "number" || !(priceListResult.TAG === /* Done */1 && priceListResult._0.TAG !== /* Ok */0)) {
      exit$1 = 3;
    } else {
      exit = 2;
    }
  }
  if (exit$1 === 3) {
    if (typeof productsTotalCountResult === "number" || productsTotalCountResult.TAG !== /* Done */1) {
      exit = 1;
    } else if (productsTotalCountResult._0.TAG === /* Ok */0) {
      if (shop$1 !== undefined && printerStatusResult !== undefined && !(typeof priceListResult === "number" || priceListResult.TAG !== /* Done */1)) {
        var printerStatus;
        printerStatus = printerStatusResult.TAG === /* Ok */0 ? ({
              TAG: /* Ok */0,
              _0: undefined
            }) : ({
              TAG: /* Error */1,
              _0: undefined
            });
        tmp = React.createElement(make$7, {
              firstPersistence: firstPersistence,
              shop: shop$1,
              organisation: match[1],
              printerStatus: printerStatus,
              productsTotalCount: productsTotalCountResult._0._0,
              initialState: initialState(priceListResult._0._0)
            });
      } else {
        exit = 1;
      }
    } else {
      exit = 2;
    }
  }
  switch (exit) {
    case 1 :
        tmp = React.createElement(Placeholder$Pos.make, {
              status: /* Loading */0
            });
        break;
    case 2 :
        tmp = React.createElement(Placeholder$Pos.make, {
              status: /* Error */2
            });
        break;
    
  }
  return React.createElement(CatalogPlaceholderShopPicker$Pos.make, {
              children: tmp,
              message: Intl$Pos.t("Please select the shop with which you would like to generate labels.")
            });
}

var make$8 = CatalogLabelCreatePage;

export {
  CatalogLabelCreateProductsValue ,
  CatalogLabelCreateLabelSheetForm ,
  CatalogLabelCreateLabelPrintForm ,
  CatalogLabelCreateReducer ,
  CatalogLabelCreateInformationCard ,
  CatalogLabelCreatePrintSettingsCard ,
  CatalogLabelCreateProductsCard ,
  CatalogLabelCreatePageInvalidBarcodesBanner ,
  CatalogLabelCreatePageLoaded ,
  PricesQuery ,
  VariantsCountQuery ,
  make$8 as make,
}
/* validationErrorText Not a pure module */
