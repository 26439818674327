// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Select$Pos from "../../resources/selection-and-input/Select.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Tooltip$Pos from "../../resources/overlays/Tooltip.bs.js";
import * as Client from "@apollo/client";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = Client.gql(["query ProductProducers($filterBy: InputProductProducersQueryFilter)  {\nproductProducers(filterBy: $filterBy)  \n}\n"]);

function parse(value) {
  var value$1 = value.productProducers;
  return {
          productProducers: Js_array.map((function (value) {
                  return value;
                }), value$1)
        };
}

function serialize(value) {
  var value$1 = value.productProducers;
  var productProducers = Js_array.map((function (value) {
          return value;
        }), value$1);
  return {
          productProducers: productProducers
        };
}

function serializeInputObjectInFilter(inp) {
  var a = inp._in;
  return {
          _in: Js_array.map((function (b) {
                  return b;
                }), a)
        };
}

function serializeInputObjectInputProductProducersQueryFilter(inp) {
  var a = inp.shopIds;
  return {
          shopIds: a !== undefined ? serializeInputObjectInFilter(a) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.filterBy;
  return {
          filterBy: a !== undefined ? serializeInputObjectInputProductProducersQueryFilter(a) : undefined
        };
}

function makeVariables(filterBy, param) {
  return {
          filterBy: filterBy
        };
}

function makeInputObjectInputProductProducersQueryFilter(shopIds, param) {
  return {
          shopIds: shopIds
        };
}

function makeInputObjectInFilter(_in, param) {
  return {
          _in: _in
        };
}

function makeDefaultVariables(param) {
  return {
          filterBy: undefined
        };
}

var Query_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputProductProducersQueryFilter: serializeInputObjectInputProductProducersQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputProductProducersQueryFilter: makeInputObjectInputProductProducersQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Query_refetchQueryDescription = include.refetchQueryDescription;

var Query_useLazy = include.useLazy;

var Query_useLazyWithVariables = include.useLazyWithVariables;

var Query = {
  Query_inner: Query_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputProductProducersQueryFilter: serializeInputObjectInputProductProducersQueryFilter,
  serializeInputObjectInFilter: serializeInputObjectInFilter,
  makeVariables: makeVariables,
  makeInputObjectInputProductProducersQueryFilter: makeInputObjectInputProductProducersQueryFilter,
  makeInputObjectInFilter: makeInputObjectInFilter,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: Query_refetchQueryDescription,
  use: use,
  useLazy: Query_useLazy,
  useLazyWithVariables: Query_useLazyWithVariables
};

function useProductProducersQuery(variables) {
  return AsyncResult$Pos.mapError(ApolloHelpers$Pos.queryResultToAsyncResult(Curry.app(use, [
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      variables
                    ])), (function (param) {
                
              }));
}

function CatalogProducerSelect(Props) {
  var shopId = Props.shopId;
  var value = Props.value;
  var useProductProducersQueryOpt = Props.useProductProducersQuery;
  var onChange = Props.onChange;
  var useProductProducersQuery$1 = useProductProducersQueryOpt !== undefined ? useProductProducersQueryOpt : useProductProducersQuery;
  var queryResult = Curry._1(useProductProducersQuery$1, {
        filterBy: {
          shopIds: shopId !== undefined ? ({
                _in: [shopId]
              }) : undefined
        }
      });
  var items;
  var exit = 0;
  var productProducers;
  if (typeof queryResult === "number") {
    items = queryResult === /* NotAsked */0 ? [] : [];
  } else if (queryResult.TAG === /* Reloading */0) {
    var match = queryResult._0;
    if (match.TAG === /* Ok */0) {
      productProducers = match._0.productProducers;
      exit = 1;
    } else {
      items = [];
    }
  } else {
    var match$1 = queryResult._0;
    if (match$1.TAG === /* Ok */0) {
      productProducers = match$1._0.productProducers;
      exit = 1;
    } else {
      items = [];
    }
  }
  if (exit === 1) {
    items = Belt_Array.map(productProducers, (function (producerName) {
            return {
                    key: producerName,
                    value: producerName,
                    label: producerName
                  };
          }));
  }
  var defaultItem_label = Intl$Pos.t("All");
  var defaultItem_sticky = true;
  var defaultItem = {
    key: "default",
    label: defaultItem_label,
    sticky: defaultItem_sticky
  };
  var sections = [
    {
      items: [defaultItem]
    },
    {
      items: items,
      title: Intl$Pos.t("Producers")
    }
  ];
  var disabled = Belt_Option.isNone(shopId) || AsyncResult$Pos.isBusy(queryResult);
  return React.createElement(Tooltip$Pos.make, {
              children: React.createElement(Select$Pos.make, {
                    label: Intl$Pos.t("Producer"),
                    disabled: disabled,
                    sections: sections,
                    preset: "filter",
                    size: "compact",
                    value: value,
                    onChange: onChange
                  }),
              placement: "top",
              content: {
                TAG: /* Text */0,
                _0: Intl$Pos.t("Please select a shop beforehand.")
              },
              disabled: !disabled,
              delay: 0
            });
}

var make = CatalogProducerSelect;

export {
  Query ,
  useProductProducersQuery ,
  make ,
}
/* query Not a pure module */
