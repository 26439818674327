// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Stylex from "@stylexjs/stylex";

var styles = Stylex.create({
      root: {
        font: "normal 400 10px \"Archivo\"",
        textAlign: "center",
        border: "1px solid transparent",
        borderRadius: "9px",
        minWidth: "8px"
      },
      size_small: {
        fontSize: "10px",
        fontWeight: "bold",
        padding: "2px 4px"
      },
      size_medium: {
        fontSize: "12px",
        padding: "4px 6px"
      },
      size_large: {
        fontSize: "14px",
        padding: "4px 6px"
      }
    });

function colorFromStyleOptions(variation, tone, accent) {
  if (variation === "solid") {
    if (tone === "brand") {
      return "#a01e72";
    } else {
      return "#25243a";
    }
  } else if (variation === "ghost") {
    if (tone === "brand") {
      if (accent) {
        return "#fbe8f8";
      } else {
        return "#fdf2fb";
      }
    } else if (accent) {
      return "#e7e7ee";
    } else {
      return "#f3f3f7";
    }
  } else {
    return "white";
  }
}

function styleFromSize(size) {
  if (size === "medium") {
    return styles.size_medium;
  } else if (size === "small") {
    return styles.size_small;
  } else {
    return styles.size_large;
  }
}

function styleFromVariation(variation, tone, accent) {
  var color = colorFromStyleOptions(variation, tone, accent);
  if (variation === "solid") {
    return {
            color: "white",
            backgroundColor: color
          };
  } else if (variation === "ghost") {
    return {
            color: color,
            backgroundColor: color
          };
  } else {
    return {
            color: color,
            backgroundColor: color,
            borderColor: color
          };
  }
}

function styleProps(size, variation, tone, accent) {
  return Stylex.props(styles.root, styleFromSize(size), styleFromVariation(variation, tone, accent), styles.border);
}

function CountBadge(Props) {
  var children = Props.children;
  var sizeOpt = Props.size;
  var variationOpt = Props.variation;
  var toneOpt = Props.tone;
  var accentOpt = Props.accent;
  var size = sizeOpt !== undefined ? sizeOpt : "medium";
  var variation = variationOpt !== undefined ? variationOpt : "solid";
  var tone = toneOpt !== undefined ? toneOpt : "brand";
  var accent = accentOpt !== undefined ? accentOpt : false;
  var match = styleProps(size, variation, tone, accent);
  var tmp = {};
  if (match.className !== undefined) {
    tmp.className = Caml_option.valFromOption(match.className);
  }
  if (match.style !== undefined) {
    tmp.style = Caml_option.valFromOption(match.style);
  }
  return React.createElement("span", tmp, children);
}

var make = React.memo(CountBadge);

export {
  make ,
}
/* styles Not a pure module */
