// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsonCodec$Pos from "../../primitives/JsonCodec.bs.js";
import * as QueryString$Pos from "../../primitives/QueryString.bs.js";

var baseRoute = "/suppliers";

function encoder(param) {
  return param.shopId;
}

function decoder(shopId) {
  return {
          TAG: /* Ok */0,
          _0: {
            shopId: shopId
          }
        };
}

var value = JsonCodec$Pos.object1(encoder, decoder, JsonCodec$Pos.field("shopId", JsonCodec$Pos.string));

var CreateSupplierQueryStringCodecs = {
  encoder: encoder,
  decoder: decoder,
  value: value
};

function encodeCreateSupplierQueryString(state) {
  return QueryString$Pos.stringify(JsonCodec$Pos.encodeWith(state, value));
}

function decodeCreateSupplierQueryString(query) {
  var match = JsonCodec$Pos.decodeWith(QueryString$Pos.parse(query), value);
  if (match.TAG === /* Ok */0) {
    return match._0.shopId;
  }
  
}

function showRoute(id) {
  return baseRoute + "/" + id;
}

function newRoute(shopId, param) {
  return baseRoute + "/new" + Belt_Option.mapWithDefault(shopId, "", (function (shopId) {
                return "?" + QueryString$Pos.stringify(JsonCodec$Pos.encodeWith({
                                shopId: shopId
                              }, value));
              }));
}

function editRoute(id) {
  return showRoute(id) + "/edit";
}

function editLocationRoute(id) {
  return showRoute(id) + "/edit/location";
}

function editContactRoute(id) {
  return showRoute(id) + "/edit/contact";
}

export {
  baseRoute ,
  CreateSupplierQueryStringCodecs ,
  encodeCreateSupplierQueryString ,
  decodeCreateSupplierQueryString ,
  showRoute ,
  newRoute ,
  editRoute ,
  editLocationRoute ,
  editContactRoute ,
}
/* value Not a pure module */
