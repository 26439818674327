// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as IpAddress$Pos from "../../primitives/IpAddress.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as BillingAccount$Pos from "../../modules/Billing/BillingAccount.bs.js";
import * as SettingsRoutes$Pos from "./SettingsRoutes.bs.js";
import * as SettingsUserPage$Pos from "./SettingsUserPage.bs.js";
import * as SettingsShopsPage$Pos from "./SettingsShopsPage.bs.js";
import * as SettingsLabelPrinterPage$Pos from "./SettingsLabelPrinterPage.bs.js";
import * as SettingsBillingAccountEditPage$Pos from "./SettingsBillingAccountEditPage.bs.js";
import * as SettingsBillingAccountShowPage$Pos from "./SettingsBillingAccountShowPage.bs.js";
import * as SettingsBillingAccountListInvoicesPage$Pos from "./SettingsBillingAccountListInvoicesPage.bs.js";

function SettingsRouter(Props) {
  var subUrlPath = Props.subUrlPath;
  var appIndexRoute = Props.appIndexRoute;
  var reloadAlertBar = Props.reloadAlertBar;
  var url = Navigation$Pos.useUrl(undefined);
  var scope = Auth$Pos.useScope(undefined);
  var shop;
  if (scope.TAG === /* Organisation */0) {
    var shop$1 = scope.activeShop;
    shop = shop$1 !== undefined ? shop$1 : scope.shops[0];
  } else {
    shop = scope._0;
  }
  if (subUrlPath) {
    switch (subUrlPath.hd) {
      case "billing-account" :
          var match = subUrlPath.tl;
          if (!match) {
            return React.createElement(Navigation$Pos.Redirect.make, {
                        route: SettingsRoutes$Pos.billingAccountShowRoute(shop.id, undefined)
                      });
          }
          var shopId = match.hd;
          var match$1 = match.tl;
          if (match$1) {
            var modal = match$1.hd;
            if (modal === "invoices") {
              if (!match$1.tl) {
                return React.createElement(SettingsBillingAccountListInvoicesPage$Pos.make, {
                            shopId: shopId,
                            invoicesRequest: BillingAccount$Pos.invoicesRequest
                          });
              }
              
            } else if (!match$1.tl) {
              var modal$1 = SettingsRoutes$Pos.BillingAccountShowPageModal.fromString(modal);
              if (modal$1 !== undefined) {
                return React.createElement(SettingsBillingAccountShowPage$Pos.make, {
                            shopId: shopId,
                            confirmSepaModalOpened: SettingsRoutes$Pos.BillingAccountShowPageModal.isConfirmMandate(modal$1),
                            editPaymentMethodModalOpened: SettingsRoutes$Pos.BillingAccountShowPageModal.isEditPaymentMethod(modal$1),
                            billingAccountRequest: SettingsBillingAccountShowPage$Pos.billingAccountRequest,
                            billingStatusRequest: SettingsBillingAccountShowPage$Pos.billingStatusRequest,
                            sepaMandateRequest: SettingsBillingAccountShowPage$Pos.sepaMandateRequest,
                            updatePaymentMethodRequest: SettingsBillingAccountShowPage$Pos.updatePaymentMethodRequest,
                            invoicesRequest: BillingAccount$Pos.invoicesRequest,
                            subscriptionsRequest: SettingsBillingAccountShowPage$Pos.subscriptionsRequest,
                            confirmSepaMandateRequest: SettingsBillingAccountShowPage$Pos.confirmSepaMandateRequest,
                            ipAddressRequest: IpAddress$Pos.request,
                            reloadAlertBar: reloadAlertBar
                          });
              } else {
                return React.createElement(Navigation$Pos.Redirect.make, {
                            route: SettingsRoutes$Pos.billingAccountShowRoute(shopId, undefined)
                          });
              }
            }
            
          } else {
            switch (shopId) {
              case "edit" :
                  var decodeUrlResult = SettingsRoutes$Pos.decodeEditBillingAccountQueryString(url.query);
                  if (decodeUrlResult.TAG !== /* Ok */0) {
                    return React.createElement(Navigation$Pos.Redirect.make, {
                                route: appIndexRoute
                              });
                  }
                  var match$2 = decodeUrlResult._0;
                  return React.createElement(SettingsBillingAccountEditPage$Pos.make, {
                              activeShopId: match$2.activeShopId,
                              corporateName: match$2.corporateName,
                              shopName: match$2.shopName,
                              email: match$2.email,
                              phone: match$2.phone,
                              vatNumber: match$2.vatNumber,
                              billingAddress: match$2.billingAddress,
                              shippingAddress: match$2.shippingAddress,
                              requestBillingAccountUpdate: SettingsBillingAccountEditPage$Pos.requestBillingAccountUpdate
                            });
              case "payment-failed" :
                  return React.createElement(SettingsBillingAccountShowPage$Pos.make, {
                              shopId: shop.id,
                              confirmSepaModalOpened: false,
                              editPaymentMethodModalOpened: false,
                              billingAccountRequest: SettingsBillingAccountShowPage$Pos.billingAccountRequest,
                              billingStatusRequest: SettingsBillingAccountShowPage$Pos.billingStatusRequest,
                              sepaMandateRequest: SettingsBillingAccountShowPage$Pos.sepaMandateRequest,
                              updatePaymentMethodRequest: SettingsBillingAccountShowPage$Pos.updatePaymentMethodRequest,
                              invoicesRequest: BillingAccount$Pos.invoicesRequest,
                              subscriptionsRequest: SettingsBillingAccountShowPage$Pos.subscriptionsRequest,
                              confirmSepaMandateRequest: SettingsBillingAccountShowPage$Pos.confirmSepaMandateRequest,
                              ipAddressRequest: IpAddress$Pos.request,
                              reloadAlertBar: reloadAlertBar,
                              notification: {
                                TAG: /* Danger */1,
                                _0: Intl$Pos.t("We were unable to complete your payment due to a technical error. Please try again or contact us for assistance.")
                              }
                            });
              case "payment-succeeded" :
                  return React.createElement(SettingsBillingAccountShowPage$Pos.make, {
                              shopId: shop.id,
                              confirmSepaModalOpened: false,
                              editPaymentMethodModalOpened: false,
                              billingAccountRequest: SettingsBillingAccountShowPage$Pos.billingAccountRequest,
                              billingStatusRequest: SettingsBillingAccountShowPage$Pos.billingStatusRequest,
                              sepaMandateRequest: SettingsBillingAccountShowPage$Pos.sepaMandateRequest,
                              updatePaymentMethodRequest: SettingsBillingAccountShowPage$Pos.updatePaymentMethodRequest,
                              invoicesRequest: BillingAccount$Pos.invoicesRequest,
                              subscriptionsRequest: SettingsBillingAccountShowPage$Pos.subscriptionsRequest,
                              confirmSepaMandateRequest: SettingsBillingAccountShowPage$Pos.confirmSepaMandateRequest,
                              ipAddressRequest: IpAddress$Pos.request,
                              reloadAlertBar: reloadAlertBar,
                              notification: {
                                TAG: /* Success */0,
                                _0: Intl$Pos.t("Your payment has been successfully processed. You can now fully enjoy our services.")
                              }
                            });
              default:
                return React.createElement(SettingsBillingAccountShowPage$Pos.make, {
                            shopId: shopId,
                            confirmSepaModalOpened: false,
                            editPaymentMethodModalOpened: false,
                            billingAccountRequest: SettingsBillingAccountShowPage$Pos.billingAccountRequest,
                            billingStatusRequest: SettingsBillingAccountShowPage$Pos.billingStatusRequest,
                            sepaMandateRequest: SettingsBillingAccountShowPage$Pos.sepaMandateRequest,
                            updatePaymentMethodRequest: SettingsBillingAccountShowPage$Pos.updatePaymentMethodRequest,
                            invoicesRequest: BillingAccount$Pos.invoicesRequest,
                            subscriptionsRequest: SettingsBillingAccountShowPage$Pos.subscriptionsRequest,
                            confirmSepaMandateRequest: SettingsBillingAccountShowPage$Pos.confirmSepaMandateRequest,
                            ipAddressRequest: IpAddress$Pos.request,
                            reloadAlertBar: reloadAlertBar
                          });
            }
          }
          break;
      case "label-printer" :
          if (!subUrlPath.tl) {
            return React.createElement(SettingsLabelPrinterPage$Pos.make, {});
          }
          break;
      case "shops" :
          if (!subUrlPath.tl) {
            return React.createElement(SettingsShopsPage$Pos.make, {
                        updateShopRequest: SettingsShopsPage$Pos.UpdateShopRequest.make
                      });
          }
          break;
      case "user" :
          if (!subUrlPath.tl) {
            return React.createElement(SettingsUserPage$Pos.make, {
                        updateEmailRequest: SettingsUserPage$Pos.updateEmailRequest,
                        updatePasswordRequest: SettingsUserPage$Pos.updatePasswordRequest,
                        updateUserNamesRequest: SettingsUserPage$Pos.updateUserNamesRequest
                      });
          }
          break;
      default:
        
    }
  }
  return React.createElement(Navigation$Pos.Redirect.make, {
              route: appIndexRoute
            });
}

var make = SettingsRouter;

export {
  make ,
}
/* react Not a pure module */
