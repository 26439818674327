// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as View$Pos from "../../primitives/View.bs.js";
import * as Badge$Pos from "../../resources/feedback-indicators/Badge.bs.js";
import * as Price$Pos from "../Price/Price.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Style$Pos from "../../primitives/Style.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as TableView$Pos from "../../resources/tables/TableView.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as Client from "@apollo/client";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as ReactUpdateEffect$Pos from "../../primitives/ReactUpdateEffect.bs.js";
import * as PriceRateTableCell$Pos from "../Price/PriceRateTableCell.bs.js";
import * as CatalogVariant__Queries$Pos from "../../app/Catalog/CatalogVariant__Queries.bs.js";
import * as CatalogVariantRetailPrice$Pos from "./CatalogVariantRetailPrice.bs.js";
import * as CatalogVariantRetailPriceReducer$Pos from "./CatalogVariantRetailPriceReducer.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";
import * as CatalogVariantPriceRetailTableEditCellWrapper$Pos from "./CatalogVariantPriceRetailTableEditCellWrapper.bs.js";

var Raw = {};

var query = Client.gql(["mutation updateVariant_retailPrices($id: ID!, $input: InputUpdateVariant!, $variantPricesInput: [InputUpdateVariantPrices!])  {\nupdateVariant(id: $id, input: $input, variantPricesInput: $variantPricesInput)  {\n__typename  \nid  \npurchasedPrice  \nvariantPrices  {\n__typename  \nedges  {\n__typename  \nnode  {\n__typename  \nid  \narchivedAt  \nprice  {\n__typename  \nid  \nname  \nenableByDefault  \ntaxIncluded  \n}\n\nvalueIncludingTax  \nvalueExcludingTax  \nfromQuantity  \ntoQuantity  \n}\n\n}\n\n}\n\n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.purchasedPrice;
  var value$3 = value$1.variantPrices;
  var value$4 = value$3.edges;
  return {
          updateVariant: {
            __typename: value$1.__typename,
            id: value$1.id,
            purchasedPrice: !(value$2 == null) ? value$2 : undefined,
            variantPrices: {
              __typename: value$3.__typename,
              edges: Js_array.map((function (value) {
                      var value$1 = value.node;
                      var value$2 = value$1.archivedAt;
                      var value$3 = value$1.price;
                      var value$4 = value$1.fromQuantity;
                      var value$5 = value$1.toQuantity;
                      return {
                              __typename: value.__typename,
                              node: {
                                __typename: value$1.__typename,
                                id: value$1.id,
                                archivedAt: !(value$2 == null) ? Caml_option.some(Scalar$Pos.Datetime.parse(value$2)) : undefined,
                                price: !(value$3 == null) ? ({
                                      __typename: value$3.__typename,
                                      id: value$3.id,
                                      name: value$3.name,
                                      enableByDefault: value$3.enableByDefault,
                                      taxIncluded: value$3.taxIncluded
                                    }) : undefined,
                                valueIncludingTax: value$1.valueIncludingTax,
                                valueExcludingTax: value$1.valueExcludingTax,
                                fromQuantity: !(value$4 == null) ? value$4 : undefined,
                                toQuantity: !(value$5 == null) ? value$5 : undefined
                              }
                            };
                    }), value$4)
            }
          }
        };
}

function serialize(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.variantPrices;
  var value$3 = value$2.edges;
  var edges = Js_array.map((function (value) {
          var value$1 = value.node;
          var value$2 = value$1.toQuantity;
          var toQuantity = value$2 !== undefined ? value$2 : null;
          var value$3 = value$1.fromQuantity;
          var fromQuantity = value$3 !== undefined ? value$3 : null;
          var value$4 = value$1.valueExcludingTax;
          var value$5 = value$1.valueIncludingTax;
          var value$6 = value$1.price;
          var price;
          if (value$6 !== undefined) {
            var value$7 = value$6.taxIncluded;
            var value$8 = value$6.enableByDefault;
            var value$9 = value$6.name;
            var value$10 = value$6.id;
            var value$11 = value$6.__typename;
            price = {
              __typename: value$11,
              id: value$10,
              name: value$9,
              enableByDefault: value$8,
              taxIncluded: value$7
            };
          } else {
            price = null;
          }
          var value$12 = value$1.archivedAt;
          var archivedAt = value$12 !== undefined ? Scalar$Pos.Datetime.serialize(Caml_option.valFromOption(value$12)) : null;
          var value$13 = value$1.id;
          var value$14 = value$1.__typename;
          var node = {
            __typename: value$14,
            id: value$13,
            archivedAt: archivedAt,
            price: price,
            valueIncludingTax: value$5,
            valueExcludingTax: value$4,
            fromQuantity: fromQuantity,
            toQuantity: toQuantity
          };
          var value$15 = value.__typename;
          return {
                  __typename: value$15,
                  node: node
                };
        }), value$3);
  var value$4 = value$2.__typename;
  var variantPrices = {
    __typename: value$4,
    edges: edges
  };
  var value$5 = value$1.purchasedPrice;
  var purchasedPrice = value$5 !== undefined ? value$5 : null;
  var value$6 = value$1.id;
  var value$7 = value$1.__typename;
  var updateVariant = {
    __typename: value$7,
    id: value$6,
    purchasedPrice: purchasedPrice,
    variantPrices: variantPrices
  };
  return {
          updateVariant: updateVariant
        };
}

function serializeInputObjectInputUpdateVariantMetaData(inp) {
  var a = inp.shopifyVariantId;
  return {
          shopifyVariantId: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInputUpdateVariantPrices(inp) {
  var a = inp.id;
  var a$1 = inp.fromQuantity;
  var a$2 = inp.toQuantity;
  return {
          id: a !== undefined ? a : undefined,
          valueIncludingTax: inp.valueIncludingTax,
          valueExcludingTax: inp.valueExcludingTax,
          fromQuantity: a$1 !== undefined ? a$1 : undefined,
          toQuantity: a$2 !== undefined ? a$2 : undefined,
          priceId: inp.priceId
        };
}

function serializeInputObjectInputUpdateVariant(inp) {
  var a = inp.stockKeepingUnit;
  var a$1 = inp.name;
  var a$2 = inp.purchasedPrice;
  var a$3 = inp.year;
  var a$4 = inp.capacityValue;
  var a$5 = inp.capacityUnit;
  var a$6 = inp.capacityPrecision;
  var a$7 = inp.alcoholVolume;
  var a$8 = inp.ean13;
  var a$9 = inp.internalNote;
  var a$10 = inp.metadata;
  var a$11 = inp.tastingNote;
  var a$12 = inp.packaging;
  var a$13 = inp.internalCode;
  var a$14 = inp.priceLookUpCode;
  var a$15 = inp.supplierId;
  var a$16 = inp.minStockThreshold;
  var a$17 = inp.maxStockThreshold;
  var a$18 = inp.stockOrderTriggerThreshold;
  return {
          stockKeepingUnit: a !== undefined ? a : undefined,
          name: a$1 !== undefined ? a$1 : undefined,
          purchasedPrice: a$2 !== undefined ? a$2 : undefined,
          year: a$3 !== undefined ? a$3 : undefined,
          capacityValue: a$4 !== undefined ? a$4 : undefined,
          capacityUnit: a$5 !== undefined ? a$5 : undefined,
          capacityPrecision: a$6 !== undefined ? a$6 : undefined,
          alcoholVolume: a$7 !== undefined ? a$7 : undefined,
          ean13: a$8 !== undefined ? a$8 : undefined,
          internalNote: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          metadata: a$10 !== undefined ? serializeInputObjectInputUpdateVariantMetaData(a$10) : undefined,
          tastingNote: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          packaging: a$12 !== undefined ? a$12 : undefined,
          internalCode: a$13 !== undefined ? a$13 : undefined,
          priceLookUpCode: a$14 !== undefined ? a$14 : undefined,
          supplierId: a$15 !== undefined ? a$15 : undefined,
          minStockThreshold: a$16 !== undefined ? a$16 : undefined,
          maxStockThreshold: a$17 !== undefined ? a$17 : undefined,
          stockOrderTriggerThreshold: a$18 !== undefined ? a$18 : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.variantPricesInput;
  return {
          id: inp.id,
          input: serializeInputObjectInputUpdateVariant(inp.input),
          variantPricesInput: a !== undefined ? Js_array.map(serializeInputObjectInputUpdateVariantPrices, a) : undefined
        };
}

function makeVariables(id, input, variantPricesInput, param) {
  return {
          id: id,
          input: input,
          variantPricesInput: variantPricesInput
        };
}

function makeInputObjectInputUpdateVariant(stockKeepingUnit, name, purchasedPrice, year, capacityValue, capacityUnit, capacityPrecision, alcoholVolume, ean13, internalNote, metadata, tastingNote, packaging, internalCode, priceLookUpCode, supplierId, minStockThreshold, maxStockThreshold, stockOrderTriggerThreshold, param) {
  return {
          stockKeepingUnit: stockKeepingUnit,
          name: name,
          purchasedPrice: purchasedPrice,
          year: year,
          capacityValue: capacityValue,
          capacityUnit: capacityUnit,
          capacityPrecision: capacityPrecision,
          alcoholVolume: alcoholVolume,
          ean13: ean13,
          internalNote: internalNote,
          metadata: metadata,
          tastingNote: tastingNote,
          packaging: packaging,
          internalCode: internalCode,
          priceLookUpCode: priceLookUpCode,
          supplierId: supplierId,
          minStockThreshold: minStockThreshold,
          maxStockThreshold: maxStockThreshold,
          stockOrderTriggerThreshold: stockOrderTriggerThreshold
        };
}

function makeInputObjectInputUpdateVariantMetaData(shopifyVariantId, param) {
  return {
          shopifyVariantId: shopifyVariantId
        };
}

function makeInputObjectInputUpdateVariantPrices(id, valueIncludingTax, valueExcludingTax, fromQuantity, toQuantity, priceId, param) {
  return {
          id: id,
          valueIncludingTax: valueIncludingTax,
          valueExcludingTax: valueExcludingTax,
          fromQuantity: fromQuantity,
          toQuantity: toQuantity,
          priceId: priceId
        };
}

var Mutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData,
  serializeInputObjectInputUpdateVariantPrices: serializeInputObjectInputUpdateVariantPrices,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData,
  makeInputObjectInputUpdateVariantPrices: makeInputObjectInputUpdateVariantPrices
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var Mutation_useWithVariables = include.useWithVariables;

var Mutation = {
  Mutation_inner: Mutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData,
  serializeInputObjectInputUpdateVariantPrices: serializeInputObjectInputUpdateVariantPrices,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData,
  makeInputObjectInputUpdateVariantPrices: makeInputObjectInputUpdateVariantPrices,
  use: use,
  useWithVariables: Mutation_useWithVariables
};

function keyExtractor(row) {
  return row.priceId + "-retail";
}

var Row = {
  keyExtractor: keyExtractor
};

var make = React.memo(function (Props) {
      var value = Props.value;
      var name = Props.name;
      var fromQuantity = Props.fromQuantity;
      var formattedName = name + Belt_Option.mapWithDefault(fromQuantity, "", (function (current) {
              if (current > 1) {
                return " +" + String(current) + "";
              } else {
                return "";
              }
            }));
      var taxTypename = Intl$Pos.t(Price$Pos.isTaxIncluded(value) ? "VAT incl." : "VAT excl.");
      return React.createElement(Inline$Pos.make, {
                  children: null,
                  space: "small"
                }, React.createElement(TextStyle$Pos.make, {
                      children: formattedName,
                      weight: "semibold"
                    }), React.createElement(Badge$Pos.make, {
                      children: taxTypename,
                      size: "small",
                      variation: "neutral"
                    }));
    });

var DecreasingPriceListTextLine = {
  make: make
};

function tableColumns(loading, allShopsFiltered, onRequestDispatch) {
  return [
          {
            key: "retailprice-list",
            name: Intl$Pos.t("Price list"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 220
              },
              width: {
                NAME: "fr",
                VAL: 1.5
              }
            },
            render: (function (param) {
                var match = param.data;
                return React.createElement(Stack$Pos.make, {
                            children: null,
                            space: "xxsmall"
                          }, React.createElement(make, {
                                value: match.value,
                                name: match.name,
                                fromQuantity: match.fromQuantity
                              }), allShopsFiltered ? React.createElement(TextStyle$Pos.make, {
                                  children: match.shopName,
                                  variation: "normal",
                                  size: "xxsmall"
                                }) : null);
              })
          },
          {
            key: "retailprice-prices",
            name: Intl$Pos.t("Retail prices"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 130
              }
            },
            render: (function (param) {
                return React.createElement(CatalogVariantPriceRetailTableEditCellWrapper$Pos.make, {
                            disabled: loading,
                            variantPrice: param.data,
                            onRequestDispatch: onRequestDispatch
                          });
              })
          },
          {
            key: "retailprice-marginrate",
            name: Intl$Pos.t("Margin rt"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 110
              }
            },
            render: (function (param) {
                var match = param.data;
                return React.createElement(PriceRateTableCell$Pos.make, {
                            rateType: /* MarginRate */0,
                            retailPrice: Belt_Option.getWithDefault(match.secondaryValue, match.value),
                            purchasePrice: match.purchasePrice
                          });
              })
          },
          {
            key: "retailprice-markuprate",
            name: Intl$Pos.t("Markup rt"),
            layout: {
              minWidth: {
                NAME: "px",
                VAL: 110
              }
            },
            render: (function (param) {
                var match = param.data;
                return React.createElement(PriceRateTableCell$Pos.make, {
                            rateType: /* MarkupRate */1,
                            retailPrice: Belt_Option.getWithDefault(match.secondaryValue, match.value),
                            purchasePrice: match.purchasePrice
                          });
              })
          }
        ];
}

function makeVariantPricesMutationInput(variantPrices) {
  return Belt_Array.map(Belt_Array.keep(Belt_Array.keep(variantPrices, (function (current) {
                        if (current.edited) {
                          return true;
                        } else {
                          return Price$Pos.toRawValue(current.value) > 0;
                        }
                      })), (function (current) {
                    var value = current.secondaryValue;
                    if (value !== undefined) {
                      return Price$Pos.toRawValue(value) >= 0;
                    } else {
                      return Price$Pos.toRawValue(current.value) >= 0;
                    }
                  })), (function (param) {
                var match = CatalogVariantRetailPrice$Pos.makeMutationValuesFromRetailPrice(param.value, param.taxRate);
                return makeInputObjectInputUpdateVariantPrices(param.id, match.valueIncludingTax, match.valueExcludingTax, param.fromQuantity, param.toQuantity, param.priceId, undefined);
              }));
}

function makeVariantPricesMutationsVariables(variantPrices) {
  return Belt_Array.map(Belt_Array.reduce(variantPrices, [], (function (acc, retailPrice) {
                    var match = retailPrice.edited;
                    var match$1 = Belt_Array.some(acc, (function (current) {
                            return current.variantId === retailPrice.variantId;
                          }));
                    if (match && !match$1) {
                      return Belt_Array.concat(acc, [retailPrice]);
                    } else {
                      return acc;
                    }
                  })), (function (variantPrice) {
                var input = makeInputObjectInputUpdateVariant(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                var variantPricesInput = makeVariantPricesMutationInput(Belt_Array.keep(variantPrices, (function (current) {
                            return current.variantId === variantPrice.variantId;
                          })));
                return {
                        id: variantPrice.variantId,
                        input: input,
                        variantPricesInput: variantPricesInput
                      };
              }));
}

var make$1 = React.memo(function (Props) {
      var status = Props.status;
      var onRequestDiscard = Props.onRequestDiscard;
      var onRequestSave = Props.onRequestSave;
      switch (status) {
        case /* Pristine */0 :
            return null;
        case /* Editing */1 :
        case /* Submitting */2 :
            break;
        case /* SubmitSucceeded */3 :
            return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                                    textStatus: {
                                      TAG: /* Success */0,
                                      _0: Intl$Pos.t("The retail prices have been successfully updated.")
                                    }
                                  })), "large", undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        case /* SubmitFailed */4 :
            return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                                    textStatus: {
                                      TAG: /* Danger */1,
                                      _0: Intl$Pos.t("Wino could not update the retail prices, please refresh the page before trying again.")
                                    }
                                  })), "large", undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        
      }
      return React.createElement(View$Pos.make, View$Pos.makeProps(Caml_option.some(React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                          children: null,
                                          space: "small"
                                        }, React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Discard"), "xsmall", "neutral", undefined, undefined, undefined, status === /* Submitting */2, undefined, undefined, undefined, onRequestDiscard, undefined, undefined, undefined)), React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Save prices"), "xsmall", "success", undefined, undefined, status === /* Submitting */2, undefined, undefined, undefined, undefined, onRequestSave, undefined, undefined, undefined)))), "large", undefined, "medium", "xxsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined))), Caml_option.some(Style$Pos.style(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "flexEnd", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)), undefined, undefined, undefined));
    });

var SaveActionBar = {
  make: make$1
};

function use$1(value) {
  var ref = React.useRef(undefined);
  React.useEffect(function () {
        ref.current = Caml_option.some(value);
      });
  return ref.current;
}

function useChange(fn, value) {
  var previousValue = use$1(value);
  React.useEffect((function () {
          if (previousValue !== undefined) {
            return Curry._1(fn, undefined);
          }
          
        }), [Caml_obj.notequal(Belt_Option.getWithDefault(previousValue, value), value)]);
}

var PreviousHook = {
  use: use$1,
  useChange: useChange
};

function CatalogVariantRetailPriceTableCard(Props) {
  var cku = Props.cku;
  var variantPrices = Props.variantPrices;
  var activeShop = Auth$Pos.useActiveShop(undefined);
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutation = match[1];
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return /* Pristine */0;
      });
  var setStatus = match$1[1];
  var status = match$1[0];
  var match$2 = React.useReducer(CatalogVariantRetailPriceReducer$Pos.make, variantPrices);
  var dispatch = match$2[1];
  var rows = match$2[0];
  useChange((function (param) {
          Curry._1(dispatch, {
                TAG: /* Reset */2,
                _0: variantPrices
              });
        }), [Belt_Array.map(variantPrices, (function (param) {
                return [
                        param.id,
                        param.value
                      ];
              }))]);
  useChange((function (param) {
          Curry._1(dispatch, {
                TAG: /* RetailPricesPurchaseValueUpdated */1,
                _0: variantPrices
              });
        }), [Belt_Array.map(variantPrices, (function (param) {
                return param.purchasePrice;
              }))]);
  ReactUpdateEffect$Pos.use1((function (param) {
          Curry._1(setStatus, (function (param) {
                  return /* Pristine */0;
                }));
        }), [activeShop]);
  React.useEffect((function () {
          if (Belt_Array.some(rows, (function (row) {
                    return row.edited;
                  }))) {
            Curry._1(setStatus, (function (param) {
                    return /* Editing */1;
                  }));
          }
          
        }), [rows]);
  React.useEffect((function () {
          if (mutation.loading) {
            Curry._1(setStatus, (function (param) {
                    return /* Submitting */2;
                  }));
          }
          
        }), [mutation.loading]);
  var onRequestDiscard = React.useCallback((function (param) {
          Curry._1(dispatch, {
                TAG: /* Reset */2,
                _0: variantPrices
              });
          Curry._1(setStatus, (function (param) {
                  return /* Pristine */0;
                }));
        }), [variantPrices]);
  var onRequestSave = React.useCallback((function (param) {
          Future.get(ApolloHelpers$Pos.mergeFutureResults(Belt_Array.map(makeVariantPricesMutationsVariables(rows), (function (mutationVariables) {
                          return ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, [Curry._3(CatalogVariant__Queries$Pos.VariantQuery.refetchQueryDescription, undefined, undefined, CatalogVariant__Queries$Pos.VariantQuery.makeVariables(Scalar$Pos.CKU.serialize(cku), undefined, undefined))], undefined, mutationVariables));
                        }))), (function (result) {
                  Curry._1(setStatus, (function (param) {
                          if (result.TAG === /* Ok */0) {
                            return /* SubmitSucceeded */3;
                          } else {
                            return /* SubmitFailed */4;
                          }
                        }));
                }));
        }), [rows]);
  var match$3 = Auth$Pos.useScope(undefined);
  var allShopsFiltered;
  allShopsFiltered = match$3.TAG === /* Organisation */0 ? match$3.activeShop === undefined : false;
  var columns = React.useMemo((function () {
          return tableColumns(status === /* Submitting */2, allShopsFiltered, dispatch);
        }), [
        status,
        allShopsFiltered
      ]);
  var placeholderEmptyState = React.createElement(Placeholder$Pos.make, {
        status: /* NoDataAvailable */1,
        customText: Intl$Pos.t("No price list has been yet recorded")
      });
  return React.createElement(React.Fragment, undefined, React.createElement(Navigation$Pos.Prompt.make, {
                  message: Intl$Pos.t("Some changes in the retail prices have not been saved."),
                  shouldBlockOnRouteChange: (function (param) {
                      if (status === /* Editing */1) {
                        return true;
                      } else {
                        return status === /* Submitting */2;
                      }
                    })
                }), React.createElement(Card$Pos.make, {
                  children: null,
                  title: Intl$Pos.t("Retail prices"),
                  variation: "table",
                  shadowed: status === /* Editing */1
                }, React.createElement(TableView$Pos.make, {
                      data: {
                        TAG: /* Done */1,
                        _0: {
                          TAG: /* Ok */0,
                          _0: rows
                        }
                      },
                      columns: columns,
                      keyExtractor: keyExtractor,
                      maxHeight: 325,
                      placeholderEmptyState: placeholderEmptyState
                    }), React.createElement(make$1, {
                      status: status,
                      onRequestDiscard: onRequestDiscard,
                      onRequestSave: onRequestSave
                    })));
}

var make$2 = React.memo(CatalogVariantRetailPriceTableCard, (function (oldProps, newProps) {
        if (Caml_obj.equal(oldProps.variantPrices, newProps.variantPrices)) {
          return oldProps.cku === newProps.cku;
        } else {
          return false;
        }
      }));

var RetailPriceReducer;

var VariantQuery;

export {
  RetailPriceReducer ,
  VariantQuery ,
  Mutation ,
  Row ,
  DecreasingPriceListTextLine ,
  tableColumns ,
  makeVariantPricesMutationInput ,
  makeVariantPricesMutationsVariables ,
  SaveActionBar ,
  PreviousHook ,
  make$2 as make,
}
/* query Not a pure module */
