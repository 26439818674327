// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Box$Pos from "../../resources/layout-and-structure/Box.bs.js";
import * as Env$Pos from "../../core/Env.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Card$Pos from "../../resources/layout-and-structure/Card.bs.js";
import * as Form$Pos from "../../bundles/Form/Form.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Json$Pos from "../../primitives/Json.bs.js";
import * as Page$Pos from "../../resources/layout-and-structure/Page.bs.js";
import * as Modal$Pos from "../../resources/overlays/Modal.bs.js";
import * as Stack$Pos from "../../resources/layout-and-structure/Stack.bs.js";
import * as Banner$Pos from "../../resources/feedback-indicators/Banner.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Button$Pos from "../../resources/actions/Button.bs.js";
import * as Column$Pos from "../../resources/layout-and-structure/Column.bs.js";
import * as Inline$Pos from "../../resources/layout-and-structure/Inline.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Columns$Pos from "../../resources/layout-and-structure/Columns.bs.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as Spinner$Pos from "../../resources/feedback-indicators/Spinner.bs.js";
import * as Accordion$Pos from "../../resources/layout-and-structure/Accordion.bs.js";
import * as InfoBlock$Pos from "../../resources/layout-and-structure/InfoBlock.bs.js";
import * as TextStyle$Pos from "../../resources/typography/TextStyle.bs.js";
import * as UserAgent$Pos from "../../primitives/UserAgent.bs.js";
import * as BugTracker$Pos from "../../core/BugTracker.bs.js";
import * as HelpCenter$Pos from "../../core/HelpCenter.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as TextAction$Pos from "../../resources/actions/TextAction.bs.js";
import * as AsyncResult$Pos from "../../primitives/AsyncResult.bs.js";
import * as CountryCode$Pos from "../../primitives/CountryCode.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as TooltipIcon$Pos from "../../resources/overlays/TooltipIcon.bs.js";
import * as BillingAccount$Pos from "../../modules/Billing/BillingAccount.bs.js";
import * as SessionTracker$Pos from "../../core/SessionTracker.bs.js";
import * as SettingsRoutes$Pos from "./SettingsRoutes.bs.js";
import * as CorporateEntity$Pos from "../../core/CorporateEntity.bs.js";
import * as BillingAccountInvoicesTable$Pos from "../../modules/Billing/BillingAccountInvoicesTable.bs.js";

function decodeResult(json) {
  var billingAccountDict = Json$Pos.decodeDict(json);
  var shippingAddressDict = Json$Pos.flatDecodeDictFieldDict(Json$Pos.flatDecodeDictFieldDict(billingAccountDict, "shipping"), "address");
  var billingAddressDict = Json$Pos.flatDecodeDictFieldDict(billingAccountDict, "address");
  var paymentMethodDict = Json$Pos.flatDecodeDictFieldDict(billingAccountDict, "defaultPaymentMethod");
  var ibanLast4 = Json$Pos.flatDecodeDictFieldString(Json$Pos.flatDecodeDictFieldDict(paymentMethodDict, "iban"), "last4");
  var decodeAddress = function (json) {
    var match = Json$Pos.flatDecodeDictFieldString(json, "address");
    var match$1 = Json$Pos.flatDecodeDictFieldString(json, "postalCode");
    var match$2 = Json$Pos.flatDecodeDictFieldString(json, "city");
    var match$3 = Json$Pos.flatDecodeDictFieldString(json, "country");
    if (match !== undefined && match$1 !== undefined && match$2 !== undefined && match$3 !== undefined) {
      return {
              address: match,
              postalCode: match$1,
              city: match$2,
              country: match$3
            };
    }
    
  };
  var corporateName = Json$Pos.flatDecodeDictFieldString(billingAccountDict, "corporateName");
  var shopName = Json$Pos.flatDecodeDictFieldString(billingAccountDict, "shopName");
  var email = Json$Pos.flatDecodeDictFieldString(billingAccountDict, "email");
  var phone = Json$Pos.flatDecodeDictFieldString(billingAccountDict, "phone");
  var billingAddress = decodeAddress(billingAddressDict);
  var shippingAddress = decodeAddress(shippingAddressDict);
  var vatNumber = Json$Pos.flatDecodeDictFieldString(billingAccountDict, "vatNumber");
  return {
          corporateName: corporateName,
          shopName: shopName,
          email: email,
          phone: phone,
          billingAddress: billingAddress,
          shippingAddress: shippingAddress,
          vatNumber: vatNumber,
          iban: ibanLast4 !== undefined ? ({
                last4: ibanLast4
              }) : undefined
        };
}

var endpoint = Env$Pos.gatewayUrl(undefined) + "/customer-billing-account/";

function make(shopId) {
  return Future.mapOk(Request$Pos.make("GET", undefined, undefined, undefined, endpoint + shopId), undefined, decodeResult);
}

var BillingAccountRequest = {
  decodeResult: decodeResult,
  endpoint: endpoint,
  make: make
};

function decodeBillingIssues(failure) {
  var issue = BillingAccount$Pos.BillingIssue.fromString(failure.kind);
  if (issue.TAG === /* Ok */0) {
    return issue._0;
  }
  
}

function make$1(shopId) {
  return Future.map(Request$Pos.make("GET", undefined, undefined, undefined, Env$Pos.gatewayUrl(undefined) + "/billing-accounts/" + shopId + "/audit"), undefined, (function (result) {
                if (result.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: Belt_Option.getWithDefault(Belt_Option.flatMap(Json$Pos.decodeArray(result._0), (function (failures) {
                                      return Belt_Option.map(Request$Pos.decodeInvalidRequestFailures(failures), (function (failures) {
                                                    return BillingAccount$Pos.BillingIssue.mostImportantIssue(Belt_Array.keepMap(failures, decodeBillingIssues));
                                                  }));
                                    })), undefined)
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: result._0
                        };
                }
              }));
}

var BillingStatusRequest = {
  decodeBillingIssues: decodeBillingIssues,
  make: make$1
};

function decodeResult$1(json) {
  var match = Json$Pos.flatDecodeDictFieldString(Json$Pos.decodeDict(json), "status");
  var match$1 = Json$Pos.flatDecodeDictField(Json$Pos.decodeDict(json), "acceptedAt", Json$Pos.decodeNumber);
  if (match === undefined) {
    return ;
  }
  if (match$1 === undefined) {
    return ;
  }
  switch (match) {
    case "active" :
        return {
                status: /* Active */0,
                acceptedAt: match$1
              };
    case "inactive" :
        return {
                status: /* Inactive */1,
                acceptedAt: match$1
              };
    case "pending" :
        return {
                status: /* Pending */2,
                acceptedAt: match$1
              };
    default:
      return ;
  }
}

var endpoint$1 = Env$Pos.gatewayUrl(undefined) + "/billing-account/sepa-mandate/";

function make$2(shopId) {
  return Future.mapOk(Request$Pos.make("GET", undefined, undefined, undefined, endpoint$1 + shopId), undefined, decodeResult$1);
}

var SepaMandateRequest = {
  decodeResult: decodeResult$1,
  endpoint: endpoint$1,
  make: make$2
};

function encodeBody(shopId, ibanNumber, ipAddress, userAgent, acceptedAt) {
  return Json$Pos.encodeDict(Js_dict.fromArray([
                  [
                    "shopId",
                    Json$Pos.encodeString(shopId)
                  ],
                  [
                    "iban",
                    Json$Pos.encodeString(ibanNumber)
                  ],
                  [
                    "ipAddress",
                    Json$Pos.encodeString(ipAddress)
                  ],
                  [
                    "userAgent",
                    Json$Pos.encodeString(userAgent)
                  ],
                  [
                    "acceptedAt",
                    Json$Pos.encodeNumber(acceptedAt)
                  ]
                ]));
}

var endpoint$2 = Env$Pos.gatewayUrl(undefined) + "/default-payment-method";

function decodeInvalidRequestFailure(error) {
  switch (error.kind) {
    case "InvalidIban" :
        return /* InvalidIban */1;
    case "WrongSepaMandateAcceptanceDetails" :
        return /* WrongSepaMandateAcceptanceDetails */0;
    default:
      return /* Unknown */2;
  }
}

function make$3(shopId, ibanNumber, ipAddress, userAgent, acceptedAt) {
  return Future.mapError(Future.mapOk(Request$Pos.make("PATCH", Caml_option.some(encodeBody(shopId, ibanNumber, ipAddress, userAgent, acceptedAt)), undefined, undefined, endpoint$2), undefined, (function (param) {
                    
                  })), undefined, (function (error) {
                if (typeof error === "number" || error.TAG !== /* InvalidRequestFailures */2) {
                  return /* Unknown */2;
                } else {
                  return Belt_Option.map(Belt_Array.get(error._0, 0), decodeInvalidRequestFailure);
                }
              }));
}

var UpdatePaymentMethodRequest = {
  encodeBody: encodeBody,
  endpoint: endpoint$2,
  decodeInvalidRequestFailure: decodeInvalidRequestFailure,
  make: make$3
};

function decodeCustomerPlans(json) {
  var dict = Json$Pos.decodeDict(json);
  var match = Json$Pos.flatDecodeDictFieldString(dict, "kind");
  var match$1 = Json$Pos.flatDecodeDictField(dict, "upcomingInvoiceDate", Json$Pos.decodeNumber);
  var match$2 = Json$Pos.flatDecodeDictField(dict, "quantity", Json$Pos.decodeNumber);
  if (match === undefined) {
    return ;
  }
  if (match$2 === undefined) {
    return ;
  }
  var upcomingInvoiceDate = match$1 !== undefined ? Caml_option.some(new Date(match$1)) : undefined;
  var match$3 = BillingAccount$Pos.BillingPlanKind.fromString(match);
  if (match$3.TAG === /* Ok */0) {
    return {
            kind: match$3._0,
            upcomingInvoiceDate: upcomingInvoiceDate,
            quantity: match$2 | 0
          };
  }
  
}

function decodeCustomerOptions(json) {
  var dict = Json$Pos.decodeDict(json);
  var match = Json$Pos.flatDecodeDictFieldString(dict, "name");
  var match$1 = Json$Pos.flatDecodeDictField(dict, "upcomingInvoiceDate", Json$Pos.decodeNumber);
  var match$2 = Json$Pos.flatDecodeDictField(dict, "quantity", Json$Pos.decodeNumber);
  if (match === undefined) {
    return ;
  }
  if (match$2 === undefined) {
    return ;
  }
  var upcomingInvoiceDate = match$1 !== undefined ? Caml_option.some(new Date(match$1)) : undefined;
  return {
          name: match,
          upcomingInvoiceDate: upcomingInvoiceDate,
          quantity: match$2 | 0
        };
}

function decodeResult$2(json) {
  var match = Json$Pos.flatDecodeDictFieldArray(Json$Pos.decodeDict(json), "plans");
  var match$1 = Json$Pos.flatDecodeDictFieldArray(Json$Pos.decodeDict(json), "options");
  if (match !== undefined && match$1 !== undefined) {
    return {
            plans: Belt_Array.keepMap(match, decodeCustomerPlans),
            options: Belt_Array.keepMap(match$1, decodeCustomerOptions)
          };
  }
  
}

var endpoint$3 = Env$Pos.gatewayUrl(undefined) + "/customer-subscriptions/";

function make$4(shopId) {
  return Future.mapOk(Request$Pos.make("GET", undefined, undefined, undefined, endpoint$3 + shopId), undefined, decodeResult$2);
}

var SubscriptionsRequest = {
  decodeCustomerPlans: decodeCustomerPlans,
  decodeCustomerOptions: decodeCustomerOptions,
  decodeResult: decodeResult$2,
  endpoint: endpoint$3,
  make: make$4
};

function encodeBody$1(shopId, ipAddress, userAgent, acceptedAt) {
  return Json$Pos.encodeDict(Js_dict.fromArray([
                  [
                    "shopId",
                    Json$Pos.encodeString(shopId)
                  ],
                  [
                    "ipAddress",
                    Json$Pos.encodeString(ipAddress)
                  ],
                  [
                    "userAgent",
                    Json$Pos.encodeString(userAgent)
                  ],
                  [
                    "acceptedAt",
                    Json$Pos.encodeNumber(acceptedAt)
                  ]
                ]));
}

var endpoint$4 = Env$Pos.gatewayUrl(undefined) + "/billing-account/sepa-mandate";

function decodeInvalidRequestFailure$1(error) {
  if (error.kind === "WrongSepaMandateAcceptanceDetails") {
    return /* WrongSepaMandateAcceptanceDetails */0;
  } else {
    return /* Unknown */2;
  }
}

function make$5(shopId, ipAddress, userAgent, acceptedAt) {
  return Future.mapError(Future.mapOk(Request$Pos.make("PATCH", Caml_option.some(encodeBody$1(shopId, ipAddress, userAgent, acceptedAt)), undefined, undefined, endpoint$4), undefined, (function (param) {
                    
                  })), undefined, (function (error) {
                if (typeof error === "number" || error.TAG !== /* InvalidRequestFailures */2) {
                  return /* Unknown */2;
                } else {
                  return Belt_Option.map(Belt_Array.get(error._0, 0), decodeInvalidRequestFailure$1);
                }
              }));
}

var ConfirmSepaMandateRequest = {
  encodeBody: encodeBody$1,
  endpoint: endpoint$4,
  decodeInvalidRequestFailure: decodeInvalidRequestFailure$1,
  make: make$5
};

function SettingsBillingAccountShowPage$SubscriptionsCard(Props) {
  var subscriptions = Props.subscriptions;
  var tmp;
  var exit = 0;
  if (subscriptions !== undefined && subscriptions.plans.length !== 0) {
    var tmp$1;
    if (subscriptions.options.length !== 0) {
      var triggerToggleView = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                        children: Intl$Pos.t("Options"),
                        weight: "strong"
                      })), undefined, "none", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
      tmp$1 = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Accordion$Pos.make, {
                        children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Columns$Pos.make, {
                                          children: React.createElement(Column$Pos.make, {
                                                children: Belt_Array.map(subscriptions.options, (function (option) {
                                                        return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                                                                children: null,
                                                                                opacity: 0.6
                                                                              }, Intl$Pos.t(option.name), option.quantity > 1 ? " (x" + String(option.quantity) + ")" : null)), undefined, undefined, "none", undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined));
                                                      })),
                                                width: "content"
                                              }),
                                          space: "medium"
                                        })), undefined, undefined, undefined, "small", "xlarge", undefined, undefined, undefined, undefined, undefined, undefined)),
                        triggerShowView: triggerToggleView,
                        triggerHideView: triggerToggleView
                      })), undefined, undefined, "small", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
    } else {
      tmp$1 = null;
    }
    tmp = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), undefined, undefined, "xsmall", "none", undefined, undefined, undefined, undefined, undefined, undefined, undefined), Belt_Array.map(subscriptions.plans, (function (plan) {
                var upcomingInvoiceDate = plan.upcomingInvoiceDate;
                return React.createElement(Inline$Pos.make, {
                            children: null,
                            key: BillingAccount$Pos.BillingPlanKind.toString(plan.kind)
                          }, React.createElement(TextStyle$Pos.make, {
                                children: Intl$Pos.t("Subscription") + " ",
                                variation: "primary",
                                weight: "medium"
                              }), React.createElement(TextStyle$Pos.make, {
                                children: null,
                                variation: "primary",
                                weight: "strong"
                              }, Intl$Pos.t(BillingAccount$Pos.BillingPlanKind.toString(plan.kind)), plan.quantity > 1 ? " (x" + String(plan.quantity) + ")" : null), upcomingInvoiceDate !== undefined ? React.createElement(TooltipIcon$Pos.make, {
                                  children: Intl$Pos.t("Next charge on") + " " + Intl$Pos.dateTimeFormat(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.valFromOption(upcomingInvoiceDate)) + ".",
                                  variation: "info",
                                  placement: "top start",
                                  crossOffset: 0
                                }) : null);
              })), tmp$1);
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Banner$Pos.make, {
          textStatus: {
            TAG: /* Warning */2,
            _0: Intl$Pos.t("Unable to display information about your subscription.")
          }
        });
  }
  return React.createElement(Card$Pos.make, {
              children: React.createElement(Stack$Pos.make, {
                    children: null,
                    space: "medium"
                  }, tmp, React.createElement(TextStyle$Pos.make, {
                        children: Intl$Pos.t("Would you like to change your subscription?") + " "
                      }), React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Contact us"), "medium", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                              HelpCenter$Pos.showMessages(undefined);
                            }), undefined, undefined, undefined)), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextAction$Pos.make, {
                                    text: Intl$Pos.t("Review the terms and conditions of sale."),
                                    highlighted: true,
                                    onPress: (function (param) {
                                        HelpCenter$Pos.showArticle(HelpCenter$Pos.termsAndConditions);
                                      })
                                  })), undefined, undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))),
              title: Intl$Pos.t("Subscription")
            });
}

var SubscriptionsCard = {
  make: SettingsBillingAccountShowPage$SubscriptionsCard
};

function get(values, field) {
  return values.ibanNumber;
}

function set(values, field, value) {
  return {
          ibanNumber: value
        };
}

var EditPaymentMethodFormLenses = {
  get: get,
  set: set
};

var EditPaymentMethodForm = Form$Pos.Make(EditPaymentMethodFormLenses);

var schema = [{
    TAG: /* CustomString */8,
    _0: /* IbanNumber */0,
    _1: (function (value, param) {
        if (CorporateEntity$Pos.Iban.validate(value)) {
          return {
                  TAG: /* Ok */0,
                  _0: undefined
                };
        } else {
          return {
                  TAG: /* Error */1,
                  _0: Intl$Pos.t("The IBAN number is not valid.")
                };
        }
      })
  }];

function SettingsBillingAccountShowPage$EditPaymentMethodFormModal(Props) {
  var opened = Props.opened;
  var onRequestCloseModal = Props.onRequestCloseModal;
  var title = Props.title;
  var activeShopId = Props.activeShopId;
  var alertBannerContent = Props.alertBannerContent;
  var updatePaymentMethodRequest = Props.updatePaymentMethodRequest;
  var reloadBillingAccount = Props.reloadBillingAccount;
  var ipAddressRequest = Props.ipAddressRequest;
  var reloadAlertBar = Props.reloadAlertBar;
  var info = Props.info;
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var error = match[0];
  var onSubmit = function (param, param$1) {
    var ibanNumber = param$1.ibanNumber;
    return Future.map(Future.flatMapOk(Future.flatMap(Curry._1(ipAddressRequest, undefined), undefined, (function (ipRequestResult) {
                          if (ipRequestResult.TAG === /* Ok */0) {
                            return Future.mapError(Curry._5(updatePaymentMethodRequest, activeShopId, ibanNumber, ipRequestResult._0, UserAgent$Pos.get(undefined), Date.now()), undefined, (function (error) {
                                          return {
                                                  NAME: "UpdatePayementMethodFailure",
                                                  VAL: error
                                                };
                                        }));
                          } else {
                            return Future.value({
                                        TAG: /* Error */1,
                                        _0: "IpAddressFailure"
                                      });
                          }
                        })), undefined, (function (param) {
                      return Future.map(Future.all2([
                                      Curry._1(reloadAlertBar, undefined),
                                      Curry._1(reloadBillingAccount, undefined)
                                    ]), undefined, (function (param) {
                                    return {
                                            TAG: /* Ok */0,
                                            _0: undefined
                                          };
                                  }));
                    })), undefined, (function (result) {
                  if (result.TAG === /* Ok */0) {
                    return {
                            TAG: /* Ok */0,
                            _0: undefined
                          };
                  }
                  var error = result._0;
                  if (error === "IpAddressFailure") {
                    BugTracker$Pos.reportErrorMessage("Fetching IP on billing settings failed");
                  }
                  var tmp;
                  if (typeof error === "object") {
                    var match = error.VAL;
                    if (match !== undefined) {
                      switch (match) {
                        case /* WrongSepaMandateAcceptanceDetails */0 :
                            tmp = Intl$Pos.t("Your internet connection has been interrupted, please reload the page before submitting the form again.");
                            break;
                        case /* InvalidIban */1 :
                            tmp = Intl$Pos.t("The IBAN number is not valid.");
                            break;
                        case /* Unknown */2 :
                            tmp = Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                            break;
                        
                      }
                    } else {
                      tmp = Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                    }
                  } else {
                    tmp = Intl$Pos.t("An unexpected error occured. Please try again or contact the support.");
                  }
                  return {
                          TAG: /* Error */1,
                          _0: tmp
                        };
                }));
  };
  var onSubmitSuccess = function (param) {
    Curry._1(onRequestCloseModal, undefined);
    Curry._1(setError, (function (param) {
            
          }));
  };
  var onSubmitFailure = function (message) {
    Curry._1(setError, (function (param) {
            return message;
          }));
  };
  var formPropState = Curry._1(EditPaymentMethodForm.useFormPropState, {
        initialValues: {
          ibanNumber: ""
        },
        schema: schema,
        resetValuesAfterSubmission: true,
        onSubmitFailure: onSubmitFailure,
        onSubmitSuccess: onSubmitSuccess
      });
  var onRequestClose = function (param) {
    Curry._1(onRequestCloseModal, undefined);
  };
  return React.createElement(Modal$Pos.make, {
              children: null,
              opened: opened,
              title: title,
              hideFooter: true,
              onRequestClose: onRequestClose
            }, alertBannerContent !== undefined ? React.createElement(Banner$Pos.make, {
                    textStatus: alertBannerContent
                  }) : null, React.createElement(EditPaymentMethodForm.Core.Provider.make, {
                  children: null,
                  propState: formPropState
                }, error !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                                    textStatus: {
                                      TAG: /* Danger */1,
                                      _0: error
                                    },
                                    onRequestClose: (function (param) {
                                        Curry._1(setError, (function (param) {
                                                
                                              }));
                                      })
                                  })), "xlarge", undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), "xlarge", undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), info !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextStyle$Pos.make, {
                                        children: Intl$Pos.t(info),
                                        opacity: 0.5
                                      })), undefined, undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(Stack$Pos.make, {
                          children: null,
                          space: "small"
                        }, React.createElement(EditPaymentMethodForm.InputText.make, {
                              field: /* IbanNumber */0,
                              label: Intl$Pos.t("IBAN number"),
                              placeholder: Intl$Pos.t("FR12 1234 5678 1000 0000 0000 123")
                            }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                                          textStatus: {
                                            TAG: /* Info */3,
                                            _0: Intl$Pos.t("By providing your IBAN and clicking 'Confirm' from this window, you authorize Wino Technologies and Stripe, our payment service provider, to send instructions to your bank to debit your account, and your bank to debit your account in accordance with these instructions. You are entitled to a refund from your bank according to the terms and conditions of the agreement you have with them. The refund must be requested within 8 weeks from the date your account was debited.")
                                          }
                                        })), undefined, undefined, "medium", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                      children: null,
                                      space: "xmedium",
                                      align: "end"
                                    }, React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Cancel"), "large", "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, (function (param) {
                                                Curry._1(onRequestCloseModal, undefined);
                                              }), undefined, undefined, undefined)), React.createElement(EditPaymentMethodForm.SubmitButton.make, {
                                          variation: "success",
                                          onSubmit: onSubmit,
                                          text: Intl$Pos.t("Confirm")
                                        }))), undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)))));
}

var EditPaymentMethodFormModal = {
  schema: schema,
  make: SettingsBillingAccountShowPage$EditPaymentMethodFormModal
};

function SettingsBillingAccountShowPage$ConfirmSepaMandateModal(Props) {
  var opened = Props.opened;
  var onRequestCloseModal = Props.onRequestCloseModal;
  var alertBannerContent = Props.alertBannerContent;
  var activeShopId = Props.activeShopId;
  var confirmSepaMandateRequest = Props.confirmSepaMandateRequest;
  var ipAddressRequest = Props.ipAddressRequest;
  var reloadBillingAccount = Props.reloadBillingAccount;
  var reloadAlertBar = Props.reloadAlertBar;
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var error = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoading = match$1[1];
  var loading = match$1[0];
  var onPress = function (param) {
    Curry._1(setLoading, (function (param) {
            return true;
          }));
    Future.get(Future.flatMapOk(Future.flatMap(Curry._1(ipAddressRequest, undefined), undefined, (function (ipRequestResult) {
                    if (ipRequestResult.TAG === /* Ok */0) {
                      return Future.mapError(Curry._4(confirmSepaMandateRequest, activeShopId, ipRequestResult._0, UserAgent$Pos.get(undefined), Date.now()), undefined, (function (param) {
                                    return "ConfirmSepaMandateRequestFailure";
                                  }));
                    } else {
                      return Future.value({
                                  TAG: /* Error */1,
                                  _0: "IpAddressFailure"
                                });
                    }
                  })), undefined, (function (param) {
                return Future.map(Future.all2([
                                Curry._1(reloadAlertBar, undefined),
                                Curry._1(reloadBillingAccount, undefined)
                              ]), undefined, (function (param) {
                              return {
                                      TAG: /* Ok */0,
                                      _0: undefined
                                    };
                            }));
              })), (function (result) {
            Curry._1(setLoading, (function (param) {
                    return false;
                  }));
            if (result.TAG === /* Ok */0) {
              Curry._1(setError, (function (param) {
                      
                    }));
              return Curry._1(onRequestCloseModal, undefined);
            }
            var error = result._0;
            if (error === "ConfirmSepaMandateRequestFailure") {
              BugTracker$Pos.reportErrorMessage("Fetching IP on billing settings failed");
            }
            var message = error === "IpAddressFailure" ? "Your internet connection has been interrupted, please reload the page before submitting the form again." : "An unexpected error occured. Please try again or contact the support.";
            Curry._1(setError, (function (param) {
                    return Intl$Pos.t(message);
                  }));
          }));
  };
  var onRequestClose = function (param) {
    Curry._1(onRequestCloseModal, undefined);
  };
  return React.createElement(Modal$Pos.make, {
              children: null,
              opened: opened,
              title: Intl$Pos.t("Confirm Your SEPA Direct Debit Authorization"),
              hideFooter: true,
              onRequestClose: onRequestClose
            }, alertBannerContent !== undefined ? React.createElement(Banner$Pos.make, {
                    textStatus: alertBannerContent
                  }) : null, error !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                                textStatus: {
                                  TAG: /* Danger */1,
                                  _0: error
                                },
                                onRequestClose: (function (param) {
                                    Curry._1(setError, (function (param) {
                                            
                                          }));
                                  })
                              })), "xlarge", undefined, "xsmall", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(null), "xlarge", undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), React.createElement(Banner$Pos.make, {
                      textStatus: {
                        TAG: /* Info */3,
                        _0: Intl$Pos.t("By providing your IBAN and clicking 'Confirm' from this window, you authorize Wino Technologies and Stripe, our payment service provider, to send instructions to your bank to debit your account, and your bank to debit your account in accordance with these instructions. You are entitled to a refund from your bank according to the terms and conditions of the agreement you have with them. The refund must be requested within 8 weeks from the date your account was debited.")
                      }
                    }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Inline$Pos.make, {
                                  children: null,
                                  space: "xmedium",
                                  align: "end"
                                }, React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Cancel"), "large", "neutral", undefined, undefined, undefined, loading === true, undefined, undefined, undefined, (function (param) {
                                            Curry._1(onRequestCloseModal, undefined);
                                          }), undefined, undefined, undefined)), React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Confirm"), "large", "success", undefined, undefined, loading, undefined, undefined, undefined, undefined, onPress, undefined, undefined, undefined)))), undefined, "xlarge", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))));
}

var ConfirmSepaMandateModal = {
  make: SettingsBillingAccountShowPage$ConfirmSepaMandateModal
};

function SettingsBillingAccountShowPage$ErrorBanner(Props) {
  return React.createElement(Banner$Pos.make, {
              textStatus: {
                TAG: /* Warning */2,
                _0: Intl$Pos.t(Intl$Pos.t("Some subscription and billing settings cannot be displayed at the moment."))
              }
            });
}

var ErrorBanner = {
  make: SettingsBillingAccountShowPage$ErrorBanner
};

function SettingsBillingAccountShowPage$PaymentMethodCard(Props) {
  var confirmSepaModalOpened = Props.confirmSepaModalOpened;
  var editPaymentMethodModalOpened = Props.editPaymentMethodModalOpened;
  var onRequestCloseModal = Props.onRequestCloseModal;
  var handleOpenConfirmMandataModal = Props.handleOpenConfirmMandataModal;
  var handleOpenEditPaymentMethodModal = Props.handleOpenEditPaymentMethodModal;
  var billingAccount = Props.billingAccount;
  var billingStatus = Props.billingStatus;
  var sepaMandateRequest = Props.sepaMandateRequest;
  var activeShopId = Props.activeShopId;
  var shopName = Props.shopName;
  var updatePaymentMethodRequest = Props.updatePaymentMethodRequest;
  var confirmSepaMandateRequest = Props.confirmSepaMandateRequest;
  var ipAddressRequest = Props.ipAddressRequest;
  var reloadBillingAccount = Props.reloadBillingAccount;
  var reloadAlertBar = Props.reloadAlertBar;
  var scope = Auth$Pos.useScope(undefined);
  var captureEvent = SessionTracker$Pos.useCaptureEvent(undefined);
  var organisationAccount;
  organisationAccount = scope.TAG === /* Organisation */0 ? true : false;
  var match = React.useState(function () {
        
      });
  var setSuccessNotification = match[1];
  var successNotification = match[0];
  var match$1 = billingStatus !== undefined ? (
      billingStatus !== 0 ? [
          "edit_light",
          "Change payment method",
          "When confirming a new payment method, your current payment method will be removed."
        ] : [
          "plus_light",
          "Add a payment method",
          undefined
        ]
    ) : (
      billingAccount !== undefined && billingAccount.iban === undefined ? [
          "plus_light",
          "Add a payment method",
          undefined
        ] : [
          "edit_light",
          "Change payment method",
          "When confirming a new payment method, your current payment method will be removed."
        ]
    );
  var action_icon = match$1[0];
  var action_title = Intl$Pos.t(match$1[1]);
  var action_handler = {
    TAG: /* Callback */0,
    _0: handleOpenEditPaymentMethodModal
  };
  var action = {
    icon: action_icon,
    title: action_title,
    handler: action_handler
  };
  var alertBannerContentUpdatePayment = billingStatus === 0 ? (
      organisationAccount ? ({
            TAG: /* Danger */1,
            _0: Intl$Pos.template(Intl$Pos.t("Valid payment method is required for the shop {{shopName}}."), {
                  shopName: shopName
                }, undefined)
          }) : ({
            TAG: /* Danger */1,
            _0: Intl$Pos.t("Valid payment method is required.")
          })
    ) : undefined;
  var alertBannerContentConfirmSepa = billingStatus === 1 ? (
      organisationAccount ? ({
            TAG: /* Warning */2,
            _0: Intl$Pos.template(Intl$Pos.t("Your SEPA direct debit authorization confirmation is required for the shop {{shopName}}."), {
                  shopName: shopName
                }, undefined)
          }) : ({
            TAG: /* Warning */2,
            _0: Intl$Pos.t("Your SEPA direct debit authorization confirmation is required.")
          })
    ) : undefined;
  var match$2 = React.useState(function () {
        return AsyncResult$Pos.notAsked(undefined);
      });
  var setMandate = match$2[1];
  var mandate = match$2[0];
  var editPaymentMethodModalTitle = billingAccount !== undefined && billingAccount.iban !== undefined ? Intl$Pos.t("Edit Payment Method") : Intl$Pos.t("Add a payment method");
  React.useEffect((function () {
          if (billingStatus !== undefined) {
            return ;
          }
          var request = Curry._1(sepaMandateRequest, activeShopId);
          Curry._1(setMandate, (function (param) {
                  return /* Loading */1;
                }));
          Future.get(request, (function (results) {
                  if (results.TAG !== /* Ok */0) {
                    return Curry._1(setMandate, (function (param) {
                                  return {
                                          TAG: /* Done */1,
                                          _0: {
                                            TAG: /* Error */1,
                                            _0: undefined
                                          }
                                        };
                                }));
                  }
                  var mandate = results._0;
                  Curry._1(setMandate, (function (param) {
                          return {
                                  TAG: /* Done */1,
                                  _0: {
                                    TAG: /* Ok */0,
                                    _0: mandate
                                  }
                                };
                        }));
                }));
          return (function (param) {
                    Future.cancel(request);
                  });
        }), [billingStatus]);
  var handleConfirmSepaMandate = function (param) {
    Curry._1(captureEvent, "validate_sepa_mandate");
    Curry._1(handleOpenConfirmMandataModal, undefined);
  };
  var tmp;
  if (billingAccount !== undefined) {
    var match$3 = billingAccount.iban;
    if (match$3 !== undefined) {
      var tmp$1;
      if (typeof mandate === "number") {
        tmp$1 = mandate === /* NotAsked */0 ? null : React.createElement(Spinner$Pos.make, {});
      } else if (mandate.TAG === /* Reloading */0) {
        tmp$1 = null;
      } else {
        var match$4 = mandate._0;
        if (match$4.TAG === /* Ok */0) {
          var match$5 = match$4._0;
          if (match$5 !== undefined && match$5.status === 0) {
            var acceptedAt = match$5.acceptedAt;
            tmp$1 = acceptedAt !== undefined ? React.createElement(TextStyle$Pos.make, {
                    children: Intl$Pos.t("Accepted mandate on") + " " + Intl$Pos.dateTimeFormat("short", undefined, undefined, undefined, undefined, undefined, undefined, undefined, new Date(acceptedAt))
                  }) : null;
          } else {
            tmp$1 = null;
          }
        } else {
          tmp$1 = React.createElement(SettingsBillingAccountShowPage$ErrorBanner, {});
        }
      }
      tmp = React.createElement(React.Fragment, undefined, React.createElement(TextStyle$Pos.make, {
                children: Intl$Pos.t("SEPA direct debit") + " • • • • " + match$3.last4
              }), tmp$1);
    } else {
      tmp = billingStatus !== undefined ? null : React.createElement(Banner$Pos.make, {
              textStatus: {
                TAG: /* Warning */2,
                _0: Intl$Pos.t("Attention, no valid payment method is registered.")
              }
            });
    }
  } else {
    tmp = null;
  }
  var tmp$2;
  if (billingStatus !== undefined) {
    switch (billingStatus) {
      case /* MissingPaymentMethod */0 :
          tmp$2 = successNotification !== undefined ? null : React.createElement(Banner$Pos.make, {
                  textStatus: {
                    TAG: /* Danger */1,
                    _0: Intl$Pos.t("Attention, no valid payment method is registered.")
                  }
                });
          break;
      case /* InvalidBillingMandate */1 :
          tmp$2 = successNotification !== undefined ? null : React.createElement(Stack$Pos.make, {
                  children: null,
                  space: "medium"
                }, React.createElement(Banner$Pos.make, {
                      textStatus: {
                        TAG: /* Warning */2,
                        _0: Intl$Pos.t("Please confirm your SEPA direct debit authorization.")
                      }
                    }), React.createElement(Button$Pos.make, Button$Pos.makeProps(Intl$Pos.t("Confirm"), undefined, "neutral", undefined, undefined, undefined, undefined, undefined, undefined, undefined, handleConfirmSepaMandate, undefined, undefined, undefined)));
          break;
      case /* InvoicePaymentOverdue */2 :
          tmp$2 = null;
          break;
      
    }
  } else {
    tmp$2 = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Card$Pos.make, {
                  children: React.createElement(Stack$Pos.make, {
                        children: null,
                        space: "medium"
                      }, tmp, tmp$2, successNotification !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                                          textStatus: successNotification,
                                          onRequestClose: (function (param) {
                                              Curry._1(setSuccessNotification, (function (param) {
                                                      
                                                    }));
                                            })
                                        })), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null),
                  title: Intl$Pos.t("Payment method"),
                  action: action
                }), React.createElement(SettingsBillingAccountShowPage$EditPaymentMethodFormModal, {
                  opened: editPaymentMethodModalOpened,
                  onRequestCloseModal: onRequestCloseModal,
                  title: editPaymentMethodModalTitle,
                  activeShopId: activeShopId,
                  alertBannerContent: alertBannerContentUpdatePayment,
                  updatePaymentMethodRequest: updatePaymentMethodRequest,
                  reloadBillingAccount: reloadBillingAccount,
                  ipAddressRequest: ipAddressRequest,
                  reloadAlertBar: reloadAlertBar,
                  info: match$1[2]
                }), React.createElement(SettingsBillingAccountShowPage$ConfirmSepaMandateModal, {
                  opened: confirmSepaModalOpened,
                  onRequestCloseModal: onRequestCloseModal,
                  alertBannerContent: alertBannerContentConfirmSepa,
                  activeShopId: activeShopId,
                  confirmSepaMandateRequest: confirmSepaMandateRequest,
                  ipAddressRequest: ipAddressRequest,
                  reloadBillingAccount: reloadBillingAccount,
                  reloadAlertBar: reloadAlertBar
                }));
}

var PaymentMethodCard = {
  make: SettingsBillingAccountShowPage$PaymentMethodCard
};

function SettingsBillingAccountShowPage$AddressBox(Props) {
  var value = Props.value;
  var title = Props.title;
  var tmp;
  if (value !== undefined) {
    var countrycode = CountryCode$Pos.fromString(value.country);
    var country;
    country = countrycode.TAG === /* Ok */0 ? React.createElement(TextStyle$Pos.make, {
            children: Intl$Pos.t(CountryCode$Pos.toMediumCountryString(countrycode._0))
          }) : React.createElement(TextStyle$Pos.make, {
            children: Intl$Pos.t("Country not specified"),
            variation: "subdued"
          });
    tmp = React.createElement(React.Fragment, undefined, React.createElement(TextStyle$Pos.make, {
              children: value.address
            }), React.createElement(TextStyle$Pos.make, {
              children: value.postalCode + " " + value.city
            }), country);
  } else {
    tmp = React.createElement(TextStyle$Pos.make, {
          children: Intl$Pos.t("Address not provided"),
          variation: "subdued"
        });
  }
  return React.createElement(InfoBlock$Pos.make, {
              children: tmp,
              title: title
            });
}

var AddressBox = {
  make: SettingsBillingAccountShowPage$AddressBox
};

function SettingsBillingAccountShowPage$BillingInformationCard(Props) {
  var activeShopId = Props.activeShopId;
  var billingAccount = Props.billingAccount;
  if (billingAccount === undefined) {
    return null;
  }
  var vatNumber = billingAccount.vatNumber;
  var shippingAddress = billingAccount.shippingAddress;
  var billingAddress = billingAccount.billingAddress;
  var phone = billingAccount.phone;
  var email = billingAccount.email;
  var shopName = billingAccount.shopName;
  var corporateName = billingAccount.corporateName;
  return React.createElement(Card$Pos.make, {
              children: React.createElement(Stack$Pos.make, {
                    children: null,
                    space: "large"
                  }, React.createElement(Stack$Pos.make, {
                        children: null
                      }, corporateName !== undefined ? React.createElement(TextStyle$Pos.make, {
                              children: corporateName
                            }) : React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.t("Corporate name not provided"),
                              variation: "subdued"
                            }), shopName !== undefined ? React.createElement(TextStyle$Pos.make, {
                              children: shopName
                            }) : null, email !== undefined ? React.createElement(TextStyle$Pos.make, {
                              children: email
                            }) : React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.t("Email not provided"),
                              variation: "subdued"
                            }), phone !== undefined ? React.createElement(TextStyle$Pos.make, {
                              children: phone
                            }) : React.createElement(TextStyle$Pos.make, {
                              children: Intl$Pos.t("Phone number not provided"),
                              variation: "subdued"
                            })), vatNumber !== undefined ? React.createElement(TextStyle$Pos.make, {
                          children: vatNumber
                        }) : React.createElement(TextStyle$Pos.make, {
                          children: Intl$Pos.t("VAT number not provided"),
                          variation: "subdued"
                        }), React.createElement(SettingsBillingAccountShowPage$AddressBox, {
                        value: billingAddress,
                        title: Intl$Pos.t("Billing address")
                      }), React.createElement(SettingsBillingAccountShowPage$AddressBox, {
                        value: shippingAddress,
                        title: Intl$Pos.t("Shipping address")
                      }), Belt_Array.some([
                        corporateName,
                        email,
                        vatNumber,
                        phone
                      ], Belt_Option.isNone) || Belt_Array.some([
                        billingAddress,
                        shippingAddress
                      ], Belt_Option.isNone) ? React.createElement(Banner$Pos.make, {
                          textStatus: {
                            TAG: /* Warning */2,
                            _0: Intl$Pos.t("Attention, important information needs to be completed.")
                          }
                        }) : null),
              title: Intl$Pos.t("Billing information"),
              action: {
                icon: "edit_light",
                title: Intl$Pos.t("Edit billing information"),
                handler: {
                  TAG: /* OpenLink */1,
                  _0: {
                    TAG: /* RouteWithQueryString */1,
                    _0: SettingsRoutes$Pos.billingAccountEditRoute,
                    _1: SettingsRoutes$Pos.encodeEditBillingAccountQueryString(activeShopId, Belt_Option.getWithDefault(corporateName, ""), Belt_Option.getWithDefault(shopName, ""), Belt_Option.getWithDefault(email, ""), Belt_Option.getWithDefault(phone, ""), billingAddress, shippingAddress, vatNumber)
                  }
                }
              }
            });
}

var BillingInformationCard = {
  make: SettingsBillingAccountShowPage$BillingInformationCard
};

function SettingsBillingAccountShowPage$WarningNotificationBanner(Props) {
  var billingAccount = Props.billingAccount;
  if (billingAccount === undefined) {
    return null;
  }
  if (billingAccount.vatNumber !== undefined && billingAccount.shippingAddress !== undefined && billingAccount.billingAddress !== undefined && billingAccount.phone !== undefined && billingAccount.email !== undefined && billingAccount.corporateName !== undefined) {
    return null;
  }
  return React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                          textStatus: {
                            TAG: /* Warning */2,
                            _0: Intl$Pos.t("Some billing information are missing. While this is not required to use the solution, providing it is necessary to ensure legal compliance.")
                          }
                        })), undefined, undefined, "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
}

var WarningNotificationBanner = {
  make: SettingsBillingAccountShowPage$WarningNotificationBanner
};

function SettingsBillingAccountShowPage$BillingInvoicesCard(Props) {
  var shopId = Props.shopId;
  var invoicesRequest = Props.invoicesRequest;
  return React.createElement(Card$Pos.make, {
              children: React.createElement(BillingAccountInvoicesTable$Pos.make, {
                    shopId: shopId,
                    invoicesRequest: invoicesRequest,
                    preview: true
                  }),
              title: Intl$Pos.t("Invoice history"),
              variation: "table"
            });
}

var BillingInvoicesCard = {
  make: SettingsBillingAccountShowPage$BillingInvoicesCard
};

function SettingsBillingAccountShowPage(Props) {
  var shopId = Props.shopId;
  var confirmSepaModalOpened = Props.confirmSepaModalOpened;
  var editPaymentMethodModalOpened = Props.editPaymentMethodModalOpened;
  var billingAccountRequest = Props.billingAccountRequest;
  var billingStatusRequest = Props.billingStatusRequest;
  var sepaMandateRequest = Props.sepaMandateRequest;
  var updatePaymentMethodRequest = Props.updatePaymentMethodRequest;
  var invoicesRequest = Props.invoicesRequest;
  var subscriptionsRequest = Props.subscriptionsRequest;
  var confirmSepaMandateRequest = Props.confirmSepaMandateRequest;
  var ipAddressRequest = Props.ipAddressRequest;
  var reloadAlertBar = Props.reloadAlertBar;
  var notification = Props.notification;
  var navigate = Navigation$Pos.useNavigate(undefined);
  var onRequestCloseModal = function (param) {
    Curry._3(navigate, true, undefined, SettingsRoutes$Pos.billingAccountShowRoute(shopId, undefined));
  };
  var handleOpenConfirmMandataModal = function (param) {
    Curry._3(navigate, true, undefined, SettingsRoutes$Pos.billingAccountShowRouteWithModal(shopId, /* ConfirmMandate */1));
  };
  var handleOpenEditPaymentMethodModal = function (param) {
    Curry._3(navigate, true, undefined, SettingsRoutes$Pos.billingAccountShowRouteWithModal(shopId, /* EditPaymentMethod */0));
  };
  var shops = Auth$Pos.useShops(undefined);
  var currentShop = Belt_Array.getBy(shops, (function (shop) {
          return shop.id === shopId;
        }));
  var scope = Auth$Pos.useScope(undefined);
  var organisationAccount;
  organisationAccount = scope.TAG === /* Organisation */0 ? true : false;
  var match = React.useState(function () {
        return AsyncResult$Pos.notAsked(undefined);
      });
  var setState = match[1];
  var state = match[0];
  React.useEffect((function () {
          Curry._1(setState, (function (param) {
                  return /* Loading */1;
                }));
          var future = Future.all3([
                Curry._1(billingAccountRequest, shopId),
                Curry._1(billingStatusRequest, shopId),
                Curry._1(subscriptionsRequest, shopId)
              ]);
          Future.get(future, (function (results) {
                  var billingAccount = results[0];
                  if (billingAccount.TAG === /* Ok */0) {
                    var billingStatus = results[1];
                    var billingAccount$1 = billingAccount._0;
                    if (billingStatus.TAG === /* Ok */0) {
                      var subscriptions = results[2];
                      var billingStatus$1 = billingStatus._0;
                      if (subscriptions.TAG === /* Ok */0) {
                        var subscriptions$1 = subscriptions._0;
                        return Curry._1(setState, (function (param) {
                                      return {
                                              TAG: /* Done */1,
                                              _0: {
                                                TAG: /* Ok */0,
                                                _0: {
                                                  billingAccount: billingAccount$1,
                                                  billingStatus: billingStatus$1,
                                                  subscriptions: subscriptions$1
                                                }
                                              }
                                            };
                                    }));
                      }
                      
                    }
                    
                  }
                  Curry._1(setState, (function (param) {
                          return {
                                  TAG: /* Done */1,
                                  _0: {
                                    TAG: /* Error */1,
                                    _0: undefined
                                  }
                                };
                        }));
                }));
          return (function (param) {
                    Future.cancel(future);
                  });
        }), [currentShop]);
  var reloadBillingAccount = function (param) {
    Curry._1(setState, AsyncResult$Pos.toBusy);
    var future = Future.all2([
          Curry._1(billingAccountRequest, shopId),
          Curry._1(billingStatusRequest, shopId)
        ]);
    return Future.map(future, undefined, (function (results) {
                  Curry._1(setState, (function (prevState) {
                          var billingAccount = results[0];
                          if (billingAccount.TAG !== /* Ok */0) {
                            return {
                                    TAG: /* Done */1,
                                    _0: {
                                      TAG: /* Error */1,
                                      _0: undefined
                                    }
                                  };
                          }
                          var billingStatus = results[1];
                          var billingAccount$1 = billingAccount._0;
                          if (billingStatus.TAG !== /* Ok */0) {
                            return {
                                    TAG: /* Done */1,
                                    _0: {
                                      TAG: /* Error */1,
                                      _0: undefined
                                    }
                                  };
                          }
                          var billingStatus$1 = billingStatus._0;
                          if (typeof prevState === "number") {
                            return {
                                    TAG: /* Done */1,
                                    _0: {
                                      TAG: /* Error */1,
                                      _0: undefined
                                    }
                                  };
                          }
                          if (prevState.TAG === /* Reloading */0) {
                            var match = prevState._0;
                            if (match.TAG === /* Ok */0) {
                              return {
                                      TAG: /* Done */1,
                                      _0: {
                                        TAG: /* Ok */0,
                                        _0: {
                                          billingAccount: billingAccount$1,
                                          billingStatus: billingStatus$1,
                                          subscriptions: match._0.subscriptions
                                        }
                                      }
                                    };
                            } else {
                              return {
                                      TAG: /* Done */1,
                                      _0: {
                                        TAG: /* Error */1,
                                        _0: undefined
                                      }
                                    };
                            }
                          }
                          var match$1 = prevState._0;
                          if (match$1.TAG === /* Ok */0) {
                            return {
                                    TAG: /* Done */1,
                                    _0: {
                                      TAG: /* Ok */0,
                                      _0: {
                                        billingAccount: billingAccount$1,
                                        billingStatus: billingStatus$1,
                                        subscriptions: match$1._0.subscriptions
                                      }
                                    }
                                  };
                          } else {
                            return {
                                    TAG: /* Done */1,
                                    _0: {
                                      TAG: /* Error */1,
                                      _0: undefined
                                    }
                                  };
                          }
                        }));
                }));
  };
  var onChangeShop = function (value) {
    Curry._3(navigate, true, undefined, SettingsRoutes$Pos.billingAccountShowRoute(value.id, undefined));
  };
  var headerActions = organisationAccount ? React.createElement(Inline$Pos.make, {
          children: React.createElement(Auth$Pos.SelectSingleShopFilter.make, {
                value: currentShop,
                onChange: onChangeShop
              })
        }) : null;
  var tmp;
  var exit = 0;
  var billingAccount;
  var billingStatus;
  var subscriptions;
  if (typeof state === "number") {
    exit = 3;
  } else if (state.TAG === /* Reloading */0) {
    var match$1 = state._0;
    if (match$1.TAG === /* Ok */0) {
      var match$2 = match$1._0;
      billingAccount = match$2.billingAccount;
      billingStatus = match$2.billingStatus;
      subscriptions = match$2.subscriptions;
      exit = 2;
    } else {
      exit = 1;
    }
  } else {
    var match$3 = state._0;
    if (match$3.TAG === /* Ok */0) {
      var match$4 = match$3._0;
      billingAccount = match$4.billingAccount;
      billingStatus = match$4.billingStatus;
      subscriptions = match$4.subscriptions;
      exit = 2;
    } else {
      exit = 1;
    }
  }
  switch (exit) {
    case 1 :
        tmp = React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                          textStatus: {
                            TAG: /* Danger */1,
                            _0: Intl$Pos.t("An unexpected error occured. Please try again or contact the support.")
                          }
                        })), undefined, undefined, "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined));
        break;
    case 2 :
        tmp = React.createElement(React.Fragment, undefined, React.createElement(SettingsBillingAccountShowPage$WarningNotificationBanner, {
                  billingAccount: billingAccount
                }), React.createElement(Stack$Pos.make, {
                  children: React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Columns$Pos.make, {
                                    children: null,
                                    space: "large"
                                  }, React.createElement(Column$Pos.make, {
                                        children: React.createElement(Stack$Pos.make, {
                                              children: null,
                                              space: "medium"
                                            }, React.createElement(SettingsBillingAccountShowPage$SubscriptionsCard, {
                                                  subscriptions: subscriptions
                                                }), React.createElement(SettingsBillingAccountShowPage$PaymentMethodCard, {
                                                  confirmSepaModalOpened: confirmSepaModalOpened,
                                                  editPaymentMethodModalOpened: editPaymentMethodModalOpened,
                                                  onRequestCloseModal: onRequestCloseModal,
                                                  handleOpenConfirmMandataModal: handleOpenConfirmMandataModal,
                                                  handleOpenEditPaymentMethodModal: handleOpenEditPaymentMethodModal,
                                                  billingAccount: billingAccount,
                                                  billingStatus: billingStatus,
                                                  sepaMandateRequest: sepaMandateRequest,
                                                  activeShopId: shopId,
                                                  shopName: currentShop.name,
                                                  updatePaymentMethodRequest: updatePaymentMethodRequest,
                                                  confirmSepaMandateRequest: confirmSepaMandateRequest,
                                                  ipAddressRequest: ipAddressRequest,
                                                  reloadBillingAccount: reloadBillingAccount,
                                                  reloadAlertBar: reloadAlertBar
                                                }), React.createElement(SettingsBillingAccountShowPage$BillingInformationCard, {
                                                  activeShopId: shopId,
                                                  billingAccount: billingAccount
                                                })),
                                        width: "one_third"
                                      }), React.createElement(Column$Pos.make, {
                                        children: React.createElement(SettingsBillingAccountShowPage$BillingInvoicesCard, {
                                              shopId: shopId,
                                              invoicesRequest: invoicesRequest
                                            }),
                                        width: "three_quarter"
                                      }))), undefined, undefined, "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined))
                }));
        break;
    case 3 :
        tmp = React.createElement(Placeholder$Pos.make, {
              status: /* Loading */0
            });
        break;
    
  }
  return React.createElement(Page$Pos.make, {
              children: null,
              variation: "compact",
              title: Intl$Pos.t("Subscription and billing"),
              renderHeaderActions: (function (param) {
                  return headerActions;
                })
            }, notification !== undefined ? React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(Banner$Pos.make, {
                                textStatus: notification
                              })), undefined, undefined, "large", undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)) : null, tmp);
}

var billingAccountRequest = make;

var billingStatusRequest = make$1;

var sepaMandateRequest = make$2;

var updatePaymentMethodRequest = make$3;

var subscriptionsRequest = make$4;

var confirmSepaMandateRequest = make$5;

var make$6 = SettingsBillingAccountShowPage;

export {
  BillingAccountRequest ,
  BillingStatusRequest ,
  SepaMandateRequest ,
  UpdatePaymentMethodRequest ,
  SubscriptionsRequest ,
  ConfirmSepaMandateRequest ,
  SubscriptionsCard ,
  EditPaymentMethodFormLenses ,
  EditPaymentMethodForm ,
  EditPaymentMethodFormModal ,
  ConfirmSepaMandateModal ,
  ErrorBanner ,
  PaymentMethodCard ,
  AddressBox ,
  BillingInformationCard ,
  WarningNotificationBanner ,
  BillingInvoicesCard ,
  billingAccountRequest ,
  billingStatusRequest ,
  sepaMandateRequest ,
  updatePaymentMethodRequest ,
  subscriptionsRequest ,
  confirmSepaMandateRequest ,
  make$6 as make,
}
/* endpoint Not a pure module */
