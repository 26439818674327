// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Svg$Pos from "../../primitives/Svg.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as ReactAria from "react-aria";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAria$Pos from "../../externals/ReactAria.bs.js";
import * as ReactStately from "react-stately";

function Checkbox$CheckboxSvg(Props) {
  var state = Props.state;
  var size = Props.size;
  var hovered = Props.hovered;
  var disabled = Props.disabled;
  var tmp;
  switch (state) {
    case /* Checked */0 :
        tmp = "M13 0a3 3 0 013 3v10a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3zm0 2H3a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1V3a1 1 0 00-1-1zm-.448 3.349a1 1 0 01.083 1.32l-.083.094-4.687 4.687a1 1 0 01-1.325.08l-.094-.084-2.313-2.344a1 1 0 011.33-1.489l.094.084 1.605 1.627 3.976-3.975a1 1 0 011.414 0z";
        break;
    case /* Indeterminate */1 :
        tmp = "M13 0a3 3 0 013 3v10a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3zm0 2H3a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1V3a1 1 0 00-1-1zm-2 5a1 1 0 010 2H5a1 1 0 110-2h6z";
        break;
    case /* Unchecked */2 :
        tmp = "M14 0a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2a2 2 0 012-2h12zm0 1H2a1 1 0 00-.993.883L1 2v12a1 1 0 00.883.993L2 15h12a1 1 0 00.993-.883L15 14V2a1 1 0 00-.883-.993L14 1z";
        break;
    
  }
  return React.createElement(Svg$Pos.make, {
              children: React.createElement(Svg$Pos.Path.make, {
                    d: tmp,
                    fill: state >= 2 ? (
                        hovered ? (
                            disabled ? (
                                disabled ? "#d7d7e0" : "#a01e72"
                              ) : "#bdbdca"
                          ) : (
                            disabled && !disabled ? "#a01e72" : "#d7d7e0"
                          )
                      ) : (
                        disabled ? "#d7d7e0" : "#a01e72"
                      )
                  }),
              width: String(size),
              height: String(size),
              viewBox: "0 0 16 16"
            });
}

function makeStyle(size) {
  return {
          backgroundColor: "#fefefe",
          display: "flex",
          margin: "auto",
          maxWidth: String(size) + "px",
          position: "relative",
          width: String(size) + "px",
          borderRadius: "5px",
          alignSelf: "start"
        };
}

function makeProxyInputStyle(size) {
  return {
          height: String(size) + "px",
          margin: "0",
          maxWidth: String(size) + "px",
          position: "absolute",
          width: String(size) + "px",
          opacity: "0"
        };
}

function Checkbox(Props) {
  var sizeOpt = Props.size;
  var indeterminateOpt = Props.indeterminate;
  var disabledOpt = Props.disabled;
  var accessibilityLabelOpt = Props.accessibilityLabel;
  var ariaProps = Props.ariaProps;
  var value = Props.value;
  var onChange = Props.onChange;
  var size = sizeOpt !== undefined ? sizeOpt : 15;
  var indeterminate = indeterminateOpt !== undefined ? indeterminateOpt : false;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var accessibilityLabel = accessibilityLabelOpt !== undefined ? accessibilityLabelOpt : "checkbox";
  var ref = React.useRef(null);
  var match = Hover$Pos.use(ref, undefined);
  var hovered = match[1];
  var defaultProps = {
    "aria-label": accessibilityLabel,
    isIndeterminate: indeterminate,
    isSelected: value,
    isDisabled: disabled,
    onChange: onChange
  };
  var props = Belt_Option.getWithDefault(Belt_Option.map(ariaProps, (function (props) {
              var newrecord = Caml_obj.obj_dup(props);
              newrecord.isSelected = value;
              newrecord["aria-label"] = accessibilityLabel;
              return newrecord;
            })), defaultProps);
  var state = ReactStately.useToggleState(props);
  var match$1 = ReactAria.useCheckbox(props, state, ref);
  var disabled$1 = props.isDisabled;
  var match$2 = props.isIndeterminate || disabled$1;
  var tmp;
  var exit = 0;
  if (value && !match$2) {
    tmp = React.createElement(Checkbox$CheckboxSvg, {
          state: /* Checked */0,
          size: size,
          hovered: hovered,
          disabled: disabled$1
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = match$2 ? React.createElement(Checkbox$CheckboxSvg, {
            state: /* Indeterminate */1,
            size: size,
            hovered: hovered,
            disabled: disabled$1
          }) : React.createElement(Checkbox$CheckboxSvg, {
            state: /* Unchecked */2,
            size: size,
            hovered: hovered,
            disabled: disabled$1
          });
  }
  return React.createElement("label", {
              style: makeStyle(size)
            }, React.createElement(ReactAria$Pos.Spread.make, {
                  props: match$1.inputProps,
                  children: React.createElement("input", {
                        ref: ref,
                        style: makeProxyInputStyle(size)
                      })
                }), tmp);
}

var make = React.memo(Checkbox);

export {
  make ,
}
/* make Not a pure module */
