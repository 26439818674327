// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as Caml_exceptions from "rescript/lib/es6/caml_exceptions.js";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as CatalogVariant$Pos from "./CatalogVariant.bs.js";
import * as StockActivityQuantity$Pos from "../StockActivity/StockActivityQuantity.bs.js";
import * as CatalogProductEditFormMutations$Pos from "./CatalogProductEditFormMutations.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var CreateProductAndVariant_ProductValuesNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogVariantEditFormMutations-Pos.CreateProductAndVariant_ProductValuesNotFound");

var CreateVariantInput_NameError = /* @__PURE__ */Caml_exceptions.create("CatalogVariantEditFormMutations-Pos.CreateVariantInput_NameError");

var CreateVariantInput_CapacityValueError = /* @__PURE__ */Caml_exceptions.create("CatalogVariantEditFormMutations-Pos.CreateVariantInput_CapacityValueError");

var CreateVariantInput_StockThresholdsInvalid = /* @__PURE__ */Caml_exceptions.create("CatalogVariantEditFormMutations-Pos.CreateVariantInput_StockThresholdsInvalid");

var CreateVariant_FormIdNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogVariantEditFormMutations-Pos.CreateVariant_FormIdNotFound");

var CreateVariant_ShopIdNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogVariantEditFormMutations-Pos.CreateVariant_ShopIdNotFound");

var CreateVariant_DeviceIdNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogVariantEditFormMutations-Pos.CreateVariant_DeviceIdNotFound");

var UpdateVariantInput_NameError = /* @__PURE__ */Caml_exceptions.create("CatalogVariantEditFormMutations-Pos.UpdateVariantInput_NameError");

var UpdateVariant_FormIdNotFound = /* @__PURE__ */Caml_exceptions.create("CatalogVariantEditFormMutations-Pos.UpdateVariant_FormIdNotFound");

var Raw = {};

var query = Client.gql(["mutation createVariant($input: InputCreateVariant!, $variantPricesInput: [InputCreateVariantPrices!]!)  {\ncreateVariant(input: $input, variantPricesInput: $variantPricesInput)  {\n__typename  \nid  \ncku  \npurchasedPrice  \nname  \nformattedName  \ncapacityValue  \ncapacityUnit  \ncapacityPrecision  \nyear  \nalcoholVolume  \nean13  \nstockKeepingUnit  \npriceLookUpCode  \ninternalCode  \nmaxStockThreshold  \nminStockThreshold  \nstockOrderTriggerThreshold  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.createVariant;
  var value$2 = value$1.purchasedPrice;
  var value$3 = value$1.capacityValue;
  var value$4 = value$1.capacityUnit;
  var value$5 = value$1.capacityPrecision;
  var value$6 = value$1.year;
  var value$7 = value$1.alcoholVolume;
  var value$8 = value$1.ean13;
  var value$9 = value$1.stockKeepingUnit;
  var value$10 = value$1.priceLookUpCode;
  var value$11 = value$1.internalCode;
  var value$12 = value$1.maxStockThreshold;
  var value$13 = value$1.minStockThreshold;
  var value$14 = value$1.stockOrderTriggerThreshold;
  return {
          createVariant: {
            __typename: value$1.__typename,
            id: value$1.id,
            cku: Scalar$Pos.CKU.parse(value$1.cku),
            purchasedPrice: !(value$2 == null) ? value$2 : undefined,
            name: value$1.name,
            formattedName: value$1.formattedName,
            capacityValue: !(value$3 == null) ? value$3 : undefined,
            capacityUnit: !(value$4 == null) ? value$4 : undefined,
            capacityPrecision: !(value$5 == null) ? value$5 : undefined,
            year: !(value$6 == null) ? value$6 : undefined,
            alcoholVolume: !(value$7 == null) ? value$7 : undefined,
            ean13: !(value$8 == null) ? value$8 : undefined,
            stockKeepingUnit: !(value$9 == null) ? value$9 : undefined,
            priceLookUpCode: !(value$10 == null) ? value$10 : undefined,
            internalCode: !(value$11 == null) ? value$11 : undefined,
            maxStockThreshold: !(value$12 == null) ? value$12 : undefined,
            minStockThreshold: !(value$13 == null) ? value$13 : undefined,
            stockOrderTriggerThreshold: !(value$14 == null) ? value$14 : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.createVariant;
  var value$2 = value$1.stockOrderTriggerThreshold;
  var stockOrderTriggerThreshold = value$2 !== undefined ? value$2 : null;
  var value$3 = value$1.minStockThreshold;
  var minStockThreshold = value$3 !== undefined ? value$3 : null;
  var value$4 = value$1.maxStockThreshold;
  var maxStockThreshold = value$4 !== undefined ? value$4 : null;
  var value$5 = value$1.internalCode;
  var internalCode = value$5 !== undefined ? value$5 : null;
  var value$6 = value$1.priceLookUpCode;
  var priceLookUpCode = value$6 !== undefined ? value$6 : null;
  var value$7 = value$1.stockKeepingUnit;
  var stockKeepingUnit = value$7 !== undefined ? value$7 : null;
  var value$8 = value$1.ean13;
  var ean13 = value$8 !== undefined ? value$8 : null;
  var value$9 = value$1.alcoholVolume;
  var alcoholVolume = value$9 !== undefined ? value$9 : null;
  var value$10 = value$1.year;
  var year = value$10 !== undefined ? value$10 : null;
  var value$11 = value$1.capacityPrecision;
  var capacityPrecision = value$11 !== undefined ? value$11 : null;
  var value$12 = value$1.capacityUnit;
  var capacityUnit = value$12 !== undefined ? value$12 : null;
  var value$13 = value$1.capacityValue;
  var capacityValue = value$13 !== undefined ? value$13 : null;
  var value$14 = value$1.formattedName;
  var value$15 = value$1.name;
  var value$16 = value$1.purchasedPrice;
  var purchasedPrice = value$16 !== undefined ? value$16 : null;
  var value$17 = value$1.cku;
  var value$18 = Scalar$Pos.CKU.serialize(value$17);
  var value$19 = value$1.id;
  var value$20 = value$1.__typename;
  var createVariant = {
    __typename: value$20,
    id: value$19,
    cku: value$18,
    purchasedPrice: purchasedPrice,
    name: value$15,
    formattedName: value$14,
    capacityValue: capacityValue,
    capacityUnit: capacityUnit,
    capacityPrecision: capacityPrecision,
    year: year,
    alcoholVolume: alcoholVolume,
    ean13: ean13,
    stockKeepingUnit: stockKeepingUnit,
    priceLookUpCode: priceLookUpCode,
    internalCode: internalCode,
    maxStockThreshold: maxStockThreshold,
    minStockThreshold: minStockThreshold,
    stockOrderTriggerThreshold: stockOrderTriggerThreshold
  };
  return {
          createVariant: createVariant
        };
}

function serializeInputObjectInputCreateVariantPrices(inp) {
  var a = inp.fromQuantity;
  var a$1 = inp.toQuantity;
  return {
          valueIncludingTax: inp.valueIncludingTax,
          valueExcludingTax: inp.valueExcludingTax,
          fromQuantity: a !== undefined ? a : undefined,
          toQuantity: a$1 !== undefined ? a$1 : undefined,
          priceId: inp.priceId
        };
}

function serializeInputObjectInputCreateVariant(inp) {
  var a = inp.stockKeepingUnit;
  var a$1 = inp.purchasedPrice;
  var a$2 = inp.year;
  var a$3 = inp.capacityValue;
  var a$4 = inp.capacityUnit;
  var a$5 = inp.capacityPrecision;
  var a$6 = inp.alcoholVolume;
  var a$7 = inp.ean13;
  var a$8 = inp.bulk;
  var a$9 = inp.packaging;
  var a$10 = inp.internalCode;
  var a$11 = inp.priceLookUpCode;
  var a$12 = inp.minStockThreshold;
  var a$13 = inp.maxStockThreshold;
  var a$14 = inp.stockOrderTriggerThreshold;
  var a$15 = inp.supplierId;
  return {
          stockKeepingUnit: a !== undefined ? a : undefined,
          name: inp.name,
          purchasedPrice: a$1 !== undefined ? a$1 : undefined,
          year: a$2 !== undefined ? a$2 : undefined,
          capacityValue: a$3 !== undefined ? a$3 : undefined,
          capacityUnit: a$4 !== undefined ? a$4 : undefined,
          capacityPrecision: a$5 !== undefined ? a$5 : undefined,
          alcoholVolume: a$6 !== undefined ? a$6 : undefined,
          ean13: a$7 !== undefined ? a$7 : undefined,
          bulk: a$8 !== undefined ? a$8 : undefined,
          packaging: a$9 !== undefined ? a$9 : undefined,
          internalCode: a$10 !== undefined ? a$10 : undefined,
          priceLookUpCode: a$11 !== undefined ? a$11 : undefined,
          minStockThreshold: a$12 !== undefined ? a$12 : undefined,
          maxStockThreshold: a$13 !== undefined ? a$13 : undefined,
          stockOrderTriggerThreshold: a$14 !== undefined ? a$14 : undefined,
          productId: inp.productId,
          supplierId: a$15 !== undefined ? a$15 : undefined,
          shopId: inp.shopId
        };
}

function serializeVariables(inp) {
  var a = inp.variantPricesInput;
  return {
          input: serializeInputObjectInputCreateVariant(inp.input),
          variantPricesInput: Js_array.map(serializeInputObjectInputCreateVariantPrices, a)
        };
}

function makeVariables(input, variantPricesInput, param) {
  return {
          input: input,
          variantPricesInput: variantPricesInput
        };
}

function makeInputObjectInputCreateVariant(stockKeepingUnit, name, purchasedPrice, year, capacityValue, capacityUnit, capacityPrecision, alcoholVolume, ean13, bulk, packaging, internalCode, priceLookUpCode, minStockThreshold, maxStockThreshold, stockOrderTriggerThreshold, productId, supplierId, shopId, param) {
  return {
          stockKeepingUnit: stockKeepingUnit,
          name: name,
          purchasedPrice: purchasedPrice,
          year: year,
          capacityValue: capacityValue,
          capacityUnit: capacityUnit,
          capacityPrecision: capacityPrecision,
          alcoholVolume: alcoholVolume,
          ean13: ean13,
          bulk: bulk,
          packaging: packaging,
          internalCode: internalCode,
          priceLookUpCode: priceLookUpCode,
          minStockThreshold: minStockThreshold,
          maxStockThreshold: maxStockThreshold,
          stockOrderTriggerThreshold: stockOrderTriggerThreshold,
          productId: productId,
          supplierId: supplierId,
          shopId: shopId
        };
}

function makeInputObjectInputCreateVariantPrices(valueIncludingTax, valueExcludingTax, fromQuantity, toQuantity, priceId, param) {
  return {
          valueIncludingTax: valueIncludingTax,
          valueExcludingTax: valueExcludingTax,
          fromQuantity: fromQuantity,
          toQuantity: toQuantity,
          priceId: priceId
        };
}

var CreateVariantMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputCreateVariant: serializeInputObjectInputCreateVariant,
  serializeInputObjectInputCreateVariantPrices: serializeInputObjectInputCreateVariantPrices,
  makeVariables: makeVariables,
  makeInputObjectInputCreateVariant: makeInputObjectInputCreateVariant,
  makeInputObjectInputCreateVariantPrices: makeInputObjectInputCreateVariantPrices
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var CreateVariantMutation_useWithVariables = include.useWithVariables;

var CreateVariantMutation = {
  CreateVariantMutation_inner: CreateVariantMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputCreateVariant: serializeInputObjectInputCreateVariant,
  serializeInputObjectInputCreateVariantPrices: serializeInputObjectInputCreateVariantPrices,
  makeVariables: makeVariables,
  makeInputObjectInputCreateVariant: makeInputObjectInputCreateVariant,
  makeInputObjectInputCreateVariantPrices: makeInputObjectInputCreateVariantPrices,
  use: use,
  useWithVariables: CreateVariantMutation_useWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["mutation createStockActivity($input: InputCreateStockActivity!)  {\ncreateStockActivity(input: $input)  {\n__typename  \nid  \n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.createStockActivity;
  return {
          createStockActivity: {
            __typename: value$1.__typename,
            id: value$1.id
          }
        };
}

function serialize$1(value) {
  var value$1 = value.createStockActivity;
  var value$2 = value$1.id;
  var value$3 = value$1.__typename;
  var createStockActivity = {
    __typename: value$3,
    id: value$2
  };
  return {
          createStockActivity: createStockActivity
        };
}

function serializeInputObjectInputCreateStockActivity(inp) {
  var a = inp.kind;
  var a$1 = inp.comment;
  var a$2 = inp.reason;
  var a$3 = inp.stockKeepingUnit;
  var a$4 = inp.capacityUnit;
  var a$5 = inp.capacityPrecision;
  return {
          kind: a === "DELIVERY_RECEIPT" ? "DELIVERY_RECEIPT" : (
              a === "RESET" ? "RESET" : (
                  a === "INCOMING_TRANSFER" ? "INCOMING_TRANSFER" : (
                      a === "DELIVERY" ? "DELIVERY" : (
                          a === "REFUND" ? "REFUND" : (
                              a === "LOSS" ? "LOSS" : (
                                  a === "CREDIT_MEMO" ? "CREDIT_MEMO" : (
                                      a === "SALE" ? "SALE" : (
                                          a === "RECEPTION" ? "RECEPTION" : "OUTGOING_TRANSFER"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
          quantity: inp.quantity,
          comment: a$1 !== undefined ? a$1 : undefined,
          reason: a$2 !== undefined ? (
              a$2 === "DAMAGE" ? "DAMAGE" : (
                  a$2 === "OUTDATED_DLUO" ? "OUTDATED_DLUO" : (
                      a$2 === "SALE_BY_GLASS" ? "SALE_BY_GLASS" : (
                          a$2 === "THEFT" ? "THEFT" : (
                              a$2 === "TASTING" ? "TASTING" : (
                                  a$2 === "LOSS" ? "LOSS" : (
                                      a$2 === "OTHER" ? "OTHER" : (
                                          a$2 === "TRADE_IN_SUPPLIER" ? "TRADE_IN_SUPPLIER" : "CORKY"
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          stockKeepingUnit: a$3 !== undefined ? a$3 : undefined,
          capacityUnit: a$4 !== undefined ? a$4 : undefined,
          capacityPrecision: a$5 !== undefined ? a$5 : undefined,
          variantId: inp.variantId,
          deviceId: inp.deviceId,
          shopId: inp.shopId
        };
}

function serializeVariables$1(inp) {
  return {
          input: serializeInputObjectInputCreateStockActivity(inp.input)
        };
}

function makeVariables$1(input, param) {
  return {
          input: input
        };
}

function makeInputObjectInputCreateStockActivity(kind, quantity, comment, reason, stockKeepingUnit, capacityUnit, capacityPrecision, variantId, deviceId, shopId, param) {
  return {
          kind: kind,
          quantity: quantity,
          comment: comment,
          reason: reason,
          stockKeepingUnit: stockKeepingUnit,
          capacityUnit: capacityUnit,
          capacityPrecision: capacityPrecision,
          variantId: variantId,
          deviceId: deviceId,
          shopId: shopId
        };
}

var CreateStockActivity_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputCreateStockActivity: serializeInputObjectInputCreateStockActivity,
  makeVariables: makeVariables$1,
  makeInputObjectInputCreateStockActivity: makeInputObjectInputCreateStockActivity
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var CreateStockActivity_useWithVariables = include$1.useWithVariables;

var CreateStockActivity = {
  CreateStockActivity_inner: CreateStockActivity_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  serializeInputObjectInputCreateStockActivity: serializeInputObjectInputCreateStockActivity,
  makeVariables: makeVariables$1,
  makeInputObjectInputCreateStockActivity: makeInputObjectInputCreateStockActivity,
  use: use$1,
  useWithVariables: CreateStockActivity_useWithVariables
};

var Raw$2 = {};

var query$2 = Client.gql(["mutation updateVariant($id: ID!, $input: InputUpdateVariant!)  {\nupdateVariant(id: $id, input: $input)  {\n__typename  \nid  \nupdatedAt  \npurchasedPrice  \nname  \nformattedName  \ncapacityValue  \ncapacityUnit  \ncapacityPrecision  \nyear  \nalcoholVolume  \nean13  \nstockKeepingUnit  \npriceLookUpCode  \ninternalCode  \n}\n\n}\n"]);

function parse$2(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.purchasedPrice;
  var value$3 = value$1.capacityValue;
  var value$4 = value$1.capacityUnit;
  var value$5 = value$1.capacityPrecision;
  var value$6 = value$1.year;
  var value$7 = value$1.alcoholVolume;
  var value$8 = value$1.ean13;
  var value$9 = value$1.stockKeepingUnit;
  var value$10 = value$1.priceLookUpCode;
  var value$11 = value$1.internalCode;
  return {
          updateVariant: {
            __typename: value$1.__typename,
            id: value$1.id,
            updatedAt: Scalar$Pos.Datetime.parse(value$1.updatedAt),
            purchasedPrice: !(value$2 == null) ? value$2 : undefined,
            name: value$1.name,
            formattedName: value$1.formattedName,
            capacityValue: !(value$3 == null) ? value$3 : undefined,
            capacityUnit: !(value$4 == null) ? value$4 : undefined,
            capacityPrecision: !(value$5 == null) ? value$5 : undefined,
            year: !(value$6 == null) ? value$6 : undefined,
            alcoholVolume: !(value$7 == null) ? value$7 : undefined,
            ean13: !(value$8 == null) ? value$8 : undefined,
            stockKeepingUnit: !(value$9 == null) ? value$9 : undefined,
            priceLookUpCode: !(value$10 == null) ? value$10 : undefined,
            internalCode: !(value$11 == null) ? value$11 : undefined
          }
        };
}

function serialize$2(value) {
  var value$1 = value.updateVariant;
  var value$2 = value$1.internalCode;
  var internalCode = value$2 !== undefined ? value$2 : null;
  var value$3 = value$1.priceLookUpCode;
  var priceLookUpCode = value$3 !== undefined ? value$3 : null;
  var value$4 = value$1.stockKeepingUnit;
  var stockKeepingUnit = value$4 !== undefined ? value$4 : null;
  var value$5 = value$1.ean13;
  var ean13 = value$5 !== undefined ? value$5 : null;
  var value$6 = value$1.alcoholVolume;
  var alcoholVolume = value$6 !== undefined ? value$6 : null;
  var value$7 = value$1.year;
  var year = value$7 !== undefined ? value$7 : null;
  var value$8 = value$1.capacityPrecision;
  var capacityPrecision = value$8 !== undefined ? value$8 : null;
  var value$9 = value$1.capacityUnit;
  var capacityUnit = value$9 !== undefined ? value$9 : null;
  var value$10 = value$1.capacityValue;
  var capacityValue = value$10 !== undefined ? value$10 : null;
  var value$11 = value$1.formattedName;
  var value$12 = value$1.name;
  var value$13 = value$1.purchasedPrice;
  var purchasedPrice = value$13 !== undefined ? value$13 : null;
  var value$14 = value$1.updatedAt;
  var value$15 = Scalar$Pos.Datetime.serialize(value$14);
  var value$16 = value$1.id;
  var value$17 = value$1.__typename;
  var updateVariant = {
    __typename: value$17,
    id: value$16,
    updatedAt: value$15,
    purchasedPrice: purchasedPrice,
    name: value$12,
    formattedName: value$11,
    capacityValue: capacityValue,
    capacityUnit: capacityUnit,
    capacityPrecision: capacityPrecision,
    year: year,
    alcoholVolume: alcoholVolume,
    ean13: ean13,
    stockKeepingUnit: stockKeepingUnit,
    priceLookUpCode: priceLookUpCode,
    internalCode: internalCode
  };
  return {
          updateVariant: updateVariant
        };
}

function serializeInputObjectInputUpdateVariantMetaData(inp) {
  var a = inp.shopifyVariantId;
  return {
          shopifyVariantId: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInputUpdateVariant(inp) {
  var a = inp.stockKeepingUnit;
  var a$1 = inp.name;
  var a$2 = inp.purchasedPrice;
  var a$3 = inp.year;
  var a$4 = inp.capacityValue;
  var a$5 = inp.capacityUnit;
  var a$6 = inp.capacityPrecision;
  var a$7 = inp.alcoholVolume;
  var a$8 = inp.ean13;
  var a$9 = inp.internalNote;
  var a$10 = inp.metadata;
  var a$11 = inp.tastingNote;
  var a$12 = inp.packaging;
  var a$13 = inp.internalCode;
  var a$14 = inp.priceLookUpCode;
  var a$15 = inp.supplierId;
  var a$16 = inp.minStockThreshold;
  var a$17 = inp.maxStockThreshold;
  var a$18 = inp.stockOrderTriggerThreshold;
  return {
          stockKeepingUnit: a !== undefined ? a : undefined,
          name: a$1 !== undefined ? a$1 : undefined,
          purchasedPrice: a$2 !== undefined ? a$2 : undefined,
          year: a$3 !== undefined ? a$3 : undefined,
          capacityValue: a$4 !== undefined ? a$4 : undefined,
          capacityUnit: a$5 !== undefined ? a$5 : undefined,
          capacityPrecision: a$6 !== undefined ? a$6 : undefined,
          alcoholVolume: a$7 !== undefined ? a$7 : undefined,
          ean13: a$8 !== undefined ? a$8 : undefined,
          internalNote: a$9 !== undefined ? Caml_option.valFromOption(a$9) : undefined,
          metadata: a$10 !== undefined ? serializeInputObjectInputUpdateVariantMetaData(a$10) : undefined,
          tastingNote: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          packaging: a$12 !== undefined ? a$12 : undefined,
          internalCode: a$13 !== undefined ? a$13 : undefined,
          priceLookUpCode: a$14 !== undefined ? a$14 : undefined,
          supplierId: a$15 !== undefined ? a$15 : undefined,
          minStockThreshold: a$16 !== undefined ? a$16 : undefined,
          maxStockThreshold: a$17 !== undefined ? a$17 : undefined,
          stockOrderTriggerThreshold: a$18 !== undefined ? a$18 : undefined
        };
}

function serializeVariables$2(inp) {
  return {
          id: inp.id,
          input: serializeInputObjectInputUpdateVariant(inp.input)
        };
}

function makeVariables$2(id, input, param) {
  return {
          id: id,
          input: input
        };
}

function makeInputObjectInputUpdateVariant(stockKeepingUnit, name, purchasedPrice, year, capacityValue, capacityUnit, capacityPrecision, alcoholVolume, ean13, internalNote, metadata, tastingNote, packaging, internalCode, priceLookUpCode, supplierId, minStockThreshold, maxStockThreshold, stockOrderTriggerThreshold, param) {
  return {
          stockKeepingUnit: stockKeepingUnit,
          name: name,
          purchasedPrice: purchasedPrice,
          year: year,
          capacityValue: capacityValue,
          capacityUnit: capacityUnit,
          capacityPrecision: capacityPrecision,
          alcoholVolume: alcoholVolume,
          ean13: ean13,
          internalNote: internalNote,
          metadata: metadata,
          tastingNote: tastingNote,
          packaging: packaging,
          internalCode: internalCode,
          priceLookUpCode: priceLookUpCode,
          supplierId: supplierId,
          minStockThreshold: minStockThreshold,
          maxStockThreshold: maxStockThreshold,
          stockOrderTriggerThreshold: stockOrderTriggerThreshold
        };
}

function makeInputObjectInputUpdateVariantMetaData(shopifyVariantId, param) {
  return {
          shopifyVariantId: shopifyVariantId
        };
}

var UpdateVariantMutation_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData,
  makeVariables: makeVariables$2,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData
};

var include$2 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2
    });

var use$2 = include$2.use;

var UpdateVariantMutation_useWithVariables = include$2.useWithVariables;

var UpdateVariantMutation = {
  UpdateVariantMutation_inner: UpdateVariantMutation_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  serializeInputObjectInputUpdateVariant: serializeInputObjectInputUpdateVariant,
  serializeInputObjectInputUpdateVariantMetaData: serializeInputObjectInputUpdateVariantMetaData,
  makeVariables: makeVariables$2,
  makeInputObjectInputUpdateVariant: makeInputObjectInputUpdateVariant,
  makeInputObjectInputUpdateVariantMetaData: makeInputObjectInputUpdateVariantMetaData,
  use: use$2,
  useWithVariables: UpdateVariantMutation_useWithVariables
};

function makeCreateVariantInput(state, productId, shopId) {
  if (state.name === "") {
    throw {
          RE_EXN_ID: CreateVariantInput_NameError,
          Error: new Error()
        };
  }
  var capacityValue = state.capacityValue;
  if (capacityValue !== undefined && state.bulk && capacityValue > 0) {
    throw {
          RE_EXN_ID: CreateVariantInput_CapacityValueError,
          Error: new Error()
        };
  }
  var minStockThreshold = state.minStockThreshold;
  var maxStockThreshold = state.maxStockThreshold;
  if (minStockThreshold >= maxStockThreshold && maxStockThreshold !== 0) {
    throw {
          RE_EXN_ID: CreateVariantInput_StockThresholdsInvalid,
          Error: new Error()
        };
  }
  var stockOrderTriggerThreshold = state.stockOrderTriggerThreshold;
  if (minStockThreshold > stockOrderTriggerThreshold && stockOrderTriggerThreshold !== 0) {
    throw {
          RE_EXN_ID: CreateVariantInput_StockThresholdsInvalid,
          Error: new Error()
        };
  }
  if (stockOrderTriggerThreshold >= maxStockThreshold && maxStockThreshold !== 0) {
    throw {
          RE_EXN_ID: CreateVariantInput_StockThresholdsInvalid,
          Error: new Error()
        };
  }
  var unit = state.capacityUnit;
  var minStockThreshold$1 = state.minStockThreshold;
  var maxStockThreshold$1 = state.maxStockThreshold;
  var stockOrderTriggerThreshold$1 = state.stockOrderTriggerThreshold;
  var tmp = state.supplierId === "" ? undefined : state.supplierId;
  return makeInputObjectInputCreateVariant(state.stockKeepingUnit, state.name, state.purchasePrice, Belt_Option.map(state.year, (function (prim) {
                    return prim | 0;
                  })), state.capacityUnit !== undefined ? state.capacityValue : undefined, unit !== undefined ? unit : undefined, state.bulk ? CatalogVariant$Pos.defaultBulkCapacityPrecision : undefined, state.alcoholVolume, state.ean13, state.bulk, undefined, state.internalCode, state.priceLookUpCode.value, minStockThreshold$1 !== 0 ? minStockThreshold$1 | 0 : undefined, maxStockThreshold$1 !== 0 ? maxStockThreshold$1 | 0 : undefined, stockOrderTriggerThreshold$1 !== 0 ? stockOrderTriggerThreshold$1 | 0 : undefined, productId, tmp, shopId, undefined);
}

function useCreateVariant(param) {
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  var match$1 = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutateStockActivity = match$1[0];
  var activeShop = Auth$Pos.useActiveShop(undefined);
  return React.useCallback((function (productId) {
                return function (state) {
                  var productId$1;
                  if (productId !== undefined) {
                    productId$1 = productId;
                  } else {
                    throw {
                          RE_EXN_ID: CreateVariant_FormIdNotFound,
                          Error: new Error()
                        };
                  }
                  var shopId;
                  if (activeShop !== undefined) {
                    shopId = activeShop.id;
                  } else {
                    throw {
                          RE_EXN_ID: CreateVariant_ShopIdNotFound,
                          Error: new Error()
                        };
                  }
                  var deviceId;
                  if (activeShop !== undefined) {
                    deviceId = activeShop.activeWebDeviceId;
                  } else {
                    throw {
                          RE_EXN_ID: CreateVariant_DeviceIdNotFound,
                          Error: new Error()
                        };
                  }
                  var input = makeCreateVariantInput(state, productId$1, shopId);
                  var variantPrices = state.variantPrices;
                  var variantPricesInput = variantPrices !== undefined ? Belt_Array.map(variantPrices, (function (param) {
                            return makeInputObjectInputCreateVariantPrices(param.valueIncludingTax, param.valueExcludingTax, undefined, undefined, param.priceId, undefined);
                          })) : [];
                  return Future.flatMapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                      input: input,
                                      variantPricesInput: variantPricesInput
                                    })), undefined, (function (param) {
                                var quantity = state.initialStockQuantity;
                                var match = param.createVariant;
                                var capacityPrecision = match.capacityPrecision;
                                var cku = match.cku;
                                if (quantity <= 0) {
                                  return Future.value({
                                              TAG: /* Ok */0,
                                              _0: cku
                                            });
                                }
                                var input = makeInputObjectInputCreateStockActivity("DELIVERY", StockActivityQuantity$Pos.toRawValue(capacityPrecision, quantity), state.initialStockComment, undefined, undefined, state.capacityUnit, capacityPrecision, match.id, deviceId, shopId, undefined);
                                var variables = {
                                  input: input
                                };
                                return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutateStockActivity, undefined, undefined, undefined, undefined, undefined, undefined, undefined, variables)), undefined, (function (param) {
                                              return cku;
                                            }));
                              }));
                };
              }), [activeShop]);
}

function makeUpdateVariantInput(state) {
  var tmp;
  if (state.name === "") {
    throw {
          RE_EXN_ID: UpdateVariantInput_NameError,
          Error: new Error()
        };
  }
  tmp = state.name;
  var tmp$1;
  var exit = 0;
  if (state.capacityUnit !== undefined) {
    var capacityValue = state.capacityValue;
    if (capacityValue !== undefined) {
      tmp$1 = capacityValue;
    } else {
      exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$1 = null;
  }
  return makeInputObjectInputUpdateVariant(state.stockKeepingUnit, tmp, undefined, Belt_Option.mapWithDefault(state.year, null, (function (prim) {
                    return prim | 0;
                  })), tmp$1, Belt_Option.getWithDefault(state.capacityUnit, null), undefined, Belt_Option.getWithDefault(state.alcoholVolume, null), state.ean13, undefined, undefined, undefined, undefined, state.internalCode, Belt_Option.getWithDefault(state.priceLookUpCode.value, null), undefined, undefined, undefined, undefined, undefined);
}

function useUpdateVariant(param) {
  var match = Curry.app(use$2, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.useCallback((function (variantId) {
                return function (state) {
                  var id;
                  if (variantId !== undefined) {
                    id = variantId;
                  } else {
                    throw {
                          RE_EXN_ID: UpdateVariant_FormIdNotFound,
                          Error: new Error()
                        };
                  }
                  var input = makeUpdateVariantInput(state);
                  return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                      id: id,
                                      input: input
                                    })), undefined, (function (param) {
                                return id;
                              }));
                };
              }), []);
}

function useCreateProductAndVariant(productValues) {
  var createProduct = CatalogProductEditFormMutations$Pos.useCreateProduct(undefined);
  var createVariant = useCreateVariant(undefined);
  return React.useCallback((function (param) {
                return function (state) {
                  var productState;
                  if (productValues !== undefined) {
                    productState = productValues;
                  } else {
                    throw {
                          RE_EXN_ID: CreateProductAndVariant_ProductValuesNotFound,
                          Error: new Error()
                        };
                  }
                  return Future.mapOk(Future.flatMapOk(Curry._2(createProduct, undefined, productState), undefined, (function (productId) {
                                    return Curry._2(createVariant, productId, state);
                                  })), undefined, (function (result) {
                                return result;
                              }));
                };
              }), [productValues]);
}

export {
  CreateProductAndVariant_ProductValuesNotFound ,
  CreateVariantInput_NameError ,
  CreateVariantInput_CapacityValueError ,
  CreateVariantInput_StockThresholdsInvalid ,
  CreateVariant_FormIdNotFound ,
  CreateVariant_ShopIdNotFound ,
  CreateVariant_DeviceIdNotFound ,
  UpdateVariantInput_NameError ,
  UpdateVariant_FormIdNotFound ,
  CreateVariantMutation ,
  CreateStockActivity ,
  UpdateVariantMutation ,
  makeCreateVariantInput ,
  useCreateVariant ,
  makeUpdateVariantInput ,
  useUpdateVariant ,
  useCreateProductAndVariant ,
}
/* query Not a pure module */
