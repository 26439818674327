// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Auth$Pos from "../../bundles/Auth/Auth.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../../core/Request.bs.js";
import * as Notifier$Pos from "../../bundles/Notifier/Notifier.bs.js";
import * as OrderEdit$Pos from "./OrderEdit.bs.js";
import * as Navigation$Pos from "../../primitives/Navigation.bs.js";
import * as Placeholder$Pos from "../../resources/layout-and-structure/Placeholder.bs.js";
import * as LegacyRouter$Pos from "../../core/LegacyRouter.bs.js";
import * as OrderEditForm$Pos from "../../modules/Order/OrderEditForm.bs.js";
import * as OrderEditPage$Pos from "./OrderEditPage.bs.js";
import * as OrderEdit__Config$Pos from "./OrderEdit__Config.bs.js";
import * as OrderEdit__Queries$Pos from "./OrderEdit__Queries.bs.js";

function OrderEditContainer(Props) {
  var id = Props.id;
  var supplierId = Props.supplierId;
  var shopId = Props.shopId;
  var authState = Auth$Pos.useState(undefined);
  var navigate = Navigation$Pos.useNavigate(undefined);
  var notifier = Notifier$Pos.use(undefined, undefined);
  var match = React.useState(function () {
        return [];
      });
  var setCartRowErrors = match[1];
  var match$1 = Curry.app(OrderEdit__Queries$Pos.OrderQuery.useLazy, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var queryResults = match$1[1];
  var executeQuery = match$1[0];
  React.useEffect((function () {
          if (Belt_Option.isSome(id)) {
            Curry._3(executeQuery, undefined, undefined, {
                  id: Belt_Option.getWithDefault(id, "")
                });
          }
          
        }), [id]);
  var tmp;
  if (queryResults.TAG === /* Executed */0) {
    var match$2 = queryResults._0.data;
    if (match$2 !== undefined) {
      var match$3 = match$2.order;
      tmp = match$3 !== undefined ? !OrderEdit$Pos.isBeforeReception(match$3.formattedStatus) : true;
    } else {
      tmp = true;
    }
  } else {
    tmp = true;
  }
  var tmp$1;
  if (queryResults.TAG === /* Executed */0) {
    var match$4 = queryResults._0.data;
    tmp$1 = match$4 !== undefined ? match$4.order !== undefined : false;
  } else {
    tmp$1 = false;
  }
  var orderVariantsStockQuery = OrderEdit__Queries$Pos.OrderVariantsStockQueryHook.use(tmp, tmp$1, id);
  var config = OrderEdit__Config$Pos.use(id, authState, queryResults, supplierId, shopId)(orderVariantsStockQuery);
  var onSubmitSuccess = React.useCallback((function (submissionResponse) {
          if (submissionResponse !== undefined) {
            if (id !== undefined) {
              Curry._3(executeQuery, undefined, undefined, OrderEdit__Queries$Pos.OrderQuery.makeVariables(submissionResponse, undefined));
              return Curry._1(notifier.clear, undefined);
            } else {
              Curry._3(navigate, true, undefined, LegacyRouter$Pos.routeToPathname(/* Order */0) + "/" + submissionResponse);
              return Curry._1(notifier.clear, undefined);
            }
          } else {
            return Curry._3(notifier.add, {
                        TAG: /* Error */1,
                        _0: Request$Pos.serverErrorMessage
                      }, undefined, undefined);
          }
        }), [id]);
  var onSubmitFailure = React.useCallback(function (message) {
        Curry._3(notifier.reset, {
              TAG: /* Error */1,
              _0: message
            }, undefined, undefined);
      });
  if (typeof config === "number") {
    if (config !== 0) {
      return React.createElement(Placeholder$Pos.make, {
                  status: /* Loading */0
                });
    } else {
      return React.createElement(Placeholder$Pos.make, {
                  status: /* Error */2
                });
    }
  }
  var match$5 = config._0;
  var statuses = match$5.statuses;
  var tmp$2 = {
    initialValues: match$5.initialValues,
    schema: [
      {
        TAG: /* CustomString */8,
        _0: /* SupplierId */2,
        _1: (function (value, param) {
            if (value === "") {
              return {
                      TAG: /* Error */1,
                      _0: Intl$Pos.t("Missing supplier")
                    };
            } else {
              return {
                      TAG: /* Ok */0,
                      _0: undefined
                    };
            }
          })
      },
      {
        TAG: /* Custom */7,
        _0: /* Cart */6,
        _1: (function (param) {
            var cart = param.cart;
            var cartErrors = Belt_Array.keepMap(cart.products, (function (product) {
                    var match;
                    if (product.TAG === /* Unit */0) {
                      var match$1 = product._0;
                      match = [
                        match$1.id,
                        match$1.quantity
                      ];
                    } else {
                      var match$2 = product._0;
                      match = [
                        match$2.id,
                        match$2.quantity.toNumber()
                      ];
                    }
                    if (match[1] <= 0) {
                      return {
                              key: match[0],
                              message: Intl$Pos.t("Ordered quantity must be greater than 0.")
                            };
                    }
                    
                  }));
            var validated = OrderEdit$Pos.isBeforeReception(statuses) ? (Curry._1(setCartRowErrors, (function (previousCartRowErrors) {
                        if (previousCartRowErrors.length === 0 && cartErrors.length === 0) {
                          return previousCartRowErrors;
                        } else {
                          return cartErrors;
                        }
                      })), cart.products.length !== 0 && cartErrors.length === 0) : cart.products.length !== 0;
            if (validated) {
              return {
                      TAG: /* Ok */0,
                      _0: undefined
                    };
            } else {
              return {
                      TAG: /* Error */1,
                      _0: "The order should have at least one product in cart."
                    };
            }
          })
      }
    ],
    children: React.createElement(OrderEditPage$Pos.make, {
          id: id,
          name: match$5.name,
          updatedAt: match$5.updatedAt,
          statuses: statuses,
          receptionFinishedAt: match$5.receptionFinishedAt,
          cartRowErrors: match[0],
          onRequestCartRowsError: (function (errors) {
              Curry._1(setCartRowErrors, (function (param) {
                      return errors;
                    }));
            })
        }),
    onSubmitFailure: onSubmitFailure,
    onSubmitSuccess: onSubmitSuccess
  };
  if (id !== undefined) {
    tmp$2.id = Caml_option.valFromOption(id);
  }
  return React.createElement(OrderEditForm$Pos.Core.LegacyProvider.make, tmp$2);
}

var make = React.memo(OrderEditContainer);

var Config;

var OrderQuery;

var OrderVariantsStockQuery;

export {
  Config ,
  OrderQuery ,
  OrderVariantsStockQuery ,
  make ,
}
/* make Not a pure module */
