// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Box$Pos from "../layout-and-structure/Box.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Hover$Pos from "../../primitives/Hover.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Popover$Pos from "../overlays/Popover.bs.js";
import * as TextStyle$Pos from "../typography/TextStyle.bs.js";
import * as Touchable$Pos from "../../primitives/Touchable.bs.js";
import * as DayPickerRange$Pos from "./DayPickerRange.bs.js";
import * as TextIconButton$Pos from "../actions/TextIconButton.bs.js";
import * as OverlayTriggerView$Pos from "../overlays/OverlayTriggerView.bs.js";

function startOfDay(date) {
  return new Date(new Date(date.getTime()).setHours(0, 0, 0, 0));
}

function endOffDay(date) {
  return new Date(new Date(date.getTime()).setHours(23, 59, 59, 999));
}

function InputDateRangeField(Props) {
  var label = Props.label;
  var value = Props.value;
  var placeholder = Props.placeholder;
  var infotip = Props.infotip;
  var errorMessage = Props.errorMessage;
  var requiredOpt = Props.required;
  var onChange = Props.onChange;
  var required = requiredOpt !== undefined ? requiredOpt : false;
  var mounted = React.useRef(false);
  var match = Popover$Pos.useTrigger(undefined, undefined);
  var popoverTriggerRef = match.ref;
  var popover = match.state;
  var match$1 = Hover$Pos.use(popoverTriggerRef, undefined);
  var match$2 = React.useState(function () {
        if (value !== undefined) {
          return [
                  Caml_option.some(value[0]),
                  Caml_option.some(value[1])
                ];
        } else {
          return [
                  undefined,
                  undefined
                ];
        }
      });
  var setDateRange = match$2[1];
  var dateRange = match$2[0];
  var match$3 = React.useState(function () {
        return DayPickerRange$Pos.defaultFocusedInput;
      });
  var setFocusedInput = match$3[1];
  React.useEffect((function () {
          var match = dateRange[0];
          var match$1 = dateRange[1];
          var exit = 0;
          if (value !== undefined) {
            if (!(match !== undefined && match$1 !== undefined && (value[0].getTime() !== Caml_option.valFromOption(match).getTime() || value[1].getTime() === Caml_option.valFromOption(match$1).getTime()))) {
              exit = 1;
            }
            
          } else if (match !== undefined || match$1 !== undefined) {
            exit = 1;
          }
          if (exit === 1) {
            Curry._1(setDateRange, (function (param) {
                    if (value !== undefined) {
                      return [
                              Caml_option.some(value[0]),
                              Caml_option.some(value[1])
                            ];
                    } else {
                      return [
                              undefined,
                              undefined
                            ];
                    }
                  }));
          }
          
        }), [value]);
  var onFocusChange = React.useCallback((function (focusedInput) {
          Curry._1(setFocusedInput, (function (param) {
                  if (focusedInput == null) {
                    return DayPickerRange$Pos.defaultFocusedInput;
                  } else {
                    return focusedInput;
                  }
                }));
        }), []);
  React.useEffect((function () {
          var startDate = dateRange[0];
          if (startDate !== undefined) {
            var endDate = dateRange[1];
            if (endDate !== undefined) {
              Curry._1(onChange, [
                    Caml_option.valFromOption(startDate),
                    Caml_option.valFromOption(endDate)
                  ]);
            }
            
          } else if (mounted.current) {
            Curry._1(onChange, undefined);
          }
          
        }), dateRange);
  React.useEffect((function () {
          mounted.current = true;
          return (function (param) {
                    mounted.current = false;
                  });
        }), []);
  var onChange$1 = function (param) {
    var endDate = param[1];
    var startDate = param[0];
    Curry._1(setDateRange, (function (param) {
            return [
                    startDate !== undefined ? Caml_option.some(startOfDay(Caml_option.valFromOption(startDate))) : undefined,
                    endDate !== undefined ? Caml_option.some(endOffDay(Caml_option.valFromOption(endDate))) : undefined
                  ];
          }));
    if (Belt_Option.isSome(startDate) && Belt_Option.isSome(endDate)) {
      return Curry._1(popover.close, undefined);
    }
    
  };
  var handleResetDate = React.useCallback((function (param) {
          Curry._1(setDateRange, (function (param) {
                  return [
                          undefined,
                          undefined
                        ];
                }));
          Curry._1(popover.close, undefined);
        }), []);
  var startDate = dateRange[0];
  var formattedValue;
  if (startDate !== undefined) {
    var endDate = dateRange[1];
    formattedValue = endDate !== undefined ? Intl$Pos.dateTimeFormat("short", undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.valFromOption(startDate)) + " → " + Intl$Pos.dateTimeFormat("short", undefined, undefined, undefined, undefined, undefined, undefined, undefined, Caml_option.valFromOption(endDate)) : "";
  } else {
    formattedValue = "";
  }
  var endDate$1 = dateRange[1];
  var tmp;
  var exit = 0;
  if (placeholder !== undefined && value === undefined) {
    tmp = React.createElement(TextStyle$Pos.make, {
          children: placeholder,
          variation: "normal"
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(TextStyle$Pos.make, {
          children: formattedValue
        });
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Touchable$Pos.make, Touchable$Pos.makeProps(React.createElement(OverlayTriggerView$Pos.make, {
                          children: tmp,
                          preset: {
                            NAME: "inputField",
                            VAL: {
                              required: required,
                              infotip: infotip,
                              errorMessage: errorMessage
                            }
                          },
                          label: label,
                          icon: "calendar",
                          hovered: match$1[1],
                          active: popover.isOpen
                        }), undefined, undefined, undefined, undefined, undefined, (function (param) {
                        Curry._1(popover.toggle, undefined);
                      }), undefined, popoverTriggerRef, undefined)), popover.isOpen ? React.createElement(Popover$Pos.make, {
                    children: React.createElement(Popover$Pos.Dialog.make, {
                          children: null,
                          ariaProps: match.ariaProps.overlayProps,
                          style: {
                            minHeight: "300px"
                          }
                        }, React.createElement(DayPickerRange$Pos.make, {
                              startDate: dateRange[0],
                              endDate: endDate$1,
                              focusedInput: match$3[0],
                              onChange: onChange$1,
                              onFocusChange: onFocusChange
                            }), React.createElement(Box$Pos.make, Box$Pos.makeProps(Caml_option.some(React.createElement(TextIconButton$Pos.make, TextIconButton$Pos.makeProps(Intl$Pos.t("Reset period"), undefined, "small", Belt_Option.isNone(endDate$1), "delete_light", undefined, handleResetDate, undefined, undefined, undefined))), undefined, undefined, "xsmall", "xmedium", "large", undefined, undefined, undefined, undefined, undefined, undefined))),
                    triggerRef: popoverTriggerRef,
                    state: {
                      isOpen: popover.isOpen,
                      close: popover.close,
                      toggle: popover.toggle
                    },
                    placement: "bottom start",
                    dismissable: false
                  }) : null);
}

var make = React.memo(InputDateRangeField, (function (oldProps, newProps) {
        if (oldProps.label === newProps.label && oldProps.value === newProps.value) {
          return oldProps.errorMessage === newProps.errorMessage;
        } else {
          return false;
        }
      }));

export {
  make ,
}
/* make Not a pure module */
