// Generated by ReScript, PLEASE EDIT WITH CARE


var Product = {};

var nonInlineBrandColor40 = "#c04695";

var nonInlineBrandColor50 = "#a01e72";

var nonInlineBrandColor60 = "#8a0262";

var placeholderTextColor = "#bdbdca";

export {
  nonInlineBrandColor40 ,
  nonInlineBrandColor50 ,
  nonInlineBrandColor60 ,
  Product ,
  placeholderTextColor ,
}
/* No side effect */
