// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Intl$Pos from "../../primitives/Intl.bs.js";
import * as Scalar$Pos from "../../core/Scalar.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Client from "@apollo/client";
import * as ApolloHelpers$Pos from "../../helpers/ApolloHelpers.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "rescript-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = Client.gql(["mutation updateProduct_internalNote($id: ID!, $input: InputUpdateProduct!)  {\nupdateProduct(id: $id, input: $input)  {\n__typename  \nid  \ninternalNote  \n}\n\n}\n"]);

function parse(value) {
  var value$1 = value.updateProduct;
  var value$2 = value$1.internalNote;
  return {
          updateProduct: {
            __typename: value$1.__typename,
            id: value$1.id,
            internalNote: !(value$2 == null) ? Scalar$Pos.$$Text.parse(value$2) : undefined
          }
        };
}

function serialize(value) {
  var value$1 = value.updateProduct;
  var value$2 = value$1.internalNote;
  var internalNote = value$2 !== undefined ? Scalar$Pos.$$Text.serialize(value$2) : null;
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var updateProduct = {
    __typename: value$4,
    id: value$3,
    internalNote: internalNote
  };
  return {
          updateProduct: updateProduct
        };
}

function serializeInputObjectInputUpdateProductMetaData(inp) {
  var a = inp.shopifyProductId;
  return {
          shopifyProductId: a !== undefined ? a : undefined
        };
}

function serializeInputObjectInputUpdateProduct(inp) {
  var a = inp.name;
  var a$1 = inp.designation;
  var a$2 = inp.color;
  var a$3 = inp.kind;
  var a$4 = inp.family;
  var a$5 = inp.producer;
  var a$6 = inp.region;
  var a$7 = inp.beerType;
  var a$8 = inp.wineType;
  var a$9 = inp.whiteWineType;
  var a$10 = inp.internalNote;
  var a$11 = inp.taxId;
  var a$12 = inp.categoryId;
  var a$13 = inp.metadata;
  return {
          name: a !== undefined ? a : undefined,
          designation: a$1 !== undefined ? a$1 : undefined,
          color: a$2 !== undefined ? (
              a$2 === "ORANGE" ? "ORANGE" : (
                  a$2 === "RED" ? "RED" : (
                      a$2 === "AMBER" ? "AMBER" : (
                          a$2 === "DARK" ? "DARK" : (
                              a$2 === "BLACK" ? "BLACK" : (
                                  a$2 === "BLOND" ? "BLOND" : (
                                      a$2 === "ROSE" ? "ROSE" : "WHITE"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          kind: a$3 !== undefined ? (
              a$3 === "SPIRITUOUS" ? "SPIRITUOUS" : (
                  a$3 === "BEER" ? "BEER" : (
                      a$3 === "WINE" ? "WINE" : "SIMPLE"
                    )
                )
            ) : undefined,
          family: a$4 !== undefined ? a$4 : undefined,
          producer: a$5 !== undefined ? a$5 : undefined,
          country: inp.country,
          region: a$6 !== undefined ? a$6 : undefined,
          beerType: a$7 !== undefined ? a$7 : undefined,
          wineType: a$8 !== undefined ? (
              a$8 === "EFFERVESCENT" ? "EFFERVESCENT" : "STILL"
            ) : undefined,
          whiteWineType: a$9 !== undefined ? (
              a$9 === "SWEET" ? "SWEET" : (
                  a$9 === "SEMIDRY" ? "SEMIDRY" : (
                      a$9 === "SOFT" ? "SOFT" : "DRY"
                    )
                )
            ) : undefined,
          internalNote: a$10 !== undefined ? Caml_option.valFromOption(a$10) : undefined,
          taxId: a$11 !== undefined ? a$11 : undefined,
          categoryId: a$12 !== undefined ? a$12 : undefined,
          metadata: a$13 !== undefined ? serializeInputObjectInputUpdateProductMetaData(a$13) : undefined
        };
}

function serializeVariables(inp) {
  return {
          id: inp.id,
          input: serializeInputObjectInputUpdateProduct(inp.input)
        };
}

function makeVariables(id, input, param) {
  return {
          id: id,
          input: input
        };
}

function makeInputObjectInputUpdateProduct(name, designation, color, kind, family, producer, country, region, beerType, wineType, whiteWineType, internalNote, taxId, categoryId, metadata, param) {
  return {
          name: name,
          designation: designation,
          color: color,
          kind: kind,
          family: family,
          producer: producer,
          country: country,
          region: region,
          beerType: beerType,
          wineType: wineType,
          whiteWineType: whiteWineType,
          internalNote: internalNote,
          taxId: taxId,
          categoryId: categoryId,
          metadata: metadata
        };
}

function makeInputObjectInputUpdateProductMetaData(shopifyProductId, param) {
  return {
          shopifyProductId: shopifyProductId
        };
}

var UpdateProductMutation_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateProduct: serializeInputObjectInputUpdateProduct,
  serializeInputObjectInputUpdateProductMetaData: serializeInputObjectInputUpdateProductMetaData,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateProduct: makeInputObjectInputUpdateProduct,
  makeInputObjectInputUpdateProductMetaData: makeInputObjectInputUpdateProductMetaData
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var UpdateProductMutation_useWithVariables = include.useWithVariables;

var UpdateProductMutation = {
  UpdateProductMutation_inner: UpdateProductMutation_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectInputUpdateProduct: serializeInputObjectInputUpdateProduct,
  serializeInputObjectInputUpdateProductMetaData: serializeInputObjectInputUpdateProductMetaData,
  makeVariables: makeVariables,
  makeInputObjectInputUpdateProduct: makeInputObjectInputUpdateProduct,
  makeInputObjectInputUpdateProductMetaData: makeInputObjectInputUpdateProductMetaData,
  use: use,
  useWithVariables: UpdateProductMutation_useWithVariables
};

var Raw$1 = {};

var query$1 = Client.gql(["mutation ArchiveProductMutation($id: ID!)  {\narchiveProduct(id: $id)  {\n__typename  \nid  \nformattedStatus  \n}\n\n}\n"]);

function parse$1(value) {
  var value$1 = value.archiveProduct;
  var value$2 = value$1.formattedStatus;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var tmp$1;
    switch (value$2) {
      case "ACTIVE" :
          tmp$1 = "ACTIVE";
          break;
      case "ARCHIVED" :
          tmp$1 = "ARCHIVED";
          break;
      case "INACTIVE" :
          tmp$1 = "INACTIVE";
          break;
      default:
        tmp$1 = {
          NAME: "FutureAddedValue",
          VAL: value$2
        };
    }
    tmp = tmp$1;
  }
  return {
          archiveProduct: {
            __typename: value$1.__typename,
            id: value$1.id,
            formattedStatus: tmp
          }
        };
}

function serialize$1(value) {
  var value$1 = value.archiveProduct;
  var value$2 = value$1.formattedStatus;
  var formattedStatus = value$2 !== undefined ? (
      typeof value$2 === "object" ? value$2.VAL : (
          value$2 === "ARCHIVED" ? "ARCHIVED" : (
              value$2 === "ACTIVE" ? "ACTIVE" : "INACTIVE"
            )
        )
    ) : null;
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var archiveProduct = {
    __typename: value$4,
    id: value$3,
    formattedStatus: formattedStatus
  };
  return {
          archiveProduct: archiveProduct
        };
}

function serializeVariables$1(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$1(id, param) {
  return {
          id: id
        };
}

var ArchiveProductMutation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var ArchiveProductMutation_useWithVariables = include$1.useWithVariables;

var ArchiveProductMutation = {
  ArchiveProductMutation_inner: ArchiveProductMutation_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  use: use$1,
  useWithVariables: ArchiveProductMutation_useWithVariables
};

var Raw$2 = {};

var query$2 = Client.gql(["mutation UnarchiveProductMutation($id: ID!)  {\nunarchiveProduct(id: $id)  {\n__typename  \nid  \nformattedStatus  \n}\n\n}\n"]);

function parse$2(value) {
  var value$1 = value.unarchiveProduct;
  var value$2 = value$1.formattedStatus;
  var tmp;
  if (value$2 == null) {
    tmp = undefined;
  } else {
    var tmp$1;
    switch (value$2) {
      case "ACTIVE" :
          tmp$1 = "ACTIVE";
          break;
      case "ARCHIVED" :
          tmp$1 = "ARCHIVED";
          break;
      case "INACTIVE" :
          tmp$1 = "INACTIVE";
          break;
      default:
        tmp$1 = {
          NAME: "FutureAddedValue",
          VAL: value$2
        };
    }
    tmp = tmp$1;
  }
  return {
          unarchiveProduct: {
            __typename: value$1.__typename,
            id: value$1.id,
            formattedStatus: tmp
          }
        };
}

function serialize$2(value) {
  var value$1 = value.unarchiveProduct;
  var value$2 = value$1.formattedStatus;
  var formattedStatus = value$2 !== undefined ? (
      typeof value$2 === "object" ? value$2.VAL : (
          value$2 === "ARCHIVED" ? "ARCHIVED" : (
              value$2 === "ACTIVE" ? "ACTIVE" : "INACTIVE"
            )
        )
    ) : null;
  var value$3 = value$1.id;
  var value$4 = value$1.__typename;
  var unarchiveProduct = {
    __typename: value$4,
    id: value$3,
    formattedStatus: formattedStatus
  };
  return {
          unarchiveProduct: unarchiveProduct
        };
}

function serializeVariables$2(inp) {
  return {
          id: inp.id
        };
}

function makeVariables$2(id, param) {
  return {
          id: id
        };
}

var UnarchiveProductMutation_inner = {
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2
};

var include$2 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$2,
      Raw: Raw$2,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$2
    });

var use$2 = include$2.use;

var UnarchiveProductMutation_useWithVariables = include$2.useWithVariables;

var UnarchiveProductMutation = {
  UnarchiveProductMutation_inner: UnarchiveProductMutation_inner,
  Raw: Raw$2,
  query: query$2,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$2,
  makeVariables: makeVariables$2,
  use: use$2,
  useWithVariables: UnarchiveProductMutation_useWithVariables
};

function useUpdate(id) {
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.useCallback((function (param) {
                return function (state) {
                  var input = makeInputObjectInputUpdateProduct(undefined, undefined, undefined, undefined, undefined, undefined, Intl$Pos.t("France"), undefined, undefined, undefined, undefined, Caml_option.some(Scalar$Pos.$$Text.serialize(state.internalNote)), undefined, undefined, undefined, undefined);
                  return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                      id: id,
                                      input: input
                                    })), undefined, (function (param) {
                                return param.updateProduct.id;
                              }));
                };
              }), [id]);
}

function useArchive(id) {
  var match = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.useCallback((function (param) {
                return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                    id: id
                                  })), undefined, (function (param) {
                              return id;
                            }));
              }), [id]);
}

function useUnarchive(id) {
  var match = Curry.app(use$2, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var mutate = match[0];
  return React.useCallback((function (param) {
                return Future.mapOk(ApolloHelpers$Pos.mutationPromiseToFutureResult(Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, undefined, undefined, {
                                    id: id
                                  })), undefined, (function (param) {
                              return id;
                            }));
              }), [id]);
}

export {
  UpdateProductMutation ,
  ArchiveProductMutation ,
  UnarchiveProductMutation ,
  useUpdate ,
  useArchive ,
  useUnarchive ,
}
/* query Not a pure module */
