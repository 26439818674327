// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Future from "rescript-future/src/Future.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Request$Pos from "../core/Request.bs.js";
import * as FuturePromise from "rescript-future/src/FuturePromise.bs.js";

function queryResultToAsyncResult(queryResult) {
  var data = queryResult.data;
  var exit = 0;
  if (data !== undefined) {
    var data$1 = Caml_option.valFromOption(data);
    var exit$1 = 0;
    if (queryResult.error !== undefined) {
      exit$1 = 2;
    } else {
      if (!queryResult.loading) {
        return {
                TAG: /* Done */1,
                _0: {
                  TAG: /* Ok */0,
                  _0: data$1
                }
              };
      }
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (queryResult.loading) {
        var match = queryResult.networkStatus;
        if (match === 1) {
          return {
                  TAG: /* Reloading */0,
                  _0: {
                    TAG: /* Ok */0,
                    _0: data$1
                  }
                };
        }
        if (match === 0) {
          return {
                  TAG: /* Done */1,
                  _0: {
                    TAG: /* Ok */0,
                    _0: data$1
                  }
                };
        }
        exit = 1;
      } else {
        exit = 1;
      }
    }
    
  } else if (queryResult.error !== undefined) {
    exit = 1;
  } else {
    if (!queryResult.loading) {
      return /* NotAsked */0;
    }
    exit = 1;
  }
  if (exit === 1) {
    var match$1 = queryResult.previousData;
    var exit$2 = 0;
    if (match$1 !== undefined && match$1.TAG === /* Ok */0 && queryResult.loading) {
      var match$2 = queryResult.networkStatus;
      var data$2 = match$1._0;
      if (match$2 === 0) {
        return {
                TAG: /* Done */1,
                _0: {
                  TAG: /* Ok */0,
                  _0: data$2
                }
              };
      }
      if (match$2 < 3) {
        return {
                TAG: /* Reloading */0,
                _0: {
                  TAG: /* Ok */0,
                  _0: data$2
                }
              };
      }
      exit$2 = 2;
    } else {
      exit$2 = 2;
    }
    if (exit$2 === 2) {
      if (queryResult.loading) {
        return /* Loading */1;
      } else {
        return {
                TAG: /* Done */1,
                _0: {
                  TAG: /* Error */1,
                  _0: queryResult.error
                }
              };
      }
    }
    
  }
  
}

function useQueryResultToAsyncResult(queryResult, loading, onDone) {
  var asyncResult = queryResultToAsyncResult(queryResult);
  React.useEffect((function () {
          if (loading && typeof asyncResult !== "number" && asyncResult.TAG === /* Done */1) {
            Curry._1(onDone, undefined);
          }
          
        }), [asyncResult]);
  if (typeof asyncResult === "number" || !(asyncResult.TAG === /* Reloading */0 && loading)) {
    return asyncResult;
  } else {
    return /* Loading */1;
  }
}

function useQueryResultToAsyncResultWithVariablesReloading1(queryResult, variableA) {
  var loadingRef = React.useRef(true);
  React.useEffect((function () {
          loadingRef.current = true;
        }), [variableA]);
  return useQueryResultToAsyncResult(queryResult, loadingRef.current, (function (param) {
                loadingRef.current = false;
              }));
}

function useQueryResultToAsyncResultWithVariablesReloading2(queryResult, variableA, variableB) {
  var loadingRef = React.useRef(true);
  React.useEffect((function () {
          loadingRef.current = true;
        }), [
        variableA,
        variableB
      ]);
  return useQueryResultToAsyncResult(queryResult, loadingRef.current, (function (param) {
                loadingRef.current = false;
              }));
}

function useQueryResultToAsyncResultWithVariablesReloading3(queryResult, variableA, variableB, variableC) {
  var loadingRef = React.useRef(true);
  React.useEffect((function () {
          loadingRef.current = true;
        }), [
        variableA,
        variableB,
        variableC
      ]);
  return useQueryResultToAsyncResult(queryResult, loadingRef.current, (function (param) {
                loadingRef.current = false;
              }));
}

function mutationPromiseToFutureResult(response) {
  return Future.map(FuturePromise.fromPromise(response), undefined, (function (mutation) {
                if (mutation.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: Request$Pos.serverErrorMessage
                        };
                }
                var error = mutation._0;
                if (error.TAG === /* Ok */0) {
                  var match = error._0;
                  var error$1 = match.error;
                  if (error$1 !== undefined) {
                    return {
                            TAG: /* Error */1,
                            _0: error$1.message
                          };
                  } else {
                    return {
                            TAG: /* Ok */0,
                            _0: match.data
                          };
                  }
                }
                var error$2 = error._0;
                var match$1 = error$2.networkError;
                if (match$1 !== undefined && match$1.TAG === /* FetchFailure */0) {
                  return {
                          TAG: /* Error */1,
                          _0: Belt_Option.getExn(match$1._0.message)
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: error$2.message
                        };
                }
              }));
}

function mergeFutureResults(all) {
  return Future.map(Future.all(all), undefined, (function (results) {
                var match = Belt_Array.get(results, 0);
                var match$1 = Belt_Array.every(results, (function (result) {
                        if (result.TAG === /* Ok */0) {
                          return true;
                        } else {
                          return false;
                        }
                      }));
                if (match !== undefined && match.TAG === /* Ok */0 && match$1) {
                  return {
                          TAG: /* Ok */0,
                          _0: match._0
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Request$Pos.serverErrorMessage
                        };
                }
              }));
}

export {
  queryResultToAsyncResult ,
  useQueryResultToAsyncResultWithVariablesReloading1 ,
  useQueryResultToAsyncResultWithVariablesReloading2 ,
  useQueryResultToAsyncResultWithVariablesReloading3 ,
  mutationPromiseToFutureResult ,
  mergeFutureResults ,
}
/* react Not a pure module */
